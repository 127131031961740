import { BandApiConstants as types } from "../../../themes/apiConstants";

// all concert
export const getConcertDataAction = (params, values) => {
  const action = {
    type: types.BAND_API_FETCH_CONCERT_LOAD,
    params,
    values,
  };
  return action;
};

// today concert
export const todayConcertAction = () => {
  const action = {
    type: types.BAND_API_FETCH_TODAY_CONCERT_LOAD,
  };
  return action;
};

// past concert
export const pastConcertAction = () => {
  const action = {
    type: types.BAND_API_FETCH_PAST_CONCERT_LOAD,
  };
  return action;
};

// upcoming concert
export const upcomingConcertAction = () => {
  const action = {
    type: types.BAND_API_FETCH_UPCOMING_CONCERT_LOAD,
  };
  return action;
};


// upcoming concert
export const unapprovedConcertAction = (params,values) => {
  const action = {
    type: types.BAND_API_FETCH_UNAPPROVED_CONCERT_LOAD,
    params,
    values
  };
  return action;
};

// add concert
export const addBandConcertAction = (values) => {
  const action = {
    type: types.BAND_API_ADD_CONCERT_LOAD,
    values,
  };
  return action;
};

export const getConcertDataByIdAction = (id) => {
  const action = {
    type: types.BAND_API_GET_CONCERT_BY_ID_LOAD,
    id,
  };
  return action;
};

export const updateConcertAction = (id, values, history) => {
  const action = {
    type: types.BAND_API_EDIT_CONCERT_LOAD,
    id,
    values,
    history
  };
  return action;
};


export const editConcertAction = (id, values) => {
  const action = {
    type: types.BAND_API_EDIT_CONCERT_LOAD,
    id,
    values,
  };
  return action;
};

// export const videoLinkDeleteAction = (id, values) => {
//   const action = {
//     type: types.BAND_API_DELETE_CONCERT_LOAD,
//     id,
//     values,
//   };
//   return action;
// };