import { BandApiConstants as types } from "../../../themes/apiConstants";
//Login
export const loginAction = (value,history) => {
  console.log(value);
  const action = {
    type: types.BAND_API_LOGIN_LOAD,
    value,
    history
  };
  return action;
};

//Forgot password
export const bandForgotPasswordAction = (values,history) => {
  const action = {
    type: types.BAND_API_FORGOT_PASSWORD_LOAD,
    payload: {
      email: values.email,
      isBand: values.isBand,
    },
    history
  };
  return action;
};

// save register data
export const registerDataAction = (value,history) => {
  const action = {
    type: types.BAND_API_REGISTER_LOAD,
    value,
    history
  };
  return action;
};

//Reset password
export const bandChangePasswordAction = (values,history) => {
  const action = {
    type: types.BAND_API_CHANGE_PASSWORD_LOAD,
    values,
    history
  };
  return action;
};

// add checkbox
export const registrationCheckboxAction = (value) => {
  const action = {
    type: types.API_ADD_BAND_REGISTRATION_CHECKBOX,
    value,
  };
  return action;
};

// clear checkbox
export const clearRegistrationCheckboxAction = () => {
  const action = {
    type: types.EMPTY_BAND_REGISTRATION_CHECKBOX_STATE,
  };
  return action;
};


// band verify email
export const verifyEmailAction = (values) => {
  const action = {
    type: types.BAND_API_VERIFY_EMAIL_LOAD,
    values
  };
  return action;
};

