import React, { useState, useEffect, useRef } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import TextInput from "../../../customComponents/InputField";
import { genreJson } from "../../../mocks/genreJson";
import Button from "../../../customComponents/Button";
import AppConstant from "../../../themes/appConstant";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import * as Yup from "yup";
import { Formik } from "formik";
import "./trailer.css";
import {
  addTrailerAction,
  emptyCheckboxState,
  getGenreList,
  getVideoDetailsById,
  updateTrailer,
} from "../../../store/actions/admin";
import { toast } from "react-toastify";
import { ValidationConstants } from "../../../themes/validationConstants";
import AppColors from "../../../themes/appColors";
import { useHistory } from "react-router";

const breadData = [
  {
    name: "Trailer Video",
    route: "/trailerVideo",
  },
  {
    name: "Trailer Details",
    route: "",
  },
];

const UpdateTrailer = () => {
  const dispatch = useDispatch();
  const formRef = useRef()
  const { onLoad, trailerDetails } = useSelector((state) => state.trailerReducer);
  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const [pickerGenre, setPickerGenre] = useState([]);
  const [genre, setGenre] = useState(null)
  const { Option } = Select;
const history = useHistory()
  const { appState } = useSelector((state) => state);
  const classes = useStyles();



  const values = {
    videoLink: "",
    videoTitle:""
  };




  const onSubmit = (values) => {
    const formData = new FormData();
    console.log("values",values,pickerGenre)
    if (values.videoLink === "") {
      toast.error(ValidationConstants.addVideoLink);
      return;
    } else if (pickerGenre.length <= 0) {
      toast.error(ValidationConstants.selectGenre);
      return;
    } else {

   let data = {
   video : values.videoLink,
   title: values.videoTitle,
   trailerId : history?.location?.state?.id,
   genre  : pickerGenre
   }


      // formData.append("video", values.videoLink);
      // formData.append("title", values.videoTitle);
      // formData.append("trailerId",history?.location?.state?.id );
      // formData.append("genre",pickerGenre);
      dispatch(updateTrailer(data));
    }
  };

  const validationSchema = Yup.object().shape({
    videoLink: Yup.string()
      .min(5, "Please enter a valid video link.")
      .trim()
      .required(ValidationConstants.mainVideoRequired)
      .matches(
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Entered YouTube URL is invalid, please enter valid URL."
      ),
      videoTitle : Yup.string().trim().min("3", "Title should be at least 3 characters long.").required("Please enter video title.").matches(/^[a-zA-Z0-9_ .-]*$/, "Please enter alpha numeric values only.")

  });

  const pickerHandleChange = (val) => {
    console.log("val",val);
    setPickerGenre(val);
    disableInput()
  };


 const disableInput = () => {
  const selects = document.getElementsByClassName(`ant-select-search__field`)
  for (let el of selects) {
    el.setAttribute(`maxlength`, 0)
  }
  }

  const disableType = (e) => {
    // e.replace(e,"")
    document.getElementById("stopType").readOnly = true;
    document.getElementById("stopType").maxLength = 0
  }

  useEffect(() => {
    document.title = AppConstant.VirtualConcertTrailerDetails;
    dispatch(getVideoDetailsById({ trailerId : history?.location?.state?.id}));
    dispatch(getGenreList())
    disableType()
  
  }, []);


  useEffect(() => {

    if (formRef.current) {
      formRef.current.setFieldValue("videoLink", trailerDetails?.video);
      formRef.current.setFieldValue("videoTitle", trailerDetails?.title);
    }
    setPickerGenre(trailerDetails?.genre?.map((item) => item?._id));

  
  }, [trailerDetails]);


  const { sidebarDrawerToggleState } = appState;
   console.log("trailerDetails",trailerDetails)
  return (
    <>
      <SideNav />
      <Header data={breadData} title={AppConstant.TrailerVideo} />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm checkbox-min-width">
            <NavCard title="Edit Trailer Details" noSearch>
              <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                innerRef={formRef}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div
                      className="user-Details-Box"
                      style={{ marginTop: 100 }}
                    >


                    <div className="row bottom-margin addTrailerVideoStyle">
                        <div className="col-sm">
                          <TextInput
                            id="mainVideoTitle"
                            placeholder="Title"
                            error={Boolean(
                              touched.videoTitle && errors.videoTitle
                            )}
                            helperText={touched.videoTitle && errors.videoTitle}
                            name="videoTitle"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.videoTitle?.trimLeft()}
                            inputProps={{
                              maxLength: 35,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="row bottom-margin addTrailerVideoStyle">
                        <div className="col-sm">
                          <TextInput
                            id="mainVideo"
                            placeholder="Trailer Video Link"
                            error={Boolean(
                              touched.videoLink && errors.videoLink
                            )}
                            helperText={touched.videoLink && errors.videoLink}
                            name="videoLink"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.videoLink?.trimLeft()}
                            inputProps={{
                              maxLength: 250,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div style={{ marginTop: 30 }}>
                        <div className="">
                          <span className="span-title-style">Genre(s)</span>
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Select
                           searchProps={{ maxLength:5 }} 
                           id="stopType"
                            mode="multiple"
                           onSearch={(e) => {
                            disableType(e)
                           }}
                            autoClearSearchValue
                            allowClear
                            name="genre"
                            style={{ width: "100%",caretColor:'white' }}
                            placeholder="Select Genre"
                            value={pickerGenre}
                            onChange={pickerHandleChange}
                          >
                            {genreListData?.map((item, index) => (
                              <Option key={item?._id}>{item?.genreName}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div
                        className="add-button"
                        style={{ marginTop: 40, marginLeft: 10 }}
                      >
                        <Button
                          title={"Update"}
                          disabled={onLoad}
                          wd
                          bg
                          mb
                          type="submit"
                          onClick={() => {}}
                          style={{
                            backgroundColor: onLoad && AppColors.mainColor,
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateTrailer;

