import { takeLatest, call, put, delay } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import history from "../../../utils/history";
import { toast } from "react-toastify";

function* concertFailedSaga(result) {
  yield put({
    type: types.API_CONCERT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* concertErrorSaga(result) {
  toast.error(result?.error);
  yield put({
    type: types.API_CONCERT_ERROR,
  });
}

export function* approvedConcertSaga(action) {
  const { sortBy, order, offset, limit, search } = action.params;
  try {
    const result = yield call(api.approvedConcert, {
      sortBy,
      order,
      offset,
      limit,
      search,
    });

    if (result.status === 1) {
      yield put({
        type: types.API_GET_APPROVED_CONCERT_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);
      history.push("/dashboard");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({
      //   type: types.API_GET_APPROVED_CONCERT_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
    concertErrorSaga(e);
  }
}

export function* approvedConcertPaymentStatusSaga(action) {
  try {
    const result = yield call(api.approvedConcertPaymentStatus, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      // toast.error(result.error.response.data.message);
      // history.push("/dashboard");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({
      //   type: types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
    // yield put({
    //   type: types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_ERROR,
    // });
    concertErrorSaga(e);
  }
}

export function* approvedConcertUserListSaga(action) {
  console.log("buyer list params data in saga ==> ", action);

  try {
    const result = yield call(api.approvedConcertBuyersList, action);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_APPROVED_CONCERT_BUYERS_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      // toast.error(result.error.response.data.message);
      // history.push("/dashboard");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      concertFailedSaga(result);
      // yield put({
      //   type: types.API_GET_APPROVED_CONCERT_BUYERS_LIST_FAILED,
      // });
    }
  } catch (e) {
    // yield put({
    //   type: types.API_GET_APPROVED_CONCERT_BUYERS_LIST_ERROR,
    // });
    concertErrorSaga(e);
  }
}

export function* unApprovedConcertSaga(action) {
  const { sortBy, order, offset, limit, search } = action.params;
  try {
    const result = yield call(api.unApprovedConcert, {
      sortBy,
      order,
      offset,
      limit,
      search,
    });

    if (result.status === 1) {
      yield put({
        type: types.API_GET_UNAPPROVED_CONCERT_SUCCESS,
        result: result.result.data,
      });
    } else {
      // yield put({
      //   type: types.API_GET_UNAPPROVED_CONCERT_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
    // yield put({
    //   type: types.API_GET_UNAPPROVED_CONCERT_ERROR,
    // });
    concertErrorSaga(e);
  }
}

//fetch concert by id

export function* deleteConcertYoutubeVideo(action) {
  try {
    const id = action.payload.concertId;
    // let result;
    const result = yield call(api.deleteConcertVideo, action.payload);

    if (result.status === 1) {
      yield put({
        type: types.API_DELETE_YOUTUBE_VIDEO_SUCCESS,
        result:result.result,
      });
      yield put({
        type: types.API_GET_CONCERT_BY_ID_LOAD,
        id
      });
    } else {
      // yield put({
      //   type: types.API_GET_CONCERT_BY_ID_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
    console.log("eeeee", e);
    toast.error(e?.error, { toastId: e?.status || "est" });
    concertErrorSaga(e);
    // yield put({
    //   type: types.API_GET_CONCERT_BY_ID_ERROR,
    // });
  }
}
export function* getConcertByIdSaga(action) {
  try {
    const result = yield call(api.getConcertById, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_CONCERT_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      // yield put({
      //   type: types.API_GET_CONCERT_BY_ID_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
   
    // yield put({
    //   type: types.API_GET_CONCERT_BY_ID_ERROR,
    // });
    concertErrorSaga(e);
  }
}

//change concert status

export function* changeConcertStatusSaga(action) {
  try {
    const result = yield call(api.changeConcertStatus, action.values);
    console.log("result in saga", result);

    if (result.status === 1) {
      toast.success(result?.result.data.message, {
        toastId: result?.status || "est",
      });

      yield delay(1500);

      yield put({
        type: types.API_UNAPPROVED_CONCERT_APPROVE_SUCCESS,
        result: result.result.data.data,
      });

      if (action.shouldNavigate) {
        history.push("/unapprovedConcert");
      }
    } else {
      // yield put({
      //   type: types.API_UNAPPROVED_CONCERT_APPROVE_FAILED,
      // });
      concertFailedSaga(result);
    }
  } catch (e) {
    // yield put({
    //   type: types.API_UNAPPROVED_CONCERT_APPROVE_ERROR,
    // });
    concertErrorSaga(e);
  }
}

//add to feature

export function* addToFEaturedConcertSaga(action) {
  try {
    const result = yield call(api.addFeaturedConcert, action.concertId);
    console.log("result", result);
    if (result.status === 1) {
      yield put({
        type: types.API_ADD_TO_FEATURED_CONCERTS_SUCCESS,
        result: result.result.data.data,
      });
      yield put({
        type: "REHIT",
        value: true,
      });
    } else {
      // yield put({
      //   type: types.API_GET_CONCERT_BY_ID_FAILED,
      // });
      console.log("result failed", result);
      // concertFailedSaga(result);
    }
  } catch (e) {
    // yield put({
    //   type: types.API_GET_CONCERT_BY_ID_ERROR,
    // });
    console.log("result error", e);
    // concertErrorSaga(e);
  }
}

export default function* rootConcertSaga() {
  yield takeLatest(types.API_GET_APPROVED_CONCERT_LOAD, approvedConcertSaga);
  yield takeLatest(
    types.API_GET_UNAPPROVED_CONCERT_LOAD,
    unApprovedConcertSaga
  );
  yield takeLatest(types.API_GET_CONCERT_BY_ID_LOAD, getConcertByIdSaga);
  yield takeLatest(
    types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_LOAD,
    approvedConcertPaymentStatusSaga
  );
  yield takeLatest(
    types.API_GET_APPROVED_CONCERT_BUYERS_LIST_LOAD,
    approvedConcertUserListSaga
  );
  yield takeLatest(
    types.API_UNAPPROVED_CONCERT_APPROVE_LOAD,
    changeConcertStatusSaga
  );

  yield takeLatest(
    types.API_ADD_TO_FEATURED_CONCERTS_LOAD,
    addToFEaturedConcertSaga
  );
  yield takeLatest(
    types.API_DELETE_YOUTUBE_VIDEO_LOAD,
    deleteConcertYoutubeVideo
  );
}
