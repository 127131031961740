import { BandApiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  bandConcertEarningList: null,
  filteredBandList: null,
  bandConcertListCurrentPage: 1,
  bandConcertListTotalCount: 0,
};

//band concert earning
export const bandConcertEarningReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_BAND_CONCERT_EARNING_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_BAND_CONCERT_EARNING_LIST_SUCCESS:
      console.log("Band Concert Earning hits");
      state.bandConcertEarningList = action.result.data;
      state.bandConcertListCurrentPage = action.result.pagination.currentPage;
      state.bandConcertListTotalCount = action.result.pagination.totalCount;
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_BAND_CONCERT_EARNING_LIST_FAILED:
      state.onLoad = false;
      return state;

    case types.API_GET_BAND_CONCERT_EARNING_LIST_ERROR:
      state.onLoad = false;
      return state;

    default:
      return state;
  }
};
