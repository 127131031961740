import React, { useEffect } from "react";
import TextInput from "../../../customComponents/InputField";
import { useHistory } from "react-router-dom";
import Button from "../../../customComponents/Button";
import AppImages from "../../../themes/appImages";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import "../componentStyle.css";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../../store/actions/admin";

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adminAuthState = useSelector(
    (state) => state.adminAuthenticationReducer
  );

  useEffect(() => {
    document.title = AppConstant.VirtualConcertLogin;
    window.history.pushState(null, null, null);
  });

  const values = {
    email: "",
    password: "",
  };

  const handleKeypress = (e, handleSubmit) => {
    if (e.charCode === 13) {
      handleSubmit();
    }
  };
  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
    password: Yup.string()
      .required(ValidationConstants.password.empty)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),
  });

  const onSubmit = (values) => {
    dispatch(loginAction(values.email, values.password));
  };

  return (
    <div className="body-background">
      <div className="body-Style">
        <img
          src={AppImages.LogoImage}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          className="login-Logo-Image"
        />
        <span className="login-style">{AppConstant.AdminLogin}</span>
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextInput
                placeholder={AppConstant.emailAddress}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                fullWidth
                style={{
                  marginTop: 8,
                }}
              />
              <TextInput
                placeholder={AppConstant.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password.trim()}
                fullWidth
                style={{
                  marginTop: 8,
                }}
                onCopy={stopCopyPaste}
                onPaste={stopCopyPaste}
                onKeyPress={(e) => {
                  handleKeypress(e, handleSubmit);
                }}
              />
              <div
                className="forgot-password-div forgot-Password-Style-button pointer-Style"
                onClick={() => {
                  history.push("/forgotPassword");
                }}
              >
                {AppConstant.ForgotPassword}
              </div>
              <div className="buttonWrapper">
                <Button title={AppConstant.Login} type="submit" bg />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
