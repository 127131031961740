import { apiConstants as types } from "../../../themes/apiConstants";

//fetch list help
export const getListHelpAction = (values) => {
  const action = {
    type: types.API_FETCH_LIST_HELP_LOAD,
    values,
  };
  return action;
};

// send reply to list help
export const sendReplyAction = (values) => {
  const action = {
    type: types.API_SEND_REPLY_TO_LIST_HELP_LOAD,
    values,
  };
  return action;
};

// FETCH REPLIED LIST
export const getRepliedFaqAction = (values) => {
  const action = {
    type: types.API_FETCH_REPLIED_LIST_LOAD,
    values,
  };
  return action;
};
