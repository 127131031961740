import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import AppColors from "../themes/appColors";
import "./customComponentStyle.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
    secondary: {
      main: AppColors.lightGrey,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      underline: "red",
    },
  },
  cssLabel: {
    color: AppColors.labelColor,
    "&.Mui-focused": {
      color: AppColors.labelColor,
    },
    fontSize: 14,
    fontWeight: 600,
  },
  formControl: {
    margin: 0,
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectInput = (props) => {
  const classes = useStyles();
  const [gender, setGender] = React.useState(props.value);

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="age-native-simple">{props.title}</InputLabel>
        <Select
          native
          defaultValue={props.default}
          className={classes.root}
          value={props.value}
          onChange={props.onChange}
          inputProps={{
            name: "data",
            id: "age-native-simple",
         
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
            // shrink: !!props.value
            shrink:props?.value?true:false
          }}
        >


{
 
 props.forGender ? (
 
  props.options.map((item) => {
    return (
      <option className="select-padding-left" value={item}>
        &nbsp;&nbsp;{item}
      </option>
    );
  })

 ) : ( 

  props?.options?.map((item) => {
    return (
      <option className="select-padding-left" value={item?._id}>
        &nbsp;&nbsp;{item?.genreName}
      </option>
    );
  })

 )

}

        
        </Select>
      </FormControl>
      {/* </ThemeProvider> */}
    </>
  );
};

export default SelectInput;
