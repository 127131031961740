import { takeLatest, call, put, delay } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import { toast } from "react-toastify";
import history from "../../../utils/history";
import cl from "../../../utils/cl";

function* bandConcertFailedSaga(result) {
  cl("authentication failed", result);
  yield put({
    type: types.BAND_API_CONCERT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* bandConcertErrorSaga(result) {
  cl("HAHAHAHAA you got error  ", result);
  yield put({
    type: types.BAND_API_CONCERT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* fetchConcertDataSaga(action) {
  try {
    const result = yield call(api.fecthConcertData, action);
    if (result.status === 1) {
      console.log("result in concert saga ===> ", result);

      yield put({
        type: types.BAND_API_FETCH_CONCERT_SUCCESS,
        result: result.result.data,
      });
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}

export function* fetchConcertDataByIdSaga(action) {
  try {
    const result = yield call(api.fecthConcertDataById, action.id);
    if (result.status === 1) {
      console.log("result in concert saga ===> ", result);

      yield put({
        type: types.BAND_API_GET_CONCERT_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}

export function* todayConcertSaga() {
  try {
    const result = yield call(api.todayConcert);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_FETCH_TODAY_CONCERT_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}

export function* pastConcertSaga() {
  try {
    const result = yield call(api.pastConcert);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_FETCH_PAST_CONCERT_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}

export function* upcomingConcertSaga() {
  try {
    const result = yield call(api.upcomingConcert);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_FETCH_UPCOMING_CONCERT_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}


export function* unapprovedConcertSaga(action) {
  try {
    const result = yield call(api.unapprovedConcert,action);


    if (result.status === 1) {
    console.log("loop" , result)

      yield put({
        type: types.BAND_API_FETCH_UNAPPROVED_CONCERT_SUCCESS,
        result: result.result.data.data,
      });

    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}



export function* addConcertSaga(action) {
  try {
    const result = yield call(api.addBandConcert, action.values);
    if (result.status === 1) {
      toast.success(result.result.data.message, {
        toastId: result?.status || "est",
      });
      yield delay(1500);
      yield put({
        type: types.BAND_API_ADD_CONCERT_SUCCESS,
        result: result.result.data.data,
      });

      history.goBack();
    } else {
      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {
    yield call(bandConcertErrorSaga, error);
  }
}

export function* editConcertSaga(action) {
  try {
    const result = yield call(api.editBandConcert, action);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_EDIT_CONCERT_SUCCESS,
        result: result.result.data.data,
      });
     
      toast.success(result.result.data.message, {
        toastId: result?.status || "est",
      });
      history.goBack();
    } else if(result.status === 4){
      toast.success(result.result.data.message, {
        toastId: result?.status || "est",
      });
      action.history.push("/band/allConcerts")
      yield put({type: "HANDLE_CONCERTS_TAB_STATE" , index:0})
    } else {


      yield call(bandConcertFailedSaga, result);
    }
  } catch (error) {

    yield call(bandConcertErrorSaga, error);
  }
}

export default function* rootBandConcertSaga() {
  yield takeLatest(types.BAND_API_FETCH_CONCERT_LOAD, fetchConcertDataSaga);
  yield takeLatest(types.BAND_API_FETCH_TODAY_CONCERT_LOAD, todayConcertSaga);
  yield takeLatest(types.BAND_API_FETCH_PAST_CONCERT_LOAD, pastConcertSaga);
  yield takeLatest(
    types.BAND_API_FETCH_UPCOMING_CONCERT_LOAD,
    upcomingConcertSaga
  );
  yield takeLatest(types.BAND_API_ADD_CONCERT_LOAD, addConcertSaga);
  yield takeLatest(
    types.BAND_API_GET_CONCERT_BY_ID_LOAD,
    fetchConcertDataByIdSaga
  );
  yield takeLatest(types.BAND_API_EDIT_CONCERT_LOAD, editConcertSaga);
  yield takeLatest(types.BAND_API_FETCH_UNAPPROVED_CONCERT_LOAD, unapprovedConcertSaga);


  
}


