import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
// import PrivateRoute from "./protectedRoute";
import ForgotPasswordReset from "../Admin/components/authentication/forgotPasswordReset";
import BandForgotPasswordSuccess from "../Band/components/authentication/forgotPasswordSuccess";
import { routeConfig } from "./routeConfig";
import PrivateRoute from "./protectedRoute";

function BandSetup() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          // component={SignIn}
          render={() =>
            localStorage.bandToken ? (
              <Redirect to="/band/dashboard" />
            ) : (
              <Redirect to="/band/login" />
            )
          }
        />
        <Route
          exact
          path="/login"
          // component={SignIn}
          render={() =>
            localStorage.bandToken ? (
              <Redirect to="/band/dashboard" />
            ) : (
              <Redirect to="/band/login" />
            )
          }
        />
        <Route
          path="/band/forgotPasswordSuccess"
          render={() => <BandForgotPasswordSuccess />}
        />

        <Route
          path="/forgotPasswordReset"
          render={() => <ForgotPasswordReset />}
        />

        {routeConfig.map((route, i) => (
          <PrivateRoute
            exact={route.exact}
            key={route}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default BandSetup;
