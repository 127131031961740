import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  productSaleData: null,
  rehit: false,
  paginationData: null,

  //   trailerDataTotalCount: 0,
  //   trailerDataCurrentPage: 1,
  //   trailerDataNextPage: 0,
  //   onDeleteTrailerReducer: false,
  //   checkboxErrorMessage: "",
};

export const ProductSaleReducer = (state = initialState, action) => {
  switch (action.type) {
    //FTECH LIST HELP
    case types.API_GET_PRODUCT_SALE_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_PRODUCT_SALE_LIST_SUCCESS:
      //   state.trailerDataTotalCount = action.result.pagination.totalCount;
      //   state.trailerDataCurrentPage = action.result.pagination.currentPage;

      return {
        ...state,
        productSaleData: action.result.data,
        paginationData: action.result.pagination,
        onLoad: false,
      };

    // change reply modal state
    // case "CAHNGE_REPLY_MODAL_STATE":
    //   return { ...state, replyModalState: action.value };

    case "REHIT_LIST":
      return { ...state, rehit: action.value };

    default:
      return state;
  }
};
