import { takeLatest, call, put, delay } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import history from "../../../utils/history";
import { toast } from "react-toastify";
import * as api from "../../Api";

function* bandEarningManagementFailedSaga(result) {
  yield put({
    type: types.API_GET_FILTERED_BAND_LIST_SUCCESS,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* bandEarningManagementErrorSaga(result) {
  yield put({
    type: types.API_GET_BAND_MANAGEMENT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* bandConcertEarningListSaga(action) {
  try {
    const result = yield call(api.bandConcertEarningList, action.params);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_BAND_CONCERT_EARNING_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      // toast.error(result.error.response.data.message);
      // history.push("/");
    } else {
      yield put({
        type: types.API_GET_BAND_CONCERT_EARNING_LIST_FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: types.API_GET_BAND_CONCERT_EARNING_LIST_ERROR,
    });
  }
}

export default function* rootBandEarningManagementSaga() {
  yield takeLatest(
    types.API_GET_BAND_CONCERT_EARNING_LIST_LOAD,
    bandConcertEarningListSaga
  );
}
