import { apiConstants as types } from "../../../themes/apiConstants";
import Types from "../../actionTypes/index";
const initialState = {
  onLoad: false,
  error: null,
  userData: [],
  userById: {
    emailId: "",
    gender: null,
    profileImage: null,
    username: "",
  },
  userDataTotalCount: 0,
  userDataCurrentPage: 1,
  userDataNextPage: 0,
  onChangeStatus: false,
  inProgress: false,
  userPredefinedImagesArray: null,
  genreListData: null,
  rehit:false
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH ALLL USERS DATA
    case types.API_GET_USER_MANAGEMENT_LOAD:
      return { ...state, onLoad: true };
    case types.API_GET_USER_MANAGEMENT_SUCCESS:
      state.userData = action.result.data;
      state.userDataTotalCount = action.result.pagination.totalCount;
      state.userDataCurrentPage = action.result.pagination.currentPage;
      return { ...state, onLoad: false };

    //UPDATE USER BY ID
    case types.API_UPDATE_USER_BY_ID_LOAD:
      return { ...state, onLoad: true };
    case types.API_UPDATE_USER_BY_ID_SUCCESS:
      return { ...state, onLoad: false };

    //BLOCK USER BY ID
    case types.API_CHANGE_USER_STATUS_LOAD:
      return {
        ...state,
        onLoad: false,
        onChangeStatus: true,
        inProgress: true,
      };
    case types.API_CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        onLoad: false,
        onChangeStatus: false,
        inProgress: false,
      };

    //FETCH USER BY ID
    case types.API_GET_USER_BY_ID_LOAD:
      return { ...state, onLoad: true };
    case types.API_GET_USER_BY_ID_SUCCESS:
      console.log("====================================");
      console.log(state, action.result);
      console.log("====================================");
      return { ...state, userById: action.result, onLoad: false };

    case types.CLEAR_REDUCER:
      state.userById.emailId = "";
      state.userById.username = "";
      state.userById.profileImage = null;
      state.userById.gender = null;

      return { ...state };

    // fetch users predefined images array
    case types.API_GET_USER_PREDEFINED_IMAGES_LOAD:
      return { ...state, onLoad: true };
    case types.API_GET_USER_PREDEFINED_IMAGES_SUCCESS:
      state.userPredefinedImagesArray = action.result;
      state.onLoad = false;
      return {
        ...state,
      };

    // add rocks to user
    case types.API_ADD_ROCKS_TO_USER_LOAD:
      return { ...state, onLoad: true };
    case types.API_ADD_ROCKS_TO_USER_SUCCESS:
      // state.userPredefinedImagesArray = action.result;
      state.onLoad = false;
      console.log("State in add rocks reducer", state);
      return {
        ...state,
      };

   case "REHIT":
  return {...state, rehit:action.value}

    // get genre lsit
    case Types.GET_GENRE_LIST_LOAD:
      return { ...state, onLoad: true };
    case Types.GET_GENRE_LIST_SUCCESS:
      state.genreListData = action.result;
      state.onLoad = false;
      return {
        ...state,
      };

    //FAILED CASES
    case types.API_GET_USER_MANAGEMENT_FAILED:
      return { ...state, onLoad: false };

    case types.API_GET_USER_MANAGEMENT_ERROR:
      return { ...state, onLoad: false };

    default:
      return state;
  }
};
