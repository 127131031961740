import React, { useEffect, useState } from "react";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import BandSideNav from "../../../customComponents/BandSideNav";
import NavCard from "../../../customComponents/NavCard";
import Button from "../../../customComponents/Button";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import Loader from "../../../customComponents/loader";
import "../../../Admin/components/componentStyle.css";
import "./profile.css";
import { getMediaUrl } from "../../../utils/helpers";
import { getProfileAction } from "../../../store/actions/Band/profileAction";

const Profiledetail = ({ data, onLoad }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();

  // function for opening the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // function for closing the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // function for getting image url
  const getProfileImage = () => {
    return data?.bandLogo !== ""
      ? getMediaUrl(data?.bandLogo)
      : AppImages.BandDummyImage;
  };

  return (
    <div className="user-Details-Box">
      <div className="row detail-height band-padding">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.bandLogo}</span>
        </div>
        <div className="col-sm center-style-items">




        {window.navigator.onLine ? 
        
        <img
        style={{
          cursor: "pointer",
        }}
        src={getProfileImage()}
        alt=""
        className="bandImage-Style"
        onClick={showModal}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = AppImages.noImage;
        }}
      />
          :
          
          <img
          style={{
            cursor: "pointer",
            display:"none"
          }}
          src={null}
          alt=""
          className="bandImage-Style"
          onClick={showModal}
          // onError={(e) => {
          //   e.target.onerror = null;
          //   e.target.src = AppImages.noImage;
          // }}
        />
          
          
          }


       
        </div>
      </div>

      {/* modal to view image */}
      <Modal visible={isModalVisible} onCancel={handleCancel} width={350}>
        <div className="image-modal ">
          
          <img
            src={getProfileImage()}
            alt=""
            className="bandImage-Style-Modal"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
          />
        </div>
      </Modal>

      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.bandName}</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data?.bandName || "N/A"}</span>
        </div>
      </div>

      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.contactName}</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data?.contactName || "N/A"}</span>
        </div>
      </div>

      <div className="row profile-genre-style">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.genre}</span>
        </div>
        <div className="col-sm center-style-items genre-column-style">
          {data?.type?.map((item,index) => {
if(index === 0){
  return  item?.genreName;
}else{
  return ", " + item?.genreName 
}

            
          } )}
        </div>
      </div>

      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.paypalId}</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data?.paypalId || "N/A"}</span>
        </div>
      </div>

      <div className="row detail-height3">
        <div className="col-sm center-style-items">
          <span className="span-title-style">{AppConstant.emailAddress}</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data?.bandEmail || "N/A"}</span>
        </div>
      </div>

      <div className="user-update-button">
        <Button
          title={AppConstant.edit}
          bg
          wd
          mt20
          onClick={() =>
            history.push("/band/editProfile", {
              profileData: {
                email: "test@test.com",
                contactName: "john",
                bandName: "Punjabi Band",
              },
              image: AppImages.BandDummyImage,
            })
          }
        />
      </div>
    </div>
  );
};

export const Profile = () => {
  const breadData = [
    {
      name: "My Profile",
      route: "/band/profile",
    },
  ];
  const { profileData, onLoad } = useSelector(
    (state) => state.bandProfileReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const { appState } = useSelector((state) => state);

  const { sidebarDrawerToggleState } = appState;
  useEffect(() => {
    document.title = AppConstant.virtualConcertMyProfile;
    dispatch(getProfileAction());
  }, []);

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.BandManagement} />
      <BandSideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="My Profile" noSearch>
              <Profiledetail data={profileData} onLaod={onLoad} />
            </NavCard>
          </div>
          <Loader visible={onLoad} />
        </div>
      </div>
    </>
  );
};

export default Profile;
