const AppConstant = {
  Login: "Login",
  AdminLogin: "Admin Login",
  Submit: "Submit",
  Send: "Send",
  otp: "OTP",
  email: "Email",
  redeemMoney: "Are you sure you want admin to redeem your money?",
  paypalId: "Paypal ID",
  resetLink: "Your reset password link is expired.",
  successfullyResetPassword: "Your password has been reset successfully",
  ForgotPassword: "Forgotten password?",
  ChangePassword: "Change Password",
  password: "Password",
  newPassword: "NEW Password",
  confirmNewPassword: "Confirm New Password",
  confirmPassword: "Confirm Password",
  ForgotPasswordEmail: "Forgotten Password",
  bandForgotPasswordEmail: "Forgot Password",
  AddTrailerVideo: "Add Trailer Video",
  Add: "Add",
  UnapprovedConcert: "Unapproved Concerts",
  ApprovedConcert: "Approved Concerts",
  AddNews: "Add News",
  Update: "Update",
  BandLogo: "Band Logo",
  Users: "Users",
  Dashboard: "Dashboard",
  UserManagement: "User Management",
  BandManagement: "Band Management",
  ConcertManagement: "Concert Management",
  featured: "Featured Concerts",
  shop: "Shop Transactions",
  TrailerVideo: "TrailerVideo",
  NewsManagement: "News Management",
  VirtualConcert: "CONCERTO-XL",
  VirtualConcertLogin: "CONCERTO-XL | Login",
  VirtualConcertForgotPassword: "CONCERTO-XL | Forgot Password",
  VirtualConcertChangePassword: "CONCERTO-XL | Change Password",

  VirtualConcertBandSupport: "CONCERTO-XL | Support",

  virtualConcertConcertManagement: "CONCERTO-XL | Concert Management",
  virtualConcertEarning: "CONCERTO-XL | Concert Earning",
  virtualConcertPaymentRequest: "CONCERTO-XL | Payment Request",
  virtualConcertConcertDetails: "CONCERTO-XL | Concert Details",
  virtualConcertConcertUser: "CONCERTO-XL | Users",
  virtualConcertBandManagement: "CONCERTO-XL | Band Management",
  virtualConcertBandDetails: "CONCERTO-XL| Band Details",
  virtualConcertEditBand: "CONCERTO-XL | Edit Band",
  virtualConcertDashboard: "CONCERTO-XL| Dashboard",
  VirtualConcertNewsManagement: "CONCERTO-XL | News Management",
  VirtualConcertNewsDetails: "CONCERTO-XL | News Details",
  VirtualConcertEditNews: "CONCERTO-XL| Edit News",
  VirtualConcertAddNews: "CONCERTO-XL | Add News",
  VirtualConcertTrailerVideo: "CONCERTO-XL | Trailer Management",
  VirtualConcertAddTrailerVideo: "CONCERTO-XL | Add Trailer Video",
  VirtualConcertTrailerDetails: "CONCERTO-XL | Trailer Details",

  VirtualConcertUserManagement: "CONCERTO-XL | User Management",
  VirtualConcertUserDetail: "CONCERTO-XL| User Details",
  VirtualConcertEditUserDetail: "CONCERTO-XL | Edit User ",

  // Band App Constant

  oldPassword: "Old Password",
  newPassword: "New Password",
  bandLogin: "Band Login",
  Registration: "Virtual Concert | Registration",
  register: " Register",
  dontHaveAccount: "Don't have an account? ",
  edit: "Edit",
  ok: "OK",
  contactName: "Contact Name",
  genre: "Genre(s)",
  emailAddress: "Email Address",
  bandName: "Band Name",
  bandLogo: "Band Logo",
  question: "Question",
  answer: "Answer",
  ques: "How can I install/upgrade Dummy Content?",
  AddConcert: "Add Concert",
  askAnyQues: "Ask Any Question",
  acceptTermsAndCoditions: "I accept terms and conditions.",
  termsAndCondition: "Terms & Conditions",
  virtualConcertFaq: "CONCERTO-XL | FAQ",
  virtualConcertMyProfile: "CONCERTO-XL | My Profile",
  virtualConcertAddConcert: "CONCERTO-XL | Add Concert",
  virtualConcertViewConcert: "CONCERTO-XL | View Concert",
  virtualConcertEditConcert: "CONCERTO-XL | Edit Concert",
  virtualConcertEditProfile: "CONCERTO-XL | Edit Profile",
  virtualConcertTermsAndConditions: "CONCERTO-XL | Terms & Conditions",
  virtualConcertBandAllConcert: "CONCERTO-XL | Concerts",
  virtualConcertFeatured: "CONCERTO-XL | Featured Concerts",
  virtualConcertSupport: "CONCERTO-XL | Support",
  virtualConcertproductSale: "CONCERTO-XL | Product Sale Analytics",
  virtualConcertBandPastConcert: "CONCERTO-XL | Past Concerts",
  virtualConcertBandTodayConcert: "CONCERTO-XL | Today's Concerts",
  virtualConcertBandUpcomingConcert: "CONCERTO-XL| Upcoming Concerts",
  virtualConcertBandUnapprovedConcert: "CONCERTO-XL| Unapproved Concerts",
  OTPsent: "OTP has been sent to your email address for reset password.",
  requestPayment:
    "You should have current earning minimum of $50 to request payment.",
  virtualConcertBandPaymentRequest: "CONCERTO-XL | Payment Request",
  virtualConcertConcertEarning: "CONCERTO-XL | Concert Earning",

  loginAgain: "Please login",
  succesfullyRegistered:
    "Congratulations, Your account has been registered successfully. A verification link has been sent to your entered email address. Please verify link first to login.",
  linkSend: "Please, Check your email for verification link.",

  // GENRE TYPES :
  Rock: "Rock",
  World: "World",
  Jazz: "Jazz",
  Pop: "Pop",
  EasyListening: "Easy listening",
  Metal: "Metal",

  createEvent: "Create Event",
};

export default AppConstant;
