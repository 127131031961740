import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  approvedConcertData: [],
  unApprovedConcertData: [],
  approvedTotalCount: 0,
  approvedCurrentPage: 1,
  unApprovedTotalCount: 0,
  unApprovedCurrentPage: 1,
  concertById: {},
  approvedBuyersList: [],
  onPaidLoad: false,
  inProgress: false,
  concertBuyersTotalCount: 0,
  concertBuyersCurrentPage: 1,
  concertBuyersNextPage: 0,
  rehit: false,
  totalFeaturedConcert: null,
};

export const concertReducer = (state = initialState, action) => {
  switch (action.type) {
    //approved concerts

    case types.API_GET_APPROVED_CONCERT_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_APPROVED_CONCERT_SUCCESS:
      state.approvedConcertData = action.result.data;
      state.approvedTotalCount = action.result.pagination.totalCount;
      state.approvedCurrentPage = action.result.pagination.currentPage;

      console.log("hope-----------", { state });
      return {
        ...state,
        onLoad: false,
        totalFeaturedConcert: action.result.totalFeature,
      };

    //unApproved concerts

    case types.API_GET_UNAPPROVED_CONCERT_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_UNAPPROVED_CONCERT_SUCCESS:
      state.unApprovedConcertData = action.result.data;
      state.unApprovedTotalCount = action.result.pagination.totalCount;
      state.unApprovedCurrentPage = action.result.pagination.currentPage;

      console.log("hope-----------", { state });
      return { ...state, onLoad: false };

    //fetch concert by id

    case types.API_GET_CONCERT_BY_ID_LOAD:
      return {
        ...state,
        onLoad: true,
      };

    case types.API_GET_CONCERT_BY_ID_SUCCESS:
      return {
        ...state,
        concertById: action.result,
        onLoad: false,
      };

    // approved concert payment status
    case types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_LOAD:
      return {
        ...state,
        onLoad: true,
        onPaidLoad: true,
      };

    case types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_SUCCESS:
      console.log("reducer payment hits");
      return {
        ...state,
        onLoad: false,
        onPaidLoad: false,
      };

    // fetch approved concert buyers list
    case types.API_GET_APPROVED_CONCERT_BUYERS_LIST_LOAD:
      return {
        ...state,
        onLoad: true,
      };

    case types.API_GET_APPROVED_CONCERT_BUYERS_LIST_SUCCESS:
      state.concertBuyersTotalCount = action.result?.pagination?.totalCount;
      state.concertBuyersCurrentPage = action.result?.pagination?.currentPage;
      state.approvedBuyersList = action.result.data;

      return {
        ...state,
        onLoad: false,
      };

    /// change concert status

    case types.API_UNAPPROVED_CONCERT_APPROVE_LOAD:
      return {
        ...state,
        onLoad: false,
        inProgress: true,
      };

    case types.CLEAR_REDUCER:
      console.log("dispatch clear reducer state");
      return { ...state, concertById: {} };

    case types.API_UNAPPROVED_CONCERT_APPROVE_SUCCESS:
      console.log("reject/approve hits");

      return {
        ...state,
        onLoad: false,
        inProgress: false,
      };

    // add to featured list

    case types.API_ADD_TO_FEATURED_CONCERTS_LOAD:
      return {
        ...state,
        onLoad: true,
        inProgress: true,
        // rehit: false,
      };

    case types.API_ADD_TO_FEATURED_CONCERTS_SUCCESS:
      console.log("add to featured concerts hits");
      return {
        ...state,
        onLoad: false,
        inProgress: false,
        // rehit: true,
      };

    case "REHIT":
      return { ...state, rehit: action.value };

    case types.API_CONCERT_FAILED:
      return {
        ...state,
        onLoad: false,
      };

    case types.API_CONCERT_ERROR:
      return {
        ...state,
        onLoad: false,
      };

    default:
      return state;
  }
};
