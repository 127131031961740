import React, { useEffect, useState } from "react";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { getBandByIdAction } from "../../../store/actions/admin";
import { handleNavigationStateAction } from "../../../store/actions";
import { getMediaUrl } from "../../../utils/helpers";
import Loader from "../../../customComponents/loader";

const Banddetail = (props) => {
  const { data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="user-Details-Box">
      <div
        className="row detail-height"
        style={{
          paddingTop: 100,
          paddingBottom: 80,
        }}
      >
        <div className="col-sm center-style-items">
          <span className="span-title-style">Band Logo</span>
        </div>
        <div className="col-sm center-style-items">
          <img
            alt=""
            style={{
              marginBottom: 40,
              borderRadius: 100,
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            src={
              data?.bandLogo ? getMediaUrl(data.bandLogo) : AppImages.noImage
            }
            className="userImage-Style pointer-Style"
            onClick={showModal}
          />
        </div>
      </div>
      <Modal visible={isModalVisible} onCancel={handleCancel} width={350}>
        <div className="image-modal">
          <img
            src={data && getMediaUrl(data.bandLogo)}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            className="bandImage-Style-Modal"
          />
        </div>
      </Modal>

      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Band Name</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data ? data.bandName : "N/A"}</span>
        </div>
      </div>
      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Contact Name</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data ? data.contactName : "N/A"}</span>
        </div>
      </div>
      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Paypal ID</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data ? data.paypalId : "N/A"}</span>
        </div>
      </div>

      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Email Address</span>
        </div>
        <div
          className="col-sm-6 center-style-items textInside"
          style={{ width: 250 }}
        >
          <span className="text-dots2">{data ? data.bandEmail : "N/A"}</span>
        </div>
      </div>

      <div className="row profile-genre-style">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Type</span>
        </div>
        <div
          className="col-sm center-style-items genre-column-style"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          {data?.type?.map((item,index) => {

if(index === 0){
  return item?.genreName;
}else{
  return ", " + item?.genreName
}


          } )}
        </div>
      </div>
    </div>
  );
};

export const BandDetail = (props) => {
  const history = useHistory();



console.log("hhhhh" , history.location.state.record)

  const breadData = [
    {
      name: "Band Management",
      route: "/bandManagement",
    },

    {
      name: "Band Details",
      route: "",
    },
  ];

  const { bandByIdData, onLoad } = useSelector(
    (state) => state.bandManagementReducer
  );

  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { sidebarDrawerToggleState } = appState;

  useEffect(() => {
    document.title = AppConstant.virtualConcertBandDetails;
    dispatch(handleNavigationStateAction(2));
    if (history.location.state.record) {
      dispatch(getBandByIdAction(history.location.state.record));
    } 
    else if(history.location.state.record) {
      dispatch(getBandByIdAction(history.location.state.record));
    }
    else {
      history.push("/bandManagement");
    }
  }, []);
  return (
    <>
      <Header data={breadData} title={AppConstant.BandManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Band Details" noSearch>
              <Banddetail props={props} data={bandByIdData} onLoad={onLoad} />
            </NavCard>
          </div>
        </div>
        <Loader visible={onLoad} />
      </div>
    </>
  );
};

export default BandDetail;
