import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  newsData: [],
  newsDataTotalCount: 0,
  newsDataCurrentPage: 1,
  newsDataNextPage: 0,
  imagesFileArray: [],
  imagesUrlArray: [null],
  videosFileArray: [],
  videosUrlArray: [{}],
  onDeleteNewsReducer: false,
  newsByIdData: [],
};

export const newsManagementReducer = (state = initialState, action) => {
  // console.log("action,------", action);
  switch (action.type) {
    // FETCH ALL NEWS
    case types.API_GET_NEWS_MANAGEMENT_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_NEWS_MANAGEMENT_SUCCESS:
      state.newsData = action.result.data;
      state.newsDataTotalCount = action.result.pagination.totalCount;
      state.newsDataCurrentPage = action.result.pagination.currentPage;

      console.log("hope-----------", { state });
      return { ...state, onLoad: false };

    // ADD NEWS

    case types.API_ADD_NEWS_LOAD:
      return { ...state, onLoad: true };

    case types.API_ADD_NEWS_IMAGE_LOAD:
      console.log("ADD NEWS IMAGES HITS");
      // state.imagesArray.push(action.values);

      state.imagesUrlArray[action.index] = action.imgUrl;
      state.imagesFileArray[action.index] = action.file;

      return state;

    case types.API_ADD_NEWS_VIDEO_LOAD:
      state.videosUrlArray[action.index] = action.imgUrl;
      state.videosFileArray[action.index] = action.file;
      return state;

    case types.CLEAR_ADDED_NEWS:
      let arrayToDeleteImageFile = state.imagesFileArray;
      let arrayToDeleteImageUrl = state.imagesUrlArray;
      arrayToDeleteImageFile.splice(action.index, 1);
      arrayToDeleteImageUrl.splice(action.index, 1);
      state.imagesFileArray = arrayToDeleteImageFile;
      state.imagesUrlArray = arrayToDeleteImageUrl;
      return state;

    case types.API_ADD_NEWS_SUCCESS:
      console.log("ADD NEWS REDUCER HITS");
      return { ...state, onLoad: false };

    // UPDATE NEWS
    case types.API_UPDATE_NEWS_LOAD:
      return { ...state, onLoad: true };

    case types.API_UPDATE_NEWS_SUCCESS:
      console.log("UPDATE NEWS BY ID REDUCER HITS");
      return { ...state, onLoad: false };

    // GET NEWS BY ID

    case types.API_GET_NEWS_BY_ID_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_NEWS_BY_ID_SUCCESS:
      // console.log("GET NEWS BY ID REDUCER HITS", action.result);
      return { ...state, onLoad: false, newsByIdData: action.result };

    // delete news
    case types.API_DELETE_NEWS_LOAD:
      return { ...state, onLoad: true, onDeleteNewsReducer: true };

    case types.API_DELETE_NEWS_SUCCESS:
      console.log("delete NEWS BY ID REDUCER HITS");
      // return { ...state, onLoad: false };
      return { ...state, onLoad: false, onDeleteNewsReducer: false };

    // add boxes

    case types.API_ADD_NEWS_BOX_LOAD:
      state.imagesUrlArray.push(null);
      console.log("reducer image url array ===> ", state.imagesUrlArray);
      return state;

    case types.API_ADD_NEWS_VIDEOS_BOX_LOAD:
      state.videosUrlArray.push("");
      console.log("reducer videos url array ===> ", state.videosUrlArray);
      return state;

    // delete news media

    case types.API_DELETE_NEWS_MEDIA_LOAD:
      return { ...state, onLoad: true };

    case types.API_DELETE_NEWS_MEDIA_SUCCESS:
      console.log("delete news media reducer hits");
      return { ...state, onLoad: false };

    // clear add news reducer previous data
    case types.ADD_NEWS_CLEAR_PREVIOUS_REDUCER_DATA:
      console.log("clear add news reducer hits");
      state.imagesFileArray = [];
      state.imagesUrlArray = [null];
      state.videosFileArray = [];
      state.videosUrlArray = [{}];
      return state;

    // add news media

    case types.API_ADD_NEWS_MEDIA_LOAD:
      return { ...state, onLoad: true };

    case types.API_ADD_NEWS_MEDIA_SUCCESS:
      console.log("add news media reducer hits");
      return { ...state, onLoad: false };

    case types.CLEAR_REDUCER:
      console.log("dispatch clear reducer state");
      return { ...state, newsByIdData: [] };

    // FAIL CASES
    case types.API_GET_NEWS_MANAGEMENT_FAILED:
      return { ...state, onLoad: false };
    case types.API_GET_NEWS_MANAGEMENT_ERROR:
      return { ...state, onLoad: false, error: action.error };
    default:
      return state;
  }
};



/*
Morning Status : 
Resource : Rahul
What i worked yesterday ?
=> worked on Stuart website bugs
what is my target for the day ?
=> to get the approval of website in react js



*/