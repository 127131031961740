import React, { useEffect } from "react";
import TextInput from "../../../customComponents/InputField";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import AppImages from "../../../themes/appImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import { useDispatch, useSelector } from "react-redux";
import cl from "../../../utils/cl";
import * as Yup from "yup";
import { Formik } from "formik";
import { bandForgotPasswordAction } from "../../../store/actions/Band";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const state = useSelector((state) => state.bandAuthenticationReducer)

  const values = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch(bandForgotPasswordAction({ email: values.email, isBand: true }, history));
  };

  useEffect(() => {
    document.title = AppConstant.VirtualConcertForgotPassword;
  });
  return (
    <div className="body-background">
      <div className="body-Style">
        <div className="back-arrow-div">
          <FontAwesomeIcon
            icon={faChevronLeft}
            size={30}
            color="black"
            onClick={() => history.push("/band/login")}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={AppImages.LogoImage}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            className="login-Logo-Image"
          />
          <span className="login-style">
            {AppConstant.bandForgotPasswordEmail}
          </span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextInput
                  id="email"
                  placeholder={AppConstant.emailAddress}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  fullWidth
                />
                <div
                  className="buttonWrapper"
                  style={{ marginTop: 14, alignSelf: "center" }}
                >
                  <Button style={{ width:100}}  loading={state.onLoad}  title={AppConstant.Send} bg type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
