import React, { useEffect } from "react";
import TextInput from "../../../customComponents/InputField";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import AppImages from "../../../themes/appImages";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { changePasswordAction } from "../../../store/actions/admin";

const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adminAuthState = useSelector(
    (state) => state.adminAuthenticationReducer
  );

  useEffect(() => {
    document.title = AppConstant.VirtualConcertChangePassword;
  });

  const values = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(ValidationConstants.password.emptyOld)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),

    newPassword: Yup.string()
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .max(255, ValidationConstants.password.longNew)
      .min(6, ValidationConstants.password.shortNew)
      .required(ValidationConstants.password.emptyNew),

    confirmPassword: Yup.string()
      .required(ValidationConstants.password.emptyConfirm)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          ValidationConstants.password.unmathcedConfirm1
        ),
      }),
  });

  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (values.newPassword !== values.confirmPassword) {
    }
    dispatch(
      changePasswordAction(
        values.oldPassword,
        values.newPassword,
        values.confirmPassword
      )
    );
  };

  return (
    <div className="body-background">
      <div className="body-Style">
        <div className="back-arrow-div">
          <FontAwesomeIcon
            icon={faChevronLeft}
            size={30}
            color="black"
            onClick={() => history.push("/dashboard")}
          />
        </div>
        <img
          src={AppImages?.LogoImage || AppImages.noImage}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          className="login-Logo-Image"
        />
        <span className="login-style">Change Password</span>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextInput
                  placeholder={AppConstant.oldPassword}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                  id="oldPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.oldPassword.trim()}
                  fullWidth
                  onCopy={stopCopyPaste}
                  onPaste={stopCopyPaste}
                  style={{
                    marginTop: 8,
                  }}
                />
                <TextInput
                  placeholder={AppConstant.newPassword}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                  id="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.newPassword.trim()}
                  fullWidth
                  onCopy={stopCopyPaste}
                  onPaste={stopCopyPaste}
                  style={{
                    marginTop: 8,
                  }}
                />
                <TextInput
                  placeholder={AppConstant.confirmNewPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  id="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword.trim()}
                  fullWidth
                  onCopy={stopCopyPaste}
                  onPaste={stopCopyPaste}
                  style={{
                    marginTop: 8,
                  }}
                />
                <div className="buttonWrapper" style={{ marginTop: 14 }}>
                  <Button title={AppConstant.Submit} type="submit" bg />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;