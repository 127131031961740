import React, { useEffect, useState } from "react";
import NavCard from "../../../customComponents/NavCard";
import { useSelector, useDispatch } from "react-redux";
import { Table, Pagination, Spin } from "antd";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import SideNav from "../../../customComponents/SideNav";
import AppConstant from "../../../themes/appConstant";
import clsx from "clsx";
import {
  concertEarningListAction,
  filteredBandListAction,
} from "../../../store/actions/admin";

let breadData = [
  {
    name: "Concert Earning",
    route: "/earningManagement",
  },
];

const EarningManagement = () => {
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { filteredBandList } = useSelector(
    (state) => state.earningManagementReducer
  );

  const {
    concertListTotalCount,
    concertListCurrentPage,
    concertEarningList,
    onLoad,
  } = useSelector((state) => state.earningManagementReducer);

  const [paginationPage, setPaginationPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState();
  const [s, setS] = useState("");

  const [selectedBand, setSelectedBand] = useState();
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });
  const { sidebarDrawerToggleState } = appState;
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: "60px" }}>
            <span>{(paginationPage - 1) * 10 + index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Concert Name",
      dataIndex: "concertName",
      sorter: {
        compare: (a, b) => a.concertName - b.concertName,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "180px" }}>
          <span>{record?.concertName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Tickets",
      dataIndex: "totalTickets",
      sorter: {
        compare: (a, b) => a.totalTickets - b.totalTickets,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>{record?.totalTickets || "0"}</span>
        </div>
      ),
    },
    {
      title: "Band Ticket Price",
      dataIndex: "ticketPriceForBand",
      sorter: {
        compare: (a, b) => a.ticketPrice - b.ticketPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>${record?.ticketPriceForBand || "0"}</span>
        </div>
      ),
    },
    {
      title: "Platform Ticket Price",
      dataIndex: "ticketPriceForAdmin",
      sorter: {
        compare: (a, b) => a.ticketPrice - b.ticketPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (ticketPriceInRocks, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>${record?.ticketPriceForAdmin || "0"}</span>
        </div>
      ),
    },

    {
      title: "Total Earning",
      dataIndex: "totalEarningForAdmin",
      sorter: {
        compare: (a, b) => a.totalEarning - b.totalEarning,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>${record?.totalEarningForAdmin || "0"}</span>
        </div>
      ),
    },
    {
      title: "Commission",
      dataIndex: "commission",
      sorter: {
        compare: (a, b) => a.commission - b.commission,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>${record?.commission.toFixed(2) || "0"}</span>
        </div>
      ),
    },
  ];

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
    search : s || ""
    
    };

    dispatch(concertEarningListAction(params));
  }

  useEffect(() => {
    document.title = AppConstant.virtualConcertEarning;
    dispatch(filteredBandListAction());
    dispatch(concertEarningListAction({ limit: 10, offset: 0 }));
  }, []);

  return (
    <>
      <Header data={breadData} title={AppConstant.Dashboard} />
      <SideNav tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
             value={s}
              selectFilter
              onSelectBandAction={(val) =>{
             setSelectedBand(val)
                dispatch(
                  concertEarningListAction({
                    limit: 10,
                    offset: 0,
                    band: val,
                    search: searchedValue,
                  })
                )
              }
              }
              filteredBandList={filteredBandList}
              title="Concerts Earning"
              onChange={(event) => {
                setPaginationPage(1)
                setS(event.target.value)
                console.log("search val --> ", event.target.value);
                setSearchedValue(event.target.value);
                if(event.target.value[0] === " " ){
                }else{ 
                dispatch(
                  concertEarningListAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                    band:selectedBand
                  })
                );
                }
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={concertEarningList}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad ? true : false,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={concertListTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(
                        concertEarningListAction({
                          limit: 10,
                          offset: (page - 1) * 10, search:s , order: sorting.sortOrder, sortBy: sorting.sortBy
                        })
                      );
                    }}
                    current={concertListCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningManagement;
