import { apiConstants as types } from "../../themes/apiConstants";
import Types from "../actionTypes/index";

// Action to handle navigatin route state
export const handleNavigationStateAction = (index) => {
  let action = {
    type: types.HANDLE_SIDEBAR_NAVIGATION_STATE,
    index,
  };

  return action;
};

// Action to handle
export const handleSidebarDrawerToggleStateAction = (toggler) => {
  let action = {
    type: types.HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE,
    toggler,
  };

  return action;
};

// Action to handle
export const blockButtonForSomeTime = (params) => {
  let action = {
    type: types.BLOCK_BUTTON,
    params,
  };
  return action;
};

// get genre list
export const getGenreList = () => {
  let action = {
    type: Types.GET_GENRE_LIST_LOAD,
  };
  return action;
};
