// Validation Constant Strings
export const ValidationConstants = {
  ticketPriceRequired: "Please enter ticket price.",
  durationRequired: "Please enter video duration.",
  usernameRequired: "Please enter name.",
  concertNameRequired: "Please enter concert name.",
  sponsorNameRequired: "Please enter sponsor name.",
  reply: "Please add your reply.",
  bandNameRequired: "Please enter name.",
  contactNameRequired: "Please enter contact name.",
  concertVenueRequired: "Please enter concert venue.",
  mainVideoRequired: "Please add youtube video link.",
  concertRequired: "Please enter concert name.",
  videoDuration: "Please enter video duration.",
  check: "Please check terms and Conditions.",
  paypal: {
    empty: "Please enter your paypal ID.",
    long: "Paypal ID should not be greater than 255 characters.",
    invalid: "Please enter valid paypal ID.",
  },
  email: {
    empty: "Please enter email address.",
    long: "The email address may not be greater than 255 characters.",
    invalid: "Please enter valid email address.",
  },
  password: {
    empty: "Please enter password.",
    newEmpty: "Please enter new password.",
    oldPassword: "Please enter old password.",
    confirmPassword: "Please enter confirm password.",
    short: "Password should be alteast 6 characters long.",
    long: "Password must contain less than 255 characters.",
    noSpace: "Space is not allowed in password.",
    spacenot: "Space is not allowed in name.",
    titleSpace: "Space is not allowed in title.",
    emptyOld: "Please enter old password.",
    emptyNew: "Please enter new password.",
    otpRequired: "Please enter OTP.",
    emptyConfirm: "Please enter confirm new password.",
    unmathcedConfirm1: "New password and confirm new password should be same.",
    unmathcedConfirm: "Password and confirm password should be same.",

    shortNew: "New password should be alteast 6 characters long.",
    longNew: "New password must contain less than 255 characters.",
  },
  username: {
    empty: "Please enter username.",
    bandName: "Please enter band name.",
    contactName: "Please enter contact name.",
    paypalId: "Please enter your paypal ID.",
    short: "The name must contain at least 3 characters.",
    long: "The name may not be greater than 30 characters.",
    noSpace: "Space is not allowed in username.",
  },
  phoneNumber: {
    empty: "Please enter your Phone Number.",
    invalid: "Please enter valid phone number.",
    long: "The phone number must be between 8 and 15 digits.",
    short: "The phone number must be between 8 and 15 digits.",
  },
  userType: {
    empty: "Please select user type.",
  },
  file: {
    empty: "Please upload file.",
    invalid: "Please upload .csv file",
  },
  invalid: {
    email: {
      invalidEmail: "Please enter valid email address.",
      invalidEmailorPassword: "Invalid email address or password.",
      unRegisteredEmail: "Please enter registered email address.",
    },
    password: {
      shortPassword: "Password should be alteast 6 characters long.",
      longPassword: "Password must contain less than 255 characters.",
      unmathcedConfirm: "Password and confirm password must be same.",
    },
    name: {
      shortName: "Name should be alteast 2 characters long.",
    },
    contact: {
      incorrectContactLength: "Phone number should be between 8 to 15 digits.",
      incorrectContact: "Please enter valid phone number.",
    },
  },
  empty: {
    emptyEmail: "Please enter email address.",
    emptyOldPassword: "Please enter old password.",
    emptyNewPassword: "Please enter new password.",
    emptyPassword: "Please enter password.",
    emptyConfirm: "Please enter confirm password.",
    emptyContact: "Please enter phone number.",
    emptyName: "Please enter name.",
    subject: "Please enter subject.",
    message: "Please enter message.",
  },
  success: {
    registerSuccess: "You have been registered successfully.",
    verifyRegisteration:
      "You have been registered successfully! Please verify your email address to login into the website.",
    forgotSuccess:
      "Forgot password link has been sent to your registered email address. ",
    updateSuccess: "User details has been updated successfully. ",
    blockSuccess: "User has been blocked successfully.",
    unBlockSuccess: "User has been unblocked successfully.",
    userAddedSuccess: "User has been added successfully.",
    deleteSuccess: "User has been delete successfully.",
    blockedEmail: "Your account has been blocked by admin.",
  },
  offline: "Your internet connection appears to be offline. Please try again.",
  // 401 message
  messageStatus401: "The user is not authorized to make the request.",
  messageStatus500: "Something went wrong.",
  sessionExpired: "Session expired.",
  selectGenre: "Please select at least one genre.",

  descriptionRequired: "Please enter description.",
  titleRequired: "Please enter title.",
  bandName: "Please enter band name.",
  contactName: "Please enter contact name.",
  selectTrailer: "Select trailer video.",
  addVideoLink: "Please add youtube video link.",
};
