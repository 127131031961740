import React, {useEffect} from "react";
import { Provider } from "react-redux";
import Setup from "./routes/setup";
import BandSetup from "./routesBand/setup";
import ForgotPasswordReset from "./Admin/components/authentication/forgotPasswordReset";
import store from "./store";
import { ToastContainer, Slide, toast } from "react-toastify";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";

import AppColors from "../src/themes/appColors";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";

function App() {
  const pathUrl = window.location.pathname;

  // urlBand[1] === "band"
  //   ? history.push("/band/dashboard")
  //   : history.push("/dashboard");
const internetOn = () => {
  toast.success("Your internet conncetion is live now.")

}


const internetOff = () => {
  toast.error("Internet connection lost, please check your internet connection.")
  
}

useEffect(() => {
  
  window.addEventListener("online",internetOn)
  window.addEventListener("offline",internetOff)

  return () => {
window.removeEventListener("online",internetOn);
window.removeEventListener("offline",internetOff)
  }
}, [])





  const checkPanel = () => {
    const panelUrl = pathUrl.split("/");

    if (panelUrl[1] !== "band" && panelUrl[1]) {
      return <Setup />;
    } else {
      return <BandSetup />;
    }
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: AppColors.mainColor,
      },
    },
    outline: "none",
  });

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {checkPanel()}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={1}
            transition={Slide}
          />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;

// var moment = require('moment');
// var duration = 'PT15M51S';
// var x = moment.duration(duration, moment.ISO_8601);
// console.log(x.asSeconds()); // => 951

// Youtube Api key
// AIzaSyAJdWjcMcbROc6opBqbsiZPrAKQuZXMIJ8
