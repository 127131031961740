import React, { useEffect } from "react";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import AppConstant from "../../../themes/appConstant";
import "../../../Band/components/authentication/authentication.css";
import AppImages from "../../../themes/appImages";

const ForgotPasswordSuccess = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = AppConstant.Registration;
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, null);
  });

  return (
    <div className="body-background">
      <div className="body-Style">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={AppImages.SuccessIcon}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            style={{ height: 100, width: 100, marginBottom: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span className="span-title-style" style={{ fontSize: 16 }}>
              {AppConstant.OTPsent}
            </span>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              marginTop: 14,
              alignSelf: "center",
            }}
          >
            <Button
              title={AppConstant.ok}
              bg
              onClick={() => history.push("/admin")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
