import React, { Fragment, useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import AppColors from "../themes/appColors";
import "./customComponentStyle.css";

const useStyles = makeStyles({
  pickerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end !important",
  },
  timeIcon: {
    position: "absolute",
    right: 0,
    height: 18,
    width: "18px !important",
    bottom: 6,
    color: "#707070",
  },
  selectInput: {
    width: "100%",
    verticalAlign: "middle !important",
  },
  root: {
    flexWrap: "wrap",
  },

  cssLabel: {
    color: AppColors.labelColor,
    "&.Mui-focused": {
      color: AppColors.labelColor,
    },
    fontSize: 14,
    fontWeight: 600,
  },
});

function DatePicker(props) {
  const {
    open,
    onOpen,
    onClose,
    onChange,
    val,
    disablePast,
    minDate,
    onAccept,
  } = props;
  const classes = useStyles();

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: AppColors.mainColor,
  //     },
  //   },
  //   outline: "none",
  // });

  console.log("value in timepicker", props);

  return (
    <Fragment>
      {/* <ThemeProvider theme={theme}> */}
      <div className={classes.pickerWrapper}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            ampm={true}
            label={props.title}
            name="terminalOpenTime"
            openTo="minutes"
            value={val}
            // disabled={disabled}
            minDate={minDate}
            onClose={onClose}
            onAccept={onAccept}
            minDateMessage={minDate}
            // disablePast={disablePast}
            onChange={onChange}
            className={clsx([
              classes.selectInput,
              classes.timePicker,
              "datePickerPointer",
            ])}
            open={open}
            onOpen={onOpen}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
          />
        </MuiPickersUtilsProvider>
        <FontAwesomeIcon
          icon={faClock}
          className={classes.timeIcon}
          onClick={onOpen}
        />
      </div>
      {/* </ThemeProvider> */}
    </Fragment>
  );
}

export default DatePicker;
