import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  listHelpData: null,
  repliedListData: null,
  listHelpPagination: null,
  repliedListPagination: null,
  replyModalState: false,
  replySent: false,
  rehit: false,

  //   trailerDataTotalCount: 0,
  //   trailerDataCurrentPage: 1,
  //   trailerDataNextPage: 0,
  //   onDeleteTrailerReducer: false,
  //   checkboxErrorMessage: "",
};

export const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    //FTECH LIST HELP
    case types.API_FETCH_LIST_HELP_LOAD:
      return { ...state, onLoad: true };

    case types.API_FETCH_LIST_HELP_SUCCESS:
      state.listHelpData = action.result.data;
      state.listHelpPagination = action.result.pagination;
      //   state.trailerDataTotalCount = action.result.pagination.totalCount;
      //   state.trailerDataCurrentPage = action.result.pagination.currentPage;

      return { ...state, onLoad: false };

    // fetch replied list

    case types.API_FETCH_REPLIED_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_FETCH_REPLIED_LIST_SUCCESS:
      console.log("  replied in reducer-----------", action.result);
      return {
        ...state,
        onLoad: false,
        repliedListData: action.result.data,
        repliedListPagination: action.result.pagination,
      };

    case types.API_FETCH_REPLIED_LIST_FAILED:
      return { ...state, onLoad: false };

    case types.API_FETCH_REPLIED_LIST_ERROR:
      return { ...state, onLoad: false };

    // send reply
    case types.API_SEND_REPLY_TO_LIST_HELP_LOAD:
      return { ...state, onLoad: true, replySent: true };

    case types.API_SEND_REPLY_TO_LIST_HELP_SUCCESS:
      return { ...state, onLoad: false, replySent: false };

    case types.API_SEND_REPLY_TO_LIST_HELP_FAILED:
      return { ...state, onLoad: false };

    case types.API_SEND_REPLY_TO_LIST_HELP_ERROR:
      return { ...state, onLoad: false };

    // change reply modal state
    case "CAHNGE_REPLY_MODAL_STATE":
      return { ...state, replyModalState: action.value };

    case "REHIT_LIST":
      return { ...state, rehit: action.value };

    default:
      return state;
  }
};
