import { takeLatest, call, put, delay } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
import { toast } from "react-toastify";
import { ValidationConstants } from "../../../themes/validationConstants";

// DASHBOARD SAGA
export function* dashboardSaga(action) {
  const { startDate, endDate } = action;
  try {
    const result = yield call(api.bandDashboard, { startDate, endDate });

    console.log("result", result);

    if (result.status === 1) {
      console.log(
        "result inside admin dashboard saga",
        result.result.data.data
      );
      yield put({
        type: types.BAND_API_GET_DASHBOARD_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 3) {
      console.log("result in 3");
      toast.error(ValidationConstants.sessionExpired);
      localStorage.removeItem("bandToken");
      history.push("/");
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else {
      yield put({
        type: types.BAND_API_GET_DASHBOARD_FAILED,
        result: 0,
      });
      toast.error(result.error.response.data.message);
    }
  } catch (error) {
    yield put({
      type: types.BAND_API_GET_DASHBOARD_ERROR,
    });
    toast.error(error);
  }
}

// dahsboard request payment

export function* dashboardRequestPaymentSaga() {
  try {
    const result = yield call(api.bandDashboardRequestPayment);
    if (result.status === 1) {
      yield put({
        type: types.API_BAND_PAYMENT_REQUEST_DASHBOARD_SUCCESS,
        result: result.result.data.data,
      });
      yield delay(3500);
      yield put({
        type: "CLOSE_MODAL",
      });
    } else if (result.status === 3) {
      toast.error(ValidationConstants.sessionExpired);
      localStorage.removeItem("bandToken");
      history.push("/");
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else {
      yield put({
        type: types.API_BAND_PAYMENT_REQUEST_DASHBOARD_FAILED,
        result: 0,
      });
      toast.error(result.response.data.message);
    }
  } catch (error) {
    yield put({
      type: types.API_BAND_PAYMENT_REQUEST_DASHBOARD_ERROR,
    });
    console.log("in error case");
    toast.error(error?.error, { toastId: error?.status || "est" });
  }
}

export default function* rootDashboardSaga() {
  yield takeLatest(types.BAND_API_GET_DASHBOARD_LOAD, dashboardSaga);
  yield takeLatest(
    types.API_BAND_PAYMENT_REQUEST_DASHBOARD_LOAD,
    dashboardRequestPaymentSaga
  );
}
