import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import TextInput from "../../../customComponents/InputField";
import { genreJson } from "../../../mocks/genreJson";
import Button from "../../../customComponents/Button";
import AppConstant from "../../../themes/appConstant";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import * as Yup from "yup";
import { Formik } from "formik";
import "./trailer.css";
import {
  addTrailerAction,
  emptyCheckboxState,
  getGenreList,
} from "../../../store/actions/admin";
import { toast } from "react-toastify";
import { ValidationConstants } from "../../../themes/validationConstants";
import AppColors from "../../../themes/appColors";

const breadData = [
  {
    name: "Trailer Video",
    route: "/trailerVideo",
  },
  {
    name: "Add Trailer Video",
    route: "",
  },
];

const AddTrailerVideo = () => {
  const dispatch = useDispatch();
  const { onLoad } = useSelector((state) => state.trailerReducer);
  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const [pickerGenre, setPickerGenre] = useState([]);
  const { Option } = Select;

  const { appState } = useSelector((state) => state);
  const classes = useStyles();
  const values = {
    videoLink: "",
    videoTitle: ""
  };


  //   const options = [];
  // for (let i = 0; i < genreListData.length; i++) {
  //   const value = `${genreListData[i]?.genreName}`;
  //   options.push({
  //     value,
  //     disabled: i === 10,
  //   });
  // }


  const onSubmit = (values) => {
    const formData = new FormData();
    if (values.videoLink === "") {
      toast.error(ValidationConstants.addVideoLink);
      return;
    } else if (pickerGenre.length <= 0) {
      toast.error(ValidationConstants.selectGenre);
      return;
    } else {
      formData.append("video", values.videoLink);
      formData.append("title", values.videoTitle);
      formData.append("genre", JSON.stringify(pickerGenre));
      dispatch(addTrailerAction(formData));
    }
  };

  const validationSchema = Yup.object().shape({
    videoLink: Yup.string()
      .min(5, "Please enter a valid video link.")
      .trim()
      .required(ValidationConstants.mainVideoRequired)
      .matches(
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Entered YouTube URL is invalid, please enter valid URL."
      ),

    videoTitle: Yup.string().trim().min("3", "Title should be at least 3 characters long.").required("Please enter video title.").matches(/^[a-zA-Z0-9_ .-]*$/, "Please enter alpha numeric values only.")

  });

  const pickerHandleChange = (val) => {
    console.log("val", val);
    setPickerGenre(val);
    disableInput()
  };



  const disableInput = () => {
    const selects = document.getElementsByClassName(`ant-select-search__field`)
    for (let el of selects) {
      el.setAttribute(`maxlength`, 0)
    }
  }


  const disableType = (e) => {
    // e.replace(e,"")
    document.getElementById("stopType").readOnly = true;
    document.getElementById("stopType").maxLength = 0
  }



  useEffect(() => {
    document.title = AppConstant.VirtualConcertAddTrailerVideo;
    // dispatch(emptyCheckboxState());
    dispatch(getGenreList())
    disableType()

  }, []);






  const { sidebarDrawerToggleState } = appState;

  return (
    <>
      <SideNav />
      <Header data={breadData} title={AppConstant.TrailerVideo} />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm checkbox-min-width">
            <NavCard title="Add Trailer Video" noSearch>
              <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div
                      className="user-Details-Box"
                      style={{ marginTop: 100 }}
                    >



                      <div className="row bottom-margin addTrailerVideoStyle">
                        <div className="col-sm">
                          <TextInput
                            id="mainVideoTitle"
                            placeholder="Title"
                            error={Boolean(
                              touched.videoTitle && errors.videoTitle
                            )}
                            helperText={touched.videoTitle && errors.videoTitle}
                            name="videoTitle"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.videoTitle}
                            inputProps={{
                              maxLength: 35,
                            }}

                            // onKeyDown={(e) => {
                            //   if (e.target.value[0] = " ") {
                            //     e.preventDefault()
                            //   }
                            // }}

                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          ></div>
                        </div>
                      </div>



                      <div className="row bottom-margin addTrailerVideoStyle">
                        <div className="col-sm">
                          <TextInput
                            id="mainVideo"
                            placeholder="Trailer Video Link"
                            error={Boolean(
                              touched.videoLink && errors.videoLink
                            )}
                            helperText={touched.videoLink && errors.videoLink}
                            name="videoLink"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.videoLink}
                            inputProps={{
                              maxLength: 250,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div style={{ marginTop: 30 }}>
                        <div className="">
                          <span className="span-title-style">Genre(s)</span>
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Select
                            searchProps={{ maxLength: 5 }}
                            id="stopType"
                            mode="multiple"
                            //  autoClearSearchValue
                            onSearch={(e) => {
                              disableType(e)
                              console.log("hi", e);

                            }}
                            autoClearSearchValue
                            allowClear
                            name="genre"
                            style={{ width: "100%", caretColor: 'white' }}
                            placeholder="Select Genre"
                            value={pickerGenre}
                            onChange={pickerHandleChange}
                          // options={options}

                          >
                            {genreListData?.map((item, index) => (
                              <Option key={item?._id}>{item?.genreName}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div
                        className="add-button"
                        style={{ marginTop: 40, marginLeft: 10 }}
                      >
                        <Button
                          title={AppConstant.Add}
                          disabled={onLoad}
                          wd
                          bg
                          mb
                          type="submit"
                          onClick={() => { }}
                          style={{
                            backgroundColor: onLoad && AppColors.mainColor,
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTrailerVideo;
