import { takeLatest, call, put, delay } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import history from "../../../utils/history";
import { toast } from "react-toastify";
import * as api from "../../Api";

function* earningManagementFailedSaga(result) {
  yield put({
    type: types.API_GET_FILTERED_BAND_LIST_SUCCESS,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* earningManagementErrorSaga(result) {
  yield put({
    type: types.API_GET_BAND_MANAGEMENT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* filteredBandListSaga() {
  try {
    const result = yield call(api.filteredBandList);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_FILTERED_BAND_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);
      history.push("/admin");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_BAND_MANAGEMENT_FAILED });
      //   yield call(earningManagementFailedSaga, result);
      yield put({
        type: types.API_GET_FILTERED_BAND_LIST_SUCCESS,
      });
    }
  } catch (e) {
    // yield put({ type: types.API_GET_BAND_MANAGEMENT_ERROR });
    // yield call(earningManagementErrorSaga, e);
    yield put({
      type: types.API_GET_BAND_MANAGEMENT_ERROR,
    });
  }
}

export function* concertEarningListSaga(action) {
  try {
    const result = yield call(api.concertEarningList, action.params);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_CONCERT_EARNING_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);
      history.push("/admin");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_BAND_MANAGEMENT_FAILED });
      //   yield call(earningManagementFailedSaga, result);
      yield put({
        type: types.API_GET_CONCERT_EARNING_LIST_FAILED,
      });
    }
  } catch (e) {
    // yield put({ type: types.API_GET_BAND_MANAGEMENT_ERROR });
    // yield call(earningManagementErrorSaga, e);
    yield put({
      type: types.API_GET_CONCERT_EARNING_LIST_ERROR,
    });
  }
}

export default function* rootEarningManagementSaga() {
  yield takeLatest(types.API_GET_FILTERED_BAND_LIST_LOAD, filteredBandListSaga);
  yield takeLatest(
    types.API_GET_CONCERT_EARNING_LIST_LOAD,
    concertEarningListSaga
  );
}
