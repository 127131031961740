import React from "react";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import Breadcrumbs from "./CustomBreadCrumb";
import { withStyles } from "@material-ui/core/styles";
import { HiMenu } from "react-icons/hi";
import "./customComponentStyle.css";
import { useStyles } from "./style";
import { useSelector, useDispatch } from "react-redux";
import { handleSidebarDrawerToggleStateAction } from "../store/actions/index";
import { Hidden } from "@material-ui/core";

const BandHeader = (props) => {
  const { appState } = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sidebarDrawerToggleState } = appState;

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  return (
    <>
      <div
        className={clsx(classes.appBar, {
          [classes.appBarShift]: sidebarDrawerToggleState,
        })}
      >
        <div
          className={clsx(["band-header-inline-style", classes.headerInline])}
        >
          <div className="breadcrumb-style">
            <Breadcrumbs pathSnippets={props.data} />
          </div>

          <Hidden mdUp={true}>
            <HiMenu
              size={24}
              onClick={() =>
                dispatch(
                  handleSidebarDrawerToggleStateAction(
                    !sidebarDrawerToggleState
                  )
                )
              }
            />
          </Hidden>
        </div>
      </div>
    </>
  );
};

export default BandHeader;
