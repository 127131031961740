import { act } from "react-dom/test-utils";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import cl from "../../../utils/cl";

const initialState = {
  onLoad: false,
  errorState: null,
};

export const bandRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BAND_API_REGISTER_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_REGISTER_SUCCESS:
      cl("register reducer success ===>", action);

      return { ...state, onLoad: true };

    default:
      return state;
  }
};
