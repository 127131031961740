import React from "react";
import "./customComponentStyle.css";
import { Spin, Tooltip } from "antd";
import Button from "./Button";
import AppImages from "../themes/appImages";
const Card = (props) => {
  return (
    <>
      <div>
        <div className="card-Icon">
          <img
            alt=""
            src={props.icon}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            style={{ height: 50, width: 50 }}
          />
        </div>

        <div className="card-Shadow">
          <div
            style={{
              position: "absolute",
              right: 0,
              top: -30,
            }}
          >
            {props.payment && (
              <Tooltip placement="topRight" title="Click to redeem payment.">
                <div>
                  <Button
                    onClick={props.onClick}
                    RequestButtonpadding
                    bg
                    title="Request Payment"
                  />
                </div>
              </Tooltip>
            )}
          </div>

          <div className="card-Content">
            {props.onLoad ? (
              <Spin className="dashboardSpin" size="small" />
            ) : (
              <span className="font-Size-Style">
                {props?.dollarSign && "$"}
                {props.data ? props.data : "0"}
              </span>
            )}
          </div>
        </div>
        <div className="card-Footer">
          <span className="footer-text-style">{props.title}</span>
        </div>
      </div>
    </>
  );
};

export default Card;
