import React, { useEffect } from "react";
import TabNavCard from "../../../customComponents/TabNavCard";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { useHistory } from "react-router-dom";
import AppConstant from "../../../themes/appConstant";
import Button from "../../../customComponents/Button";
import clsx from "clsx";


let breadData = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "All Concerts" ,
    route: "/band/allConcerts",
  },
];
let breadData2 = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "Past Concerts",
    route: "/band/allConcerts",
  },
];
let breadData3 = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "Today's Concerts",
    route: "/band/todayConcert",
  },
];
let breadData4 = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "Upcoming Concerts",
    route: "/band/upcomingConcert",
  },
];


let breadData5 = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "Unapproved Concerts",
    route: "/band/uapprovedConcert",
  },
];


const AllConcerts = () => {

  const tabState = useSelector((state) => state.concertTabsState.value);



  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

const check = () => {
  if(tabState === 0){
    return breadData
  } else if(tabState === 1){
    return breadData2
  } else if(tabState === 2){
    return breadData3
  } else if(tabState === 3){
    return breadData4
  }else{
    return breadData5
  }
}





  const [Breadvalue, setValue] = React.useState(check);
  const [urlTitle, setUrlTitle] = React.useState(
    AppConstant.virtualConcertBandAllConcert
  );

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const history = useHistory();

  // function for rendering breadcrumb on tab changes

  const breadcrumbData = (val) => {
    switch (val) {
      case 1:
        setValue(breadData2);
        setUrlTitle(AppConstant.virtualConcertBandPastConcert);
        return Breadvalue;

      case 2:
        setValue(breadData3);
        setUrlTitle(AppConstant.virtualConcertBandTodayConcert);
        return Breadvalue;

      case 3:
        setValue(breadData4);
        setUrlTitle(AppConstant.virtualConcertBandUpcomingConcert);
        return Breadvalue;

      case 4:
        setValue(breadData5);
        setUrlTitle(AppConstant.virtualConcertBandUnapprovedConcert);
        return Breadvalue;

        
      // case 0:
      //   setValue(breadData);
      //   setUrlTitle(AppConstant.virtualConcertBandAllConcert);
      //   return Breadvalue;

      default:
        setValue(breadData);
        setUrlTitle(AppConstant.virtualConcertBandAllConcert);
        return Breadvalue;
    }
  };

  useEffect(() => {
    document.title = urlTitle;
  }, []);

  useEffect(() => {
    if(tabState === 0){

      setValue(breadData)
    }
  }, [tabState]);

  
  return (
    <>
      <BandHeader data={Breadvalue} title={AppConstant.Dashboard} />
      <BandSideNAv tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.AddConcert}
          bg
          mb
          onClick={() => {
            history.push("/band/addConcert");
          }}
        />
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <TabNavCard
              tabNav
              sendData={(sendData) => breadcrumbData(sendData)}
            ></TabNavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllConcerts;
