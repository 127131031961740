import { Route, Redirect } from "react-router-dom";
import React from "react";
import { routeConfig } from "./routeConfig";

const PrivateRoute = ({ component: Component, ...rest }) => (
  // <Route
  //   {...rest}
  //   render={(props) =>
  //     localStorage.getItem("bandToken") ? (
  //       <Component {...props} />
  //     ) : (
  //       <Redirect to="/band/login" />
  //     )
  //   }
  // />
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export default PrivateRoute;
// localStorage.token ? <Component {...props} /> : <Redirect to="/login" />
//  <Route {...rest} render={(props) => <Component {...props} />} />
// localStorage.token ? (
//   <Route {...rest} render={(props) => <Component {...props} />} />
// ) : (
//   <Redirect to="/band/login" />
// );
