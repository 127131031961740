import React, { useEffect, useState } from "react";
import NavCard from "../../../customComponents/NavCard";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination, Spin, Select } from "antd";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { useHistory } from "react-router-dom";
import { bandConcertEarningListAction } from "../../../store/actions/Band";
import AppConstant from "../../../themes/appConstant";
import clsx from "clsx";

let breadData = [
  {
    name: "Concert / Event Earnings",
    route: "/band/bookingManagement",
  },
];

const BookingManagement = () => {
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const [paginationPage, setPaginationPage] = useState(1);
  const [s, setS] = useState("");

  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });
  const { sidebarDrawerToggleState } = appState;
  const { Option } = Select;
  const dispatch = useDispatch();

  const {
    bandConcertListTotalCount,
    bandConcertListCurrentPage,
    bandConcertEarningList,
    onLoad,
  } = useSelector((state) => state.bandConcertEarningReducer);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: "60px" }}>
            <span>{(paginationPage - 1) * 10 + index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Concert Name",
      dataIndex: "concertName",
      sorter: {
        compare: (a, b) => a.concertName - b.concertName,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Tickets",
      dataIndex: "totalTickets",
      sorter: {
        compare: (a, b) => a.totalTickets - b.totalTickets,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>{record?.totalTickets || "0"}</span>
        </div>
      ),
    },

    {
      title: "Ticket Price",
      dataIndex: "ticketPriceForBand",
      sorter: {
        compare: (a, b) => a.ticketPrice - b.ticketPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>${record?.ticketPriceForBand || "0"}</span>
        </div>
      ),
    },

    {
      title: "Total Earning",
      dataIndex: "totalEarningForBand",
      sorter: {
        compare: (a, b) => a.totalEarning - b.totalEarning,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>${record?.totalEarningForBand || "0"}</span>
        </div>
      ),
    },
  ];

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  
  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(bandConcertEarningListAction(params));
  }

  useEffect(() => {
    document.title = AppConstant.virtualConcertConcertEarning;
    dispatch(bandConcertEarningListAction({ limit: 10, offset: 0 }));
  }, []);

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
             value={s}
              title="Concert / Event Earnings"
              onChange={(event) => {
                console.log("search val --> ", event.target.value);
                setS(event.target.value)
                dispatch(
                  bandConcertEarningListAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                  })
                );
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={bandConcertEarningList}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={bandConcertListTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(
                        bandConcertEarningListAction({
                          limit: 10,
                          offset: (page - 1) * 10,
                        })
                      );
                    }}
                    current={bandConcertListCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingManagement;
