import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  bandData: [],
  bandByIdData: [],
  bandDataTotalCount: 0,
  bandDataCurrentPage: 1,
  bandDataNextPage: 0,
  onBlock: false,
  inProgress: false,
  checkbox: [],
};

export const bandManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //fetch all bands

    case types.API_GET_BAND_MANAGEMENT_LOAD:
      return { ...state, onLoad: true };
    case types.API_GET_BAND_MANAGEMENT_SUCCESS:
      console.log("action  data  in reducer ===> ", action);
      state.bandData = action.result.data;
      state.bandDataTotalCount = action.result.pagination.totalCount;
      state.bandDataCurrentPage = action.result.pagination.currentPage;

      console.log("hope-----------", { state });
      return { ...state, onLoad: false };

    // fetch band by id

    case types.API_GET_BAND_BY_ID_LOAD:
      return { ...state, bandByIdData: [], onLoad: true };
    case types.API_GET_BAND_BY_ID_SUCCESS:
      return {
        ...state,
        bandByIdData: action.result,
        onLoad: false,
        checkbox: action.result.type,
      };

    // block band status

    case types.API_BLOCK_BAND_LOAD:
      return { ...state, onLoad: false, onBlock: true, inProgress: true };
    case types.API_BLOCK_BAND_SUCCESS:
      console.log(" change band block status hits");
      return { ...state, onLoad: false, onBlock: false, inProgress: false };

    //update band by id

    case types.API_UPDATE_BAND_BY_ID_LOAD:
      return { ...state, onLoad: true };
    case types.API_UPDATE_BAND_BY_ID_SUCCESS:
      console.log("update band reducer hits");
      return { ...state, onLoad: false };

    case types.CLEAR_REDUCER:
      console.log("dispatch clear reducer state");
      return { ...state, bandByIdData: [] };

    // add band checkbox in edit screen

    case types.API_ADD_BAND_EDIT_CHECKBOX:
      if (state.checkbox.includes(action.value)) {
        const index = state.checkbox.indexOf(action.value);
        state.checkbox.splice(index, 1);
      } else {
        state.checkbox.push(action.value);
      }

      console.log("checkbox", state.checkbox);

      return state;

    // fail cases

    case types.API_GET_BAND_MANAGEMENT_FAILED:
      return { ...state, onLoad: false };

    case types.API_GET_BAND_MANAGEMENT_ERROR:
      return { ...state, onLoad: false, error: action.error };

    default:
      return state;
  }
};
