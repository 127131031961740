import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    textTransform: "none",
  },
});
const BandTabPanel = (props) => {
  const classes = useStyles();
  const { value, index, other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.root}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{props.children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default BandTabPanel;
