import { tr } from "date-fns/locale";
import { actionChannel } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import cl from "../../../utils/cl";

const initialState = {
  onLoad: false,
  errorState: null,
  profileData: {
    bandName: "",
    bandEmail: "",
    contactName: "",
  },
  checkbox: [],
  checkboxErrorMesssage: "",
};

export const bandProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    // fetch profile
    case types.BAND_API_PROFILE_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_PROFILE_SUCCESS:
      console.log("reducre", action.result.type);
      return {
        ...state,
        profileData: action.result,
        checkbox: action.result.type,
        onLoad: false,
      };

    // edit profile
    case types.BAND_API_EDIT_PROFILE_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_EDIT_PROFILE_SUCCESS:
      console.log("edit profile reducer ==> ", action);
      return { ...state, onLoad: false };

    case types.EMPTY_PROFILE_CHECKBOX_STATE:
      state.checkbox = [];
      return state;

    case types.API_ADD_PROFILE_CHECKBOX:
      if (state.checkbox.includes(action.value)) {
        const index = state.checkbox.indexOf(action.value);
        state.checkbox.splice(index, 1);
      } else {
        state.checkbox.push(action.value);
      }

      console.log("checkbox", state.checkbox);

      return state;

    //failed case
    case types.BAND_API_PROFILE_FAILED:
      return { ...state, onLoad: true };

    // error case
    case types.BAND_API_PROFILE_ERROR:
      return { ...state, onLoad: false };

    default:
      return state;
  }
};
