import React, { useState } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import AppColors from "../themes/appColors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
    secondary: {
      main: AppColors.mainColor,
    },
  },
});

const CheckBox = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </ThemeProvider>
  );
};

export default CheckBox;
