import { actionChannel } from "redux-saga/effects";
import { Method, BandMethod } from "./apiMethod";

// ===> Admin Authentication <===
//Admin Login
export const login = (email, password) =>
  Method.POST(
    "admin/auth/login",
    JSON.stringify({
      email: email,
      password: password,
    })
  );
// Admin Forgot Password
export const forgotPassword = (values) =>
  Method.POST(
    "admin/auth/forgotPassword",
    JSON.stringify({
      email: values.email,
      isAdmin: values.isAdmin,
    })
  );
// Admin Change password
export const changePassword = (oldPassword, newPassword, confirmPassword) =>
  Method.POST(
    "admin/auth/changePassword",
    JSON.stringify({
      password: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    })
  );

// admin reset password

export const resetPassword = (values) =>
  Method.POST("admin/auth/resetPassword", values);

// check reset password expire

export const checkResetPassword = (values) =>
  Method.POST("admin/auth/checkPassword", values);
// band Forgot Password
export const dashboard = (dashboardValues) =>
  Method.POST("admin/dashboard", dashboardValues);

//trailer
export const trailer = ({ sortBy, order, offset, limit, search }) =>
  Method.GET(
    sortBy
      ? `admin/trailer?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${search}`
      : `admin/trailer?offset=${offset}&limit=${limit}&search=${search}`
  );
export const addTrailer = (value) => Method.POST("admin/trailer", value);
export const deleteTrailer = (id) => Method.DELETE(`admin/trailer/${id}`);

//user management
export const userManagement = ({ sortBy, order, offset, limit, search }) =>
  Method.GET(
    sortBy
      ? `admin/users?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/users?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );
//fetch user by id
export const getUserById = (id) => Method.GET(`admin/users/${id}`);

//block user api
export const ChangeUserStatus = (userId, isBlock) =>
  Method.POST(`admin/users/changeUserStatus`, { userId, isBlock });

//update user by id
export const updateUserById = (action) =>
  Method.PUT(`admin/users/${action.id}`, action.values);

// get users predefined images

export const getUserPredefinedImages = () => Method.GET(`application/avatar`);

// get genre list

export const getGenreList = () => Method.GET(`genre/list`);

//band management
export const bandManagement = ({ sortBy, order, offset, limit, search }) => {
  return Method.GET(
    sortBy
      ? `admin/bands?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/bands?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );
};

//update band by id
export const updateBandById = (action) => {
  return Method.PUT(`admin/bands/${action.id}`, action.value);
};

// change band block status
//get band by id
export const changeBandBlockStatus = (values) =>
  Method.POST(`admin/bands/block`, values);

//get band by id
export const getBandById = (id) => Method.GET(`admin/bands/${id}`);

//news management
export const newsManagement = ({ sortBy, order, offset, limit, search }) =>
  Method.GET(
    sortBy
      ? `admin/news?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/news?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

//get news by id

export const getNewsById = (id) => Method.GET(`admin/news/details/${id}`);

// add news
export const addNews = (values) => Method.POST("admin/news", values);

//delete news
export const deleteNews = (id) => Method.DELETE(`admin/news/delete/${id}`);

// delete news media

export const deleteNewsMedia = (action) => {
  return Method.DELETE(`admin/news/deleteMedia`, action);
};

// add news media

export const addNewsMedia = (action) => {
  return Method.POST(`admin/news/uploadMedia`, action);
};

// update news by id
export const updateNews = (action) =>
  Method.PUT(`admin/news/${action.id}`, action.values);

//Concert
export const approvedConcert = ({ sortBy, order, offset, limit, search }) =>
  Method.GET(
    sortBy
      ? `admin/concerts_approved?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/concerts_approved?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

// approved concert payment status
export const approvedConcertPaymentStatus = (id) =>
  Method.PUT(`admin/paid/${id}`);

// get approved concert buyers list

export const approvedConcertBuyersList = (action) => {
  const { params, id } = action;
  const { sortBy, order, offset, limit, search } = params;

  return Method.GET(
    sortBy
      ? `admin/buyers/${id}?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/buyers/${id}?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );
};
// Method.GET(`admin/buyers/${id}`

//change concert status
export const changeConcertStatus = (values) =>
  Method.POST(`admin/change_status`, values);

export const getConcertById = (id) => Method.GET(`admin/concerts/${id}`);

export const deleteConcertVideo = (data) => Method.PUT(`admin/concert_video/remove`, data);

export const unApprovedConcert = ({ sortBy, order, offset, limit, search }) =>
  Method.GET(
    sortBy
      ? `admin/concerts_not_approved?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/concerts_not_approved?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

// Earning Management

export const filteredBandList = () => Method.POST(`/admin/bands/list`);
export const concertEarningList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
  band,
}) =>
  Method.GET(
    sortBy
      ? `/admin/earning/list?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `/admin/earning/list?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }&band=${band}`
  );

// Payment Request
export const paymentRequestList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
  band,
}) =>
  Method.GET(
    sortBy
      ? `/admin/earning/redeemList?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `/admin/earning/redeemList?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

export const getListHelp = ({ sortBy, order, offset, limit, search, type }) =>
  Method.GET(
    sortBy
      ? `/admin/auth/listHelp?sortBy=${sortBy}&order=${order}&offset=${offset}&type=${type}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `/admin/auth/listHelp?offset=${offset}&limit=${limit}&type=${type}&search=${
          search ? search : ""
        }`
  );

export const getRepliedList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
  isReply,
  type,
}) =>
  Method.GET(
    sortBy
      ? `admin/auth/listHelp?isReply=${isReply}&sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&type=${type}&search=${
          search ? search : ""
        }`
      : `admin/auth/listHelp?isReply=${isReply}&offset=${offset}&limit=${limit}&type=${type}&search=${
          search ? search : ""
        }`
  );

export const sendReply = (values) => Method.POST(`admin/auth/reply`, values);

//add rocks to user
export const addRocksToUser = (values) => {
  console.log(values);
  return Method.POST(`admin/users/addCoins`, values);
};

// pay to band
export const payToBand = (id) =>
  Method.GET(`/admin/earning/changeStatus/${id}`);

// get featured concert list
export const getFeaturedConcertList = () => Method.GET(`/admin/featureList`);

// add to featured concert
export const addFeaturedConcert = (id) =>
  Method.POST(`/admin/addFeature`, { concertId: id });

// remove from featured concert
export const removeFeaturedConcert = (id) =>
  Method.POST(`/admin/removeFeature`, { concertId: id });

// get product sales list
export const fetchProductSaleList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
}) =>
  Method.GET(
    sortBy
      ? `admin/item/list?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `admin/item/list?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

// update news by id
export const updateTrailer = (action) =>
  Method.PUT(`admin/trailer/edit`, action);

// update news by id
export const getTrailer = (action) => {
  console.log("action id in ", action);
  return Method.POST(`admin/trailer/detail`, action);
};

///////////////////////////////////////////
///////////////////////////////////////////
//////////// BAND FUNCTIONS ///////////////
///////////////////////////////////////////
///////////////////////////////////////////

// band login
export const bandLogin = (value) => BandMethod.POST("bands/login", value);

//Band registration
export const bandRegistration = (value) =>
  BandMethod.POST("bands/register", value);

// band Forgot Password
export const bandForgotPassword = (value) =>
  BandMethod.POST("admin/auth/forgotPassword", value);

// fetch past concert
export const pastConcert = () => BandMethod.GET("bands/concerts/pastConcerts");

// fetch band Dashboard
export const bandDashboard = (dashboardValues) =>
  BandMethod.POST("bands/dashboard", dashboardValues);

// dashboard request payment
export const bandDashboardRequestPayment = () => BandMethod.GET("bands/redeem");

// fetch all concert
export const allConcert = () => BandMethod.GET("bands/concerts/allConcerts");

// fetch band concerts data

// export const unApprovedConcert = ({ sortBy, order, offset, limit, search }) =>
//   Method.GET(
//     sortBy
//       ? `admin/concerts_not_approved?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}`
//       : `admin/concerts_not_approved?offset=${offset}&limit=${limit}&search=${
//           search ? search : ""
//         }`
//   );

export const fecthConcertData = (action) => {
  const { sortBy, order, offset, limit, search } = action.params;

  return BandMethod.POST(
    sortBy
      ? `bands/concerts/allConcerts?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `bands/concerts/allConcerts?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`,
    action.values
  );
};

// fetch unapproved concert
export const unapprovedConcert = (action) => {
  const { sortBy, order, offset, limit, search } = action.params;

  return BandMethod.POST(
    sortBy
      ? `bands/concerts/pending?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `bands/concerts/pending?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`,
    action.values
  );
};

// fetch band concert by id
export const fecthConcertDataById = (id) =>
  BandMethod.GET(`bands/concerts/concert/${id}`);

// add concert
export const addBandConcert = (values) =>
  BandMethod.POST("/bands/concerts/concert", values);

// edit concert
export const editBandConcert = (action) =>
  BandMethod.PUT(`/bands/concerts/concert/${action.id}`, action.values);

// fetch today concert
export const todayConcert = () =>
  BandMethod.GET("bands/concerts/todayConcerts");

// fetch upcoming concert
export const upcomingConcert = () =>
  BandMethod.GET("bands/concerts/upcomingConcerts");

// band change password
export const bandChangePassword = (value) =>
  BandMethod.POST("bands/changePassword", value);

// band verify email
export const bandVerifyEmail = (values) =>
  BandMethod.POST("application/resend", values);

// fetch FAQ
export const getFaq = () => BandMethod.GET("bands/faqs");

// add FAQ
export const addFaq = (question) =>
  BandMethod.POST("bands/concerts/upcomingConcerts", question);

// fetch Profile
export const getProfile = () => BandMethod.GET("bands/myProfile");

// edit profile
export const editProfile = (values) =>
  BandMethod.PUT("bands/editProfile", values);

// fetch terms and conditions
export const getTermsAndConditions = () => BandMethod.GET("bands/t&c");

// band concert earning
export const bandConcertEarningList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
}) =>
  BandMethod.GET(
    sortBy
      ? `/bands/earning/list?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `/bands/earning/list?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

// band payment request
export const bandPaymentRequestList = ({
  sortBy,
  order,
  offset,
  limit,
  search,
}) =>
  BandMethod.GET(
    sortBy
      ? `/bands/redeemList?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
      : `/bands/redeemList?offset=${offset}&limit=${limit}&search=${
          search ? search : ""
        }`
  );

// band verify email
export const bandSupport = (values) => BandMethod.POST("bands/help", values);
