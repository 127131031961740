import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  value: 0,
  //   sidebarDrawerToggleState: false,
};

export const concertTabsState = (state = initialState, action) => {
  switch (action.type) {
    // concert tabs navigation handler action case
    case types.HANDLE_CONCERTS_TAB_STATE:
      localStorage.setItem("concertTabItem", action.index);

      return {
        ...state,
        value: action.index,
      };

    default:
      return { ...state };
  }
};
