import { apiConstants as types } from "../../../themes/apiConstants";

export const approvedConcertAction = (params) => {
  const action = {
    type: types.API_GET_APPROVED_CONCERT_LOAD,
    params,
  };
  return action;
};

export const approvedConcertPaymentStatusAction = (id) => {
  const action = {
    type: types.API_GET_APPROVED_CONCERT_PAYMENT_STATUS_LOAD,
    id,
  };
  return action;
};

export const approvedConcertUserListAction = (params, id) => {
  const action = {
    type: types.API_GET_APPROVED_CONCERT_BUYERS_LIST_LOAD,
    params,
    id,
  };
  return action;
};

export const unapprovedConcertAction = (params) => {
  const action = {
    type: types.API_GET_UNAPPROVED_CONCERT_LOAD,
    params,
  };
  return action;
};

export const getConcertByIdAction = (id) => {
  const action = {
    type: types.API_GET_CONCERT_BY_ID_LOAD,
    id,
  };
  return action;
};

export const addToFeatureById = (concertId) => {
  const action = {
    type: types.API_ADD_TO_FEATURED_CONCERTS_LOAD,
    concertId,
  };
  return action;
};

// export const rejectConcertAction = (id) => {
//   const action = {
//     type: types.API_UNAPPROVED_CONCERT_REJECT_LOAD,
//     id,
//   };
//   return action;
// };

// export const approveConcertAction = (id) => {
//   const action = {
//     type: types.API_UNAPPROVED_CONCERT_APPROVE_LOAD,
//     id,
//   };
//   return action;
// };

export const changeConcertStatusAction = (values, shouldNavigate) => {
  const action = {
    type: types.API_UNAPPROVED_CONCERT_APPROVE_LOAD,
    values,
    shouldNavigate,
  };
  return action;
};
export const clearPreviousReducerData = () => {
  const action = {
    type: types.CLEAR_REDUCER,
  };
  return action;
};
export const removevideoLink = (payload) => {
  const action = {
    type: types.API_DELETE_YOUTUBE_VIDEO_LOAD,payload
  };
  return action;
};
