import { apiConstants as types } from "../../../themes/apiConstants";

export const getUserData = (params) => {
  return {
    type: types.API_GET_USER_MANAGEMENT_LOAD,
    params,
  };
};

export const getUserByIdAction = (id) => {
  return {
    type: types.API_GET_USER_BY_ID_LOAD,
    id,
  };
};

export const userBlockStatusAction = (id, status) => {
  return {
    type: types.API_CHANGE_USER_STATUS_LOAD,
    userId: id,
    isBlock: status,
  };
};

export const updateUserByIdAction = (id, values) => {
  return {
    type: types.API_UPDATE_USER_BY_ID_LOAD,
    id,
    values,
  };
};

export const clearPreviousReducerData = () => {
  const action = {
    type: types.CLEAR_REDUCER,
  };
  return action;
};

export const getUserPredefinedImages = () => {
  const action = {
    type: types.API_GET_USER_PREDEFINED_IMAGES_LOAD,
  };
  return action;
};


export const addRocksToUserAction = (values) => {
  const action = {
    type: types.API_ADD_ROCKS_TO_USER_LOAD,
    values
  };
  return action;
};

