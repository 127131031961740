import React, { useState, useEffect } from "react";
import NavCard from "../../../customComponents/NavCard";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination, Spin } from "antd";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { bandPaymentRequestListAction } from "../../../store/actions/Band";
import { dateWithTimeConverter } from "../../../utils/dateTimeConverter";
import AppConstant from "../../../themes/appConstant";
import clsx from "clsx";

let breadData = [
  {
    name: "Payment Requests",
    route: "/band/paymentRequest",
  },
];

const PaymentRequest = () => {
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const [paginationPage, setPaginationPage] = useState(1);
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const {
    bandPaymentRequestTotalCount,
    bandPaymentRequestCurrentPage,
    bandPaymentRequestList,
    onLoad,
  } = useSelector((state) => state.bandPaymentRequestReducer);

  const dispatch = useDispatch();

  const getColor = (action) => {
    if (action) {
      return "paid-button";
    } else {
      return "action-button-inline-style";
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: "60px" }}>
            <span>{(paginationPage - 1) * 10 + index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "uniqueId",
      sorter: {
        compare: (a, b) => a.bandName - b.bandName,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.uniqueId || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>${record?.amount || "0"}</span>
        </div>
      ),
    },

    {
      title: "Date Of Request",
      dataIndex: "createdOn",
      sorter: {
        compare: (a, b) => a.createdOn - b.createdOn,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px", maxWidth: "250px" }}>
          <span>{dateWithTimeConverter(record?.createdOn) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "isPaid",
      key: "isPaid",

      render: (text, record) => {
        return (
          <div className="action-style" style={{ minWidth: "120px" }}>
            <Button
              disabled
              className={`${getColor(record.isPaid)} pending`}
              type="primary"
            >
              <span
                style={{ fontWeight: 600, minWidth: "60px", paddingTop: 1.3 }}
              >
                {record.isPaid ? "Complete" : "Pending"}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
    };

    dispatch(bandPaymentRequestListAction(params));
  }

  useEffect(() => {
    document.title = AppConstant.virtualConcertBandPaymentRequest;
    dispatch(bandPaymentRequestListAction({ limit: 10, offset: 0 }));
  }, []);

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              noSearch
              title="My Payment Request"
              onChange={(event) => {
                console.log("search val --> ", event.target.value);
                dispatch(
                  bandPaymentRequestListAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                  })
                );
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={bandPaymentRequestList}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={bandPaymentRequestTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(
                        bandPaymentRequestListAction({
                          limit: 10,
                          offset: (page - 1) * 10,
                        })
                      );
                    }}
                    current={bandPaymentRequestCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentRequest;
