import React, { useState, useEffect } from "react";
import { Table, Button as AntButton, Pagination, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import ConfirmationModal from "../../../customComponents/paymentConfirmationModal";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import Button from "../../../customComponents/Button";
import {
  dateConvert,
  timeConvert,
  convertInMinutesSeconds,
} from "../../../utils/dateTimeConverter";
import "../../../customStyles/antdStyles.css";
import "../../../customComponents/dashboardLayout.scss";
import {
  approvedConcertAction,
  approvedConcertPaymentStatusAction,
  clearPreviousReducerData,
  getFeaturedConcertList,
  removeFeaturedConcertById,
} from "../../../store/actions/admin";

function onChange(pagination, filters, sorter, extra) {}

const ConcertTable = (props) => {
  const {
    data,
    history,
    dispatch,
    current,
    totalCount,
    onLoad,
    setModalState,
    setPaginationPage,
    paginationPage,
    setOnId,
    onId,
  } = props;

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "50px" }}>
          <span>{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "concertName",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Venue",
      dataIndex: "concertVenue",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertVenue || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateFrom",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{dateConvert(record?.dateFrom || "N/A")}</span>
        </div>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "timeFrom",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span> {timeConvert(record?.dateFrom) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Video Duration (Minutes)",
      dataIndex: "totalDuration",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>{convertInMinutesSeconds(record?.totalDuration) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Ticket Price",
      dataIndex: "ticketPrice",

      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>${record?.ticketPrice + record?.commission || "0"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (text, record) => {
        return (
          <div className="action-style">
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/concertDetails", {
                  id: record._id,
                  breadCrumb: true,
                })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </AntButton>
            <AntButton
              className="action-button-inline-style danger-color"
              type="primary"
              onClick={
                () => {
                  setOnId(record._id);
                  setModalState(true);
                }
                // history.push("/concertDetails", { id: record._id })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>REMOVE</span>
            </AntButton>
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   console.log("spinner state ====> ", spin, data);
  //   if (data.length > 0) {
  //     setSpin(false);
  //   } else {
  //     setSpin(true);
  //   }
  // }, [data]);
  return (
    <>
      <div className="">
        <Table
          className="table-responsive user-table-style"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={false}
          showSorterTooltip={false}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: onLoad ? true : false,
          }}
          rowKey={(record) => record._id}
        />
        {/* <div className="pagination-style">
          <Pagination
            className="pagination"
            total={totalCount}
            onChange={(page) => {
              setPaginationPage(page);
              dispatch(
                approvedConcertAction({ limit: 10, offset: (page - 1) * 10 })
              );
            }}
            current={current}
            showSizeChanger={false}
          />
        </div> */}
      </div>
    </>
  );
};

const FeaturedConcert = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [onId, setOnId] = useState(null);

  const [paginationPage, setPaginationPage] = useState(1);

  const [Id, setId] = useState();

  const breadData = [
    {
      name: "Featured Concerts",
      route: "/featuredConcerts",
    },
    // {
    //   name: "Approved Concerts",
    //   route: "",
    // },
  ];
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const { onLoad, onPaidLoad, featuredList, rehit } = useSelector(
    (state) => state.FeaturedConcertReducer
  );

  useEffect(() => {
    document.title = AppConstant.virtualConcertFeatured;
    // dispatch(clearPreviousReducerData());
    dispatch(getFeaturedConcertList());
  }, []);

  useEffect(() => {
    if (rehit) {
      dispatch(getFeaturedConcertList());
      dispatch({ type: "REHIT_LIST", value: false });
    }
  });

  console.log("featuredList", featuredList);
  return (
    <>
      <Header data={breadData} title={AppConstant.featured} />
      <SideNav />
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          dispatch(removeFeaturedConcertById(onId));
          setModalState(!modalState);
          // dispatch(approvedConcertPaymentStatusAction(Id));
          // setOnPayStatus(true);
        }}
        text="Are you sure, you want to remove this concert from featured list?"
      />

      {/* <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.UnapprovedConcert}
          bg
          mb
          onClick={() => {
            history.push("/concertManagement");
          }}
        />
      </div> */}

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              //  value={s}
              noSearch
              title="Featured Concerts"
              onChange={(event) => {
                console.log("serach val --> ", event.target.value);
                // dispatch(
                //   approvedConcertAction({
                //     limit: 10,
                //     offset: 0,
                //     search: event.target.value,
                //   })
                // );
              }}
            >
              <ConcertTable
                history={history}
                data={featuredList}
                dispatch={dispatch}
                // current={approvedCurrentPage}
                // totalCount={approvedTotalCount}
                onLoad={onLoad}
                setModalState={setModalState}
                setId={setId}
                setOnId={setOnId}
                onId={onId}
                // paginationPage={paginationPage}
                // setPaginationPage={setPaginationPage}
              />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedConcert;
