import { BandApiConstants as types } from "../../../themes/apiConstants";

//dashboard
export const dashboardAction = (startDate, endDate) => {
  const action = {
    type: types.BAND_API_GET_DASHBOARD_LOAD,
    startDate,
    endDate,
  };
  return action;
};

// request payment
export const dashboardRequestPaymentAction = () => {
  const action = {
    type: types.API_BAND_PAYMENT_REQUEST_DASHBOARD_LOAD,
  };
  return action;
};

// request payment
export const closeModal = () => {
  const action = {
    type: "CLOSE_MODAL",
  };
  return action;
};
