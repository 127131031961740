import { apiConstants as types } from "../../../themes/apiConstants";

export const getBandManagementData = (params) => {
  return {
    type: types.API_GET_BAND_MANAGEMENT_LOAD,
    params,
  };
};

export const getBandByIdAction = (id) => {
  const action = {
    type: types.API_GET_BAND_BY_ID_LOAD,
    id,
  };
  return action;
};

export const changeBandStatusAction = (id, status) => {
  const action = {
    type: types.API_BLOCK_BAND_LOAD,
    bandId: id,
    isBlock: status,
  };
  return action;
};

export const updateBandByIdAction = (value, id) => {
  const action = {
    type: types.API_UPDATE_BAND_BY_ID_LOAD,
    value,
    id,
  };
  return action;
};

export const bandSorterAction = (value) => {
  const action = {
    type: types.API_SORT_BAND_LOAD,
    value,
  };
  return action;
};

export const clearPreviousReducerData = () => {
  const action = {
    type: types.CLEAR_REDUCER,
  };
  return action;
};

// add checkbox
export const bandCheckboxAction = (value) => {
  const action = {
    type: types.API_ADD_BAND_EDIT_CHECKBOX,
    value,
  };
  return action;
};
