import { takeLatest, call, put } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
import { toast } from "react-toastify";

function* authenticationFailedSaga(result) {
  cl("authentication failed", result);
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* authenticationErrorSaga(result) {
  cl(" you got authentication error  ", result);
  yield put({
    type: types.API_AUTHENTICATION_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* loginSaga(action) {
  // const { bandEmail, bandPassword } = action;
  cl("hey, you hit band login Saga ");

  try {
    const result = yield call(api.bandLogin, action.value);
    if (result.status === 1) {
      cl("result inside login saga", result);
      yield put({
        type: types.BAND_API_LOGIN_SUCCESS,
        result: result.result.data.token,
      });
      // cl("navigate");
      console.log("vfjkvvkdjvlkdfjv", history)
      action.history.push("/band/dashboard");
      // window.location.reload();
      // cl(history);
    } 
    
    else if(result.status === 11){
      yield put({
        type: "OPEN_VERIFICATION_MODAL",
        value: true,
      });
    }
    
    else if (result.status === 4) {
      toast.error(result.result.data.message, {
        toastId: result?.status || "est",
      });
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* bandForgotPasswordSaga(action) {
  cl("forgot password saga data ", action);
  try {
    const result = yield call(api.bandForgotPassword, action.payload);
  
    console.log("vhjkshvjkhsjkvhskjvhsdv",result)

    if (result.status === 1) {
      cl("result inside forgot password saga", result);
      yield put({
        type: types.BAND_API_FORGOT_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });
      action.history.push("/band/forgotPasswordSuccess");
      // window.location.reload();

      toast.success(result.result.message, {
        toastId: result?.status || "abc",
      });
    } 
    
    else if(result.status === 11){
      toast.success(result.result.data.message, {
        toastId: result?.status || "abc",
      });
         yield put({type:"STOP_LOADING", value:false})

    }
    
    else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* bandRegistrationSaga(action) {
  cl("registration saga data ", action);
  try {
    const result = yield call(api.bandRegistration, action.value);
    if (result.status === 1) {
      cl("result inside band regisration saga", result);
      yield put({
        type: types.BAND_API_REGISTER_SUCCESS,
        result: result.result.data.data,
      });
      action.history.push("/band/registrationPopUp");
      // window.location.reload();

      // toast.success(result.result.data.message, {
      //   toastId: result?.status || "abc",
      // });
    } else {
      cl("error in else saga");
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    cl("error in else saga");
    yield call(authenticationErrorSaga, error);
  }
}

function* bandChangePasswordSaga(action) {
  cl("Change password saga data ", action);
  try {
    const result = yield call(api.bandChangePassword, action.values);
    if (result.status === 1) {
      cl("result inside change password saga", result);
      yield put({
        type: types.BAND_API_CHANGE_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message);
      setTimeout(() => {
        action.history.push("/band/login");
        // window.location.reload();
      }, 800);
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}


function* bandVerifyEmailSaga(action) {
  cl("bandVerifyEmailSaga", action);
  try {
    const result = yield call(api.bandVerifyEmail, action.values);
    if (result.status === 1) {
      cl("result inside change password saga", result);
      yield put({
        type: types.BAND_API_VERIFY_EMAIL_SUCCESS,
        result: result.result.data.data,
      });
      yield put({
        type:"OPEN_VERIFICATION_MODAL",
        value:false
      })
      toast.success(result.result.data.message);

    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}


export default function* rootBandAuthenticationSaga() {
  yield takeLatest(types.BAND_API_LOGIN_LOAD, loginSaga);
  yield takeLatest(types.BAND_API_FORGOT_PASSWORD_LOAD, bandForgotPasswordSaga);
  yield takeLatest(types.BAND_API_REGISTER_LOAD, bandRegistrationSaga);
  yield takeLatest(types.BAND_API_CHANGE_PASSWORD_LOAD, bandChangePasswordSaga);
  yield takeLatest(types.BAND_API_VERIFY_EMAIL_LOAD, bandVerifyEmailSaga);

}
