import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import Loader from "../../../customComponents/loader";
import AppConstant from "../../../themes/appConstant";
import NavCard from "../../../customComponents/NavCard";
import clsx from "clsx";
import "./faq.css";
import { getFaqAction } from "../../../store/actions/Band";

const breadData = [
  {
    name: "FAQ",
    route: "/band/faq",
  },
];

const Faq = () => {
  const { appState } = useSelector((state) => state);

  const { faqData, onLoad } = useSelector((state) => state.bandFaqReducer);
  const { sidebarDrawerToggleState } = appState;
  const dispatch = useDispatch();
  const classes = useStyles();

  function DummyFaq() {
    return (
      <div className="user-Details-Box" style={{ paddingTop: 30 }}>
        <div className=" faq-inline-style" style={{ textAlign: "justify" }}>
          {faqData?.map((item, index) => {
            return (
              <>
                <div style={{ textAlign: "justify", marginBottom: 20 }}>
                  <span style={{ marginLeft: -20, paddingRight: 10 }}>
                    Q.{index + 1}
                  </span>
                  <span className="span-title-style">{item.question}</span>
                  <br></br>
                  <div
                    style={{
                      textAlign: "left",
                      marginLeft: 11,
                    }}
                  >
                    <span className="faq-text-style">{item.answer}</span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  }

  useEffect(() => {
    document.title = AppConstant.virtualConcertFaq;
    dispatch(getFaqAction());
  }, []);

  return (
    <>
      <>
        <BandHeader data={breadData} title={AppConstant.Dashboard} />
        <BandSideNAv tabPath={window.location.pathname} />

        <div
          className={clsx(classes.content, {
            [classes.contentShift]: sidebarDrawerToggleState,
          })}
        >
          <div className="row">
            <div className="col-sm">
              <NavCard noSearch title="FAQ">
                {DummyFaq()}
              </NavCard>
            </div>
            <Loader visible={onLoad} />
          </div>
        </div>
      </>
    </>
  );
};

export default Faq;
