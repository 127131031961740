import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination } from "antd";
import { useHistory } from "react-router-dom";
import Loader from "./loader";
import { Input, Spin } from "antd";
import { dateConvert, timeConvert } from "../utils/dateTimeConverter";
import moment from "moment";
import { BandConcertTabStateAction } from "../store/actions/Band/ConcertTabState";
import "./customComponentStyle.css";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import "./dashboardLayout.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BandTabPanel from "./BandTabPanel";
import AppColors from "../themes/appColors";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConcertDataAction, unapprovedConcertAction } from "../store/actions/Band";

const { Search } = Input;

const useStyles = makeStyles({
  root: {
    textTransform: "none",

  },
});
const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
  },
  typography: {
    button: {
      textTransform: "unset !important",
      fontSize: 14,
      fontWeight: 600,
    },
  },
});

const TabNavCard = (props) => {
  let type = "all";
  const classes = useStyles();
  const dispatch = useDispatch();
  const tabState = useSelector((state) => state.concertTabsState.value);
  const {
    concertData,
    concertDataTotalCount,
    concertDataCurrentPage,
    onLoad,
  } = useSelector((state) => state.bandConcertReducer);
  const [paginationPage, setPaginationPage] = useState(1);
  const getColor = (action) => {
    if (action) {
      return "paid-button";
    } else {
      return "action-button-inline-style";
    }
  };
  const history = useHistory();
  const [concertType, setConcertType] = useState(null);
  const [s, setS] = useState("");

  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search: s || ""
    };

    // dispatch(approvedConcertAction(params));
    if (concertType === "unapproved") {
      dispatch(unapprovedConcertAction(params,
        {
          type: "unapproved",
          startDate: moment.utc().startOf('D'),
          endDate: moment.utc().endOf('D'),
        }))
    } else {
      dispatch(
        getConcertDataAction(params, {
          type: concertType,
          startDate: moment.utc().startOf('D'),
          endDate: moment.utc().endOf('D'),
        })
      );
    }

  }



  useEffect(() => {
    if (tabState === 1) {
      setConcertType("past");
      type = "past";
    } else if (tabState === 2) {
      setConcertType("today");
      type = "today";
    } else if (tabState === 3) {
      setConcertType("upcoming");
      type = "upcoming";
    }
    else if (tabState === 4) {
      setConcertType("unapproved");
      type = "unapproved";
    }
    else {
      setConcertType("all");
      type = "all";
    }

    var start = new Date();
    start.setHours(0, 0, 0, 0);
    var end = new Date();
    end.setHours(23, 59, 59, 999);
    // dispatch(
    //   dashboardAction(
    //     moment.utc(start).format("DD-MM-YYYYThh:mm:ss"),
    //     moment.utc(end).format("DD-MM-YYYYThh:mm:ss")
    //   )
    // );

    setPaginationPage(1);

    if (type === "unapproved") {
      dispatch(unapprovedConcertAction({ limit: 10, offset: 0 },
        {
          type: type,
          startDate: moment.utc(start),
          endDate: moment.utc(end),
        }))
    } else {
      dispatch(
        getConcertDataAction(
          { limit: 10, offset: 0 },
          {
            type: type,
            startDate: moment.utc(start),
            endDate: moment.utc(end),
          }
        )
      );
    }
  }, [tabState]);



  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "60px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },

    {
      title: "Concert Name",
      dataIndex: "concertName",
      sorter: {
        compare: (a, b) => a.concertName - b.concertName,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{record.concertName}</span>
        </div>
      ),
    },
    {
      title: "Concert Date",
      dataIndex: "dateFrom",
      sorter: {
        compare: (a, b) => a.concertDate - b.concertDate,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span> {dateConvert(record?.dateFrom)}</span>
        </div>
      ),
    },
    {
      title: "Concert Time",
      dataIndex: "dateFrom",
      sorter: {
        compare: (a, b) => a.concertTime - b.concertTime,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "140px" }}>
          <span> {timeConvert(record?.dateFrom)} </span>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "view",

      render: (action, record, index) => {
        console.log("record   ",record)
        return (
          <div className="action-style">
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/band/viewConcert", {
                  id: record?._id,
                  breadType: type,
                })
              }
            >
              <span style={{ fontWeight: 600, paddingTop: 1.3 }}>VIEW</span>
            </Button>


          </div>
        );
      },
    },
  ];

  function SimpleTabs() {
    const handleChange = (event, newValue) => {
      dispatch(BandConcertTabStateAction(newValue));
      demoMethod(newValue);
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    return (
      <div className="tabs-responsive-style">
        <AppBar position="static">
          <div className="topNav-responsive">
            <Tabs
              indicatorColor="transparent"
              value={tabState}
              onChange={handleChange}
              className={classes.root}
            >
              <Tab className="tab" label="All Concerts" {...a11yProps(0)} />
              <Tab className="tab" label="Past Concerts" {...a11yProps(1)} />
              <Tab className="tab" label="Today's Concerts" {...a11yProps(2)} />
              <Tab
                className="tab"
                label="Upcoming Concerts"
                {...a11yProps(3)}
              />
              <Tab
                className="tab"
                label="Unapproved Concerts"
                {...a11yProps(4)}
              />
            </Tabs>
          </div>
        </AppBar>
      </div>
    );
  }
  const demoMethod = (newValue) => {
    props.sendData(newValue);
  };

  return (
    <>
      <div className={props.tabNav ? "band-top-nav-Header" : "band-top-Header"}>
        <div className="band-tabs-table-header-style">
          <SimpleTabs />
        </div>
      </div>
      <div
        className={`nav-Card  ${props.noResponsive ? null : "table-responsive"
          } ${props.extraClass} bandTabsTopMargin `}
      >
        <div
          className="band-responsive-search-style"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Search
            className="table-search-input band band-search"
            placeholder="Search"
            onSearch={() => { }}
            value={s.trimLeft()}
            onChange={(event) => {
              console.log("search val --> ", event.target.value);
              setS(event.target.value)
              if (concertType === "unapproved") {
                dispatch(
                  unapprovedConcertAction(
                    { limit: 10, offset: 0, search: event.target.value },
                    {
                      type: concertType,
                      startDate: moment.utc(),
                      endDate: moment.utc(),
                    }
                  )
                );
              } else {
                dispatch(
                  getConcertDataAction(
                    { limit: 10, offset: 0, search: event.target.value },
                    {
                      type: concertType,
                      startDate: moment.utc(),
                      endDate: moment.utc(),
                    }
                  )
                );
              }


            }}
            style={{
              width: 200,
              borderWidth: 0.5,
              borderColor: "orange",
              borderStyle: "solid",
            }}
          />
        </div>
        <BandTabPanel value={tabState} index={0}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={concertData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={concertDataTotalCount}
                onChange={(page) => {
                  setPaginationPage(page);

                  dispatch(
                    getConcertDataAction(
                      { limit: 10, offset: (page - 1) * 10 },
                      {
                        type: concertType,
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                      }
                    )
                  );
                }}
                current={concertDataCurrentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>
        <BandTabPanel value={tabState} index={1}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={concertData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={concertDataTotalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  dispatch(
                    getConcertDataAction(
                      { limit: 10, offset: (page - 1) * 10 },
                      {
                        type: concertType,
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                      }
                    )
                  );
                }}
                current={concertDataCurrentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>
        <BandTabPanel value={tabState} index={2}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={concertData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={concertDataTotalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  dispatch(
                    getConcertDataAction(
                      { limit: 10, offset: (page - 1) * 10 },
                      {
                        type: concertType,
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                      }
                    )
                  );
                }}
                current={concertDataCurrentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>
        <BandTabPanel value={tabState} index={3}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={concertData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={concertDataTotalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  dispatch(
                    getConcertDataAction(
                      { limit: 10, offset: (page - 1) * 10 },
                      {
                        type: concertType,
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                      }
                    )
                  );
                }}
                current={concertDataCurrentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>


        <BandTabPanel value={tabState} index={4}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={concertData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            {/* <div className="pagination-style">
              <Pagination
                className="pagination"
                total={concertDataTotalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  dispatch(
                    getConcertDataAction(
                      { limit: 10, offset: (page - 1) * 10 },
                      {
                        type: concertType,
                        startDate: moment.utc(),
                        endDate: moment.utc(),
                      }
                    )
                  );
                }}
                current={concertDataCurrentPage}
                showSizeChanger={false}
              />
            </div> */}
          </div>
        </BandTabPanel>
      </div>
    </>
  );
};

export default TabNavCard;



