import React, { useEffect , useState} from "react";
import TextInput from "../../../customComponents/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../customComponents/Button";
import AppImages from "../../../themes/appImages";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import VerifyModal from '../../../customComponents/VerifyModal'
import { loginAction,verifyEmailAction } from "../../../store/actions/Band/index";
import { Formik } from "formik";
import "../../../Admin/components/componentStyle.css";
import "./authentication.css";

  const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailforVerify, setEmailforVerify] = useState('');

const data = useSelector((state) => state.bandAuthenticationReducer)
  const values = {
    bandEmail: "",
    bandPassword: "",
  };

  const handleKeypress = (e, handleSubmit) => {
    console.log("button", e);
    if (e.charCode === 13) {
      handleSubmit();
    }
  };

  const validationSchema = Yup.object().shape({
    bandEmail: Yup.string()
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
    bandPassword: Yup.string()
      .required(ValidationConstants.password.empty)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),
  });

  const onSubmit = (values) => {
    setEmailforVerify(values.bandEmail)
    dispatch(loginAction(values , history));
  };

  const changeEvent = () => {};
  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.title = AppConstant.VirtualConcertLogin;
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, null);
  });

  return (
    <div className="body-background">
      <div className="body-Style">
        <img
          src={AppImages.LogoImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          alt=""
          className="login-Logo-Image"
        />

        <span className="login-style">{AppConstant.bandLogin}</span>

        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextInput
                id="bandEmail"
                placeholder={AppConstant.emailAddress}
                error={Boolean(touched.bandEmail && errors.bandEmail)}
                helperText={touched.bandEmail && errors.bandEmail}
                name="bandEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.bandEmail}
                fullWidth
                style={{
                  marginTop: 8,
                }}
              />
              <TextInput
                id="bandPassword"
                placeholder={AppConstant.password}
                securePassword={true}
                error={Boolean(touched.bandPassword && errors.bandPassword)}
                helperText={touched.bandPassword && errors.bandPassword}
                name="bandPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.bandPassword.trim()}
                fullWidth
                onKeyDown={changeEvent}
                onKeyPress={(e) => {
                  handleKeypress(e, handleSubmit);
                }}
                onCopy={stopCopyPaste}
                onPaste={stopCopyPaste}
                style={{
                  marginTop: 8,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  onClick={() => {
                    history.push("/band/forgotPassword");
                  }}
                  style={{ width: "50%" }}
                  className="forgot-password-div forgot-Password-Style-button pointer-Style"
                >
                  {AppConstant.ForgotPassword}
                </span>
              </div>
              <div className="buttonWrapper">
                <Button
                  title={AppConstant.Login}
                  type="submit"
                  htmlType="submit"
                  bg
                  mt20
                />
              </div>

              <div className="dont-have-account-div buttonWrapper">
                <div>
                  <span>{AppConstant.dontHaveAccount}</span>
                </div>
                <div
                  className="forgot-Password-Style-button pointer-Style"
                  onClick={() => {
                    history.push("/band/registration");
                  }}
                  style={{ marginLeft: 5 }}
                >
                  <span>{AppConstant.register}</span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>


      <VerifyModal
      modalState = {data?.verifyModal}
      close={() => {
        dispatch({type:"OPEN_VERIFICATION_MODAL" , value:false})
      }}

      resend={() => {
        dispatch(verifyEmailAction({emailId : emailforVerify  , type:"band"}))
      }}
      msg={"Please verify your email first."}
      
      />

    </div>



  );
};




export default SignIn;
