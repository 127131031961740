import React, { useEffect } from "react";
import SupportCard from "../../../customComponents/SupportCard";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import SideNav from "../../../customComponents/SideNav";
import { useHistory } from "react-router-dom";
import AppConstant from "../../../themes/appConstant";
import clsx from "clsx";

let breadData = [
  {
    name: "Support",
    route: "/support",
  },
  {
    name: "Asked Problems",
    route: "",
  },
];
let breadData2 = [
  {
    name: "Support",
    route: "/support",
  },
  {
    name: "Replied",
    route: "",
  },
];

const Support = () => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const [Breadvalue, setValue] = React.useState(breadData);
  const [urlTitle, setUrlTitle] = React.useState(
    AppConstant.virtualConcertSupport
  );

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const history = useHistory();
  const breadcrumbData = (val) => {
    switch (val) {
      case 1:
        setValue(breadData2);
        setUrlTitle(AppConstant.virtualConcertBandPastConcert);
        return Breadvalue;

      default:
        setValue(breadData);
        setUrlTitle(AppConstant.virtualConcertBandAllConcert);
        return Breadvalue;
    }
  };

  useEffect(() => {
    document.title = urlTitle;
  }, []);

  return (
    <>
      <Header data={Breadvalue} title={AppConstant.Dashboard} />
      <SideNav tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >

      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <SupportCard
             selectType
              tabNav
              sendData={(sendData) => breadcrumbData(sendData)}
            ></SupportCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
