import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";

import { toast } from "react-toastify";
import history from "../../../utils/history";
import * as api from "../../Api";

function* featuredListFailedSaga(result) {
  yield put({
    type: types.API_GET_FEATURED_CONCERTS_LIST_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* featuredListErrorSaga(result) {
  yield put({
    type: types.API_GET_FEATURED_CONCERTS_LIST_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* getFeaturedListSaga() {
  //   const { sortBy, order, offset, limit, search } = action.params;
  try {
    console.log("in getFeaturedConcertList saga");
    const result = yield call(api.getFeaturedConcertList);
    if (result.status === 1) {
      yield put({
        type: types.API_GET_FEATURED_CONCERTS_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);

      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_FAILED });
      featuredListFailedSaga(result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_NEWS_MANAGEMENT_ERROR });
    featuredListErrorSaga(e);
  }
}

export function* removeFeaturedConcertSaga(action) {
  //   const { sortBy, order, offset, limit, search } = action.params;
  try {
    console.log("in removeFeaturedConcertSaga saga");
    const result = yield call(api.removeFeaturedConcert, action.concertId);
    console.log("in saga result", result);
    if (result.status === 1) {
      yield put({
        type: types.API_DELETE_FROM_FEATURED_CONCERTS_SUCCESS,
        result: result.result.data,
      });
      yield put({
        type: "REHIT_LIST",
        value: true,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);

      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_FAILED });
      featuredListFailedSaga(result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_NEWS_MANAGEMENT_ERROR });
    featuredListErrorSaga(e);
  }
}

export default function* rootFeaturedListSaga() {
  yield takeLatest(
    types.API_GET_FEATURED_CONCERTS_LIST_LOAD,
    getFeaturedListSaga
  );
  yield takeLatest(
    types.API_DELETE_FROM_FEATURED_CONCERTS_LOAD,
    removeFeaturedConcertSaga
  );
}
