import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import { genreJson } from "../../../mocks/genreJson";
import {
  getProfileAction,
  editProfileAction,
} from "../../../store/actions/Band/profileAction";
import clsx from "clsx";
import BandSideNav from "../../../customComponents/BandSideNav";
import Button from "../../../customComponents/Button";
import Loader from "../../../customComponents/loader";
import { getMediaUrl } from "../../../utils/helpers";
import NavCard from "../../../customComponents/NavCard";
import TextInput from "../../../customComponents/InputField";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import { Tooltip, Select } from "antd";
import "../../../Admin/components/componentStyle.css";
import "../../../Admin/components/trailerVideo/trailer.css";
import "./profile.css";
import { toast } from "react-toastify";
import { getGenreList } from "../../../store/actions";

const EditProfile = (props) => {
  const formRef = useRef();
  const [editImage, setEditImage] = useState(null);
  const [pickerGenre, setPickerGenre] = useState([]);
  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const { Option } = Select;
  const breadData = [
    {
      name: "My Profile",
      route: "/band/profile",
    },

    {
      name: "Edit Profile",
      route: "/band/editProfile",
    },
  ];

  const dispatch = useDispatch();
  const { profileData, onLoad, checkbox } = useSelector(
    (state) => state.bandProfileReducer
  );

console.log("profileData ",profileData);

  const [updateImage, setUpdateImage] = useState(null);
  const [emailAddress, setEmailAddress] = useState(profileData.bandEmail);
  const [showEditImage, setEditImageUrl] = useState(null);
  const [bandName, setBandName] = useState(profileData?.bandName);
  const [contactName, setContactName] = useState(profileData?.contactName);
  const [paypal, setPaypal] = useState(profileData?.paypalId);

  const values = {
    bandName: bandName,
    email: emailAddress,
    contactName: contactName,
    paypalId: paypal,
  };

  const validationSchema = Yup.object().shape({
    bandName: Yup.string()
      .trim()
      .min(2, "Band name should be at least 2 characters long.")
      .max(60)
      .required(ValidationConstants.bandName)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    contactName: Yup.string()
      .trim()
      .min(2, "Contact name should be at least 2 characters long.")
      .max(60)
      .required(ValidationConstants.contactName)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    paypalId: Yup.string()
      .email(ValidationConstants.paypal.invalid)
      .max(255)
      .required(ValidationConstants.paypal.empty),
    email: Yup.string()
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
  });

  const onSubmit = (values) => {
    let formData = new FormData();

    if (pickerGenre.length > 0) {
      if (updateImage) {
        formData.append("bandName", values.bandName);
        formData.append("bandEmail", values.email);
        formData.append("paypalId", values.paypalId);
        formData.append("contactName", values.contactName);
        formData.append("type", JSON.stringify(pickerGenre));
        formData.append("image", updateImage);
      } else {
        formData.append("bandName", values.bandName);
        formData.append("bandEmail", values.email);
        formData.append("paypalId", values.paypalId);
        formData.append("contactName", values.contactName);
        formData.append("type", JSON.stringify(pickerGenre));
      }
      dispatch(editProfileAction(formData));
    } else {
      toast.error("Please select at least one genre type.");
    }
  };

  const selectHeroImage = () => {
    const hero_logo = document.getElementById("select_hero_image");
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };

  const pickerHandleChange = (val) => {
    setPickerGenre(val);
  };


  console.log("picker-----",pickerGenre);

  const setHeroSelectImage = (data) => {
    if (data?.files[0] !== undefined) {
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;
      const imgData = URL.createObjectURL(data.files[0]);
      let checkSize = file_size / max_size <= 10 ? true : false;






      var aspr;
      var img = document.createElement("img");
      var blob = URL.createObjectURL(file);
      img.src = blob;
      if (extentType.indexOf(extentT) >= 0) {
      img.onload = async function () {
        var w = img.width;
        var h = img.height;

        console.log("NEW IMAGE width", w);
        console.log("NEW IMAGE height: ", h);
        // aspr = await ratio(w, h)
        // console.log("aspect ratio: ", aspr);
        let ratioCalculate = w / h;
        let roundUpValue = ratioCalculate.toFixed(1);
        console.log(roundUpValue, "NEW IMAGE Divide: ", w / h);

        // if (aspr == '16:9') {
        // if (roundUpValue == 1.78) {

        const imgData = URL.createObjectURL(data.files[0]);
        let checkSize = file_size / max_size <= 10 ? true : false;
    
          if (checkSize) {
            if (roundUpValue.toString().includes("1.4")) {
              setUpdateImage(file);
                  setEditImageUrl(imgData);
                  setEditImage(imgData);
            } else {
              toast.error(" Only 7:5 ratio images are allowed.");
            }
          } else {
            toast.error("Maximum 10 MB size images are allowed");
          }
        }
      } else{
        toast.error("Please select image only.");
      }




      // if (extentType.indexOf(extentT) >= 0) {
      //   if (checkSize) {
      //     setUpdateImage(file);
      //     setEditImageUrl(imgData);
      //     setEditImage(imgData);
      //   } else {
      //     toast.error("Maximum 10 MB size images are allowed");
      //   }
      // } else if (file) {
      //   toast.error("Select image only.");
      // }
    }
  };

  const { appState } = useSelector((state) => state);
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;


  const disableType = (e) => {
    // e.replace(e,"")
    document.getElementById("stopType").readOnly = true;
    document.getElementById("stopType").maxLength = 0
  }

  useEffect(() => {
    document.title = AppConstant.virtualConcertEditProfile;
    disableType();
    dispatch(getProfileAction());
    dispatch(getGenreList())
  }, []);

  useEffect(() => {
    if (formRef.current) {
      console.log("formRef", formRef);
      formRef.current.setFieldValue("bandName", profileData.bandName);
      formRef.current.setFieldValue("email", profileData.bandEmail);
      formRef.current.setFieldValue("contactName", profileData.contactName);
      formRef.current.setFieldValue("paypalId", profileData.paypalId);
    }

    if (profileData?.type) {
      setPickerGenre(profileData?.type?.map((item) => item?._id));
    }

    if (profileData?.bandLogo) {
      setEditImage(profileData.bandLogo);
    }
  }, [profileData]);

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.BandManagement} />
      <BandSideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Edit Profile" noSearch>
              <div className="edit-user-table-style">
                <div className="row bottom-margin-30">
                  <div
                    className="col-sm-3 center-style band-logo-responsive"
                    style={{ padding: 0 }}
                  >
                    <span className="span-title-style">
                      {AppConstant.BandLogo} (7:5 ratio, PNG or JPEG)
                    </span>
                  </div>
                  <div className="col-2" />

                  <div className="col-3">
                    <Tooltip
                      placement="topLeft"
                      title="Click to upload band logo"
                    >
                      <div
                        onClick={selectHeroImage}
                        className="edit-band-image"
                        style={{ display: "flex" }}
                      >
                        {editImage ? (
                          <img
                            src={
                              showEditImage
                                ? showEditImage
                                : editImage
                                ? getMediaUrl(editImage)
                                : AppImages.BandDummyImage
                            }
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: 200,
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = AppImages.noImage;
                            }}
                            className="pointer-Style"
                          />
                        ) : (
                          <img
                            src={AppImages.Upload}
                            alt=""
                            style={{
                              height: 20,
                              width: 20,
                              position: "absolute",
                              right: "47%",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = AppImages.noImage;
                            }}
                          />
                        )}

                        <input
                          style={{ height: "100%", width: "100%" }}
                          className="d-none"
                          type="file"
                          placeholder="Basic usage"
                          id="select_hero_image"
                          onChange={(evt) => {
                            setHeroSelectImage(evt.target);
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-3" />
                </div>

                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  innerRef={formRef}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div
                        className=" row bottom-margin-30"
                        style={{ marginTop: 30 }}
                      >
                        <TextInput
                          placeholder="Band Name"
                          value={values.bandName}
                          onChange={handleChange}
                          error={Boolean(touched.bandName && errors.bandName)}
                          helperText={touched.bandName && errors.bandName}
                          name="bandName"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </div>
                      <div className=" row bottom-margin-30">
                        <TextInput
                          placeholder="Contact Name"
                          value={values.contactName}
                          onChange={handleChange}
                          error={Boolean(
                            touched.contactName && errors.contactName
                          )}
                          helperText={touched.contactName && errors.contactName}
                          name="contactName"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </div>

                      <div className=" row bottom-margin-30">
                        <TextInput
                          placeholder="Paypal ID"
                          value={values.paypalId}
                          onChange={handleChange}
                          error={Boolean(touched.paypalId && errors.paypalId)}
                          helperText={touched.paypalId && errors.paypalId}
                          name="paypalId"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                      </div>

                      <div style={{ marginBottom: 30, marginTop: 30 }}>
                        <div>
                          <span className="span-title-style">Genre(s)</span>
                        </div>
                        <div
                          className="bottom-margin-checkbox "
                          style={{ marginTop: 10 }}
                        >
                          <Select
                            className="select-style-border"
                            mode="multiple"
                            allowClear
                            id="stopType"
                            onSearch={(e) => {

                              disableType(e)

                            }}
                            name="genre"
                            style={{ width: "100%",caretColor:'white' }}
                            placeholder="Select Genre"
                            value={pickerGenre}
                            onChange={pickerHandleChange}
                          >
                            {genreListData?.map((item, index) => (
                              <Option key={item?._id}>{item?.genreName}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className=" row bottom-margin-30">
                        <TextInput
                          style={{ color: "black" }}
                          placeholder="Email Address"
                          value={values.email}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          name="email"
                          onBlur={handleBlur}
                          type="email"
                          fullWidth
                          disabled={true}
                          inputProps={{
                            maxLength: 80,
                          }}
                        />
                      </div>

                      <div className="band-update-button">
                        <Button
                          title={AppConstant.Update}
                          bg
                          wd
                          type="submit"
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </NavCard>
          </div>
          <Loader visible={onLoad} />
        </div>
      </div>
    </>
  );
};

export default EditProfile;
