import { apiConstants as types } from "../../../themes/apiConstants";
//Login
export const loginAction = (email, password) => {
  console.log(email, password);
  const action = {
    type: types.API_LOGIN_LOAD,
    email,
    password,
  };
  return action;
};

//Forgot password
export const forgotPasswordAction = (email, isAdmin) => {
  const action = {
    type: types.API_FORGOT_PASSWORD_LOAD,
    email,
    isAdmin,
  };
  return action;
};

//Reset password
export const changePasswordAction = (
  oldPassword,
  newPassword,
  confirmPassword
) => {
  const action = {
    type: types.API_CHANGE_PASSWORD_LOAD,
    oldPassword,
    newPassword,
    confirmPassword,
  };
  return action;
};

//Forgot password
export const resetPasswordAction = (values, userType) => {
  const action = {
    type: types.API_RESET_PASSWORD_LOAD,
    values,
    userType,
  };
  return action;
};

//reset password expire
export const checkResetPasswordAction = (values, userType) => {
  const action = {
    type: types.API_RESET_PASSWORD_EXPIRE_LOAD,
    values,
    userType,
  };
  return action;
};
