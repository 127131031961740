import React from "react";
import { Link, Route, Switch, Redirect, Router } from "react-router-dom";
// import PrivateRoute from "./protectedRoute";
import SignIn from "../Admin/components/authentication/signIn.jsx";
import ForgotPassword from "../Admin/components/authentication/forgotPassword.jsx";
import ForgotPasswordSuccess from "../Admin/components/authentication/forgotPasswordSuccess";
import ForgotPasswordReset from "../Admin/components/authentication/forgotPasswordReset";
import { routeConfig } from "./routeConfig";
import PrivateRoute from "./protectedRoute";
import history from "../utils/history";

const Setup = () => {
  console.log("localStorage.token", localStorage.token);
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin"
          render={() =>
            localStorage.token ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/login"
          render={() =>
            localStorage.token ? <Redirect to="/dashboard" /> : <SignIn />
          }
        />

        <Route
          path="/forgotPassword"
          render={() =>
            localStorage.token ? (
              <Redirect to="/dashboard" />
            ) : (
              <ForgotPassword />
            )
          }
        />

        <Route
          path="/forgotPasswordSuccess"
          render={() => <ForgotPasswordSuccess />}
        />

        <Route
          path="/forgotPasswordReset"
          render={() => <ForgotPasswordReset />}
        />

        {/* <Route
          path="/bandManagement"
          component={ForgotPassword}
          //   render={() =>
          //     localStorage.token ? (
          //       <Redirect to="/dashboard" />
          //     ) : (
          //       <ForgotPassword />
          //     )
          //   }
        /> */}

        {/* <Route
          path="/userDetails"
          component={ViewUserDetails}
          //   render={() =>
          //     localStorage.token ? (
          //       <Redirect to="/dashboard" />
          //     ) : (
          //       <ForgotPassword />
          //     )
          //   }
        />

        <Route
          path="/editUser"
          component={EditUser}

        /> */}
        {/* <Route path="/bandManagement" component={BandManagement} />

        <Route path="/concertManagement" component={ConcertManagement} />

        <Route path="/newsManagement" component={NewsManagement} />

        <Route path="/trailerVideo" component={TrailerVideo} /> */}

        {routeConfig.map((route, i) => (
          <PrivateRoute
            exact={route.exact}
            key={route}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default Setup;
