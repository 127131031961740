import { act } from "react-dom/test-utils";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import cl from "../../../utils/cl";

const initialState = {
  onLoad: false,
  errorState: null,
};

export const bandSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BAND_API_SUPPORT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_SUPPORT_SUCCESS:
      cl("support reducer success ===>", action);
      return { ...state, onLoad: false };

    default:
      return state;
  }
};
