import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import "./dashboardLayout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faQuestionCircle,
  faSignOutAlt,
  faFileAlt,
  faLock,
  faPortrait,
  faDollarSign,
  faMoneyCheckAlt,
  faCommentAlt
} from "@fortawesome/free-solid-svg-icons";
import { BandConcertTabStateAction } from "../store/actions/Band/ConcertTabState";
import { fa500px } from "@fortawesome/free-brands-svg-icons";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  List,
  Divider,
  ListItem,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  handleSidebarDrawerToggleStateAction,
} from "../store/actions/index";
import ConfirmationModal from "./confirmationModal";

import AppImages from "../themes/appImages";

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerLogo: {
    position: "relative",
    textAlign: "center",
    padding: theme.spacing(1.1),
  },
  title: {
    fontWeight: 500,
  },
  listPadding: {
    padding: 0,
    marginTop: 20,
  },
  sidebarList: {},
  sidebarIcon: {
    width: 18,
    marginTop: 2,
  },
  typographyRoot: {
    margin: "0 !important",
  },
  breadIcon: {
    width: 24,
    marginBottom: 4,
  },
  customNavbar: {
    padding: 0,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  customNavbarShift: {
    padding: 0,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    display: "flex",
  },
  appBar: {
    padding: "1%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    padding: "1%",
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      marginRight: drawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingLeft: "1%",
    paddingRight: "1%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "baseline",
      justifyContent: "flex-end",
    },
  },
  menuButton: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "#196ea1",
    marginRight: theme.spacing(2),
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      background: "#f5f8f9",
    },
  },
  hide: {
    display: "none",
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  breadcrumbIcon: {
    fontSize: 22,
    marginTop: 5,
  },
  icon: {
    fontSize: 20,
    float: "left",
    lineHeight: 30,
    width: "30px !important",
    textAlign: "center",
    marginRight: 15,
  },
  selectedIcon: {
    fontSize: 21,
    float: "left",
    lineHeight: 30,
    width: "30px !important",
    textAlign: "center",
    marginRight: 15,
  },
  nestedMenuWrapper: {
    backgroundColor: "white",
    marginTop: "0.5rem",
    borderRadius: 4,
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
  },
  logo: {
    width: 160,
    height: 100,
    resize: "contain",
    cursor: "pointer",
  },
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState(false);
  const [noButtonDisabled, setDisabledButton] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);

  const { sidebarDrawerToggleState } = appState;

  const [toggleMenu, setToggleMenu] = useState(false);

  const tabs = [
    {
      icon: faHome,
      name: "Dashboard",
      path: "/band/dashboard",
    },
    {
      icon: fa500px,
      name: "Concerts / Events",
      path: "/band/allConcerts",
    },
    {
      icon: faDollarSign,
      name: "Concert / Event Earnings",
      path: "/band/bookingManagement",
    },
    {
      icon: faMoneyCheckAlt,
      name: "Payment Requests",
      path: "/band/paymentRequest",
    },
    {
      icon: faQuestionCircle,
      name: "FAQ",
      path: "/band/faq",
    },
    {
      icon: faPortrait,
      name: "My Profile",
      path: "/band/profile",
    },
    {
      icon: faLock,
      name: "Change Password",
      path: "/band/changePassword",
    },

    {
      icon: faCommentAlt,
      name: "Support",
      path: "/band/support",
    },

    {
      icon: faFileAlt,
      name: "Terms & Conditions",
      path: "/band/termsAndConditions",
    },
    { icon: faSignOutAlt, name: "Logout" },
  ];

  useEffect(() => {
    setToggleMenu(sidebarDrawerToggleState);
  }, [sidebarDrawerToggleState]);

  useEffect(() => {
    const path = window.location.pathname;

    const urlIndex = tabs.findIndex((item) => {
      return item.path === path;
    });

    urlIndex >= 0 && dispatch(handleNavigationStateAction(urlIndex, false));
  }, []);

  useEffect(() => {
    if (isWidthUp("md", props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(true));
    } else if (isWidthDown("md", props.width)) {
      dispatch(handleSidebarDrawerToggleStateAction(false));
    }
  }, [props.width]);

  const tabItem = localStorage.getItem("tabItem");

  const handleTabClick = (event, name, index, path, isTerminalTab) => {
    if (name === "Logout") {
      setModalState(true);
      return true;
    }

    if (name === "Concerts") {
      dispatch(BandConcertTabStateAction(0));
    }

    if (!isTerminalTab) {
      dispatch(handleNavigationStateAction(index, false));
      setToggleMenu(false);

      history.push(path);
    } else {
      handleToggleMenuTab();
    }
  };

  const handleToggleMenuTab = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className={`${classes.root} hideSideNavScroll`}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant={!isWidthUp("md", props.width) ? "temporary" : "persistent"}
        open={sidebarDrawerToggleState}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={!isWidthUp("md", props.width) ? "right" : "left"}
        onClose={() =>
          toggleMenu
            ? dispatch(handleSidebarDrawerToggleStateAction(false))
            : dispatch(handleSidebarDrawerToggleStateAction(true))
        }
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className={classes.drawerLogo}>
          <img
            src={AppImages.LogoImage}
            alt=""
            className={classes.logo}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            onClick={() => history.push("/band/dashboard")}
          />
        </div>
        <Divider variant="middle" />
        <List classes={{ padding: classes.listPadding }}>
          <div className={classes.sidebarList}>
            {tabs.map(({ icon, iconWhite, name, path }, index) => {
              let isTerminalTab = name === "Terminal Management" ? true : false;
              let isSelectedtab = tabItem == index ? true : false;

              return (
                <React.Fragment key={name}>
                  <ListItem
                    onClick={(event) =>
                      handleTabClick(event, name, index, path, isTerminalTab)
                    }
                  >
                    <div
                      className={
                        isSelectedtab ? "sidebarBtnSelected" : "sidebarBtn"
                      }
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        color={isSelectedtab ? "#ffffff" : "#a9afbb"}
                        className={clsx([
                          classes.icon,
                          isSelectedtab && classes.seletcedTabIcon,
                        ])}
                      />
                      <p
                        className={
                          isSelectedtab
                            ? "sidebarBtnTextSelected"
                            : "sidebarBtnText"
                        }
                      >
                        {name}
                      </p>
                    </div>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </div>
        </List>
        <div className="sidebarBackground" style={{ zIndex: -7 }} />
      </Drawer>

      <ConfirmationModal
        title="Alert"
        visible={modalState}
        setVisible={() => {
          setModalState(!modalState);
          setDisabledButton(true);
          setTimeout(() => {
            setDisabledButton(false);
          }, 1000);
        }}
        disabled={noButtonDisabled}
        onRemoveStorage={() => localStorage.removeItem("bandToken")}
        text="Are you sure you want to logout?"
        logout
        path="/band/login"
      />
    </div>
  );
};

export default withWidth()(DashboardLayout);
