import { takeLatest, call, put } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import { toast } from "react-toastify";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
function* profileFailedSaga(result) {
  cl("FAQ failed", result);
  yield put({
    type: types.BAND_API_PROFILE_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* profileErrorSaga(result) {
  cl("HAHAHAHAA you got error  ", result);
  yield put({
    type: types.BAND_API_PROFILE_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* getProfileSaga() {
  try {
    const result = yield call(api.getProfile);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_PROFILE_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(profileFailedSaga, result);
    }
  } catch (error) {
    yield call(profileErrorSaga, error);
  }
}

export function* editProfileSaga(action) {
  try {
    const result = yield call(api.editProfile, action.values);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_EDIT_PROFILE_SUCCESS,
        result: result.result.data.data,
      });

      toast.success(result.result.data.message);

      history.goBack();
    } else {
      yield call(profileFailedSaga, result);
    }
  } catch (error) {
    yield call(profileErrorSaga, error);
  }
}

export default function* rootProfileSaga() {
  yield takeLatest(types.BAND_API_PROFILE_LOAD, getProfileSaga);
  yield takeLatest(types.BAND_API_EDIT_PROFILE_LOAD, editProfileSaga);
}
