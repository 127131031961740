// const apiUrl = "http://192.168.3.152:5005/"; ////local
// const apiUrl = "http://52.15.253.94:3000/"; ////live

// const apiUrl = "http://18.219.253.106:3000/"; ////live new

// new url
// const apiUrl ="http://1.6.98.141:3015/"
// const apiUrl = "http://192.168.3.173:3000/"
// const apiUrl = "http://1.6.98.142:4005/";
const apiUrl = "https://api.concerto-xl.com/"; ////live production

// Api Action type constants
const apiConstants = {
  // Clear state
  CLEAR_STATE: "CLEAR_STATE",
  // To Handle concert tabs
  HANDLE_CONCERTS_TAB_STATE: "HANDLE_CONCERTS_TAB_STATE",
  // Handle side navigation
  HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
  HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

  // add checkbox
  API_ADD_BAND_EDIT_CHECKBOX: "API_ADD_BAND_EDIT_CHECKBOX",

  // clear added news
  CLEAR_ADDED_NEWS: "CLEAR_ADDED_NEWS",
  // ************ DASHBOARD ADMIN************
  API_GET_DASHBOARD_DETAILS_LOAD: "API_GET_DASHBOARD_DETAILS_LOAD",
  API_GET_DASHBOARD_DETAILS_SUCCESS: "API_GET_DASHBOARD_DETAILS_SUCCESS",
  API_GET_DASHBOARD_DETAILS_FAILED: "API_AUTHENTICATION_FAILED",
  API_GET_DASHBOARD_DETAILS_ERROR: "API_AUTHENTICATION_ERROR",

  // ************ AUTHENTICATION ADMIN************
  // Authentications types
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // Login
  API_LOGIN_LOAD: "API_LOGIN_LOAD",
  API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

  // Forgot Password
  API_FORGOT_PASSWORD_LOAD: "API_FORGOT_PASSWORD_LOAD",
  API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

  // reset Password
  API_RESET_PASSWORD_LOAD: "API_RESET_PASSWORD_LOAD",
  API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

  // reset Password
  API_RESET_PASSWORD_EXPIRE_LOAD: "API_RESET_PASSWORD_EXPIRE_LOAD",
  API_RESET_PASSWORD_EXPIRE_SUCCESS: "API_RESET_PASSWORD_EXPIRE_SUCCESS",

  // Change Password
  API_CHANGE_PASSWORD_LOAD: "API_CHANGE_PASSWORD_LOAD",
  API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",

  // ************ USER MANAGEMENT ************
  // User Management
  API_USER_MANAGEMENT_FAILED: "API_USER_MANAGEMENT_FAILED",
  API_USER_MANAGEMENT_ERROR: "API_USER_MANAGEMENT_ERROR",

  // User Management
  API_GET_USER_MANAGEMENT_LOAD: "API_GET_USER_MANAGEMENT_LOAD",
  API_GET_USER_MANAGEMENT_SUCCESS: "API_GET_USER_MANAGEMENT_SUCCESS",
  API_GET_USER_MANAGEMENT_FAILED: "API_GET_USER_MANAGEMENT_FAILED",
  API_GET_USER_MANAGEMENT_ERROR: "API_GET_USER_MANAGEMENT_ERROR",

  // add rocks to user
  API_ADD_ROCKS_TO_USER_LOAD: "API_ADD_ROCKS_TO_USER_LOAD",
  API_ADD_ROCKS_TO_USER_SUCCESS: "API_ADD_ROCKS_TO_USER_SUCCESS",
  API_ADD_ROCKS_TO_USER_FAILED: "API_ADD_ROCKS_TO_USER_FAILED",
  API_ADD_ROCKS_TO_USER_ERROR: "API_ADD_ROCKS_TO_USER_ERROR",

  //get user by id
  API_GET_USER_BY_ID_LOAD: "API_GET_USER_BY_ID_LOAD",
  API_GET_USER_BY_ID_SUCCESS: "API_GET_USER_BY_ID_SUCCESS",
  API_GET_USER_BY_ID_FAILED: "API_GET_USER_BY_ID_FAILED",
  API_GET_USER_BY_ID_ERROR: "API_GET_USER_BY_ID_ERROR",

  //Update User By Id
  API_UPDATE_USER_BY_ID_LOAD: "API_UPDATE_USER_BY_ID_LOAD",
  API_UPDATE_USER_BY_ID_SUCCESS: "API_UPDATE_USER_BY_ID_SUCCESS",
  API_UPDATE_USER_BY_ID_FAILED: "API_UPDATE_USER_BY_ID_FAILED",
  API_UPDATE_USER_BY_ID_ERROR: "API_UPDATE_USER_BY_ID_ERROR",

  //Get Users Predefined Images
  API_GET_USER_PREDEFINED_IMAGES_LOAD: "API_GET_USER_PREDEFINED_IMAGES_LOAD",
  API_GET_USER_PREDEFINED_IMAGES_SUCCESS:
    "API_GET_USER_PREDEFINED_IMAGES_SUCCESS",
  API_GET_USER_PREDEFINED_IMAGES_FAILED:
    "API_GET_USER_PREDEFINED_IMAGES_FAILED",
  API_GET_USER_PREDEFINED_IMAGES_ERROR: "API_GET_USER_PREDEFINED_IMAGES_ERROR",

  // Band Management
  API_GET_BAND_MANAGEMENT_LOAD: "API_GET_BAND_MANAGEMENT_LOAD",
  API_GET_BAND_MANAGEMENT_SUCCESS: "API_GET_BAND_MANAGEMENT_SUCCESS",
  API_GET_BAND_MANAGEMENT_FAILED: "API_GET_BAND_MANAGEMENT_FAILED",
  API_GET_BAND_MANAGEMENT_ERROR: "API_GET_BAND_MANAGEMENT_ERROR",

  // Band Sorter
  API_SORT_BAND_LOAD: "API_SORT_BAND_LOAD",
  // API_GET_BAND_MANAGEMENT_LOAD: "API_GET_BAND_MANAGEMENT_LOAD",

  // get band by id
  API_GET_BAND_BY_ID_LOAD: "API_GET_BAND_BY_ID_LOAD",
  API_GET_BAND_BY_ID_SUCCESS: "API_GET_BAND_BY_ID_SUCCESS",
  API_GET_BAND_BY_ID_FAILED: "API_GET_BAND_BY_ID_FAILED",
  API_GET_BAND_BY_ID_ERROR: "API_GET_BAND_BY_ID_ERROR",

  // change band status
  API_BLOCK_BAND_LOAD: "API_BLOCK_BAND_LOAD",
  API_BLOCK_BAND_SUCCESS: "API_BLOCK_BAND_SUCCESS",

  // update band by id
  API_UPDATE_BAND_BY_ID_LOAD: "API_UPDATE_BAND_BY_ID_LOAD",
  API_UPDATE_BAND_BY_ID_SUCCESS: "API_UPDATE_BAND_BY_ID_SUCCESS",
  API_UPDATE_BAND_BY_ID_FAILED: "API_UPDATE_BAND_BY_ID_FAILED",
  API_UPDATE_BAND_BY_ID_ERROR: "API_UPDATE_BAND_BY_ID_ERROR",

  // NEWS Management
  API_GET_NEWS_MANAGEMENT_LOAD: "API_GET_NEWS_MANAGEMENT_LOAD",
  API_GET_NEWS_MANAGEMENT_SUCCESS: "API_GET_NEWS_MANAGEMENT_SUCCESS",
  API_GET_NEWS_MANAGEMENT_FAILED: "API_GET_NEWS_MANAGEMENT_FAILED",
  API_GET_NEWS_MANAGEMENT_ERROR: "API_GET_NEWS_MANAGEMENT_ERROR",

  // get news by id
  API_GET_NEWS_BY_ID_LOAD: "API_GET_NEWS_BY_ID_LOAD",
  API_GET_NEWS_BY_ID_SUCCESS: "API_GET_NEWS_BY_ID_SUCCESS",
  API_GET_NEWS_BY_ID_FAILED: "API_GET_NEWS_BY_ID_FAILED",
  API_GET_NEWS_BY_ID_ERROR: "API_GET_NEWS_BY_ID_ERROR",

  // Add News
  API_ADD_NEWS_LOAD: "API_ADD_NEWS_LOAD",
  API_ADD_NEWS_SUCCESS: "API_ADD_NEWS_SUCCESS",
  API_ADD_NEWS_FAILED: "API_ADD_NEWS_FAILED",
  API_ADD_NEWS_ERROR: "API_ADD_NEWS_ERROR",

  // add news clear reducer data
  ADD_NEWS_CLEAR_PREVIOUS_REDUCER_DATA: "ADD_NEWS_CLEAR_PREVIOUS_REDUCER_DATA",

  // Delete News
  API_DELETE_NEWS_LOAD: "API_DELETE_NEWS_LOAD",
  API_DELETE_NEWS_SUCCESS: "API_DELETE_NEWS_SUCCESS",
  API_DELETE_NEWS_FAILED: "API_DELETE_NEWS_FAILED",
  API_DELETE_NEWS_ERROR: "API_DELETE_NEWS_ERROR",

  // update News by id
  API_UPDATE_NEWS_LOAD: "API_UPDATE_NEWS_LOAD",
  API_UPDATE_NEWS_SUCCESS: "API_UPDATE_NEWS_SUCCESS",
  API_UPDATE_NEWS_FAILED: "API_UPDATE_NEWS_FAILED",
  API_UPDATE_NEWS_ERROR: "API_UPDATE_NEWS_ERROR",

  // add news images and videos
  API_ADD_NEWS_IMAGE_LOAD: "API_ADD_NEWS_IMAGE_LOAD",
  API_ADD_NEWS_IMAGE_SUCCESS: "API_ADD_NEWS_IMAGE_SUCCESS",
  API_ADD_NEWS_VIDEO_LOAD: "API_ADD_NEWS_VIDEO_LOAD",
  API_ADD_NEWS_VIDEO_SUCCESS: "API_ADD_NEWS_VIDEO_SUCCESS",

  // add news media box in reducer

  API_ADD_NEWS_BOX_LOAD: "API_ADD_NEWS_BOX_LOAD",
  API_ADD_NEWS_VIDEOS_BOX_LOAD: "API_ADD_NEWS_VIDEOS_BOX_LOAD",

  // delete news media in edit

  API_DELETE_NEWS_MEDIA_LOAD: "API_DELETE_NEWS_MEDIA_LOAD",
  API_DELETE_NEWS_MEDIA_SUCCESS: "API_DELETE_NEWS_MEDIA_SUCCESS",

  // delete youtube videos
  API_DELETE_YOUTUBE_VIDEO_LOAD: "API_DELETE_YOUTUBE_VIDEO_LOAD",
  API_DELETE_YOUTUBE_VIDEO_SUCCESS: "API_DELETE_YOUTUBE_VIDEO_SUCCESS",

  // delete news media in edit

  API_ADD_NEWS_MEDIA_LOAD: "API_ADD_NEWS_MEDIA_LOAD",
  API_ADD_NEWS_MEDIA_SUCCESS: "API_ADD_NEWS_MEDIA_SUCCESS",

  // Get User Details
  API_GET_USER_DETAILS_LOAD: "API_GET_USER_DETAILS_LOAD",
  API_GET_USER_DETAILS_SUCCESS: "API_GET_USER_DETAILS_SUCCESS",

  // user block api
  API_CHANGE_USER_STATUS_LOAD: "API_CHANGE_USER_STATUS_LOAD",
  API_CHANGE_USER_STATUS_SUCCESS: "API_CHANGE_USER_STATUS_SUCCESS",

  //Edit User Details
  API_EDIT_USER_DETAILS_LOAD: "API_EDIT_USER_DETAILS_LOAD",
  API_EDIT_USER_DETAILS_SUCCESS: "API_EDIT_USER_DETAILS_SUCCESS",

  //Block User
  API_BLOCK_USER_LOAD: "API_BLOCK_USER_LOAD",
  API_BLOCK_USER_SUCCESS: "API_BLOCK_USER_SUCCESS",

  //Trailer
  API_GET_TRAILER_DATA_LOAD: "API_GET_TRAILER_DATA_LOAD",
  API_GET_TRAILER_DATA_SUCCESS: "API_GET_TRAILER_DATA_SUCCESS",
  API_TRAILER_FAILED: "API_TRAILER_FAILED",
  API_TRAILER_ERROR: "API_TRAILER_ERROR",

  // Add Trailer
  API_ADD_TRAILER_LOAD: "API_ADD_TRAILER_LOAD",
  API_ADD_TRAILER_SUCCESS: "API_ADD_TRAILER_SUCCESS",
  API_ADD_TRAILER_FAILED: "API_ADD_TRAILER_FAILED",
  API_ADD_TRAILER_ERROR: "API_ADD_TRAILER_ERROR",
  API_ADD_TRAILER_CHECKBOX: "API_ADD_TRAILER_CHECKBOX",

  // Delete Trailer
  API_DELETE_TRAILER_LOAD: "API_DELETE_TRAILER_LOAD",
  API_DELETE_TRAILER_SUCCESS: "API_DELETE_TRAILER_SUCCESS",
  API_DELETE_TRAILER_FAILED: "API_DELETE_TRAILER_FAILED",
  API_DELETE_TRAILER_ERROR: "API_DELETE_TRAILER_ERROR",

  //Concerts

  //approved
  API_GET_APPROVED_CONCERT_LOAD: "API_GET_APPROVED_CONCERT_LOAD",
  API_GET_APPROVED_CONCERT_SUCCESS: "API_GET_APPROVED_CONCERT_SUCCESS",

  API_CONCERT_FAILED: "API_CONCERT_FAILED",
  API_CONCERT_ERROR: "API_CONCERT_ERROR",

  //approved concert buyers user list
  API_GET_APPROVED_CONCERT_BUYERS_LIST_LOAD:
    "API_GET_APPROVED_CONCERT_BUYERS_LIST_LOAD",
  API_GET_APPROVED_CONCERT_BUYERS_LIST_SUCCESS:
    "API_GET_APPROVED_CONCERT_BUYERS_LIST_SUCCESS",
  API_GET_APPROVED_CONCERT_BUYERS_LIST_FAILED:
    "API_GET_APPROVED_CONCERT_BUYERS_LIST_FAILED",
  API_GET_APPROVED_CONCERT_BUYERS_LIST_ERROR:
    "API_GET_APPROVED_CONCERT_BUYERS_LIST_ERROR",

  // approve concert payment status
  API_GET_APPROVED_CONCERT_PAYMENT_STATUS_LOAD:
    "API_GET_APPROVED_CONCERT_PAYMENT_STATUS_LOAD",
  API_GET_APPROVED_CONCERT_PAYMENT_STATUS_SUCCESS:
    "API_GET_APPROVED_CONCERT_PAYMENT_STATUS_SUCCESS",
  API_GET_APPROVED_CONCERT_PAYMENT_STATUS_FAILED:
    "API_GET_APPROVED_CONCERT_PAYMENT_STATUS_FAILED",
  API_GET_APPROVED_CONCERT_PAYMENT_STATUS_ERROR:
    "API_GET_APPROVED_CONCERT_PAYMENT_STATUS_ERROR",

  //unapproved
  API_GET_UNAPPROVED_CONCERT_LOAD: "API_GET_UNAPPROVED_CONCERT_LOAD",
  API_GET_UNAPPROVED_CONCERT_SUCCESS: "API_GET_UNAPPROVED_CONCERT_SUCCESS",
  API_GET_UNAPPROVED_CONCERT_FAILED: "API_GET_UNAPPROVED_CONCERT_FAILED",
  API_GET_UNAPPROVED_CONCERT_ERROR: "API_GET_UNAPPROVED_CONCERT_ERROR",

  // unapproved reject
  API_UNAPPROVED_CONCERT_REJECT_LOAD: "API_UNAPPROVED_CONCERT_REJECT_LOAD",
  API_UNAPPROVED_CONCERT_REJECT_SUCCESS:
    "API_UNAPPROVED_CONCERT_REJECT_SUCCESS",
  API_UNAPPROVED_CONCERT_REJECT_FAILED: "API_UNAPPROVED_CONCERT_REJECT_FAILED",
  API_UNAPPROVED_CONCERT_REJECT_ERROR: "API_UNAPPROVED_CONCERT_REJECT_ERROR",

  // unapproved approved
  API_UNAPPROVED_CONCERT_APPROVE_LOAD: "API_UNAPPROVED_CONCERT_APPROVE_LOAD",
  API_UNAPPROVED_CONCERT_APPROVE_SUCCESS:
    "API_UNAPPROVED_CONCERT_APPROVE_SUCCESS",
  API_UNAPPROVED_CONCERT_APPROVE_FAILED:
    "API_UNAPPROVED_CONCERT_APPROVE_FAILED",
  API_UNAPPROVED_CONCERT_APPROVE_ERROR: "API_UNAPPROVED_CONCERT_APPROVE_ERROR",

  //get concert by id
  API_GET_CONCERT_BY_ID_LOAD: "API_GET_CONCERT_BY_ID_LOAD",
  API_GET_CONCERT_BY_ID_SUCCESS: "API_GET_CONCERT_BY_ID_SUCCESS",
  API_GET_CONCERT_BY_ID_FAILED: "API_GET_CONCERT_BY_ID_FAILED",
  API_GET_CONCERT_BY_ID_ERROR: "API_GET_CONCERT_BY_ID_ERROR",

  EMPTY_CHECKBOX_STATE: "EMPTY_CHECKBOX_STATE",

  CLEAR_REDUCER: "CLEAR_REDUCER",

  BLOCK_BUTTON: "BLOCK_BUTTON",

  // Concert Earning

  // filtered list
  API_GET_FILTERED_BAND_LIST_LOAD: "API_GET_FILTERED_BAND_LIST_LOAD",
  API_GET_FILTERED_BAND_LIST_SUCCESS: "API_GET_FILTERED_BAND_LIST_SUCCESS",
  API_GET_FILTERED_BAND_LIST_FAILED: "API_GET_FILTERED_BAND_LIST_FAILED",
  API_GET_FILTERED_BAND_LIST_ERROR: "API_GET_FILTERED_BAND_LIST_ERROR",

  // concert earning list
  API_GET_CONCERT_EARNING_LIST_LOAD: "API_GET_CONCERT_EARNING_LIST_LOAD",
  API_GET_CONCERT_EARNING_LIST_SUCCESS: "API_GET_CONCERT_EARNING_LIST_SUCCESS",
  API_GET_CONCERT_EARNING_LIST_FAILED: "API_GET_CONCERT_EARNING_LIST_FAILED",
  API_GET_CONCERT_EARNING_LIST_ERROR: "API_GET_CONCERT_EARNING_LIST_ERROR",

  // Payment Request
  API_GET_PAYMENT_REQUEST_LIST_LOAD: "API_GET_PAYMENT_REQUEST_LIST_LOAD",
  API_GET_PAYMENT_REQUEST_LIST_SUCCESS: "API_GET_PAYMENT_REQUEST_LIST_SUCCESS",
  API_GET_PAYMENT_REQUEST_LIST_FAILED: "API_GET_PAYMENT_REQUEST_LIST_FAILED",
  API_GET_PAYMENT_REQUEST_LIST_ERROR: "API_GET_PAYMENT_REQUEST_LIST_ERROR",

  // pay to band
  API_PAY_TO_BAND_LOAD: "API_PAY_TO_BAND_LOAD",
  API_PAY_TO_BAND_SUCCESS: "API_PAY_TO_BAND_SUCCESS",
  API_PAY_TO_BAND_FAILED: "API_PAY_TO_BAND_FAILED",
  API_PAY_TO_BAND_ERROR: "API_PAY_TO_BAND_ERROR",

  // fetch list help
  API_FETCH_LIST_HELP_LOAD: "API_FETCH_LIST_HELP_LOAD",
  API_FETCH_LIST_HELP_SUCCESS: "API_FETCH_LIST_HELP_SUCCESS",
  API_FETCH_LIST_HELP_FAILED: "API_FETCH_LIST_HELP_FAILED",
  API_FETCH_LIST_HELP_ERROR: "API_FETCH_LIST_HELP_ERROR",

  // send reply
  API_SEND_REPLY_TO_LIST_HELP_LOAD: "API_SEND_REPLY_TO_LIST_HELP_LOAD",
  API_SEND_REPLY_TO_LIST_HELP_SUCCESS: "API_SEND_REPLY_TO_LIST_HELP_SUCCESS",
  API_SEND_REPLY_TO_LIST_HELP_FAILED: "API_SEND_REPLY_TO_LIST_HELP_FAILED",
  API_SEND_REPLY_TO_LIST_HELP_ERROR: "API_SEND_REPLY_TO_LIST_HELP_ERROR",

  // fetch replied faq
  API_FETCH_REPLIED_LIST_LOAD: "API_FETCH_REPLIED_LIST_LOAD",
  API_FETCH_REPLIED_LIST_SUCCESS: "API_FETCH_REPLIED_LIST_SUCCESS",
  API_FETCH_REPLIED_LIST_FAILED: "API_FETCH_REPLIED_LIST_FAILED",
  API_FETCH_REPLIED_LIST_ERROR: "API_FETCH_REPLIED_LIST_ERROR",

  //Add concert To Featured Concert
  API_ADD_TO_FEATURED_CONCERTS_LOAD: "API_ADD_TO_FEATURED_CONCERTS_LOAD",
  API_ADD_TO_FEATURED_CONCERTS_SUCCESS: "API_ADD_TO_FEATURED_CONCERTS_SUCCESS",
  API_ADD_TO_FEATURED_CONCERTS_FAILED: "API_ADD_TO_FEATURED_CONCERTS_FAILED",
  API_ADD_TO_FEATURED_CONCERTS_ERROR: "API_ADD_TO_FEATURED_CONCERTS_ERROR",

  //Delete concert From Featured Concert
  API_DELETE_FROM_FEATURED_CONCERTS_LOAD:
    "API_DELETE_FROM_FEATURED_CONCERTS_LOAD",
  API_DELETE_FROM_FEATURED_CONCERTS_SUCCESS:
    "API_DELETE_FROM_FEATURED_CONCERTS_SUCCESS",
  API_DELETE_FROM_FEATURED_CONCERTS_FAILED:
    "API_DELETE_FROM_FEATURED_CONCERTS_FAILED",
  API_DELETE_FROM_FEATURED_CONCERTS_ERROR:
    "API_DELETE_FROM_FEATURED_CONCERTS_ERROR",

  // Get Featured Concert List
  API_GET_FEATURED_CONCERTS_LIST_LOAD: "API_GET_FEATURED_CONCERTS_LIST_LOAD",
  API_GET_FEATURED_CONCERTS_LIST_SUCCESS:
    "API_GET_FEATURED_CONCERTS_LIST_SUCCESS",
  API_GET_FEATURED_CONCERTS_LIST_FAILED:
    "API_GET_FEATURED_CONCERTS_LIST_FAILED",
  API_GET_FEATURED_CONCERTS_LIST_ERROR: "API_GET_FEATURED_CONCERTS_LIST_ERROR",

  // Shop transactions list
  API_GET_PRODUCT_SALE_LIST_LOAD: "API_GET_PRODUCT_SALE_LIST_LOAD",
  API_GET_PRODUCT_SALE_LIST_SUCCESS: "API_GET_PRODUCT_SALE_LIST_SUCCESS",
  API_GET_PRODUCT_SALE_LIST_FAILED: "API_GET_PRODUCT_SALE_LIST_FAILED",
  API_GET_PRODUCT_SALE_LIST_ERROR: "API_GET_PRODUCT_SALE_LIST_ERROR",

  // view video details by id
  API_GET_TRAILER_BY_ID_LOAD: "API_GET_TRAILER_BY_ID_LOAD",
  API_GET_TRAILER_BY_ID_SUCCESS: "API_GET_TRAILER_BY_ID_SUCCESS",
  API_GET_TRAILER_BY_ID_FAILED: "API_GET_TRAILER_BY_ID_FAILED",
  API_GET_TRAILER_BY_ID_ERROR: "API_GET_TRAILER_BY_ID_ERROR",

  // edit video details
  API_EDIT_TRIALER_LOAD: "API_EDIT_TRIALER_LOAD",
  API_EDIT_TRIALER_SUCCESS: "API_EDIT_TRIALER_SUCCESS",
  API_EDIT_TRIALER_FAILED: "API_EDIT_TRIALER_FAILED",
  API_EDIT_TRIALER_ERROR: "API_EDIT_TRIALER_ERROR",
};

// BAND API CONSTANTS

const BandApiConstants = {
  // LOGIN
  BAND_API_LOGIN_LOAD: "BAND_API_LOGIN_LOAD",
  BAND_API_LOGIN_SUCCESS: "BAND_API_LOGIN_SUCCESS",
  BAND_API_LOGIN_FAIL: "BAND_API_LOGIN_FAIL",

  //AUTH BAND
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // FORGOT PASSWORD
  BAND_API_FORGOT_PASSWORD_LOAD: "BAND_API_FORGOT_PASSWORD_LOAD",
  BAND_API_FORGOT_PASSWORD_SUCCESS: "BAND_API_FORGOT_PASSWORD_SUCCESS",

  // CHANGE PASSWORD
  BAND_API_CHANGE_PASSWORD_LOAD: "BAND_API_CHANGE_PASSWORD_LOAD",
  BAND_API_CHANGE_PASSWORD_SUCCESS: "BAND_API_CHANGE_PASSWORD_SUCCESS",

  // Verify Email
  BAND_API_VERIFY_EMAIL_LOAD: "BAND_API_VERIFY_EMAIL_LOAD",
  BAND_API_VERIFY_EMAIL_FAILED: "BAND_API_VERIFY_EMAIL_FAILED",
  BAND_API_VERIFY_EMAIL_SUCCESS: "BAND_API_VERIFY_EMAIL_SUCCESS",

  //REGISTER
  BAND_API_REGISTER_SAVE_DATA: "BAND_API_REGISTER_SAVE_DATA",
  BAND_API_REGISTER_LOAD: "BAND_API_REGISTER_LOAD",
  BAND_API_REGISTER_SUCCESS: "BAND_API_REGISTER_SUCCESS",

  //DASHBOARD
  BAND_API_GET_DASHBOARD_LOAD: "BAND_API_GET_DASHBOARD_LOAD",
  BAND_API_GET_DASHBOARD_SUCCESS: "BAND_API_GET_DASHBOARD_SUCCESS",
  BAND_API_GET_DASHBOARD_ERROR: "BAND_API_GET_DASHBOARD_ERROR",
  BAND_API_GET_DASHBOARD_FAILED: "BAND_API_GET_DASHBOARD_FAILED",

  //BAND CONCERTS
  BAND_API_CONCERT_FAILED: "BAND_API_CONCERT_FAILED",
  BAND_API_CONCERT_ERROR: "BAND_API_CONCERT_ERROR",

  // FETCH PAST CONCERTS
  BAND_API_FETCH_PAST_CONCERT_LOAD: "BAND_API_FETCH_PAST_CONCERT_LOAD",
  BAND_API_FETCH_PAST_CONCERT_SUCCESS: "BAND_API_FETCH_PAST_CONCERT_SUCCESS",

  // FETCH UPCOMING CONCERTS
  BAND_API_FETCH_UPCOMING_CONCERT_LOAD: "BAND_API_FETCH_UPCOMING_CONCERT_LOAD",
  BAND_API_FETCH_UPCOMING_CONCERT_SUCCESS:
    "BAND_API_FETCH_UPCOMING_CONCERT_SUCCESS",

  // FETCH ALL CONCERTS
  BAND_API_FETCH_CONCERT_LOAD: "BAND_API_FETCH_CONCERT_LOAD",
  BAND_API_FETCH_CONCERT_SUCCESS: "BAND_API_FETCH_CONCERT_SUCCESS",
  BAND_API_FETCH_CONCERT_FAILED: "BAND_API_FETCH_CONCERT_FAILED",
  BAND_API_FETCH_CONCERT_ERROR: "BAND_API_FETCH_CONCERT_ERROR",

  // FETCH UNAPPROVED CONCERTS
  BAND_API_FETCH_UNAPPROVED_CONCERT_LOAD:
    "BAND_API_FETCH_UNAPPROVED_CONCERT_LOAD",
  BAND_API_FETCH_UNAPPROVED_CONCERT_SUCCESS:
    "BAND_API_FETCH_UNAPPROVED_CONCERT_SUCCESS",
  BAND_API_FETCH_UNAPPROVED_CONCERT_FAILED:
    "BAND_API_FETCH_UNAPPROVED_CONCERT_FAILED",
  BAND_API_FETCH_UNAPPROVED_CONCERT_ERROR:
    "BAND_API_FETCH_UNAPPROVED_CONCERT_ERROR",

  // FETCH TODAY CONCERTS
  BAND_API_FETCH_TODAY_CONCERT_LOAD: "BAND_API_FETCH_TODAY_CONCERT_LOAD",
  BAND_API_FETCH_TODAY_CONCERT_SUCCESS: "BAND_API_FETCH_TODAY_CONCERT_SUCCESS",

  // FAQ
  BAND_API_FETCH_FAQ_LOAD: "BAND_API_FETCH_FAQ_LOAD",
  BAND_API_FETCH_FAQ_SUCCESS: "BAND_API_FETCH_FAQ_SUCCESS",
  BAND_API_FETCH_FAQ_FAILED: "BAND_API_FETCH_FAQ_FAILED",
  BAND_API_FETCH_FAQ_ERROR: "BAND_API_FETCH_FAQ_ERROR",

  // PROFILE
  BAND_API_PROFILE_LOAD: "BAND_API_PROFILE_LOAD",
  BAND_API_PROFILE_SUCCESS: "BAND_API_PROFILE_SUCCESS",
  BAND_API_PROFILE_FAILED: "BAND_API_PROFILE_FAILED",
  BAND_API_PROFILE_ERROR: "BAND_API_PROFILE_ERROR",

  // SUPPORT
  BAND_API_SUPPORT_LOAD: "BAND_API_SUPPORT_LOAD",
  BAND_API_SUPPORT_SUCCESS: "BAND_API_SUPPORT_SUCCESS",
  BAND_API_SUPPORT_FAILED: "BAND_API_SUPPORT_FAILED",
  BAND_API_SUPPORT_ERROR: "BAND_API_SUPPORT_ERROR",

  // ADD CONCERT
  BAND_API_ADD_CONCERT_LOAD: "BAND_API_ADD_CONCERT_LOAD",
  BAND_API_ADD_CONCERT_SUCCESS: "BAND_API_ADD_CONCERT_SUCCESS",
  BAND_API_ADD_CONCERT_FAILED: "BAND_API_ADD_CONCERT_FAILED",
  BAND_API_ADD_CONCERT_ERROR: "BAND_API_ADD_CONCERT_ERROR",

  // fetch band concert by id
  BAND_API_GET_CONCERT_BY_ID_LOAD: "BAND_API_GET_CONCERT_BY_ID_LOAD",
  BAND_API_GET_CONCERT_BY_ID_SUCCESS: "BAND_API_GET_CONCERT_BY_ID_SUCCESS",
  BAND_API_GET_CONCERT_BY_ID_FAILED: "BAND_API_GET_CONCERT_BY_ID_FAILED",
  BAND_API_GET_CONCERT_BY_ID_ERROR: "BAND_API_GET_CONCERT_BY_ID_ERROR",

  // edit band concert
  BAND_API_EDIT_CONCERT_LOAD: "BAND_API_EDIT_CONCERT_LOAD",
  BAND_API_EDIT_CONCERT_SUCCESS: "BAND_API_EDIT_CONCERT_SUCCESS",
  BAND_API_EDIT_CONCERT_FAILED: "BAND_API_EDIT_CONCERT_FAILED",
  BAND_API_EDIT_CONCERT_ERROR: "BAND_API_EDIT_CONCERT_ERROR",

  // EDIT PROFILE
  BAND_API_EDIT_PROFILE_LOAD: "BAND_API_EDIT_PROFILE_LOAD",
  BAND_API_EDIT_PROFILE_SUCCESS: "BAND_API_EDIT_PROFILE_SUCCESS",
  // // EDIT PROFILE
  // BAND_API_DELETE_CONCERT_LOAD: "BAND_API_DELETE_CONCERT_LOAD",
  // BAND_API_DELETE_CONCERT_SUCCESS: "BAND_API_DELETE_CONCERT_SUCCESS",

  // BAND GET TERMS AND CONDITIONS
  BAND_API_TERMS_AND_CONDITIONS_LOAD: "BAND_API_TERMS_AND_CONDITIONS_LOAD",
  BAND_API_TERMS_AND_CONDITIONS_SUCCESS:
    "BAND_API_TERMS_AND_CONDITIONS_SUCCESS",
  BAND_API_TERMS_AND_CONDITIONS_FAILED: "BAND_API_TERMS_AND_CONDITIONS_FAILED",
  BAND_API_TERMS_AND_CONDITIONS_ERROR: "BAND_API_TERMS_AND_CONDITIONS_ERROR",

  EMPTY_PROFILE_CHECKBOX_STATE: "EMPTY_PROFILE_CHECKBOX_STATE",

  API_ADD_PROFILE_CHECKBOX: "API_ADD_PROFILE_CHECKBOX",

  EMPTY_BAND_REGISTRATION_CHECKBOX_STATE:
    "EMPTY_BAND_REGISTRATION_CHECKBOX_STATE",
  API_ADD_BAND_REGISTRATION_CHECKBOX: "API_ADD_BAND_REGISTRATION_CHECKBOX",

  // band concert earning list
  API_GET_BAND_CONCERT_EARNING_LIST_LOAD:
    "API_GET_BAND_CONCERT_EARNING_LIST_LOAD",
  API_GET_BAND_CONCERT_EARNING_LIST_SUCCESS:
    "API_GET_BAND_CONCERT_EARNING_LIST_SUCCESS",
  API_GET_BAND_CONCERT_EARNING_LIST_FAILED:
    "API_GET_BAND_CONCERT_EARNING_LIST_FAILED",
  API_GET_BAND_CONCERT_EARNING_LIST_ERROR:
    "API_GET_BAND_CONCERT_EARNING_LIST_ERROR",

  // band payment request
  API_GET_BAND_PAYMENT_REQUEST_LOAD: "API_GET_BAND_PAYMENT_REQUEST_LOAD",
  API_GET_BAND_PAYMENT_REQUEST_SUCCESS: "API_GET_BAND_PAYMENT_REQUEST_SUCCESS",
  API_GET_BAND_PAYMENT_REQUEST_FAILED: "API_GET_BAND_PAYMENT_REQUEST_FAILED",
  API_GET_BAND_PAYMENT_REQUEST_ERROR: "API_GET_BAND_PAYMENT_REQUEST_ERROR",

  // band payment request from dashboard
  API_BAND_PAYMENT_REQUEST_DASHBOARD_LOAD:
    "API_BAND_PAYMENT_REQUEST_DASHBOARD_LOAD",
  API_BAND_PAYMENT_REQUEST_DASHBOARD_SUCCESS:
    "API_BAND_PAYMENT_REQUEST_DASHBOARD_SUCCESS",
  API_BAND_PAYMENT_REQUEST_DASHBOARD_FAILED:
    "API_BAND_PAYMENT_REQUEST_DASHBOARD_FAILED",
  API_BAND_PAYMENT_REQUEST_DASHBOARD_ERROR:
    "API_BAND_PAYMENT_REQUEST_DASHBOARD_ERROR",
};

export { apiConstants, BandApiConstants, apiUrl };
