import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  addNewsAction,
  addNewsImagesAction,
  addNewsVideosAction,
  clearAddNewsReducerPreviousDataAction,
  deleteAddedNewsImageAction,
} from "../../../store/actions/admin";
import Button from "../../../customComponents/Button";
import TextArea from "../../../customComponents/TextArea";
import TextInput from "../../../customComponents/InputField";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import AppImages from "../../../themes/appImages";
import * as Yup from "yup";
import { Formik } from "formik";
import "../trailerVideo/trailer.css";
import AppColors from "../../../themes/appColors";

const AddNews = () => {
  const [heroImage1, setHeroImage1] = useState(null);
  const [heroImage5, setHeroImage5] = useState(null);

  const { appState } = useSelector((state) => state);
  const { onLoad } = useSelector((state) => state.newsManagementReducer);
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const { imagesFileArray, imagesUrlArray, videosUrlArray } = useSelector(
    (state) => state.newsManagementReducer
  );

  const [videoIndex, setVideoIndex] = useState(0);

  const [imageIndex, setImageIndex] = useState(0);
  const [imagesArray, setImagesArray] = useState([]);

  const [videosArray, setVideosArray] = useState([]);
  const [videosArrayData, setVideosArrayData] = useState([1, 2]);

  const values = {
    title: "",
    description: "",
    videoLink1: "",
    videoLink2: "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(2, "Title should be at least 2 characters long")
      .required(ValidationConstants.titleRequired)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    description: Yup.string()
      .trim()
      .min(50, "Description should be at least 50 characters long")
      .max(5000, "Description should not be more than 5000 characters.")
      .required(ValidationConstants.descriptionRequired)
      .matches(
        /^[aA-zZ\d\-_'".:;/,@#$%&*^()\s]+$/,
        "Enter alphanumeric values only."
      ),
    videoLink1: Yup.string()
      .min(5, "Please enter a valid video link.")
      .trim()
      .matches(
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Entered YouTube URL is invalid, please enter valid URL."
      ),
    videoLink2: Yup.string()
      .min(5, "Please enter a valid video link.")
      .trim()
      .matches(
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        "Entered YouTube URL is invalid, please enter valid URL."
      ),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    if (imagesFileArray.length > 0) {
      for (let i = 0; i < imagesFileArray.length; ++i) {
        formData.append("images", imagesFileArray[i]);
      }
    }
    formData.append("video1", values.videoLink1);
    formData.append("video2", values.videoLink2);
    formData.append("title", values.title);
    formData.append("description", values.description);
    dispatch(addNewsAction(formData));
  };

  const dispatch = useDispatch();
  const breadData = [
    {
      name: "News Management",
      route: "/newsManagement",
    },
    {
      name: "Add News",
      route: "",
    },
  ];

  useEffect(() => {
    document.title = AppConstant.VirtualConcertAddNews;
    dispatch(clearAddNewsReducerPreviousDataAction());
  }, []);

  
  const selectHeroImage1 = () => {
    const hero_logo = document.getElementById("select_hero_image1");
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };

  const setHeroSelectImage1 = (data, index) => {
    console.log("inside", data.files[0]);
    if (data?.files[0] !== undefined) {
      console.log("data of image file", data.file);
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;

      const imgData = URL.createObjectURL(data.files[0]);
      let checkSize = file_size / max_size <= 10 ? true : false;
      if (extentType.indexOf(extentT) >= 0) {
        if (checkSize) {
          setHeroImage1(imgData);
          dispatch(addNewsImagesAction(index, imgData, file));
          imagesArray.splice(imageIndex, 1, file);
        } else {
          toast.error("Maximum 10 MB size images are allowed.");
        }
      } else if (file) {
        toast.error("Please select png, jpg, jpeg format images only.");
      }
    }
  };

  return (
    <>
      <SideNav />
      <Header data={breadData} title={AppConstant.NewsManagement} />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm">
                  <NavCard title="Add News" noSearch>
                    <>
                      <div
                        className="user-Details-Box"
                        style={{ marginTop: 100 }}
                      >
                        <div className="media-style">
                          <span
                            className="span-title-style"
                            style={{ fontSize: 20 }}
                          >
                            Media
                          </span>
                        </div>
                        <span className="span-title-style">Images</span>

                        <div
                          className="row bottom-margin-20"
                          style={{ marginTop: 10 }}
                        >
                          {imagesUrlArray.map((item, index) => {
                            return (
                              <div
                                className="col-sm-3"
                                style={{ marginBottom: 20 }}
                              >
                                <Tooltip
                                  placement="topLeft"
                                  title={
                                    item
                                      ? "Click to change image"
                                      : "Click to upload image"
                                  }
                                >
                                  <div
                                    onClick={() => {
                                      selectHeroImage1();
                                      setImageIndex(index);
                                    }}
                                    className="upload-image responsive-bottom-margin pointer-Style"
                                    style={{
                                      display: "flex",
                                      height: 180,
                                      width: 180,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item ? (
                                      <>
                                        <img
                                          id="selected_IMG"
                                          src={item}
                                          alt=""
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = AppImages.noImage;
                                          }}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <img
                                        src={AppImages.Upload}
                                        alt=""
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = AppImages.noImage;
                                        }}
                                        style={{
                                          height: 25,
                                          width: 25,
                                        }}
                                      />
                                    )}

                                    <input
                                      style={{ height: "100%", width: "100%" }}
                                      className="d-none input-upload"
                                      type="file"
                                      placeholder="Basic usage"
                                      id="select_hero_image1"
                                      onClick={(event) =>
                                        (event.target.value = null)
                                      }
                                      onChange={(evt) => {
                                        setHeroSelectImage1(
                                          evt.target,
                                          imageIndex
                                        );
                                      }}
                                    />
                                  </div>
                                </Tooltip>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 10,
                                  }}
                                  onClick={() => {}}
                                >
                                  <Tooltip
                                    placement="topLeft"
                                    title="Click to delete image"
                                  >
                                    {imagesUrlArray.length > 1 && (
                                      <img
                                        alt=""
                                        className="pointer-Style"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = AppImages.noImage;
                                        }}
                                        src={AppImages.remove}
                                        style={{ height: 25, width: 25 }}
                                        onClick={() => {
                                          dispatch(
                                            deleteAddedNewsImageAction(index)
                                          );
                                        }}
                                      />
                                    )}
                                  </Tooltip>
                                </div>
                              </div>
                            );
                          })}

                          {imagesFileArray.length > 0 &&
                            imagesFileArray.length < 5 && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 15,
                                }}
                                onClick={() => {
                                  selectHeroImage1();
                                  setImageIndex(imagesFileArray.length);
                                }}
                              >
                                <Tooltip
                                  placement="topLeft"
                                  title="Click to upload image"
                                >
                                  <img
                                    className="pointer-Style"
                                    alt=""
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = AppImages.noImage;
                                    }}
                                    src={AppImages.plus}
                                    style={{ height: 30, width: 30 }}
                                    onClick={() => {}}
                                  />
                                </Tooltip>
                              </div>
                            )}
                        </div>

                        {/* <div
                          className="row bottom-margin-20"
                          style={{ marginTop: 10 }}
                        >
                          {videosUrlArray.map((item, index) => {
                            return (
                              <div className="col-sm responsive-trailer-video-width">
                                <TextInput
                                  id="mainVideo"
                                  placeholder="Video Link 1"
                                  error={Boolean(
                                    touched.videoLink1 && errors.videoLink1
                                  )}
                                  helperText={
                                    touched.videoLink1 && errors.videoLink1
                                  }
                                  name="videoLink1"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.videoLink1}
                                  inputProps={{
                                    maxLength: 250,
                                  }}
                                />

                                <TextInput
                                  style={{ marginTop: 30 }}
                                  id="mainVideo"
                                  placeholder="Video Link 2"
                                  error={Boolean(
                                    touched.videoLink2 && errors.videoLink2
                                  )}
                                  helperText={
                                    touched.videoLink2 && errors.videoLink2
                                  }
                                  name="videoLink2"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.videoLink2}
                                  inputProps={{
                                    maxLength: 250,
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div> */}

                        <div
                          className="row bottom-margin-30"
                          style={{ marginTop: 30 }}
                        >
                          <TextInput
                            placeholder="Title"
                            value={values.title}
                            onChange={handleChange}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            name="title"
                            onBlur={handleBlur}
                            fullWidth
                            inputProps={{
                              maxLength: 30,
                            }}
                          />
                        </div>
                        <div
                          className="row bottom-margin-30"
                          style={{ marginTop: 60 }}
                        >
                          <TextArea
                            placeholder="Description"
                            value={values.description}
                            onChange={handleChange}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            helperText={
                              touched.description && errors.description
                            }
                            name="description"
                            onBlur={handleBlur}
                            fullWidth
                            InputProps
                          />
                        </div>

                        <span
                          style={{
                            color: "red",
                            marginTop: -26,
                            position: "absolute",
                            fontSize: 13,
                          }}
                        >
                          {touched.description && errors.description}
                        </span>

                        <div className="news-button">
                          <Button
                            disabled={onLoad}
                            title={AppConstant.Add}
                            bg
                            mb
                            wd
                            type="submit"
                            style={{
                              backgroundColor: onLoad && AppColors.mainColor,
                              color: "white",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  </NavCard>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddNews;

/*

  const setHeroSelectImage = (data, key) => {
    if (data?.files[0] !== undefined) {
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;

      let h = state?.dimensions?.height;
      let w = state?.dimensions?.width;
      let aspr = w / h;
      let roundUpValue = aspr.toFixed(2);

      console.log("roundValue", w, h, aspr);
      console.log("roundValue dim", state?.dimensions);

      const imgData = URL.createObjectURL(data.files[0]);
      let checkSize = file_size / max_size <= 10 ? true : false;
      if (extentType.indexOf(extentT) >= 0) {
        if (checkSize) {
          if (key === "sponsorLogo") {
            if (roundUpValue.toString().includes("1.7")) {
              setSponosorLogo(file);
              setSponsorImage(imgData);
            } else {
              toast.error(" Only 16:9 ratio images are allowed.");
            }
          } else {
            if (roundUpValue.toString().includes("0.8")) {
              setBandImage(file);
              setHeroImage(imgData);
            } else {
              toast.error(" Only 7:6 ratio images are allowed.");
            }
          }
        } else {
          toast.error("Maximum 10 MB size images are allowed");
        }
      } else if (file) {
        toast.error("Please select image only.");
      }
    }
  };

*/
