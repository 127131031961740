import React, { useEffect, useState } from "react";
import TextInput from "../../../customComponents/InputField";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import AppImages from "../../../themes/appImages";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  resetPasswordAction,
  checkResetPasswordAction,
} from "../../../store/actions/admin";

const ForgotPasswordReset = () => {
  const dispatch = useDispatch();
  let loginType = "isBand";
  const [type, setType] = useState(null);
  const [path, setPath] = useState("");
  const { linkExpire } = useSelector(
    (state) => state.adminAuthenticationReducer
  );
  let url = path?.split("=");
  const forgotType = url[1]?.split("&");
  const time = url[2]?.split("&");

  const checkExpireReset = async () => {
    let url = window.location.href?.split("=");
    const forgotType = url[1]?.split("&");
    const time = url[2]?.split("&");
    if (forgotType[0] === "admin" && forgotType[0] !== "") {
      loginType = "isAdmin";
    } else if (forgotType[0] === "user") {
      loginType = "isUser";
    } else {
      loginType = "isBand";
    }
    let resetFormData = new FormData();
    resetFormData.append("email", url[3]);
    resetFormData.append(loginType, true);
    dispatch(checkResetPasswordAction(resetFormData, loginType));
  };

  useEffect(() => {
    checkExpireReset();
    document.title = AppConstant.VirtualConcertChangePassword;
    setPath(window.location.href);
  }, []);

  const values = {
    otp: "",
    password: "",
    confirmPassword: "",
  };

  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .max(255, ValidationConstants.password.longNew)
      .required(ValidationConstants.password.otpRequired)
      .matches(/^[0-9\s]*$/, "Please enter digits only."),

    password: Yup.string()
      .min(6, "New password should be at least 6 characters long.")
      .required(ValidationConstants.password.newEmpty)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),

    confirmPassword: Yup.string()
      .required(ValidationConstants.password.emptyConfirm)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          ValidationConstants.password.unmathcedConfirm1
        ),
      }),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    let loginType = "isBand";
    if (forgotType[0] === "admin" && forgotType[0] !== "") {
      loginType = "isAdmin";
    } else if (forgotType[0] === "user" && forgotType[0] !== "") {
      loginType = "isUser";
    } else {
      loginType = "isBand";
    }

    let resetFormData = new FormData();
    resetFormData.append("otp", values.otp);
    resetFormData.append("email", url[3]);
    resetFormData.append("password", values.password);
    resetFormData.append(loginType, true);
    resetFormData.append("time", time[0]);


    if (values.password !== values.confirmPassword) {
    }
    dispatch(resetPasswordAction(resetFormData, loginType));
  };

  const linkExpireView = () => {
    return (
      <div className="body-Style">
        <img
          src={AppImages.sadFace}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          className="sadFace-Logo-Image"
        />
        <span className="login-style" style={{ fontSize: 16 }}>
          {AppConstant.resetLink}
        </span>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        ></div>
      </div>
    );
  };

  const resetPasswordView = () => {
    return (
      <div className="body-Style">
        <img
          src={AppImages?.LogoImage || AppImages.noImage}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          className="login-Logo-Image"
        />
        <span className="login-style">Reset Password</span>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextInput
                  placeholder={AppConstant.email}
                  id="email"
                  type="email"
                  value={url[3]}
                  fullWidth
                  disabled={true}
                  style={{
                    marginTop: 8,
                  }}
                />

                <TextInput
                  placeholder={AppConstant.otp}
                  error={Boolean(touched.otp && errors.otp)}
                  helperText={touched.otp && errors.otp}
                  id="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if(e.key === " "){
                      e.preventDefault()
                    }
                  }}
                  type="text"
                  value={values.otp}
                  fullWidth
                  style={{
                    marginTop: 8,
                  
                  }}  
                  
                />

                <TextInput
                  placeholder={AppConstant.newPassword}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  id="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password.trim()}
                  fullWidth
                  onCopy={stopCopyPaste}
                  onPaste={stopCopyPaste}
                  style={{
                    marginTop: 8,
                  }}
                />

                <TextInput
                  placeholder={AppConstant.confirmNewPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  id="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword.trim()}
                  fullWidth
                  onCopy={stopCopyPaste}
                  onPaste={stopCopyPaste}
                  style={{
                    marginTop: 8,
                  }}
                />

                <div className="buttonWrapper" style={{ marginTop: 14 }}>
                  <Button title={AppConstant.Submit} type="submit" bg />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <div className="body-background">
      {linkExpire === null && <div></div>}
      {linkExpire === false && resetPasswordView()}
      {linkExpire === true && linkExpireView()}
    </div>
  );
};
export default ForgotPasswordReset;
