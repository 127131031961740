import { combineReducers } from "redux";
import { appState } from "./appState";
import { adminAuthenticationReducer } from "./admin/authenticationReducer";
import { bandAuthenticationReducer } from "./Band/bandAuthenticationReducer";
import { concertTabsState } from "../reducers/Band/concertTabState";
import { bandRegisterReducer } from "../reducers/Band/bandRegisterReducer";
import { dashboardReducer } from "../reducers/admin/dashboardReducer";
import { trailerReducer } from "../reducers/admin/trailerReducer";
import { concertReducer } from "../reducers/admin/concertReducer";
import { userManagementReducer } from "../reducers/admin/userManagementReducer";
import { bandManagementReducer } from "../reducers/admin/bandManagementReducer";
import { newsManagementReducer } from "../reducers/admin/newsManagementReducer";
import { bandConcertReducer } from "./Band/bandConcertReducer";
import { bandFaqReducer } from "./Band/bandFaqReducer";
import { bandDashboardReducer } from "./Band/bandDashboardReducer";
import { bandProfileReducer } from "./Band/bandProfileReducer";
import { bandTermsAndConditionReducer } from "./Band/bandTermsAndConditionsReducer";
import { earningManagementReducer } from "./admin/earningManagementReducer";
import { paymentRequestReducer } from "./admin/paymentRequestReducer";
import { bandConcertEarningReducer } from "./Band/bandConcertEarningReducer";
import { bandPaymentRequestReducer } from "./Band/bandPaymentRequestReducer";
import { supportReducer } from "./admin/supportReducer";
import { FeaturedConcertReducer } from "./admin/featuredConcertReducer";
import { ProductSaleReducer } from "./admin/productSaleReducer";
import { bandSupportReducer } from "./Band/bandSupportReducer";
const rootReducer = combineReducers({
  appState,
  adminAuthenticationReducer,
  concertTabsState,
  bandAuthenticationReducer,
  dashboardReducer,
  bandRegisterReducer,
  trailerReducer,
  concertReducer,
  userManagementReducer,
  bandManagementReducer,
  newsManagementReducer,
  bandConcertReducer,
  bandFaqReducer,
  bandProfileReducer,
  bandDashboardReducer,
  bandTermsAndConditionReducer,
  earningManagementReducer,
  paymentRequestReducer,
  bandConcertEarningReducer,
  bandPaymentRequestReducer,
  supportReducer,
  FeaturedConcertReducer,
  ProductSaleReducer,
  bandSupportReducer
});

export default rootReducer;
