import React, { useEffect, useState } from "react";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";

import {
  getBandByIdAction,
  getVideoDetailsById,
} from "../../../store/actions/admin";
import { handleNavigationStateAction } from "../../../store/actions";
import { getMediaUrl, getVideoUrl } from "../../../utils/helpers";
import Loader from "../../../customComponents/loader";
// import Iframely from "../../../customComponents/iframly";

const Banddetail = (props) => {
  const { data, arr } = props;

  return (
    <div className="user-Details-Box">
      <div className="row detail-height">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Video Title</span>
        </div>
        <div className="col-sm center-style-items">
          <span>{data?.title ? data?.title : "N/A"}</span>
        </div>
      </div>

      <div className="row profile-genre-style">
        <div className="col-sm center-style-items">
          <span className="span-title-style">Genre</span>
        </div>
        <div
          className="col-sm center-style-items genre-column-style"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          {data?.genre?.map((item, index) => {
            if (index === 0) {
              return item?.genreName;
            } else {
              return ", " + item?.genreName;
            }
          })}
        </div>
      </div>

      <div className="" style={{ marginTop: 30 }}>
        <div className="" style={{ marginTop: 30, marginBottom: 30 }}>
          <span className="span-title-style">Video</span>
        </div>
        {/* <Iframely url={data?.video} /> */}
        <iframe
                className="video-dimension"
                height="100%"
                width="100%"
                // style={{}}
                src={  data?.video && `//www.youtube.com/embed/${getVideoUrl(data?.video)}`}
                frameBorder={0}
                allowFullScreen={true}
                title="video"
              />


      </div>
    </div>
  );
};

export const ViewTrailer = (props) => {
  const history = useHistory();

  const [arr, setArr] = useState(null);

  const breadData = [
    {
      name: "Trailer Video",
      route: "/trailerVideo",
    },
    {
      name: "Trailer Details",
      route: "",
    },
  ];

  const { trailerDetails, onLoad } = useSelector(
    (state) => state.trailerReducer
  );

  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { sidebarDrawerToggleState } = appState;

  useEffect(() => {
    document.title = AppConstant.virtualConcertBandDetails;

    dispatch(getVideoDetailsById({ trailerId: history?.location?.state?.id }));
  }, []);

  useEffect(() => {
    let newArr = trailerDetails?.genre?.sort((a, b) =>
      a.genreName > b.genreName ? 1 : -1
    );
    setArr(newArr);
  }, [trailerDetails]);

  return (
    <>
      <Header data={breadData} title={AppConstant.BandManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Trailer Details" noSearch>
              <Banddetail
                arr={arr}
                props={props}
                data={trailerDetails}
                onLoad={onLoad}
              />
            </NavCard>
          </div>
        </div>
        <Loader visible={onLoad} />
      </div>
    </>
  );
};

export default ViewTrailer;
