import React, { useEffect } from "react";
import Header from "../../../customComponents/Header";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import { getMediaUrl, getVideoUrl } from "../../../utils/helpers";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";

const ReplyDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { replyData } = props?.location?.state;
  console.log("propd", replyData);
  const breadData = [
    {
      name: "Support",
      route: "/support",
    },
    {
      name: "Reply Details",
      route: "",
    },
  ];
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;

  useEffect(() => {
    document.title = AppConstant.VirtualConcertNewsDetails;
  }, []);

  return (
    <div>
      <Header data={breadData} title={AppConstant.NewsManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Reply Details" noSearch>
              <div
                style={{
                  marginTop: 120,
                  marginBottom: 50,
                }}
              >
                <div className="reply-style">
                  <span>Username</span>
                  <p>{replyData?.user ? replyData?.user?.username : replyData?.band?.bandName}</p>
                </div>

                <div className="reply-style">
                  <span>Email Address</span>
                  <p>{replyData?.user ? replyData?.user?.emailId  : replyData?.band?.bandEmail  }</p>
                </div>

                <div className="reply-style">
                  <span>Title</span>
                  <p>{replyData?.title}</p>
                </div>
                <div className="reply-style">
                  <span>Description</span>
                  <p>{replyData?.concern}</p>
                </div>
                <div className="reply-style">
                  <span>Reply</span>
                  <p>{replyData?.reply}</p>
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyDetails;
