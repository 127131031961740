import { takeLatest, call, put } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
import { toast } from "react-toastify";

function* termsAndConditionFailedSaga(result) {
  cl("authentication failed", result);
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* termsAndConditionErrorSaga(result) {
  cl("HAHAHAHAA you got authentication error  ", result);
  yield put({
    type: types.BAND_API_TERMS_AND_CONDITIONS_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* bandTermsAndConditionSaga(action) {
  try {
    const result = yield call(api.getTermsAndConditions);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_TERMS_AND_CONDITIONS_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(termsAndConditionFailedSaga, result);
    }
  } catch (error) {
    yield call(termsAndConditionErrorSaga, error);
  }
}

export default function* rootTermsAndConditionSaga() {
  yield takeLatest(
    types.BAND_API_TERMS_AND_CONDITIONS_LOAD,
    bandTermsAndConditionSaga
  );
}
