import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  concertEarningList: null,
  filteredBandList: null,
  concertListCurrentPage: 1,
  concertListTotalCount: 0,
};

//earning Managemen tReducer
export const earningManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_FILTERED_BAND_LIST_LOAD:
      return { ...state, onLoad: true };
    case types.API_GET_FILTERED_BAND_LIST_SUCCESS:
      state.filteredBandList = action.result.data;
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_FILTERED_BAND_LIST_FAILED:
      state.onLoad = false;
      return state;

    case types.API_GET_FILTERED_BAND_LIST_ERROR:
      state.onLoad = false;
      return state;

    case types.API_GET_CONCERT_EARNING_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_CONCERT_EARNING_LIST_SUCCESS:
      state.concertEarningList = action.result.data;
      state.concertListCurrentPage = action.result.pagination.currentPage;
      state.concertListTotalCount = action.result.pagination.totalCount;
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_CONCERT_EARNING_LIST_FAILED:
      state.onLoad = false;
      return state;

    case types.API_GET_CONCERT_EARNING_LIST_ERROR:
      state.onLoad = false;
      return state;

    default:
      return state;
  }
};
