import { apiConstants as types } from "../../../themes/apiConstants";

//dashboard
export const dashboardAction = (startDate, endDate) => {
  const action = {
    type: types.API_GET_DASHBOARD_DETAILS_LOAD,
    startDate,
    endDate,
  };
  return action;
};
