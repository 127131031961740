import { BandApiConstants as types } from "../../../themes/apiConstants";
// import adminDashboardDataModal from "../../../helper/ObjectModal/adminDashboardModalData";

const initialState = {
  onLoad: false,
  error: null,
  myTermsAndConditions: null,
};

//DASHBOARD REDUCER
export const bandTermsAndConditionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BAND_API_TERMS_AND_CONDITIONS_LOAD:
      //   state.dashboardData = adminDashboardDataModal(0);
      return { ...state, onLoad: true };
    case types.BAND_API_TERMS_AND_CONDITIONS_SUCCESS:
      //   state.dashboardData = adminDashboardDataModal(action.result);

      console.log("myTermsAndConditions", action);

      return {
        ...state,
        myTermsAndConditions: action.result,
        onLoad: false,
      };

    case types.BAND_API_TERMS_AND_CONDITIONS_FAILED:
      //   state.dashboardData = adminDashboardDataModal(0);
      state.onLoad = false;
      return state;

    case types.BAND_API_TERMS_AND_CONDITIONS_ERROR:
      //   state.dashboardData = adminDashboardDataModal(0);
      state.onLoad = false;

      return state;

    default:
      return state;
  }
};
