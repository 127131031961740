import { BandApiConstants as types } from "../../../themes/apiConstants";

import cl from "../../../utils/cl";
import sessionStorage from "../../../utils/localStorage";
const initialState = {
  onLoad: false,
  errorState: null,
  checkbox: [],
  checkboxErrorMessage: "",
  verifyModal : false,
  loading : false
};

// AUTH REDUCER
export const bandAuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login cases
    case types.BAND_API_LOGIN_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_LOGIN_SUCCESS:
      console.log(action.result);
      sessionStorage.setBandAuthToken(action.result);
      // window.location.reload();
      return { ...state, onLoad: false };


      case "OPEN_VERIFICATION_MODAL":
        return {...state, verifyModal : action.value}

        
    // Forgot password
    case types.BAND_API_FORGOT_PASSWORD_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FORGOT_PASSWORD_SUCCESS:
      cl("forgot password api in band reducer ", action);
      // window.location.reload();
      return { ...state, onLoad: false };

    // Change Password
    case types.BAND_API_CHANGE_PASSWORD_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_CHANGE_PASSWORD_SUCCESS:
      console.log(" band change password reducer hits");
      localStorage.clear();
      return { ...state, onLoad: false };

    //empty checkboxes
    case types.EMPTY_BAND_REGISTRATION_CHECKBOX_STATE:
      state.checkbox = [];
      return state;

    //adding checkboxes
    case types.API_ADD_BAND_REGISTRATION_CHECKBOX:
      let default_CheckBox_Array = JSON.parse(JSON.stringify(state.checkbox));
      if (default_CheckBox_Array.includes(action.value)) {
        const index = default_CheckBox_Array.indexOf(action.value);
        default_CheckBox_Array.splice(index, 1);
      } else {
        default_CheckBox_Array.push(action.value);
      }
      state.checkbox = default_CheckBox_Array;

      // console.log("checkbox array", state);

      return state;



      case types.BAND_API_VERIFY_EMAIL_LOAD:
      return {...state , onLoad:true, loading:true}

      case types.BAND_API_VERIFY_EMAIL_SUCCESS:
      return {...state , onLoad:false,loading:false}

case "STOP_LOADING":
  return {...state, onLoad:action.value}

    //band registration
    case types.BAND_API_REGISTER_LOAD:
      return { ...state, onLoad: true, loading:true };
    case types.BAND_API_REGISTER_SUCCESS:
      console.log("registarion reducer hits ==> ", action);
      return { ...state, onLoad: false, loading:false };

    // Fail cases
    case types.API_AUTHENTICATION_FAILED:
      return { ...state, onLoad: false };
    case types.API_AUTHENTICATION_ERROR:
      cl(" band reducer not working properly");
      return { ...state, onLoad: false };
    default:
      return state;
  }
};
