import React, { useState, useEffect } from "react";
import { Table, Button as AntButton, Pagination, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import ConfirmationModal from "../../../customComponents/paymentConfirmationModal";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import Button from "../../../customComponents/Button";
import { dateConvert, timeConvert,convertInMinutesSeconds } from "../../../utils/dateTimeConverter";
import "../../../customStyles/antdStyles.css";
import "../../../customComponents/dashboardLayout.scss";
import {
  approvedConcertAction,
  approvedConcertPaymentStatusAction,
  clearPreviousReducerData,
  addToFeatureById
} from "../../../store/actions/admin";

function onChange(pagination, filters, sorter, extra) {}

const ConcertTable = (props) => {
  const {
    data,
    history,
    dispatch,
    current,
    totalCount,
    onLoad,
    setPaginationPage,
    paginationPage,
    totalFeaturedConcert,
    s
  } = props;
  const [spin, setSpin] = useState(true);

  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(approvedConcertAction(params));
  }

  const getColor = (action) => {
    if (action) {
      return "paid-button";
    } else {
      return "action-button-inline-style";
    }
  };


  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "50px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "concertName",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Venue",
      dataIndex: "concertVenue",
      sorter: {
        compare: (a, b) => a.venue - b.venue,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertVenue || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateFrom",
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{dateConvert(record?.dateFrom || "N/A")}</span>
        </div>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "timeFrom",
      sorter: {
        compare: (a, b) => a.start - b.start,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span> {timeConvert(record?.dateFrom) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Video Duration (Minutes)",
      dataIndex: "totalDuration",
      sorter: {},
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>{ convertInMinutesSeconds(record?.totalDuration)  || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Ticket Price",
      dataIndex: "ticketPrice",
      sorter: {
        compare: (a, b) => a.ticketPrice - b.ticketPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>${record?.ticketPrice + record?.commission || "0"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (text, record) => {
        return (
          <div className="action-style">
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/concertDetails", { id: record._id })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </AntButton>
       { totalFeaturedConcert < 6 ? <AntButton
            className={`${getColor(record?.isFeatured)} action-button-inline-style`}
            disabled={record?.isFeatured}
              type="primary"
              style={{
                width:180
              }}
              onClick={() =>
                dispatch(addToFeatureById(record._id))
                // history.push("/concertDetails", { id: record._id })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}> {record?.isFeatured ? "FEATURED" : "MARK AS FEATURED"} </span>
            </AntButton> : null}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("spinner state ====> ", spin, data);
    if (data.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
    }
  }, [data]);
  return (
    <>
      <div className="">
        <Table
          className="table-responsive user-table-style"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={false}
          showSorterTooltip={false}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: onLoad ? true : false,
          }}
          rowKey={(record) => record._id}
        />
        <div className="pagination-style">
          <Pagination
            className="pagination"
            total={totalCount}
            onChange={(page) => {
              setPaginationPage(page);
              dispatch(
                approvedConcertAction({ limit: 10, offset: (page - 1) * 10, search:s  })
              );
            }}
            current={current}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
};

const ConcertManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [onPayStatus, setOnPayStatus] = useState(false);

  const [paginationPage, setPaginationPage] = useState(1);
  const [s, setS] = useState("");


  const [Id, setId] = useState();

  const breadData = [
    {
      name: "Concert Management",
      route: "/concertManagement",
    },
    {
      name: "Approved Concerts",
      route: "",
    },
  ];
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const {
    approvedConcertData,
    approvedTotalCount,
    approvedCurrentPage,
    onLoad,
    onPaidLoad,
    rehit,
    totalFeaturedConcert
  } = useSelector((state) => state.concertReducer);

  useEffect(() => {
    document.title = AppConstant.virtualConcertConcertManagement;
    dispatch(clearPreviousReducerData());
    dispatch(approvedConcertAction({ limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    if (onPayStatus === true && onPaidLoad === false) {
      dispatch(approvedConcertAction({ limit: 10, offset: 0 }));
      setOnPayStatus(false);
    }
  });

  useEffect(() => {
    if (rehit) {
      dispatch(approvedConcertAction({ limit: 10, offset: 0 }));
      dispatch({type:'REHIT',value:false})
    }
  });


  return (
    <>
      <Header data={breadData} title={AppConstant.ConcertManagement} />
      <SideNav />
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          setModalState(!modalState);
          dispatch(approvedConcertPaymentStatusAction(Id));
          setOnPayStatus(true);
        }}
        text="Are you sure you want to pay for this Concert?"
      />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.UnapprovedConcert}
          bg
          mb
          onClick={() => {
            history.push("/concertManagement");
          }}
        />
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              title="Approved Concerts"
              value={s}
              onChange={(event) => {
                console.log("serach val --> ", event.target.value);
                setS(event.target.value);
                if(event.target.value[0] === " " ){
                }else{ 
                dispatch(
                  approvedConcertAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                  })
                );
                }
              }}
            >
              <ConcertTable
                history={history}
                data={approvedConcertData}
                dispatch={dispatch}
                current={approvedCurrentPage}
                totalCount={approvedTotalCount}
                onLoad={onLoad}
                setModalState={setModalState}
                setId={setId}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
                totalFeaturedConcert={totalFeaturedConcert}
                s={s}
              />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConcertManagement;
