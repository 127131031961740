import { apiConstants as types } from "../../../themes/apiConstants";

//dashboard
export const trailerAction = (params) => {
  const action = {
    type: types.API_GET_TRAILER_DATA_LOAD,
    params,
  };
  return action;
};

export const addTrailerAction = (value) => {
  const action = {
    type: types.API_ADD_TRAILER_LOAD,
    value,
  };
  return action;
};

export const addTrailerCheckboxAction = (value) => {
  const action = {
    type: types.API_ADD_TRAILER_CHECKBOX,
    value,
  };
  return action;
};

export const deleteTrailerAction = (id) => {
  const action = {
    type: types.API_DELETE_TRAILER_LOAD,
    id,
  };
  return action;
};

export const emptyCheckboxState = () => {
  const action = {
    type: types.EMPTY_CHECKBOX_STATE,
  };
  return action;
};


export const getVideoDetailsById = (id) => {
  const action = {
    type: types.API_GET_TRAILER_BY_ID_LOAD,
    id
  };
  return action;
};



export const updateTrailer = (values) => {
  const action = {
    type: types.API_EDIT_TRIALER_LOAD,
    values
  };
  return action;
};
