import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import CusetomDateTimePicker from "../../../customComponents/CustomDateTimePicker";
import {
  getConcertDataByIdAction,
  updateConcertAction,
} from "../../../store/actions/Band";
import { Tooltip } from "antd";
import { TiDelete } from "react-icons/ti";

import AppImages from "../../../themes/appImages";

import SelectInput from "../../../customComponents/Select";
import { getGenreList } from "../../../store/actions";

import AppConstant from "../../../themes/appConstant";
import TextInput from "../../../customComponents/InputField";
import DatePicker from "../../../customComponents/DatePicker";
import TimePicker from "../../../customComponents/TimePicker";
import Loader from "../../../customComponents/loader";
import NavCard from "../../../customComponents/NavCard";
import { convertTime, getMediaUrl } from "../../../utils/helpers";
import { getMinuteAndSeconds } from "../../../utils/dateTimeConverter";

import { ValidationConstants } from "../../../themes/validationConstants";
import Button from "../../../customComponents/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import "./concert.css";
import AppColors from "../../../themes/appColors";
import ConfirmationModal from "../../../customComponents/confirmationModal";
import { videoLinkDeleteAction } from "../../../store/actions/Band/concertAction";

// const breadData = [
//   {
//     name: "Concerts",
//     route: "/band/allConcerts",
//   },
//   {
//     name: "Edit Concert",
//     route: "/band/editConcert",
//   },
// ];

let breadData = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "All Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Edit Concert",
    route: "",
  },
];
let breadData2 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Past Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Edit Concert",
    route: "",
  },
];
let breadData3 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Today's Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Edit Concert",
    route: "",
  },
];
let breadData4 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Upcoming Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Edit Concert",
    route: "",
  },
];

let breadData5 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Unapproved Concerts",
    route: "/band/unapprovedConcerts",
  },
  {
    name: "Edit Concert",
    route: "",
  },
];

const EditConcert = (props) => {
  const id = props?.location?.state?.id;
  const history = useHistory();
  const formRef = useRef();
  const { appState } = useSelector((state) => state);
  const { concertDataById, onLoad } = useSelector(
    (state) => state.bandConcertReducer
  );
  const { value } = useSelector((state) => state.concertTabsState);
  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const dateValue = new Date();
  const [val, setVal] = useState(null);
  const [val2, setVal2] = useState(null);
  const [val3, setVal3] = useState(null);
  const [val4, setVal4] = useState(null);
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [toggle, setTogle] = useState(false);

  //setTogle
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [Breadvalue, setValue] = useState(breadData);
  const [bandImage, setBandImage] = useState(null);
  const [heroImage, setHeroImage] = useState(null);
  const [sponsorLogo, setSponosorLogo] = useState(null);
  const [sponsorImage, setSponsorImage] = useState(null);
  const [PreviousVideos, setPreviousVideos] = useState([]);

  const dispatch = useDispatch();

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const [onDeleteTrailer, setOnDeleteTrailer] = useState(false);

  const [modalState, setModalState] = useState(false);
  const [index, setIndex] = useState(0);
  const [VideosUrl, setVideosUrl] = useState([]);

  const [VideoLinkObj, setVideoLinkObj] = useState({
    url: "",
    duration: "",
    videoDurationSec: "",
  });

  const [VideoLinkArr, setVideoLinkArr] = useState([]);

  const [concertName, setConcertName] = useState("");
  const [concertVenue, setconcertVenue] = useState("");
  const [videoLink, setVideoLink] = useState(concertDataById?.mainVideo);
  const [dateDuration, setDateDuration] = useState(null);
  const [ticketPrice, setTicketPrice] = useState(concertDataById?.ticketPrice);
  const [isForever, setisForever] = useState(concertDataById?.isLastForever);
  const [time, setTime] = useState(null);

  const [videoDuration, setVideoDuration] = useState(
    concertDataById?.totalDuration
  );
  const values = {
    concertName: concertName,
    concertVenue: concertVenue,
    mainVideo: videoLink,
    ticketPrice: ticketPrice,
    totalDuration: videoDuration,
    totalDurationInSeconds: "",
    isForever: isForever,
  };

  const selectHeroImage = (id) => {
    const hero_logo = document.getElementById(id);
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };

  const setHeroSelectImage = (data, key) => {
    if (data?.files[0] !== undefined) {
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent?.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;

      var img = document.createElement("img");
      var blob = URL.createObjectURL(file);
      img.src = blob;
      if (extentType.includes(extent[1])) {
        img.onload = async function () {
          var w = img.width;
          var h = img.height;
          let ratioCalculate = w / h;
          let roundUpValue = ratioCalculate.toFixed(1);

          const imgData = URL.createObjectURL(data.files[0]);
          let checkSize = file_size / max_size <= 10 ? true : false;

          if (checkSize) {
            if (roundUpValue.toString().includes("0.9")) {
              setBandImage(file);
              setHeroImage(imgData);
            } else {
              toast.error(" Only 6:7 ratio images are allowed.");
            }
          } else {
            toast.error("Maximum 10 MB size images are allowed");
          }
        };
      } else if (file) {
        toast.error("Please select png, jpeg and jpg format images only.", {
          toastId: "fdfdf",
        });
      }
    }
  };

  // function for handling custom input state changes in formik
  const customHandleChange = (e, key) => {
    let value = e.target.value;
    if (formRef.current) {
      if (key === "totalDuration") {
        formRef.current.setFieldValue(
          key,
          value.replace(/^[^1-9][^0-9]*$/g, "")
        );
        return;
      }
      formRef.current.setFieldValue(key, value.replace(/[^0-9]*$/g, ""));
    }
  };

  // function to disable current day

  const disableDatesUptoNextDay = (date) => {
    let oneDayLater = moment(new Date(), "DD-MM-YYYYTHH:mm:ss A");
    return date < oneDayLater;
  };

  // function to disable 5 days from current date
  const disableDates = (date) => {
    let fiveDaysAfter = moment(val, "DD-MM-YYYYTHH:mm:ss A").add(4, "days");
    return date < fiveDaysAfter;
  };

  const validationSchema = Yup.object().shape({
    concertName: Yup.string()
      .trim()
      .min(2, "Concert name should be at least 2 characters long.")
      .required(ValidationConstants.concertRequired),
    // mainVideo: Yup.string()
    //   .required(ValidationConstants.mainVideoRequired)
    //   .matches(
    //     /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    //     "Entered YouTube URL is invalid, please enter valid URL."
    //   ),
    ticketPrice: Yup.string().required(ValidationConstants.ticketPriceRequired),
    totalDuration: Yup.string().required(ValidationConstants.videoDuration),
    totalDurationInSeconds: Yup.number().max(
      60,
      "Video seconds should not be greater than 60."
    ),

    // sponsorName: Yup.string()
    //   .min(2, "Sponsor name should be at least 2 characters long.")
    //   .trim()
    //   .required(ValidationConstants.sponsorNameRequired)
    //   .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    concertVenue: Yup.string()
      .min(2, "Concert venue should be at least 2 characters long.")
      .trim()
      .required(ValidationConstants.concertVenueRequired)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
  });
  const validate = (values, props) => {
    const errors = {};
    if (VideoLinkArr?.length == 0) {
      if (!values.url) {
        errors.url = ValidationConstants.mainVideoRequired;
      } else if (
        !values.url.match(
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        )
      ) {
        errors.url = "Entered YouTube URL is invalid, please enter valid URL.";
      }
      if (!values?.duration) {
        errors.duration = ValidationConstants.durationRequired;
      }
      // if (!values?.VideoDurationSec > 60) {
      //   errors.VideoDurationMin =  "Video seconds should not be greater than 60.";
      // }
    }
    return errors;
  };
  console.log("VideosUrl", VideosUrl);
  const onSubmit = (values) => {
    let minutes = parseInt(values.totalDuration) * 60;
    let seconds = values.totalDurationInSeconds
      ? parseInt(values.totalDurationInSeconds)
      : 0;
    let totalTime = minutes + seconds;
    moment.utc(val);
    moment.utc(val2);
    const startDate = moment(val).utc();
    const endDate = moment(val2).utc();
    const startTime = moment.utc(val3).format("HH:mm:ss");
    const endTime = moment.utc(val4).format("HH:mm:ss");
    const dateFrom = startDate + " " + startTime;
    const dateTo = endDate + " " + endTime;

    let genre = genreListData[0]?._id;

    if (type?.length > 1) {
      genre = type;
    }

    let formData = new FormData();

    if (bandImage != null) {
      formData.append("concertImage", bandImage);
    }

    // if (sponsorLogo != null) {
    //   formData.append("sponserLogo", sponsorLogo);
    // }
    let arr = [];
    for (let i = 0; i < concertDataById?.videos?.length; i++) {
      delete concertDataById?.videos[i]?.id;
      arr.push(concertDataById?.videos[i]);
    }
    formData.append("concertName", values.concertName);
    formData.append("videos", JSON.stringify(VideosUrl));
    formData.append("ticketPrice", values.ticketPrice);
    formData.append("totalDuration", totalTime);
    // formData.append("sponserName", values?.sponsorName);
    formData.append("concertVenue", values?.concertVenue);
    formData.append("dateFrom", startDate);
    formData.append("dateTo", endDate);
    formData.append("concertType", genre);
    formData.append("isLastForever", values.isForever);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(updateConcertAction(id, formData, history));
  };

  useEffect(() => {
    document.title = AppConstant.virtualConcertEditConcert;
    if (id) {
      dispatch(getConcertDataByIdAction(id));
      dispatch(getGenreList());
    } else {
      history.push("/band/allConcerts");
    }

    // return ()=>{
    //   toast.dismiss();
    // }
  }, []);

  useEffect(() => {
    switch (value) {
      case 1:
        setValue(breadData2);
        return Breadvalue;

      case 2:
        setValue(breadData3);
        return Breadvalue;

      case 3:
        setValue(breadData4);
        return Breadvalue;

      case 4:
        setValue(breadData5);
        return Breadvalue;

      default:
        setValue(breadData);
        return Breadvalue;
    }
  }, [value]);

  // useEffect for seeting values in formik on page reload

  useEffect(() => {
    if (formRef.current) {
      let timeObject = getMinuteAndSeconds(concertDataById?.totalDuration);
      setTime(timeObject);
      formRef.current.setFieldValue(
        "concertName",
        concertDataById?.concertName
      );
      // formRef.current.setFieldValue(
      //   "isForever",
      //   concertDataById?.isLastForever
      // );
      // formRef.current.setFieldValue("mainVideo", concertDataById?.mainVideo);
      formRef.current.setFieldValue("totalDuration", timeObject?.m);
      formRef.current.setFieldValue("totalDurationInSeconds", timeObject?.s);
      formRef.current.setFieldValue(
        "ticketPrice",
        concertDataById?.ticketPrice
      );
      // formRef.current.setFieldValue(
      //   "sponsorName",
      //   concertDataById?.sponserName
      // );
      formRef.current.setFieldValue(
        "concertVenue",
        concertDataById?.concertVenue
      );
    }
    if (concertDataById?.videos?.length > 0) {
      // let arr = [];
      // for (let i = 0; i < concertDataById?.videos?.length; i++) {
      //   delete concertDataById?.videos[i]?.id;
      //   arr.push(concertDataById?.videos[i]);
      // }
      setVideoLinkArr(concertDataById?.videos);
      setVideosUrl(concertDataById?.videos);
    }
    setVal(moment(concertDataById?.dateFrom));
    setVal2(moment(concertDataById?.dateTo));
    setVal3(moment(concertDataById?.dateFrom));
    setVal4(moment(concertDataById?.dateTo));
    setType(concertDataById?.concertType?._id);
    setisForever(concertDataById.isLastForever);
    setConcertName(concertDataById?.concertName);
    setTicketPrice(concertDataById?.ticketPrice);
    setconcertVenue(concertDataById?.concertVenue);
    // setVideoLinkObj((value) => {
    //   return {
    //     ...value,
    //     duration: (concertDataById?.totalDuration / 60)
    //       .toString()
    //       .split(".")[0],
    //   };
    // });
    // setSponsorImage(getMediaUrl(concertDataById?.sponserLogo));
    setHeroImage(getMediaUrl(concertDataById?.concertImage));
  }, [concertDataById]);

  const onAddVideoClick = () => {
    const { url, duration, videoDurationSec } = VideoLinkObj;
    if (url?.length > 0 && duration?.length > 0 && videoDurationSec < 60) {
      VideoLinkObj.id = Math.random().toString();
      let videoObject = JSON.parse(JSON.stringify(VideoLinkObj));
      videoObject.duration = +(duration * 60) + +videoDurationSec;
      delete videoObject?.videoDurationSec;
      if (VideoLinkArr?.length < 5) {
        setVideosUrl([...VideosUrl, videoObject]);
        setVideoLinkArr([...VideoLinkArr, VideoLinkObj]);
        formRef.current.setFieldValue("url", "");
        formRef.current.setFieldValue("duration", "");
        formRef.current.setFieldValue("videoDurationSec", "");
        setVideoLinkObj({
          url: "",
          duration: "",
          videoDurationSec: "",
        });
      } else {
        toast.error("You only add up to 5 video links.");
      }
    } else if (url?.length === 0) {
      toast.error("Please enter the video link.");
    } else if (duration?.length === 0) {
      toast.error("Please enter the video time in minutes.");
    }
  };
  useEffect(() => {
    if (onDeleteTrailer === true) {
      let arr = [...VideoLinkArr];
      let arr2 = [...VideosUrl];

      arr.splice(index, 1);
      setVideoLinkArr(arr);

      arr2.splice(index, 1);
      setVideosUrl(arr2);
      setOnDeleteTrailer(false);
    }
  }, [modalState]);

  const onVideoLinkCancel = (id, preSelected) => {
    if (VideoLinkArr.length > 1) {
      setModalState(true);
      setIndex(id);
    } else {
      toast.error("Their should be atleast one video in the consert");
    }
  };
  const setFutureDate = () => {
    const d = new Date();
    let a = d.getFullYear();
    d.setFullYear(+a + 100);
    return a;
  };
  const showvideoLinks = () => {
    return VideoLinkArr?.map((item, index) => {
      return (
        <div style={{ position: "relative" }} key={Math.random().toString()}>
          <div
            style={{
              border: "1px solid #9e9e9e",
              position: "relative",
              padding: "15px",
              marginBottom: "20px",
            }}
          >
            <div className="row bottom-margin-30">
              <TextInput
                disabled
                value={item["url"]}
                placeholder={"Video Link"}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="col-sm-5 top-margin-style videoDuration"
                style={{
                  display: "flex",
                  marginBottom: 30,
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                <TextInput
                  disabled
                  value={
                    item?._id
                      ? (item["duration"] / 60).toString().split(".")[0]
                      : item["duration"]
                  }
                  placeholder={"Video duration (minutes)"}
                />
              </div>
              <div
                className="col-sm-5 top-margin-style videoDuration"
                style={{
                  display: "flex",
                  marginBottom: 30,
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                <TextInput
                  disabled
                  value={
                    item?._id
                      ? item["duration"] % 60
                      : item["videoDurationSec"]
                      ? item["videoDurationSec"]
                      : "0"
                  }
                  placeholder={"Video duration (seconds)"}
                />
              </div>
            </div>
          </div>
          <span
            style={{ position: "absolute", top: "-14px", right: " -13px" }}
            onClick={() => onVideoLinkCancel(index)}
          >
            <TiDelete size={30} color={"#F58020"} cursor={"pointer"} />
          </span>
        </div>
      );
    });
  };
  return (
    <>
      <BandHeader data={Breadvalue} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard noSearch title="Edit Concert">
              <div className="band-add-concert-style">
                <div className="row bottom-margin "></div>

                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 30,
                  }}
                >
                  <span className="registration-span-style">
                    Concert Image (6:7 ratio, PNG or JPEG)
                  </span>

                  <Tooltip
                    placement="topLeft"
                    title="Click to change concert image"
                  >
                    <div
                      onClick={() => {
                        selectHeroImage("select_hero_image");
                      }}
                      className={`${
                        heroImage
                          ? "upload-border-radius-style1"
                          : "upload-border-radius-style"
                      } pointer-Style`}
                      style={{ alignSelf: "center" }}
                    >
                      {heroImage ? (
                        <>
                          <img
                            src={heroImage}
                            alt="avatar"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = AppImages.noImage;
                            }}
                            className="pointer-Style register-image"
                          />
                        </>
                      ) : (
                        <img
                          src={AppImages.uploadLight}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          style={{
                            height: 20,
                            width: 20,
                            position: "absolute",
                          }}
                        />
                      )}

                      <input
                        style={{ height: "100%", width: "100%" }}
                        className="d-none"
                        type="file"
                        placeholder="Basic usage"
                        id="select_hero_image"
                        onChange={(evt) => {
                          setHeroSelectImage(evt.target);
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                {/* <div style={{ marginBottom: 20, marginTop: 30 }}>
                  <span className="registration-span-style">
                    Sponsor Logo (16:9 ratio, PNG or JPEG)
                  </span>
                  <Tooltip
                    placement="topLeft"
                    title="Click to change sponsor logo."
                  >
                    <div
                      onClick={() => {
                        selectHeroImage("sponsorLogo");
                      }}
                      className="upload-border-radius-style pointer-Style"
                      style={{ alignSelf: "center" }}
                    >
                      {sponsorImage ? (
                        <img
                          src={sponsorImage}
                          // onLoad={onImgLoad}
                          alt="avatar"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          className="pointer-Style register-image"
                        />
                      ) : (
                        <img
                          src={AppImages.uploadLight}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          style={{
                            height: 20,
                            width: 20,
                            position: "absolute",
                          }}
                        />
                      )}

                      <input
                        style={{ height: "100%", width: "100%" }}
                        className="d-none"
                        type="file"
                        placeholder="Basic usage"
                        id="sponsorLogo"
                        onChange={(evt) => {
                          setHeroSelectImage(evt.target, "sponsorLogo");
                        }}
                      />
                    </div>
                  </Tooltip>
                </div> */}

                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                  innerRef={formRef}
                  validate={validate}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div
                        className="row bottom-margin-30"
                        style={{
                          marginTop: 50,
                        }}
                      >
                        {console.log("errors", errors)}
                        <TextInput
                          placeholder="Artist and Event Name (i.e. The Generals - Video Premier)"
                          value={values.concertName}
                          onChange={handleChange}
                          error={Boolean(
                            touched.concertName && errors.concertName
                          )}
                          helperText={touched.concertName && errors.concertName}
                          name="concertName"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </div>
                      <div className="row bottom-margin-30">
                        <TextInput
                          id="concertVenue"
                          placeholder="Concert Venue"
                          value={values.concertVenue}
                          onChange={handleChange}
                          error={Boolean(
                            touched.concertVenue && errors.concertVenue
                          )}
                          helperText={
                            touched.concertVenue && errors.concertVenue
                          }
                          name="concertVenue"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 35,
                          }}
                        />
                      </div>

                      {/* <div className="row bottom-margin-30">
                        <TextInput
                          id="sponsorName"
                          placeholder="Sponsor Name"
                          value={values.sponsorName}
                          onChange={handleChange}
                          error={Boolean(
                            touched.sponsorName && errors.sponsorName
                          )}
                          helperText={touched.sponsorName && errors.sponsorName}
                          name="sponsorName"
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            maxLength: 35,
                          }}
                        />
                      </div> */}

                      <div
                        style={{
                          border: "1px solid #9e9e9e ",
                          padding: " 19px 19px 0px 19px",
                          display: `${
                            VideoLinkArr?.length === 5 ? "none" : "block"
                          }`,
                        }}
                        className={"bottom-margin-30"}
                      >
                        {/* <div> */}
                        <div className="row bottom-margin-30">
                          <TextInput
                            id="mainVideo"
                            placeholder="Video Link"
                            error={Boolean(touched.url && errors.url)}
                            helperText={touched.url && errors.url}
                            name="url"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setVideoLinkObj((value) => {
                                return {
                                  ...value,
                                  url: e.target.value,
                                };
                              });
                            }}
                            value={values.url}
                            inputProps={{
                              maxLength: 250,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="col-sm-5 top-margin-style videoDuration"
                            style={{
                              display: "flex",
                              marginBottom: 0,
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            <TextInput
                              addName="vid-duration"
                              id="totalDuration"
                              placeholder="Video duration (minutes)"
                              error={Boolean(
                                touched.duration && errors.duration
                              )}
                              helperText={touched.duration && errors.duration}
                              name="duration"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                customHandleChange(e, "duration");
                              }}
                              value={values.duration}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                                setVideoLinkObj((value) => {
                                  return {
                                    ...value,
                                    duration: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div
                            className="col-sm-5 top-margin-style videoDuration"
                            style={{
                              display: "flex",
                              marginBottom: 0,
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            <TextInput
                              addName="vid-duration"
                              id="totalDuration"
                              placeholder="Video duration (seconds)"
                              error={Boolean(
                                touched.videoDurationSec &&
                                  errors.videoDurationSec
                              )}
                              helperText={
                                touched.videoDurationSec &&
                                errors.videoDurationSec
                              }
                              name="videoDurationSec"
                              onBlur={handleBlur}
                              onChange={(e) =>
                                customHandleChange(e, "videoDurationSec")
                              }
                              value={values.videoDurationSec}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 2);
                                setVideoLinkObj((value) => {
                                  return {
                                    ...value,
                                    videoDurationSec: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <span style={{ justifyContent: "center" }}>
                          <div className="band-button">
                            <Button
                              // disabled={inProgress}
                              title={AppConstant.Add}
                              bg
                              wd
                              // loading={inProgress}
                              type="button"
                              style={{
                                backgroundColor: AppColors.mainColor,
                                color: "white",
                                margin: "20px 0px",
                              }}
                              onClick={onAddVideoClick}
                            />
                          </div>
                        </span>
                        {/* </div> */}
                      </div>
                      <div
                        style={{
                          border: "1px solid #9e9e9e ",
                          padding: "19px 19px 0px 19px",
                          display: `${
                            VideoLinkArr?.length === 0 ? "none" : "block"
                          }`,
                        }}
                        className={"bottom-margin-30"}
                      >
                        {VideoLinkArr?.length > 0 &&
                          showvideoLinks(VideoLinkArr, false)}
                        {PreviousVideos?.length > 0 &&
                          showvideoLinks(PreviousVideos, true)}
                      </div>
                      <div className="row bottom-margin-30">
                        <div
                          className="col-sm-5"
                          style={{ padding: 0, display: "grid" }}
                        >
                          <CusetomDateTimePicker
                            title="Start Date/Time"
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            val={val}
                            disablePast
                            shouldDisableDate={disableDatesUptoNextDay}
                            onChange={(date, value) => {
                              setVal2(moment(date).add(5, "days"));
                              setVal(date);
                              setVal3(date);
                              setVal4(date);
                            }}
                            onAccept={(time) => {
                              let close = moment(val2);
                              let open = moment(time);
                              let diff = open.diff(close, "minute");

                              let date = moment(val);

                              let nextDay = moment(val);
                              let currentTime = open.diff(
                                new Date(),
                                "minutes"
                              );
                              let NextDayTime = open.diff(
                                new Date(),
                                "minutes"
                              );

                              if (NextDayTime < 1440) {
                                // setVal3(new Date());
                                toast.error(
                                  "you cannot select start time before 24 hours fron current date/time."
                                );
                                setVal(
                                  moment(dateValue, "DD-MM-YYYYTHH:mm:ss").add(
                                    1,
                                    "days"
                                  )
                                );
                                setVal2(
                                  moment(dateValue, "DD-MM-YYYYTHH:mm:ss").add(
                                    5,
                                    "days"
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-sm-2"></div>
                        <div
                          className="col-sm-5  responsive-picker-margin"
                          style={{ padding: 0, display: "grid" }}
                        >
                          <CusetomDateTimePicker
                            title="End Date/Time"
                            open={open2}
                            maxDate={() => setFutureDate()}
                            disablePast
                            onOpen={() => setOpen2(true)}
                            onClose={() => setOpen2(false)}
                            val={val2}
                            shouldDisableDate={disableDates}
                            onChange={(date, value) => setVal2(date)}
                            onAccept={(time) => {
                              let close = moment(val);
                              let open = moment(time);

                              let selectedTime = moment(time);
                              let timediff = selectedTime.diff(val, "minute");

                              let diff = open.diff(close, "minute");
                              setDateDuration(diff);
                              if (diff < 0) {
                                toast.error(
                                  "End date should be greater than start date"
                                );
                                setOpen4(null);
                                setVal2(val2);
                              } else if (timediff < 7200) {
                                toast.error(
                                  "Minimum difference between start date/time and end date/time should be of 5 days."
                                );
                                setVal2(val2);
                              } else {
                                setOpen4(null);
                              }
                              setOpen4(false);
                            }}
                          />
                        </div>
                        <div className="endDate-style">
                          <span>(End Date / Time sets automatically)</span>
                        </div>
                      </div>

                      <div
                        className="row bottom-margin-30"
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="col-sm-5  responsive-picker-margin2"
                          style={{ padding: 0, display: "grid" }}
                        >
                          <TextInput
                            id="totalDuration"
                            placeholder="Total Video Duration (minutes)"
                            value={values.totalDuration}
                            onChange={(e) =>
                              customHandleChange(e, "totalDuration")
                            }
                            error={Boolean(
                              touched.totalDuration && errors.totalDuration
                            )}
                            helperText={
                              touched.totalDuration && errors.totalDuration
                            }
                            name="videoDuration"
                            onBlur={handleBlur}
                            fullWidth
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 4);
                            }}
                          />
                        </div>

                        <div
                          className="col-sm-5  responsive-picker-margin2"
                          style={{ padding: 0, display: "grid" }}
                        >
                          <TextInput
                            id="totalDuration"
                            placeholder="Total Video Duration (seconds)"
                            value={values.totalDurationInSeconds}
                            onChange={(e) =>
                              customHandleChange(e, "totalDurationInSeconds")
                            }
                            error={Boolean(
                              touched.totalDurationInSeconds &&
                                errors.totalDurationInSeconds
                            )}
                            helperText={
                              touched.totalDurationInSeconds &&
                              errors.totalDurationInSeconds
                            }
                            name="totalDurationInSeconds"
                            onBlur={handleBlur}
                            fullWidth
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 2);
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                          <span
                            style={{
                              fontWeight: "700",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{
                              fontWeight: "600",
                              marginLeft: 10,
                              color: "#aaaaaa",
                            }}
                          >
                            In this early release, you need to manually add up
                            your total event length and enter above – thanks.
                          </span>
                        </div>
                      </div>

                      <div
                        className=" row bottom-margin-30 selectStyle"
                        style={{ marginTop: 10 }}
                      >
                        <SelectInput
                          default={concertDataById?.concertType?._id}
                          title="Concert Genre"
                          options={genreListData}
                          value={type}
                          onChange={(event) => setType(event.target.value)}
                        />
                      </div>

                      <div className="row bottom-margin-30">
                        <TextInput
                          id="ticketPrice"
                          placeholder="Ticket Price (in USD that you wish to receive) - Enter 0 for a free concert / event."
                          value={values.ticketPrice}
                          onChange={(e) => customHandleChange(e, "ticketPrice")}
                          error={Boolean(
                            touched.ticketPrice && errors.ticketPrice
                          )}
                          helperText={touched.ticketPrice && errors.ticketPrice}
                          name="ticketPrice"
                          onBlur={handleBlur}
                          fullWidth
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                        />
                      </div>
                      <div className="row bottom-margin-30">
                        <span
                          style={{
                            fontWeight: "700",
                            color: "red",
                          }}
                        >
                          *
                        </span>
                        <span
                          style={{
                            fontWeight: "600",
                            marginLeft: 10,
                            color: "#aaaaaa",
                          }}
                        >
                          Make this concert / event run permanently
                        </span>
                        <input
                          className="CheckBoxInput"
                          defaultChecked={values?.isForever}
                          type="checkbox"
                          name="isForever"
                          onChange={handleChange}
                          value={values?.isForever}
                        />
                      </div>
                      <div className="addConcert-note">
                        <span
                          style={{
                            fontWeight: "600",
                            marginLeft: 10,
                            color: "#aaaaaa",
                          }}
                        >
                          When you submit your concert / event, please remember
                          it will be queued for approval and will not show in
                          your concerts / events until approved.
                        </span>
                      </div>

                      <div className="band-button">
                        <Button
                          loading={onLoad}
                          title={AppConstant.Update}
                          bg
                          mb
                          wd
                          type="submit"
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </NavCard>
          </div>
          <ConfirmationModal
            title="Alert"
            visible={modalState}
            closeModal={() => {
              setModalState(false);
            }}
            setVisible={() => {
              setModalState(false);
              setOnDeleteTrailer(true);
            }}
            text="Are you sure, you want to delete this video link?"
          />
          <Loader visible={onLoad} />
        </div>
      </div>
    </>
  );
};

export default EditConcert;
