import React, { useRef } from "react";
import { Modal, Button as AntButton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { sendReplyAction } from "../store/actions/admin";
import TextArea from "./TextArea";
import Button from "./Button";
import { ValidationConstants } from "../themes/validationConstants";

const ReplyModal = (props) => {
  const dispatch = useDispatch();
  const { replyModalState, onLoad } = useSelector(
    (state) => state.supportReducer
  );
  const formRef = useRef();
  const values = {
    reply: "",
  };

  const validationSchema = Yup.object().shape({
    reply: Yup.string()
      .trim()
      .min(50, "Reply should be at least 50 characters long.")
      .max(5000, "Reply should not be more than 5000 characters.")
      .required(ValidationConstants.reply)
      .matches(
        /^[aA-zZ\d\-_'".:;/,@#$%&*^()\s]+$/,
        "Enter alphanumeric values only."
      ),
  });

  const onSubmit = (values) => {
    // dispatch({ type: "REHIT_LIST", value: true });
    dispatch(sendReplyAction({ helpId: props?.replyId, reply: values.reply }));
    // formRef.current.setFieldValue("reply", "");
    formRef.current.resetForm();
    dispatch({
      type: "CAHNGE_REPLY_MODAL_STATE",
      value: false,
    });

    // resetForm();
  };

  return (
    <>
      <Modal
        className="reply-modal"
        // title={props.title}
        visible={replyModalState}
        onOk={props.onOk}
        onCancel={props.onCancel}
        // confirmLoading={confirmLoading}
      >
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            resetForm,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextArea
                  placeholder="Reply.."
                  value={values.reply}
                  onChange={handleChange}
                  error={Boolean(touched.reply && errors.reply)}
                  helperText={touched.reply && errors.reply}
                  name="reply"
                  onBlur={handleBlur}
                />
                <span
                  style={{
                    color: "red",
                    fontSize: 13,
                  }}
                >
                  {touched.reply && errors.reply}
                </span>
              </div>

              <div className="reply-modal-button-style">
                <div className="spacing">
                  <Button
                    title={"Cancel"}
                    bg
                    onClick={() => {
                      formRef.current.resetForm();

                      dispatch({
                        type: "CAHNGE_REPLY_MODAL_STATE",
                        value: false,
                      });
                    }}
                  ></Button>
                </div>
                <div className="spacing">
                  <Button
                    title={"Submit"}
                    // disabled
                    bg
                    type="submit"
                    onClick={() => {
                      props.onSubmitReply();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ReplyModal;

// Method = "GET"
// URL = /admin/auth/listHelp

// send query parameter "isReply" to fetch list for replied faq
// otherwise new faq comes

// Method = "POST"
// URL = /admin/auth/reply

// body = {
//     "helpId":"6093a8c1edf62a456a50a12c",
//     "reply":"reply"
// }
