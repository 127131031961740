import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import AppConstant from "../../../themes/appConstant";
import TextInput from "../../../customComponents/InputField";
import NavCard from "../../../customComponents/NavCard";
import Button from "../../../customComponents/Button";
import { bandChangePasswordAction } from "../../../store/actions/Band";
import { ValidationConstants } from "../../../themes/validationConstants";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../myProfile/profile.css";
import { useHistory } from "react-router";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { appState } = useSelector((state) => state);
  const state = useSelector((state) => state.bandAuthenticationReducer)

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const breadData = [
    {
      name: "Change Password",
      route: "/changePassword",
    },
  ];

  const values = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(ValidationConstants.password.oldPassword)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),

    newPassword: Yup.string()
      .required(ValidationConstants.password.newEmpty)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .min(6, "New password should be at least 6 characters long."),

    confirmPassword: Yup.string()
      .required(ValidationConstants.password.emptyConfirm)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          ValidationConstants.password.unmathcedConfirm1
        ),
      }),
  });
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch(bandChangePasswordAction(values,history));
  };

  useEffect(() => {
    document.title = AppConstant.VirtualConcertChangePassword;
  }, []);

  const ChangePasswordScreen = () => {
    return (
      <>
        <div
          className="band-user-Details-Box"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
            }}
          >
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <TextInput
                      id="oldPassword"
                      placeholder="Old Password"
                      type="password"
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password.trim()}
                      fullWidth
                      onCopy={stopCopyPaste}
                      onPaste={stopCopyPaste}
                      style={{
                        marginTop: 8,
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <TextInput
                      id="newPassword"
                      placeholder="New Password"
                      type="password"
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.newPassword.trim()}
                      fullWidth
                      onCopy={stopCopyPaste}
                      onPaste={stopCopyPaste}
                      style={{
                        marginTop: 8,
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <TextInput
                      id="confirmPassword"
                      placeholder="Confirm New Password"
                      type="password"
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword.trim()}
                      fullWidth
                      onCopy={stopCopyPaste}
                      onPaste={stopCopyPaste}
                      style={{
                        marginTop: 8,
                      }}
                    />
                  </div>

                  <div
                    className="buttonWrapper"
                    style={{ marginTop: 14, alignSelf: "center" }}
                  >
                    <Button style={{ width:100}}  loading={state.onLoad} title={AppConstant.Update} bg type="submit" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Change Password" noSearch>
              <ChangePasswordScreen />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
