import React, { useEffect, useState } from "react";
import Header from "../../../customComponents/Header";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import { getMediaUrl, getVideoUrl } from "../../../utils/helpers";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import { getNewsByIdAction } from "../../../store/actions/admin";
import Loader from "../../../customComponents/loader";
import AppImages from "../../../themes/appImages";

const Newsdetail = ({ data, onLoad }) => {
  const { description, title } = data;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [popUpImage, setPopUpImage] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {(data?.images || data?.video1 || data?.video2) && (
        <div
          className="user-Details-Box"
          style={{
            marginTop: data?.images?.length > 0 ? 100 : 0,
          }}
        >
          <span className="span-title-style">
            {data.images.length > 0 && "Images"}
          </span>
          <div className="row" style={{ marginBottom: 50, marginTop: 20 }}>
            {data?.images?.map((item) => {
              return (
                <div
                  className="col-sm-3 show-detail-image  responsive-bottom-margin"
                  style={{
                    resize: "cover",
                    margin: 20,
                    marginLeft: 0,
                    minWidth: 180,
                  }}
                >
                  <img
                    className="pointer-Style"
                    alt=""
                    src={getMediaUrl(item?.url)}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = AppImages.noImage;
                    }}
                    style={{
                      width: 180,
                      height: 180,
                    }}
                    resize="contain"
                    onClick={() => {
                      setPopUpImage(item.url);
                      showModal();
                    }}
                  />
                </div>
              );
            })}

            <Modal visible={isModalVisible} onCancel={handleCancel} width={350}>
              <div className="image-modal">
                <img
                  src={getMediaUrl(popUpImage)}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = AppImages.noImage;
                  }}
                  className="bandImage-Style-Modal"
                />
              </div>
            </Modal>
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div
              className="col-sm-4 "
              style={{ paddingTop: 10, textAlign: "justify" }}
            >
              <span className="span-title-style">Title</span>
            </div>
            <div
              className="col-sm-8 textInside"
              style={{ paddingTop: 10, width: 200 }}
            >
              <span>{title ? title : "N/A"}</span>
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div
              className="col-sm-4 "
              style={{
                paddingTop: 10,
              }}
            >
              <span className="span-title-style">Description</span>
            </div>
            <div
              className="col-sm-8 textInside"
              style={{
                paddingTop: 10,
                width: 200,
              }}
            >
              <span>{description ? description : "N/A"}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const ViewNewsDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { newsByIdData, onLoad } = useSelector(
    (state) => state.newsManagementReducer
  );
  const id = props.location?.state?.record?._id;
  const breadData = [
    {
      name: "News Management",
      route: "/newsManagement",
    },
    {
      name: "News Details",
      route: "",
    },
  ];
  const classes = useStyles();
  const { appState } = useSelector((state) => state);

  useEffect(() => {
    document.title = AppConstant.VirtualConcertNewsDetails;

    if (!id) {
      history.push("/newsManagement");
    } else {
      dispatch(getNewsByIdAction(id));
    }
  }, []);
  const { sidebarDrawerToggleState } = appState;

  return (
    <>
      <Header data={breadData} title={AppConstant.NewsManagement} />
      <SideNav />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="News Details" noSearch>
              <Newsdetail data={newsByIdData} onLoad={onLoad} />
            </NavCard>
          </div>
        </div>
        <Loader visible={onLoad} />
      </div>
    </>
  );
};

export default ViewNewsDetail;
