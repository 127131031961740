import { BandApiConstants as types } from "../../../themes/apiConstants";

// support
export const supportAction = (values) => {
  const action = {
    type: types.BAND_API_SUPPORT_LOAD,
    values
  };
  return action;
};

