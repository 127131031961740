const AppImages = {
  LogoImage: require("../images/logo.png").default,
  TestImage: require("../images/test.jpg").default,
  BandDollar: require("../images/band-dollar.png").default,
  Band: require("../images/band.png").default,
  Booking: require("../images/booking.png").default,
  DollarCard: require("../images/cards-with-dollar-sign.png").default,
  Users: require("../images/user.png").default,
  Background: require("../images/splash.png").default,
  uploadWhite: require("../images/uploadWhite.png").default,
  plus: require("../images/plus.png").default,
  BandDummyImage: require("../images/bandImage.jpg").default,
  Upload: require("../images/upload.png").default,
  RightArrow: require("../images/right-arrow.png").default,
  userProfile: require("../images/userProgileImage.jpg").default,
  ProfileImage: require("../images/tableUser.png").default,
  SuccessIcon: require("../images/successIcon.png").default,
  uploadLight: require("../images/uploadLight.png").default,
  remove: require("../images/remove.png").default,


  noImage: require("../images/img21.png").default,


  todayIcon: require("../images/today.png").default,
  pastIcon: require("../images/past.png").default,
  upcomingIcon: require("../images/upcoming_icon.png").default,
  sadFace: require("../images/sadFace.png").default,
  minus: require("../images/minus.png").default,
  totalEarning: require("../images/totalEarning.png").default,
  currentEarning: require("../images/currentEarning.png").default,
  requestPayment: require("../images/request-payment.png").default,
};

export default AppImages;
