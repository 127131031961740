import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { useHistory } from "react-router-dom";
import CusetomDateTimePicker from "../../../customComponents/CustomDateTimePicker";
import { Modal, Tooltip } from "antd";
import { genre_List } from "../../../mocks/genreJson";
import moment from "moment";
import { toast } from "react-toastify";
import SelectInput from "../../../customComponents/Select";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import TextInput from "../../../customComponents/InputField";
import DatePicker from "../../../customComponents/DatePicker";
import TimePicker from "../../../customComponents/TimePicker";
import NavCard from "../../../customComponents/NavCard";
import Button from "../../../customComponents/Button";
import { addBandConcertAction } from "../../../store/actions/Band";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";
import Loader from "../../../customComponents/loader";
import "./concert.css";
import "../myProfile/profile.css";
import AppColors from "../../../themes/appColors";
import { getGenreList } from "../../../store/actions";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import { TiDelete } from "react-icons/ti";
import ConfirmationModal from "../../../customComponents/confirmationModal";

const breadData = [
  {
    name: "Concerts / Events",
    route: "/band/allConcerts",
  },
  {
    name: "Add Concert",
    route: "/band/addConcert",
  },
];

const AddConcert = (props) => {
  const formRef = useRef();

  const { appState } = useSelector((state) => state);
  const [bandImage, setBandImage] = useState(null);
  const [heroImage, setHeroImage] = useState(null);
  // const [sponsorLogo, setSponosorLogo] = useState(null);
  const [sponsorImage, setSponsorImage] = useState(null);
  const [dateDuration, setDateDuration] = useState(null);
  const [youtubeDuration, setYoutubeDuration] = useState(null);
  const [state, setState] = useState({ dimensions: null });
  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const { inProgress } = useSelector((state) => state.bandConcertReducer);
  const dateValue = new Date();
  const [onDeleteTrailer, setOnDeleteTrailer] = useState(false);

  const [modalState, setModalState] = useState(false);
  const [index, setIndex] = useState(0);
  const [VideosUrl, setVideosUrl] = useState([]);

  const [VideoLinkObj, setVideoLinkObj] = useState({
    url: "",
    duration: "",
    videoDurationSec: "",
  });
  const [VideoLinkArr, setVideoLinkArr] = useState([]);

  // console.log("vvvvv",genreListData[0].genreName)

  const dispatch = useDispatch();
  const [val, setVal] = useState(
    moment(dateValue, "DD-MM-YYYYTHH:mm").add(1, "days")
  );
  const [val2, setVal2] = useState(
    moment(dateValue, "DD-MM-YYYYTHH:mm").add(6, "days")
  );

  const [val3, setVal3] = useState(dateValue);
  const [val4, setVal4] = useState(dateValue);
  const [type, setType] = useState("");
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);

  const values = {
    concertName: "",
    url: "",
    totalDuration: "",
    ticketPrice: "",
    concertVenue: "",
    // sponsorName: "",
    totalDurationInSeconds: "",
    duration: "",
    videoDurationSec: "",
    isForever: false,
  };

  const customHandleChange = (e, key) => {
    let value = e.target.value;
    if (formRef.current) {
      if (key === "totalDuration") {
        formRef.current.setFieldValue(
          key,
          value.replace(/^[^1-9][^0-9]*$/g, "")
        );
        return;
      }
      formRef.current.setFieldValue(key, value.replace(/[^0-9]*$/g, ""));
    }
  };

  // const getYoutubeVideoId = (url) => {
  //   let videoId = url.split("v=");
  //   getVideoDuration(videoId[1]);
  // };

  // const getVideoDuration = (id) => {
  //   axios
  //     .get(
  //       `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=AIzaSyAJdWjcMcbROc6opBqbsiZPrAKQuZXMIJ8&part=snippet,contentDetails,statistics,status`
  //     )
  //     .then((response) => {
  //       const d = response.data.items[0]?.contentDetails?.duration;
  //       let x = moment.duration(d, moment.ISO_8601);
  //       const duration = Math.trunc(x.asMinutes());
  //       setYoutubeDuration(duration);
  //     })
  //     .catch((e) => {
  //       console.log("error for youtube api ===> ", e);
  //     });
  // };

  const selectHeroImage = (id) => {
    const hero_logo = document.getElementById(id);
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };

  const onImgLoad = async ({ target: img }) => {
    setState({
      dimensions: { height: img?.naturalHeight, width: img?.naturalWidth },
    });
  };

  const setHeroSelectImage = (data, key) => {
    if (data?.files[0] !== undefined) {
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent?.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;

      var aspr;
      var img = document.createElement("img");
      var blob = URL.createObjectURL(file);
      img.src = blob;
      if (extentType.indexOf(extentT) >= 0) {
        img.onload = async function () {
          var w = img.width;
          var h = img.height;
          // aspr = await ratio(w, h)
          // console.log("aspect ratio: ", aspr);
          let ratioCalculate = w / h;
          let roundUpValue = ratioCalculate.toFixed(1);

          // if (aspr == '16:9') {
          // if (roundUpValue == 1.78) {

          const imgData = URL.createObjectURL(data.files[0]);
          let checkSize = file_size / max_size <= 10 ? true : false;
          if (checkSize) {
            if (roundUpValue.toString().includes("0.9")) {
              setBandImage(file);
              setHeroImage(imgData);
            } else {
              toast.error(" Only 6:7 ratio images are allowed.");
            }
          } else {
            toast.error("Maximum 10 MB size images are allowed");
          }
        };
      } else if (file) {
        toast.error("Please select image only.");
      }
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    let minutes = parseInt(values.totalDuration) * 60;
    let seconds = values.totalDurationInSeconds
      ? parseInt(values.totalDurationInSeconds)
      : 0;
    let totalTime = minutes + seconds;

    setSubmitting(false);
    const startDate = moment(val).utc();
    const endDate = moment(val2).utc();
    startDate.set({ second: 0 });
    endDate.set({ second: 0 });
    let genre = genreListData[0]?._id;

    if (type?.length > 1) {
      genre = type;
    }

    if (bandImage) {
      let formData = new FormData();
      formData.append("concertName", values.concertName);
      // formData.append("totalDuration", youtubeDuration);
      formData.append("totalDuration", totalTime);
      formData.append("totalDurationInSeconds", values.totalDurationInSeconds);
      formData.append("videos", JSON.stringify(VideosUrl));
      formData.append("ticketPrice", values.ticketPrice);
      formData.append("dateFrom", startDate);
      formData.append("dateTo", endDate);
      formData.append("concertVenue", values.concertVenue);
      formData.append("concertType", genre);
      formData.append("concertImage", bandImage);
      formData.append("isLastForever", values.isForever);
      // formData.append("sponserName", values.sponsorName);
      // formData.append("sponserLogo", sponsorLogo);
      dispatch(addBandConcertAction(formData));
    } else {
      toast.error("Please select concert image.");
    }
  };
  const validate = (values, props) => {
    const errors = {};
    console.log("formRef.current", formRef.current);
    // if(formRef.current){}
    if (VideoLinkArr?.length == 0) {
      console.log(values.url);
      if (!values.url) {
        errors.url = ValidationConstants.mainVideoRequired;
      } else if (
        !values.url.match(
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        )
      ) {
        errors.url = "Entered YouTube URL is invalid, please enter valid URL.";
      }
      if (!values?.duration) {
        errors.duration = ValidationConstants.durationRequired;
      }
      // if (!values?.VideoDurationSec > 60) {
      //   errors.VideoDurationMin =  "Video seconds should not be greater than 60.";
      // }
    }
    return errors;
  };
  const validationSchema = Yup.object().shape({
    concertName: Yup.string()
      .min(2, "Concert name should be at least 2 characters long.")
      .trim()
      .required(ValidationConstants.concertNameRequired)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    // sponsorName: Yup.string()
    //   .min(2, "Sponsor name should be at least 2 characters long.")
    //   .trim()
    //   .required(ValidationConstants.sponsorNameRequired)
    //   .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    // videoLink: Yup.string()
    //   .min(5, "Please enter a valid video link.")
    //   .trim()
    //   .required(ValidationConstants.mainVideoRequired)
    //   .matches(
    //     /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    //     "Entered YouTube URL is invalid, please enter valid URL."
    //   ),
    ticketPrice: Yup.string()
      .max(20, "Ticket price is too high.")
      .required(ValidationConstants.ticketPriceRequired)
      .matches(/^[0-9]*$/, "Ticket price should be greater than 0."),
    totalDuration: Yup.string()
      .max(10, "Video duration should not be greater than 10.")
      .required(ValidationConstants.durationRequired)
      .matches(/^[0-9]*$/, "Total video duration should be greater than 0."),

    totalDurationInSeconds: Yup.number().max(
      60,
      "Video seconds should not be greater than 59."
    ),
    videoDurationSec: Yup.number().max(
      60,
      "Video seconds should not be greater than 60."
    ),
    // .required(ValidationConstants.durationRequired)
    // .matches(/^[0-9]*$/, "Total video duration should be greater than 0."),
    // yup.number( ).max(5)
    concertVenue: Yup.string()
      .min(2, "Concert venue should be at least 2 characters long.")
      .trim()
      .required(ValidationConstants.concertVenueRequired)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
  });

  const disableDates = (date) => {
    let fiveDaysAfter = moment(val, "DD-MM-YYYYThh:mm:ss A").add(4, "days");
    return date < fiveDaysAfter;
  };

  const disableDatesUptoNextDay = (date) => {
    let oneDayLater = moment(new Date(), "DD-MM-YYYYTHH:mm A");
    // setDisableTime(oneDayLater);
    return date < oneDayLater;
  };

  const disableTimeUptoNextDay = (date) => {
    let oneDayLater = moment(new Date(), "DD-MM-YYYYTHH:mm A");
    return date < oneDayLater;
  };

  useEffect(() => {
    document.title = AppConstant.virtualConcertAddConcert;
    dispatch(getGenreList());
    // setType("Alternative")
  }, []);

  // useEffect(() => {
  //   const videoId = getYoutubeVideoId(values.videoLink);
  //   console.log("videoId", videoId);
  // }, [values.videoLink.length > 0]);

  // useEffect(() => {
  //   if (formRef.current) {
  //     console.log("formRef", formRef.current);
  //     formRef.current.handleChange = (e) => {
  //       console.log("e", e);
  //     };
  //   }
  // }, [values]);

  const onAddVideoClick = () => {
    const { url, duration, videoDurationSec } = VideoLinkObj;
    if (url?.length > 0 && duration?.length > 0 && videoDurationSec < 60) {
      let videoObject = JSON.parse(JSON.stringify(VideoLinkObj));
      videoObject.duration = +(duration * 60) + +videoDurationSec;
      delete videoObject?.videoDurationSec;

      if (VideoLinkArr?.length < 5) {
        setVideosUrl([...VideosUrl, videoObject]);
        setVideoLinkArr([...VideoLinkArr, VideoLinkObj]);
        formRef.current.setFieldValue("url", "", false);
        formRef.current.setFieldValue("duration", "", false);
        formRef.current.setFieldValue("videoDurationSec", "");
        setVideoLinkObj({
          url: "",
          duration: "",
          videoDurationSec: "",
        });
      } else {
        toast.error("You only add up to 5 video links.");
      }
    } else if (url?.length === 0) {
      toast.error("Please enter the video link.");
    } else if (duration?.length === 0) {
      toast.error("Please enter the video time in minutes.");
    }
  };
  useEffect(() => {
    if (onDeleteTrailer === true) {
      let arr = [...VideoLinkArr];
      let arr2 = [...VideosUrl];
      console.log(VideosUrl);
      arr.splice(index, 1);
      setVideoLinkArr(arr);

      arr2.splice(index, 1);
      setVideosUrl(arr2);
      setOnDeleteTrailer(false);
    }
  }, [modalState]);
  const onVideoLinkCancel = (index) => {
    setModalState(true);
    setIndex(index);
  };

  const showvideoLinks = () => {
    return VideoLinkArr?.map((item, index) => {
      return (
        <div style={{ position: "relative" }} key={Math.random().toString()}>
          <div
            style={{
              border: "1px solid #9e9e9e",
              position: "relative",
              padding: "15px",
              marginBottom: "20px",
            }}
          >
            <div className="row bottom-margin-30">
              <TextInput
                disabled
                value={item["url"]}
                placeholder={"Video Link"}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="col-sm-5 top-margin-style videoDuration"
                style={{
                  display: "flex",
                  marginBottom: 30,
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                <TextInput
                  disabled
                  value={item["duration"]}
                  placeholder={"Video duration (minutes)"}
                />
              </div>
              <div
                className="col-sm-5 top-margin-style videoDuration"
                style={{
                  display: "flex",
                  marginBottom: 30,
                  paddingRight: 0,
                  paddingLeft: 0,
                }}
              >
                <TextInput
                  disabled
                  value={
                    item["videoDurationSec"] ? item["videoDurationSec"] : "0"
                  }
                  placeholder={"Video duration (seconds)"}
                />
              </div>
            </div>
          </div>
          <span
            style={{ position: "absolute", top: "-14px", right: " -13px" }}
            onClick={() => onVideoLinkCancel(index)}
          >
            <TiDelete size={30} color={"#F58020"} cursor={"pointer"} />
          </span>
        </div>
      );
    });
  };
  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard noSearch title="Add Concert">
              <div className="band-add-concert-style">
                <div className="row bottom-margin "></div>

                <div
                  style={{
                    marginBottom: 20,
                    marginTop: 30,
                  }}
                >
                  <span className="registration-span-style">
                    Concert Image (6:7 ratio, PNG or JPEG)
                  </span>

                  <Tooltip
                    placement="topLeft"
                    title="Click to upload concert image"
                  >
                    <div
                      onClick={() => {
                        selectHeroImage("select_hero_image");
                      }}
                      className={`${
                        heroImage
                          ? "upload-border-radius-style1"
                          : "upload-border-radius-style"
                      } pointer-Style`}
                      style={{ alignSelf: "center" }}
                    >
                      {heroImage ? (
                        <>
                          <img
                            src={heroImage}
                            alt="avatar"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = AppImages.noImage;
                            }}
                            className="pointer-Style register-image"
                          />
                        </>
                      ) : (
                        <img
                          src={AppImages.uploadLight}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          style={{
                            height: 20,
                            width: 20,
                            position: "absolute",
                          }}
                        />
                      )}

                      <input
                        style={{ height: "100%", width: "100%" }}
                        className="d-none"
                        type="file"
                        placeholder="Basic usage"
                        id="select_hero_image"
                        onChange={(evt) => {
                          setHeroSelectImage(evt.target);
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                {/* <div style={{ marginBottom: 20, marginTop: 30 }}>
                  <span className="registration-span-style">
                    Sponsor Logo (16:9 ratio, PNG or JPEG)
                  </span>
                  <Tooltip
                    placement="topLeft"
                    title="Click to upload sponsor logo."
                  >
                    <div
                      onClick={() => {
                        selectHeroImage("sponsorLogo");
                      }}
                      className="upload-border-radius-style pointer-Style"
                      style={{ alignSelf: "center" }}
                    >
                      {sponsorImage ? (
                        <img
                          src={sponsorImage}
                          onLoad={onImgLoad}
                          alt="avatar"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          className="pointer-Style register-image"
                        />
                      ) : (
                        <img
                          src={AppImages.uploadLight}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = AppImages.noImage;
                          }}
                          style={{
                            height: 20,
                            width: 20,
                            position: "absolute",
                          }}
                        />
                      )}

                      <input
                        style={{ height: "100%", width: "100%" }}
                        className="d-none"
                        type="file"
                        placeholder="Basic usage"
                        id="sponsorLogo"
                        onChange={(evt) => {
                          setHeroSelectImage(evt.target, "sponsorLogo");
                        }}
                      />
                    </div>
                  </Tooltip>
                </div> */}

                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  innerRef={formRef}
                  validate={validate}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="row bottom-margin-30">
                        <TextInput
                          id="concertName"
                          placeholder="Artist and Event Name (i.e. The Generals - Video Premier)"
                          error={Boolean(
                            touched.concertName && errors.concertName
                          )}
                          inputProps={{
                            maxLength: 30,
                          }}
                          helperText={touched.concertName && errors.concertName}
                          name="concertName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="bandName"
                          value={values.concertName}
                        />
                      </div>
                      {/* <div className="row bottom-margin-30">
                        <TextInput
                          id="sponsorName"
                          placeholder="Sponsor Name"
                          error={Boolean(
                            touched.sponsorName && errors.sponsorName
                          )}
                          inputProps={{
                            maxLength: 30,
                          }}
                          helperText={touched.sponsorName && errors.sponsorName}
                          name="sponsorName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="sponsorName"
                          value={values.sponsorName}
                        />
                      </div> */}

                      <div className="row bottom-margin-30">
                        <TextInput
                          id="concertVenue"
                          placeholder="Concert Venue"
                          error={Boolean(
                            touched.concertVenue && errors.concertVenue
                          )}
                          helperText={
                            touched.concertVenue && errors.concertVenue
                          }
                          inputProps={{
                            maxLength: 30,
                          }}
                          name="concertVenue"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="concertVenue"
                          value={values.concertVenue}
                        />
                      </div>

                      <div
                        style={{
                          border: "1px solid #9e9e9e ",
                          padding: " 19px 19px 0px 19px",
                          display: `${
                            VideoLinkArr?.length === 5 ? "none" : "block"
                          }`,
                        }}
                        className={"bottom-margin-30"}
                      >
                        {/* <div> */}
                        <div className="row bottom-margin-30">
                          <TextInput
                            id="mainVideo"
                            placeholder="Video Link"
                            error={Boolean(touched.url && errors.url)}
                            helperText={touched.url && errors.url}
                            name="url"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setVideoLinkObj((value) => {
                                return {
                                  ...value,
                                  url: e.target.value,
                                };
                              });
                            }}
                            value={values.url}
                            inputProps={{
                              maxLength: 250,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="col-sm-5 top-margin-style videoDuration"
                            style={{
                              display: "flex",
                              marginBottom: 0,
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            <TextInput
                              addName="vid-duration"
                              id="totalDuration"
                              placeholder="Video duration (minutes)"
                              error={Boolean(
                                touched.duration && errors.duration
                              )}
                              helperText={touched.duration && errors.duration}
                              name="duration"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                customHandleChange(e, "duration");
                              }}
                              value={values.duration}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                                setVideoLinkObj((value) => {
                                  return {
                                    ...value,
                                    duration: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                          <div
                            className="col-sm-5 top-margin-style videoDuration"
                            style={{
                              display: "flex",
                              marginBottom: 0,
                              paddingRight: 0,
                              paddingLeft: 0,
                            }}
                          >
                            <TextInput
                              addName="vid-duration"
                              id="totalDuration"
                              placeholder="Video duration (seconds)"
                              error={Boolean(
                                touched.videoDurationSec &&
                                  errors.videoDurationSec
                              )}
                              helperText={
                                touched.videoDurationSec &&
                                errors.videoDurationSec
                              }
                              name="videoDurationSec"
                              onBlur={handleBlur}
                              onChange={(e) =>
                                customHandleChange(e, "videoDurationSec")
                              }
                              value={values.videoDurationSec}
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 2);
                                setVideoLinkObj((value) => {
                                  return {
                                    ...value,
                                    videoDurationSec: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <span style={{ justifyContent: "center" }}>
                          <div className="band-button">
                            <Button
                              disabled={inProgress}
                              title={AppConstant.Add}
                              bg
                              wd
                              // loading={inProgress}
                              type="button"
                              style={{
                                backgroundColor:
                                  inProgress && AppColors.mainColor,
                                color: "white",
                                margin: "20px 0px",
                              }}
                              onClick={onAddVideoClick}
                            />
                          </div>
                        </span>
                        {/* </div> */}
                      </div>
                      <div
                        style={{
                          border: "1px solid #9e9e9e ",
                          padding: "19px 19px 0px 19px",
                          display: `${
                            VideoLinkArr?.length === 0 ? "none" : "block"
                          }`,
                        }}
                        className={"bottom-margin-30"}
                      >
                        {showvideoLinks()}
                      </div>
                      <div
                        className="row bottom-margin-30"
                        style={{ marginTop: -10 }}
                      >
                        <div
                          className="col-sm-5 pointer-Style "
                          style={{ padding: 0, display: "grid" }}
                        >
                          <CusetomDateTimePicker
                            title="Start Date/Time"
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            val={val}
                            disablePast
                            shouldDisableDate={disableDatesUptoNextDay}
                            onChange={(date, value) => {
                              setVal2(moment(date).add(5, "days"));
                              setVal(date);
                              setVal3(date);
                              setVal4(date);
                            }}
                            onAccept={(time) => {
                              let close = moment(val2);
                              let open = moment(time);
                              let diff = open.diff(close, "minute");

                              let date = moment(val);

                              let nextDay = moment(val);
                              let currentTime = open.diff(
                                new Date(),
                                "minutes"
                              );
                              let NextDayTime = open.diff(
                                new Date(),
                                "minutes"
                              );

                              if (NextDayTime < 1440) {
                                // setVal3(new Date());
                                toast.error(
                                  "Please select start time after 24 hours from current time. "
                                );
                                setVal(
                                  moment(dateValue, "DD-MM-YYYYTHH:mm").add(
                                    1,
                                    "days"
                                  )
                                );
                                setVal2(
                                  moment(dateValue, "DD-MM-YYYYTHH:mm").add(
                                    6,
                                    "days"
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-sm-2"></div>
                        <div
                          className="col-sm-5 pointer-Style responsive-picker-margin"
                          style={{ padding: 0, display: "grid" }}
                        >
                          <CusetomDateTimePicker
                            title="End Date/Time"
                            open={open2}
                            disablePast
                            onOpen={() => setOpen2(true)}
                            onClose={() => setOpen2(false)}
                            val={val2}
                            shouldDisableDate={disableDates}
                            onChange={(date, value) => setVal2(date)}
                            onAccept={(time) => {
                              let close = moment(val);
                              let open = moment(time);
                              let selectedTime = moment(time);
                              let diff = open.diff(close, "minute");
                              let timediff = selectedTime.diff(val, "minute");

                              setDateDuration(diff);
                              if (diff < 0) {
                                toast.error(
                                  "End date should be greater than start date"
                                );
                                // setVal4(null);
                                setOpen4(null);
                                setVal2(val2);
                              } else if (timediff < 7200) {
                                toast.error(
                                  "Minimum difference between start date/time and end date/time should be of 5 days."
                                );
                                setVal2(val2);
                              } else {
                                setOpen4(null);
                                // setVal4(time);
                              }
                              setOpen4(false);
                            }}
                          />
                        </div>
                        <div className="endDate-style">
                          <span>(End Date / Time sets automatically)</span>
                        </div>
                      </div>

                      <div
                        className="row bottom-margin-30"
                        style={{
                          marginTop: -10,
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="col-sm-5 top-margin-style videoDuration"
                          style={{
                            display: "flex",
                            marginBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <TextInput
                            addName="vid-duration"
                            id="totalDuration"
                            placeholder="Total video duration (minutes)"
                            error={Boolean(
                              touched.totalDuration && errors.totalDuration
                            )}
                            helperText={
                              touched.totalDuration && errors.totalDuration
                            }
                            name="totalDuration"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              customHandleChange(e, "totalDuration")
                            }
                            value={values.totalDuration}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 4);
                            }}
                          />
                        </div>

                        <div
                          className="col-sm-5 top-margin-style videoDuration"
                          style={{
                            display: "flex",
                            marginBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                          }}
                        >
                          <TextInput
                            addName="vid-duration"
                            id="totalDurationInSeconds"
                            placeholder=" Total video duration (seconds)"
                            error={Boolean(
                              touched.totalDurationInSeconds &&
                                errors.totalDurationInSeconds
                            )}
                            helperText={
                              touched.totalDurationInSeconds &&
                              errors.totalDurationInSeconds
                            }
                            name="totalDurationInSeconds"
                            onBlur={handleBlur}
                            onChange={(e) =>
                              customHandleChange(e, "totalDurationInSeconds")
                            }
                            value={values.totalDurationInSeconds}
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 2);
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                          <span
                            style={{
                              fontWeight: "700",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                          <span
                            style={{
                              fontWeight: "600",
                              marginLeft: 10,
                              color: "#aaaaaa",
                            }}
                          >
                            In this early release, you need to manually add up
                            your total event length and enter above – thanks.
                          </span>
                        </div>
                      </div>

                      <div
                        className=" row bottom-margin-30 selectStyle"
                        style={{ marginTop: 10 }}
                      >
                        <SelectInput
                          default="60935eb19163860f13efe670"
                          title="Concert Genre"
                          options={genreListData}
                          value={type}
                          onChange={(event) => setType(event.target.value)}
                        />
                      </div>

                      <div className="row bottom-margin-30 " style={{}}>
                        <TextInput
                          id="ticketPrice"
                          placeholder="Ticket Price (in USD that you wish to receive) – Enter 0 for a free concert / event."
                          error={Boolean(
                            touched.ticketPrice && errors.ticketPrice
                          )}
                          helperText={touched.ticketPrice && errors.ticketPrice}
                          name="ticketPrice"
                          onBlur={handleBlur}
                          onChange={(e) => customHandleChange(e, "ticketPrice")}
                          value={values.ticketPrice}
                          inputProps={{
                            maxLength: 30,
                          }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 6);
                          }}
                          maxLength={6}
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                        />
                      </div>
                      <div className="row bottom-margin-30 checkBox">
                        <input
                        style={{
                          accentColor :"#F58020"
                        }}
                          className="CheckBoxInput"
                          type="checkbox"
                          name="isForever"
                          onChange={handleChange}
                          value={values?.isForever}
                        />
                        <span
                          style={{
                            fontWeight: "600",
                            marginLeft: 10,
                            color: "#aaaaaa",
                          }}
                        >
                          Make this concert / event run permanently (suggested
                          for all event other than video premiers).
                        </span>
                      </div>
                      <div className="addConcert-note">
                        <span
                          style={{
                            fontWeight: "600",
                            marginLeft: 10,
                            color: "#aaaaaa",
                          }}
                        >
                          When you submit your concert / event, please remember
                          it will be queued for approval and will not show in
                          your concerts / events until approved.
                        </span>
                      </div>

                      <div className="band-button">
                        <Button
                          disabled={inProgress}
                          title={AppConstant.createEvent}
                          bg
                          wd
                          // loading={inProgress}
                          type="submit"
                          style={{
                            backgroundColor: inProgress && AppColors.mainColor,
                            color: "white",
                            width: "143px",
                          }}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          setModalState(false);
          setOnDeleteTrailer(true);
        }}
        text="Are you sure, you want to delete this video link?"
      />
      <Loader visible={inProgress} />
    </>
  );
};

export default AddConcert;
