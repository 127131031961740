import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
import { toast } from "react-toastify";
import { ValidationConstants } from "../../../themes/validationConstants";

// DASHBOARD SAGA
export function* dashboardSaga(action) {
  const { startDate, endDate } = action;
  const { token } = action;
  try {
    const result = yield call(api.dashboard, { startDate, endDate });

    console.log("result", result.status);

    if (result.status === 1) {
      console.log(
        "result inside admin dashboard saga",
        result.result.data.data
      );
      yield put({
        type: types.API_GET_DASHBOARD_DETAILS_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 3) {
      console.log("result in 3");
      toast.error(ValidationConstants.sessionExpired);
      localStorage.removeItem("token");
      history.push("/login");
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else {
      yield put({
        type: types.API_GET_DASHBOARD_DETAILS_FAILED,
        result: 0,
      });
      toast.error(result.error.response.data.message);
    }
  } catch (error) {
    yield put({
      type: types.API_GET_DASHBOARD_DETAILS_ERROR,
    });
    toast.error(error);
  }
}

export default function* rootDashboardSaga() {
  yield takeLatest(types.API_GET_DASHBOARD_DETAILS_LOAD, dashboardSaga);
}
