import React, { useEffect } from "react";
import SideNav from "../../../customComponents/SideNav";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import moment from "moment";
import AppConstant from "../../../themes/appConstant";
import Card from "../../../customComponents/Card";
import Header from "../../../customComponents/Header";
import { dashboardAction } from "../../../store/actions/admin";

const breadData = [];
const Dashboard = () => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const { dashboardData, onLoad } = useSelector(
    (state) => state.dashboardReducer
  );
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  console.log("dashboard data in ===> ", dashboardData);
  useEffect(() => {
    localStorage.setItem("tabItem", 0);
    document.title = AppConstant.virtualConcertDashboard;
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    var end = new Date();
    end.setHours(23, 59, 59, 999);
    dispatch(dashboardAction(moment.utc(start), moment.utc(end)));
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, null);
  }, []);

  return (
    <>
      <SideNav />
      <Header data={breadData} title={AppConstant.Dashboard} />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        {dashboardData ? (
          <div className="row">
            {dashboardData.map((item, index) => {
              return (
                <div key={index} className="col-sm-4 dashboard-width-card ">
                  <Card
                    icon={item.icon}
                    data={item.data}
                    title={item.title}
                    dollarSign={item.dollar}
                    onLoad={onLoad}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Dashboard;
