import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  paymentRequestList: null,
  paymentRequestCurrentPage: 1,
  paymentRequestTotalCount: 0,
  onPayRequest: false,
};

// Payment Request Reducer
export const paymentRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_PAYMENT_REQUEST_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_PAYMENT_REQUEST_LIST_SUCCESS:
      console.log("payemnt request", action.result);
      state.paymentRequestList = action.result.data;
      state.paymentRequestCurrentPage = action.result.pagination.currentPage;
      state.paymentRequestTotalCount = action.result.pagination.totalCount;
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_PAYMENT_REQUEST_LIST_FAILED:
      state.onLoad = false;
      return state;

    case types.API_GET_PAYMENT_REQUEST_LIST_ERROR:
      state.onLoad = false;
      return state;

    case types.API_PAY_TO_BAND_LOAD:
      return { ...state, onLoad: true, onPayRequest: true };

    case types.API_PAY_TO_BAND_SUCCESS:
      console.log("payemnt request paid to bands", action.result);

      return {
        ...state,
        onLoad: false,
        onPayRequest: false,
      };

    case types.API_PAY_TO_BAND_FAILED:
      state.onLoad = false;
      return state;

    case types.API_PAY_TO_BAND_ERROR:
      state.onLoad = false;
      return state;

    default:
      return state;
  }
};
