import React, { useEffect } from "react";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import AppConstant from "../../../themes/appConstant";

import "./authentication.css";
import AppImages from "../../../themes/appImages";

const RegistrationPopUp = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = AppConstant.Registration;
  });

  return (
    <div className="body-background">
      <div className="body-Style99">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={AppImages.SuccessIcon}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            style={{ height: 100, width: 100, marginBottom: 30 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "85%",
              marginLeft: 20,
            }}
          >
            <span className="span-title-style" style={{ fontSize: 16, textAlign:'center' }}>
              {AppConstant.succesfullyRegistered}
              {/* {AppConstant.loginAgain} */}
            </span>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              marginTop: 14,
              alignSelf: "center",
            }}
          >
            <Button
              title={AppConstant.ok}
              bg
              onClick={() => history.push("/band/login")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPopUp;
