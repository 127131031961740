import { BandApiConstants as types } from "../../../themes/apiConstants";

// fetch profile data
export const getProfileAction = () => {
  const action = {
    type: types.BAND_API_PROFILE_LOAD,
  };
  return action;
};

// edit profile data
export const editProfileAction = (values) => {
  const action = {
    type: types.BAND_API_EDIT_PROFILE_LOAD,
    values,
  };
  return action;
};

// add checkbox
export const profileCheckboxAction = (value) => {
  const action = {
    type: types.API_ADD_PROFILE_CHECKBOX,
    value,
  };
  return action;
};
