import moment from "moment";
import axios from "axios";
import { apiUrl } from "../themes/apiConstants";

export const stringify = (obj) => JSON.stringify(obj);

export const getVideoUrl = (url) => {

  const regExpa = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExpa)
  console.log("match[2]",match)
  if(match && match?.[2].length === 11){
    return match[2]
  }



//  if (url) {
//  console.log("newVideoUrl[0] url",url)
// if(url[0]==="w" || url[0]==="y"  ){


// console.log("newVideoUrl[0] at 0",url[0] )

//   return url
// }else{
//   let videoUrl = url.replace("watch?v=", "embed/");
//   const newVideoUrl = videoUrl.split("&", 1);

//    console.log("newVideoUrl[0]",newVideoUrl[0])

//   return newVideoUrl[0];
// }
   

//   } else {
//     console.log("newVideoUrl[0] lese")
//     return "";
//   }
};

export const getYoutubeVideoId = (url) => {
  let videoId = url.split("v=");
  const duration = getVideoDuration(videoId[1]);
  console.log("duration", duration);
  return duration;
};

export const getMediaUrl = (url) => {
  let mediaPath = `${apiUrl}${url}`;
  return mediaPath;
};

export const getYoutubeVideoUrl = (url) => {
  return url;
};

export const convertTime = (time) => {
  let dateS = moment(time);
  let timeS = moment(time);
  return { dateS, timeS };
};

export const getVideoDuration = (id) => {
  axios
    .get(
      `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=AIzaSyAJdWjcMcbROc6opBqbsiZPrAKQuZXMIJ8&part=snippet,contentDetails,statistics,status`
    )
    .then((response) => {
      const d = response.data.items[0]?.contentDetails?.duration;
      console.log("ddddd", d);
      return d;
    })
    .catch((e) => {
      console.log("error for youtube api ===> ", e);
    });
};

// var moment = require('moment');
// var duration = 'PT15M51S';
// var x = moment.duration(duration, moment.ISO_8601);
// console.log(x.asSeconds()); // => 951
