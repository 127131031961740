import { all, fork } from "redux-saga/effects";
import rootBandAuthenticationSaga from "./bandAuthenticationSaga";
import rootBandConcertSaga from "./bandConcertSaga";
import rootFaqSaga from "./faqSaga";
import rootProfileSaga from "./bandProfileSaga";
import rootDashboardSaga from "./bandDashboardSaga";
import rootTermsAndConditionSaga from "./bandTermsAndConditionSaga";
import rootBandEarningManagementSaga from "./concertEarningSaga";
import rootBandPaymentRequestSaga from "./bandPaymentRequestSaga";
import rootSupportSaga from "./bandSupportSaga";


export default function* rootBandSaga() {
  yield all([
    fork(rootBandAuthenticationSaga),
    fork(rootBandConcertSaga),
    fork(rootFaqSaga),
    fork(rootProfileSaga),
    fork(rootDashboardSaga),
    fork(rootTermsAndConditionSaga),
    fork(rootBandEarningManagementSaga),
    fork(rootBandPaymentRequestSaga),
    fork(rootSupportSaga),
  ]);
}
