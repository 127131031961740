import React, { useEffect } from "react";
import TextInput from "../../../customComponents/InputField";
import Button from "../../../customComponents/Button";
import { useHistory } from "react-router-dom";
import AppImages from "../../../themes/appImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordAction } from "../../../store/actions/admin";

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const adminAuthState = useSelector(
    (state) => state.adminAuthenticationReducer
  );

  useEffect(() => {
    document.title = AppConstant.VirtualConcertForgotPassword;
  }, []);

  const values = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch(forgotPasswordAction(values.email, true));
  };

  return (
    <div className="body-background">
      <div className="body-Style">
        <div className="back-arrow-div">
          <FontAwesomeIcon
            icon={faChevronLeft}
            size={30}
            color="black"
            onClick={() => history.push("/admin")}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={AppImages.LogoImage ? AppImages.LogoImage : AppImages.noImage}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            className="login-Logo-Image"
          />
          <span className="login-style">{AppConstant.ForgotPasswordEmail}</span>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <TextInput
                  placeholder={AppConstant.emailAddress}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  fullWidth
                />
                <div className="buttonWrapper" style={{ marginTop: 14 }}>
                  <Button title={AppConstant.Send} type="submit" bg />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
