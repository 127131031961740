//React router navigation

import SignIn from "../Admin/components/authentication/signIn.jsx";
import ForgotPassword from "../Admin/components/authentication/forgotPassword.jsx";
import ChangePassword from "../Admin/components/authentication/changePassword.jsx";

import Dashboard from "../Admin/components/Dashboard/dashboard";
import ForgotPasswordSuccess from "../Admin/components/authentication/forgotPasswordSuccess";
import ForgotPasswordReset from "../Admin/components/authentication/forgotPasswordReset";

import UserManagement from "../Admin/components/user/userManagement";
import EditUser from "../Admin/components/user/editUser";
import ViewUserDetail from "../Admin/components/user/viewUser";
import PremiumUser from "../Admin/components/user/premiumUsers";

import BandManagement from "../Admin/components/bandManagement/index.jsx";
import EditBandDetail from "../Admin/components/bandManagement/editBand.jsx";
import BandDetail from "../Admin/components/bandManagement/viewBand.jsx";

import TrailerVideo from "../Admin/components/trailerVideo";
import AddTrailerVideo from "../Admin/components/trailerVideo/addTrailerVideo";

import ConcertManagement from "../Admin/components/concertManagement";
import ConcertDetail from "../Admin/components/concertManagement/viewConcert";
import UnapprovedConcert from "../Admin/components/concertManagement/unapprovedConcert";

import NewsManagement from "../Admin/components/newsManagement";
import EditNews from "../Admin/components/newsManagement/editNews";
import NewsDetail from "../Admin/components/newsManagement/viewNews";
import AddNews from "../Admin/components/newsManagement/addNews";
import EarningManagement from "../Admin/components/earningManagement/earningManagement.jsx";
import PaymentRequest from "../Admin/components/paymentRequest/paymentRequest";
import Support from "../Admin/components/support/support";
import ReplyDetails from "../Admin/components/support/ReplyDetails";
import FeaturedConcert from "../Admin/components/featuredConcert/featuredConcerts";
import UpdateTrailer from '../Admin/components/trailerVideo/updateTrailer'
import ViewTrailer from '../Admin/components/trailerVideo/viewTrailer'

import ProductSale from "../Admin/components/productSale/productSale";

export const routeConfig = [
  // {
  //   path: "/login",
  //   component: SignIn,
  //   exact: true,
  // },
  // {
  //   path: "/forgotPassword",
  //   component: ForgotPassword,
  // },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/forgotPasswordSuccess",
    component: ForgotPasswordSuccess,
  },
  {
    path: "/resetPassword",
    component: ForgotPasswordReset,
  },
  {
    path: "/userManagement",
    component: UserManagement,
  },
  {
    path: "/bandManagement",
    component: BandManagement,
  },
  {
    path: "/concertManagement",
    component: ConcertManagement,
  },
  {
    path: "/paymentRequest",
    component: PaymentRequest,
  },
  {
    path: "/newsManagement",
    component: NewsManagement,
  },
  {
    path: "/earningManagement",
    component: EarningManagement,
  },
  {
    path: "/trailerVideo",
    component: TrailerVideo,
  },
  {
    path: "/userDetails",
    component: ViewUserDetail,
  },
  {
    path: "/editUser",
    component: EditUser,
  },
  { path: "/bandManagement", component: BandManagement },

  { path: "/concertManagement", component: ConcertManagement },

  { path: "/newsManagement", component: NewsManagement },

  { path: "/trailerVideo", component: TrailerVideo },
  {
    path: "/changePassword",
    component: ChangePassword,
  },

  {
    path: "/editBand",
    component: EditBandDetail,
  },
  {
    path: "/bandDetails",
    component: BandDetail,
  },
  {
    path: "/concertDetails",
    component: ConcertDetail,
  },
  {
    path: "/editNews",
    component: EditNews,
  },
  {
    path: "/newsDetails",
    component: NewsDetail,
  },
  {
    path: "/editUser",
    component: EditUser,
  },
  {
    path: "/addTrailervideo",
    component: AddTrailerVideo,
  },
  {
    path: "/unapprovedConcert",
    component: UnapprovedConcert,
  },
  {
    path: "/addNews",
    component: AddNews,
  },
  {
    path: "/premiumUser",
    component: PremiumUser,
  },

  {
    path: "/support",
    component: Support,
  },

  {
    path: "/ReplyDetails",
    component: ReplyDetails,
  },

  {
    path: "/featuredConcerts",
    component: FeaturedConcert,
  },
  {
    path: "/productSales",
    component: ProductSale,
  },
  {
    path: "/trailerDetails",
    component: UpdateTrailer,
  },
  {
    path: "/trailerDetail",
    component: ViewTrailer,
  },
];
