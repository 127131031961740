import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";

import { toast } from "react-toastify";
import history from "../../../utils/history";
import * as api from "../../Api";

function* getProductSaleListFailedSaga(result) {
  yield put({
    type: types.API_GET_PRODUCT_SALE_LIST_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* getProductSaleListErrorSaga(result) {
  yield put({
    type: types.API_GET_PRODUCT_SALE_LIST_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* getProductSaleListSaga(action) {
  //   const { sortBy, order, offset, limit, search } = action.params;
  try {
    console.log("in getProductSaleListSaga saga", action.params);
    const result = yield call(api.fetchProductSaleList, action.params);
    console.log("in saga result", result);
    if (result.status === 1) {
      yield put({
        type: types.API_GET_PRODUCT_SALE_LIST_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);

      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_FAILED });
      getProductSaleListFailedSaga(result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_NEWS_MANAGEMENT_ERROR });
    getProductSaleListErrorSaga(e);
  }
}

export default function* rootProductSaleListSaga() {
  yield takeLatest(
    types.API_GET_PRODUCT_SALE_LIST_LOAD,
    getProductSaleListSaga
  );
}
