import { BandApiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  bandPaymentRequestList: null,
  bandPaymentRequestCurrentPage: 1,
  bandPaymentRequestTotalCount: 0,
  bandPaymentRequest: false,
};

// Payment Request Reducer
export const bandPaymentRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_BAND_PAYMENT_REQUEST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_BAND_PAYMENT_REQUEST_SUCCESS:
      console.log("band gailed to hit api");

      state.bandPaymentRequestList = action.result.data;
      state.bandPaymentRequestCurrentPage =
        action.result.pagination.currentPage;
      state.bandPaymentRequestTotalCount = action.result.pagination.totalCount;
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_BAND_PAYMENT_REQUEST_FAILED:
      state.onLoad = false;
      return state;

    case types.API_GET_BAND_PAYMENT_REQUEST_ERROR:
      state.onLoad = false;
      return state;

    default:
      return state;
  }
};
