import { takeLatest, call, put, delay } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import cl from "../../../utils/cl";
import history from "../../../utils/history";
import { toast } from "react-toastify";

function* authenticationFailedSaga(result) {
  cl("authentication failed", result);
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* authenticationErrorSaga(result) {
  cl("authentication error", result);
  yield put({
    type: types.API_AUTHENTICATION_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* loginSaga(action) {
  const { email, password } = action;
  try {
    const result = yield call(api.login, email, password);
    console.log("response result in login saga ---> ", result);
    if (result.status === 1) {
      cl("result inside login saga", result);
      yield put({
        type: types.API_LOGIN_SUCCESS,
        result: result.result.data.token,
      });
      history.push("/dashboard");
      // window.location.reload();
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else if (result.status === 4) {
      toast.error(result.result.data.message, {
        toastId: result?.status || "est",
      });
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* forgotPasswordSaga(action) {
  const { email, isAdmin } = action;
  try {
    const result = yield call(api.forgotPassword, { email, isAdmin });
    if (result.status === 1) {
      cl("result inside forgot password saga", result);
      yield put({
        type: types.API_FORGOT_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });

      history.push("/forgotPasswordSuccess");
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* changePasswordSaga(action) {
  const { oldPassword, newPassword, confirmPassword } = action;
  try {
    const result = yield call(
      api.changePassword,
      oldPassword,
      newPassword,
      confirmPassword
    );
    if (result.status === 1) {
      cl("result inside change password saga", result);
      yield put({
        type: types.API_CHANGE_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message, {
        toastId: result?.status || "abc",
      });
      history.push("/admin");
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* resetPasswordSaga(action) {
  console.log("resetPasswordSaga", action);
  try {
    const result = yield call(api.resetPassword, action.values);
    if (result.status === 1) {
      cl("result inside change password saga", result);
      yield put({
        type: types.API_RESET_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });

      toast.success(result.result.data.message, {
        toastId: result?.status || "abc",
      });

      yield delay(1500);

      if (action.userType === "isAdmin") {
        history.push("/admin");
      } else {
        history.push("/band/login");
        window.location.reload();
      }

      // localStorage.clear();

      // history.push("/login");
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* checkResetPasswordExpireSaga(action) {
  console.log("resetPasswordSaga", action);
  try {
    const result = yield call(api.checkResetPassword, action.values);
    if (result.status === 1) {
      cl("result inside change password saga", result);
      yield put({
        type: types.API_RESET_PASSWORD_EXPIRE_SUCCESS,
        result: result.result.data,
      });

      toast.success(result.result.data.message, {
        toastId: result?.status || "abc",
      });

      // yield delay(1500);

      // if (action.userType === "isAdmin") {
      //   history.push("/login");
      // } else {
      //   history.push("/band/login");
      //   window.location.reload();
      // }

      // localStorage.clear();

      // history.push("/login");
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      yield call(authenticationFailedSaga, result);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

export default function* rootAuthenticationSaga() {
  yield takeLatest(types.API_LOGIN_LOAD, loginSaga);
  yield takeLatest(types.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga);
  yield takeLatest(types.API_CHANGE_PASSWORD_LOAD, changePasswordSaga);
  yield takeLatest(types.API_RESET_PASSWORD_LOAD, resetPasswordSaga);
  yield takeLatest(
    types.API_RESET_PASSWORD_EXPIRE_LOAD,
    checkResetPasswordExpireSaga
  );
}
