import moment from "moment";

export const dateConvert = (str) => moment(str).format("ll");

export const timeConvert = (str) => moment(str).format("LT");

export const listingTimeConverter = (str) => {
  return moment(str).format("hh:mm A");
};

export const dateTimeConverter = (str) => {
  return moment(str).format("hh:mm A");
};

export const dateWithTimeConverter = (str) => {
  return moment(str).format("ll hh:mm A");
};


export const convertInMinutesSeconds = (seconds) => {

  let m = Math.floor(seconds / 60);
  let s = Math.floor(seconds % 3600 % 60);
  let mDisplay = m > 0 ? m + (m === 1 ? ":" : ":") : "";
  let sDisplay = s > 0 ? s : "00"
  return  mDisplay + sDisplay; 

}


export const getMinuteAndSeconds = (seconds) => {

  let m = Math.floor(seconds / 60);
  let s = Math.floor(seconds % 3600 % 60);
  return {m,s}; 

}