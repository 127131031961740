import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import "./customComponentStyle.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    width: 500,
    height: 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
  },
}))(MuiDialogActions);

export default function ConfirmationModal(props) {
  const history = useHistory();
  const classes = useStyles();
  const { onLoad } = useSelector((state) => state.trailerReducer);
  const {
    visible,
    setVisible,
    title,
    text,
    logout,
    closeModal,
    path,
    onRemoveStorage,
    disabled,
    payment,
  } = props;
  console.log(">>>>>>>>",
    visible,"visible",
    setVisible,"setVisible",
  );
  return (
    <div>
      {/* aria-labelledby="customized-dialog-title"  */}
      {/* id="customized-dialog-title" */}
      <Dialog open={visible}>
        <DialogTitle  onClose={setVisible}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.confirmationText}>
            {text}
          </DialogContentText>
        </DialogContent>

        {logout ? (
          <DialogActions>
            <Button
              className="modal-button"
              onClick={() => {
                setVisible();
              }}
              variant="outlined"
              color="primary"
              size="small"
              disabled={disabled}
            >
              NO
            </Button>
            <Button
              className="modal-button logout"
              onClick={() => {
                setVisible();
                // onRemoveStorage()
                localStorage.removeItem("bandToken");

                // window.location.reload()
                history.push("/band/login");
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              <span style={{ color: "white" }}>YES</span>
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              className="modal-button "
              autoFocus
              onClick={closeModal}
              variant="outlined"
              color="primary"
              size="small"
            >
              CANCEL
            </Button>
            <Button
              className="modal-button"
              onClick={setVisible}
              variant="contained"
              color="primary"
              size="small"
              disabled={onLoad}
            >
              <span style={{ color: "white", fontWeight: 600 }}>DELETE</span>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
