import React, { useEffect, useState } from "react";
import { Table, Button as AntButton, Pagination, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import Button from "../../../customComponents/Button";
import {
  unapprovedConcertAction,
  changeConcertStatusAction,
} from "../../../store/actions/admin";
import { dateConvert, timeConvert ,convertInMinutesSeconds} from "../../../utils/dateTimeConverter";

function onChange(pagination, filters, sorter, extra) {}

const ConcertTable = (props) => {
  const {
    history,
    data,
    dispatch,
    current,
    totalCount,
    setOnStatusChange,
    onLoad,
    inProgress,
    s
  } = props;

  const [paginationPage, setPaginationPage] = useState(1);
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(unapprovedConcertAction(params));
  }

  const unApprovedStatus = (values, isApproved, shouldNavigate) => {
    let formData = new FormData();
    formData.append("concertId", values._id);
    formData.append("isApproved", isApproved);
    dispatch(changeConcertStatusAction(formData, shouldNavigate));
    setOnStatusChange(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: "60px" }}>
            <span>{(paginationPage - 1) * 10 + index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "concertName",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.concertName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Venue",
      dataIndex: "concertVenue",
      sorter: {
        compare: (a, b) => a.venue - b.venue,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px", maxWidth: "250px" }}>
          <span>{record?.concertVenue || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "dateFrom",
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "90px" }}>
          <span>{dateConvert(record.dateFrom)}</span>
        </div>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "timeFrom",
      sorter: {
        compare: (a, b) => a.start - b.start,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "90px" }}>
          <span> {timeConvert(record?.dateFrom) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Total Video Duration (Minutes)",
      dataIndex: "totalDuration",
      sorter: {
        compare: (a, b) => a.totalVideoDuration - b.totalVideoDuration,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>{ convertInMinutesSeconds(record?.totalDuration ) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Ticket Price",
      dataIndex: "ticketPrice",
      sorter: {
        compare: (a, b) => a.ticketPrice - b.ticketPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>${record?.ticketPrice + record?.commission || "0"}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="action-style">
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/concertDetails", {
                  from: "unapproved",
                  id: record._id,
                })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </AntButton>
            <AntButton
              disabled={inProgress}
              className="action-button-inline-style danger-color"
              type="primary"
              onClick={() => {
                unApprovedStatus(record, false, false);
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>REJECT</span>
            </AntButton>
            <AntButton
              disabled={inProgress}
              className="action-button-inline-style danger-color"
              type="primary"
              onClick={() => {
                unApprovedStatus(record, true, true);
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>APPROVE</span>
            </AntButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Table
          className="table-responsive user-table-style"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={false}
          showSorterTooltip={false}
          rowKey={(record) => record._id}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: onLoad ? true : false,
          }}
        />
        <div className="pagination-style">
          <Pagination
            className="pagination"
            total={totalCount}
            onChange={(page) => {
              setPaginationPage(page);
              dispatch(
                unapprovedConcertAction({ limit: 10, offset: (page - 1) * 10, search:s,order: sorting.sortOrder, sortBy: sorting.sortBy  })
              );
            }}
            current={current}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
};

const UnapprovedConcert = () => {
  const dispatch = useDispatch();
  const [onStatusChange, setOnStatusChange] = useState(false);
  const [s, setS] = useState("");
  const {
    unApprovedConcertData,
    unApprovedTotalCount,
    unApprovedCurrentPage,
    onLoad,
    inProgress,
  } = useSelector((state) => state.concertReducer);
  const breadData = [
    {
      name: "Concert Management",
      route: "/concertManagement",
    },
    {
      name: "Unapproved Concerts",
      route: "",
    },
  ];

  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const history = useHistory();

  useEffect(() => {
    document.title = AppConstant.virtualConcertConcertManagement;
    dispatch(unapprovedConcertAction({ limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    if (onStatusChange === true && onLoad === false) {
      dispatch(unapprovedConcertAction({ limit: 10, offset: 0 }));
      setOnStatusChange(false);
    }
  });

  return (
    <>
      <Header data={breadData} title={AppConstant.ConcertManagement} />
      <SideNav />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.ApprovedConcert}
          bg
          mb
          onClick={() => {
            history.push("/unapprovedConcert");
          }}
        />
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              title="Unapproved Concerts"
              value={s}
              onChange={(event) => {
   
                setS(event.target.value)

                if(event.target.value[0] === " " ){
                }else{ 
                dispatch(
                  unapprovedConcertAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                  })
                );
                }
              }}
            >
              <ConcertTable
                history={history}
                data={unApprovedConcertData}
                dispatch={dispatch}
                current={unApprovedCurrentPage}
                totalCount={unApprovedTotalCount}
                setOnStatusChange={setOnStatusChange}
                onLoad={onLoad}
                inProgress={inProgress}
              />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnapprovedConcert;
