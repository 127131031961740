import { apiConstants as types } from "../../../themes/apiConstants";

export const getProductSaleList = (params) => {
  console.log("params", params);
  const action = {
    type: types.API_GET_PRODUCT_SALE_LIST_LOAD,
    params,
  };
  return action;
};
