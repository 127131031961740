import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppConstant from "../../../themes/appConstant";
import { termsAndConditionsAction } from "../../../store/actions/Band";
import Loader from "../../../customComponents/loader";

const RegistrationTermsAndConditions = () => {
  const { myTermsAndConditions, onLoad } = useSelector(
    (state) => state.bandTermsAndConditionReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = AppConstant.virtualConcertTermsAndConditions;
    dispatch(termsAndConditionsAction());
  }, []);

  return (
    <div className="body-background-2">
      <div className="body-Style-terms">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="login-style">{AppConstant.termsAndCondition}</span>
        </div>

        <div className="termsDivStyle hide-scrollbar">
          <div className="span-title-style " style={{ marginBottom: 30 }}>
            <span>{myTermsAndConditions?.title}</span>
          </div>
          {myTermsAndConditions?.lines?.map((item) => {
            return (
              <span className="termsFontStyle">
                <li>{item}</li>
              </span>
            );
          })}
        </div>
      </div>

      <Loader visible={onLoad} />
    </div>
  );
};

export default RegistrationTermsAndConditions;
