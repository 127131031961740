import React from "react";
import { Button as MatButton } from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import Load from './Spinner'

import clsx from "clsx";
import { useStyles } from "./style";

const newStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});
const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "unset !important",
    },
  },
});

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: "green" || "red",
  },
});

const Button = ({
  title,
  mr,
  mb,
  onClick,
  bg,
  noBg,
  wd,
  mt20,
  type,
  id,
  ref,
  disabled,
  style,
  RequestButtonpadding,
  loading,
  width
}) => {
  const classes = useStyles();
  const classess = styles();
  return (
    <MuiThemeProvider theme={theme}>
      <MatButton
        className={clsx(classes.button, [
          mr && classes.mr,
          mb && classes.mb,
          bg && classes.bg,
          wd && classes.wd,
          noBg && classes.noBg,
          mt20 && classes.mt20,
          RequestButtonpadding && classes.RequestButtonpadding,
        ])}
        onClick={onClick}
        variant="contained"
        type={type}
        id={id}
        ref={ref}
        disabled={disabled}
        classes={{ disabled: classes.disabledButton }}
        style={style}
      >
        <span className="button-font-style">
          
          {loading ? <Load thickness={1} size={14} color={"white"}/> : title}

          </span>
      </MatButton>
    </MuiThemeProvider>
  );
};

export default Button;
