import React, { useState } from "react";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";

import AppColors from "../themes/appColors";
import "./customComponentStyle.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import clsx from "clsx";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "&:focus": {
      borderColor: AppColors.mainColor,
      borderWidth: 2,
    },
  },
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resize: {
    fontSize: 16,
  },
}));

const TextArea = ({
  placeholder,
  editValue,
  faq,
  value,
  desc,
  onChange,
  error,
  noValidate,
  helperText,
  name,
  onBlur,
  type,
  id,
  inputProps,
  max
}) => {
  const [description, setDescription] = useState(editValue);
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <TextareaAutosize
        className={clsx("textarea-Style", [classes.root, classes.resize])}
        aria-label="empty textarea"
        rowsMax={1}
        // style={{ maxHeight: 400, minHeight: 120, padding: 10 }}
        style={
          faq
            ? { maxHeight: 60, minHeight: 60, padding: 10 }
            : { maxHeight: 120, minHeight: 120, padding: 10 }
        }
        placeholder={placeholder}
        value={value}
        // description
        maxLength={max ? max : 5000}
        onChange={onChange}
        error={error}
        noValidate
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        label={placeholder}
        type={type}
        id={id}
        
      />
    </ThemeProvider>
  );
};

export default TextArea;
