import AppImages from "../../themes/appImages";

const adminDashboardDataModal = (data) => {
  const dashboardArray = [
    {
      icon: AppImages.Users,
      data: data.totalRegisteredUsers ? data.totalRegisteredUsers : 0,
      title: "Total Number Of Registered Users",
      dollar: false,
    },
    {
      icon: AppImages.Band,
      data: data.registeredBands ? data.registeredBands : 0,
      title: "Total Number Of Registered Bands",
      dollar: false,
    },
    {
      icon: AppImages.Band,
      data: data.totalConcerts ? data.totalConcerts : 0,
      title: "Total Number Of Concerts Made",
      dollar: false,
    },
    {
      icon: AppImages.DollarCard,
      data: data.totalEarnedFromConcert ? data.totalEarnedFromConcert : 0,
      title: "Total Earning From Concert",
      dollar: true,
    },
    {
      icon: AppImages.DollarCard,
      data: data.totalEarnedFromStore ? data.totalEarnedFromStore : 0,
      title: "Total Earning From Shop",
      dollar: true,
    },
    {
      icon: AppImages.DollarCard,
      data: data.totalAmountPaidToBand ? data.totalAmountPaidToBand : 0,
      title: "Amount Paid To Bands",
      dollar: true,
    },

    {
      icon: AppImages.Band,
      data: data.totalFeaturedConcert ? data.totalFeaturedConcert : 0,
      title: "Total Number of Featured Concerts",
      dollar: false,
    },

    {
      icon: AppImages.Booking,
      data: data.totalBookingToday ? data.totalBookingToday : 0,
      title: "Total Bookings Today",
      dollar: false,
    },
    {
      icon: AppImages.Booking,
      data: data.totalBookingThisWeek ? data.totalBookingThisWeek : 0,
      title: "Total Bookings This Week",
      dollar: false,
    },
    {
      icon: AppImages.Booking,
      data: data.totalBookingThisMonth ? data.totalBookingThisMonth : 0,
      title: "Total Bookings This Month",
      dollar: false,
    },
    {
      icon: AppImages.Booking,
      data: data.totalBookingThisQuarter ? data.totalBookingThisQuarter : 0,
      title: "Total Bookings This Quarter",
      dollar: false,
    },
    {
      icon: AppImages.Booking,
      data: data.totalBookingThisYear ? data.totalBookingThisYear : 0,
      title: "Total Bookings This Year",
      dollar: false,
    },
  ];

  return dashboardArray;
};

export default adminDashboardDataModal;
