import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import Loader from "../../../customComponents/loader";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import { getUserByIdAction } from "../../../store/actions/admin";
import { getMediaUrl } from "../../../utils/helpers";
import { handleNavigationStateAction } from "../../../store/actions";

const Userdetail = (data, onLoad) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="user-Details-Box">
        <div
          className="row detail-height"
          style={{
            paddingTop: 100,
            paddingBottom: 70,
          }}
        >
          <div className="col-sm center-style-items">
            <span className="span-title-style">Profile Picture</span>
          </div>
          <div className="col-sm center-style-items">
            <img
              style={{
                marginBottom: 40,
                cursor: "pointer",
                borderRadius: 100,
              }}
              src={
                data.profileImage
                  ? getMediaUrl(data.profileImage)
                  : AppImages.noImage
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = AppImages.noImage;
              }}
              className="userImage-Style"
              onClick={showModal}
            />
          </div>
        </div>

        <Modal visible={isModalVisible} onCancel={handleCancel} width={350}>
          <div className="image-modal">
            <img
              src={
                data.profileImage
                  ? getMediaUrl(data.profileImage)
                  : AppImages.noImage
              }
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = AppImages.noImage;
              }}
              className="userImage-Style-Modal"
              style={{ borderRadius: 200 }}
            />
          </div>
        </Modal>

        <div className="row detail-height">
          <div className="col-sm center-style-items">
            <span className="span-title-style">Name</span>
          </div>
          <div className="col-sm center-style-items">
            <span>{data.username ? data.username : "N/A"}</span>
          </div>
        </div>

        <div className="row detail-height">
          <div className="col-sm center-style-items">
            <span className="span-title-style">Email Address</span>
          </div>

          <div className="col-sm center-style-items">
            <span>{data.emailId ? data.emailId : "N/A"}</span>
          </div>
        </div>

        <div className="row detail-height">
          <div className="col-sm center-style-items">
            <span className="span-title-style">Rock$</span>
          </div>

          <div className="col-sm center-style-items">
            <span>{data.rocksCount ? data.rocksCount : "N/A"}</span>
          </div>
        </div>

        <div className="row detail-height">
          <div className="col-sm center-style-items">
            <span className="span-title-style">Gender</span>
          </div>
          <div className="col-sm center-style-items">
            <span>{data.gender ? data.gender : "N/A"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const ViewUserDetail = (props) => {
  const history = useHistory();
  const id = props?.location?.state?.record;
  const breadData = [
    {
      name: "User Management",
      route: "/userManagement",
    },
    {
      name: " User Details",
      route: "",
    },
  ];

  const dispatch = useDispatch();
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const { userById, onLoad } = useSelector(
    (state) => state.userManagementReducer
  );
  useEffect(() => {
    document.title = AppConstant.VirtualConcertUserDetail;
    dispatch(handleNavigationStateAction(1));
    if (id) {
      dispatch(getUserByIdAction(id));
    } else {
      history.push("/userManagement");
    }
  }, []);
  
  return (
    <>
      <Header data={breadData} title={AppConstant.UserManagement} />
      <SideNav />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="User Details" noSearch>
              {Userdetail(userById, onLoad)}
            </NavCard>
          </div>
        </div>
        <Loader visible={onLoad} />
      </div>
    </>
  );
};

export default ViewUserDetail;
