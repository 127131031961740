import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import AppConstant from "../../../themes/appConstant";
import TextInput from "../../../customComponents/InputField";
import TextArea from "../../../customComponents/TextArea";

import NavCard from "../../../customComponents/NavCard";
import Button from "../../../customComponents/Button";
import { bandChangePasswordAction ,supportAction} from "../../../store/actions/Band";
import { ValidationConstants } from "../../../themes/validationConstants";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../myProfile/profile.css";

const Support = () => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const state = useSelector((state) => state.bandSupportReducer);
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const breadData = [
    {
      name: "Support",
      route: "/support",
    },
  ];

  const values = {
   title : "",
   concern : ""
  };

  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().min("3", "Title should be at least 3 characters long.").max("35","Title should not be greater than 35 characters.").required("Please enter title.").matches(/^[a-zA-Z0-9_ .-]*$/, "Please enter alpha numeric values only."),
    concern: Yup.string().min("3", "Message should be at least 3 characters long.").required("Please enter your message.").matches(/^[a-zA-Z0-9_ .'"?@-]*$/, "Please enter alpha numeric values only.")

  });
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch(supportAction(values));
  };

  useEffect(() => {
    document.title = AppConstant.VirtualConcertBandSupport;
  }, []);


console.log("loader state", state)

  const ChangePasswordScreen = () => {
    return (
      <>
        <div
          className="band-user-Details-Box"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
            }}
          >
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <TextInput
                      id="title"
                      placeholder="Title"
                      type="text"
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      name="title"
                      maxLength={10}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title.trimLeft()}
                      inputProps={{
                        maxLength:35
                      }}
                      fullWidth
                      onCopy={stopCopyPaste}
                      onPaste={stopCopyPaste}
                      style={{
                        marginTop: 8,
                      }}
                    />
                  </div>
                  <div className="mb-2" style={{
                      marginTop:40
                  }}>
                     <TextArea
                            placeholder="Message"
                            value={values.concern.trimLeft()}
                            onChange={handleChange}
                            error={Boolean(
                              touched.concern && errors.concern
                            )}
                            helperText={
                              touched.concern && errors.concern
                            }
                            name="concern"
                            onBlur={handleBlur}
                            fullWidth
                            InputProps
                            max={300}
                          />

                  </div>
                  <span
                          style={{
                            color: "red",
                            marginTop: -10,
                            position: "absolute",
                            fontSize: 13,
                        
                          }}
                        >
                          {touched.concern && errors.concern}
                        </span>

                  <div
                    className="buttonWrapper"
                    style={{ marginTop: 30, alignSelf: "center" }}
                  >
                    <Button loading={state?.onLoad} title={"Submit"} bg type="submit" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Support" noSearch >
              <ChangePasswordScreen />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
