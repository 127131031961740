import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import Snackbar from "@material-ui/core/Snackbar";
import {
  closeModal,
  dashboardAction,
  dashboardRequestPaymentAction,
} from "../../../store/actions/Band";
import { Modal, Button as AntButton } from "antd";
import moment from "moment";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { getMediaUrl } from "../../../utils/helpers";
import Card from "../../../customComponents/Card";
import Loader from "../../../customComponents/loader";
import clsx from "clsx";
import "./dashboard.css";
import MuiAlert from "@material-ui/lab/Alert";

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dispatch = useDispatch();
  const [onPay, setOnPay] = useState(false);
  const [onWarningOpen, setOnWarningOpen] = useState(false);
  const breadData = [];
  const { appState } = useSelector((state) => state);
  const {
    dashboardData,
    onLoad,
    onPayRequest,
    successModalState,
    paymentRequested,
  } = useSelector((state) => state.bandDashboardReducer);
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleWarningClose = () => {
    setOnWarningOpen(false);
  };

  const { sidebarDrawerToggleState } = appState;

  var start = new Date();
  start.setHours(0, 0, 0, 0);
  var end = new Date();
  end.setHours(23, 59, 59, 999);

  useEffect(() => {
    window.history.pushState(null, null, null);
    localStorage.setItem("tabItem", 0);
    document.title = AppConstant.virtualConcertDashboard;
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    var end = new Date();
    end.setHours(23, 59, 59, 999);
    dispatch(dashboardAction(moment.utc(start), moment.utc(end)));
  }, []);

  useEffect(() => {
    if (onPay === true && onPayRequest === false) {
      dispatch(dashboardAction(moment.utc(start), moment.utc(end)));
      setOnPay(false);
    }
  });

  const getBandLogo = () => {
    return dashboardData?.bandInfo?.bandLogo !== ""
      ? getMediaUrl(dashboardData?.bandInfo?.bandLogo)
      : AppImages.BandDummyImage;
  };
  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="band-dashboard-style">
          <div className="dashboard-image-style">
            <img
              alt=""
              src={getBandLogo()}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: 8,
                resize: "contain",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = AppImages.noImage;
              }}
            />
          </div>
          <div
            style={{
              height: 50,
              marginTop: 20,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: 600, fontSize: 24 }}>
              {dashboardData?.bandInfo?.bandName}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm dashboard-width-card ">
            <Card
              icon={AppImages.pastIcon}
              data={dashboardData?.pastConcerts || "0"}
              title="Total Number Of Past Concerts"
            />
          </div>

          <div className="col-sm dashboard-width-card ">
            <Card
              icon={AppImages.todayIcon}
              data={dashboardData?.todaysConcerts || "0"}
              title="Total Number Of Concerts Today"
            />
          </div>
          <div className="col-sm dashboard-width-card ">
            <Card
              icon={AppImages.upcomingIcon}
              data={dashboardData?.upcomingConcerts || "0"}
              title="Total Number Of Upcoming Concerts"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm dashboard-width-card ">
            <Card
              payment
              icon={AppImages.currentEarning}
              data={dashboardData?.redeem || "0"}
              title="Current Earning"
              dollarSign={true}
              onClick={() => {
                let amountToRedeem = dashboardData?.redeem;
                if (amountToRedeem < 50) {
                  setOnWarningOpen(true);
                } else {
                  setIsModalVisible(true);
                }
              }}
            />
          </div>

          <div className="col-sm dashboard-width-card ">
            <Card
              icon={AppImages.totalEarning}
              data={dashboardData?.totalEarning || "0"}
              title="Total Earning"
              dollarSign={true}
            />
          </div>
          <div className="col-sm dashboard-width-card "></div>
        </div>

        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={vertical + horizontal}
          open={successModalState}
          onClose={() => {
            handleClose();
          }}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Message</span>}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {`Your request for redeeming payment of $${paymentRequested} has been sent successfully.`}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          open={onWarningOpen}
          autoHideDuration={4000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id"> Message </span>}
          onClose={() => setOnWarningOpen(false)}
        >
          <Alert
            onClose={handleWarningClose}
            severity="warning"
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {AppConstant.requestPayment}
          </Alert>
        </Snackbar>
        <Loader visible={onLoad} />
        <Modal
         className="payment-request-modal-style"
          title="Redeem Money"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ marginTop: 150, position: "absolute" }}
          maskClosable={false}
        >
          <p>{AppConstant.redeemMoney}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <AntButton
              onClick={() => {
                setIsModalVisible(false);
              }}
              style={{ marginRight: 10 }}
              type="primary"
            >
              NO
            </AntButton>
            <AntButton
              onClick={() => {
                setIsModalVisible(false);
                dispatch(dashboardRequestPaymentAction());
                setOnPay(true);
              }}
              type="primary"
            >
              YES
            </AntButton>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
