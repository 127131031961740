import React, { useState, useEffect } from "react";
import { Table, Button as AntButton, Pagination, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import Button from "../../../customComponents/Button";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import ConfirmationModal from "../../../customComponents/confirmationModal.jsx";
import {
  getNewsManagementData,
  deleteNewsAction,
  clearPreviousReducerData,
} from "../../../store/actions/admin";

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const NewsManagement = () => {
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState();
  const [onDeleteNews, setOnDeleteNews] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [spin, setSpin] = useState(true);
  const [s, setS] = useState("");

  const breadData = [
    {
      name: "News Management",
      route: "/newsManagement",
    },
  ];

  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const history = useHistory();
  const classes = useStyles();
  const [modalState, setModalState] = useState(false);
  const {
    newsData,
    newsDataTotalCount,
    newsDataCurrentPage,

    onLoad,
    onDeleteNewsReducer,
  } = useSelector((state) => state.newsManagementReducer);

  useEffect(() => {
    if (onDeleteNews === true && onDeleteNewsReducer === false) {
      dispatch(getNewsManagementData({ limit: 10, offset: 0 }));
      setOnDeleteNews(false);
    }
  });

  useEffect(() => {
    document.title = AppConstant.VirtualConcertNewsManagement;
    dispatch(clearPreviousReducerData());
    dispatch(getNewsManagementData({ limit: 10, offset: 0 }));
  }, []);

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(getNewsManagementData(params));
  }

  useEffect(() => {
    if (newsData.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
    }
  }, [newsData]);

  const { appState } = useSelector((state) => state);

  const { sidebarDrawerToggleState } = appState;

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",

      render: (text, record, index) => (
        <div style={{ width: "50px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: {
        compare: (a, b) => a.title - b.title,
        multiple: 3,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "150px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots4">{record?.title}</span>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: {
        compare: (a, b) => a.description - b.description,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "150px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots">{record.description}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="action-style">
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() => history.push("/newsDetails", { record })}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </AntButton>
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() => history.push("/editNews", { editData: record })}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>EDIT</span>
            </AntButton>
            <AntButton
              className="action-button-inline-style danger-color"
              type="primary"
              onClick={() => {
                setModalState(true);
                setDeleteId(record._id);
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>DELETE</span>
            </AntButton>
          </div>
        );
      },
    },
  ];


  return (
    <>
      <Header data={breadData} title={AppConstant.NewsManagement} />
      <SideNav />
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        setVisible={() => {
          setModalState(!modalState);
          dispatch(deleteNewsAction(deleteId));
          setOnDeleteNews(true);
        }}
        closeModal={() => {
          setModalState(false);
        }}
        text="Are you sure you want to delete this news?"
      />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.AddNews}
          bg
          mb
          onClick={() => {
            history.push("/addNews");
          }}
        />
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
             value={s}
              title="News Management"
              onChange={(event) => {
                setPaginationPage(1)
                setS(event.target.value)
                console.log("serach val --> ", event.target.value);
                if(event.target.value[0] === " "){
                }else{ 
                dispatch(
                  getNewsManagementData({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                    order: sorting.sortOrder,
                    sortBy: sorting.sortBy
                  })
                );
                }
              }}
            >


<div className="">
          <Table
            className="table-responsive user-table-style"
            columns={columns}
            dataSource={newsData}
            pagination={false}
            onChange={onChange}
            showSorterTooltip={false}
            rowKey={(record) => record._id}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),

              spinning: onLoad ? true : false,
            }}
          />

          <div className="pagination-style">
            <Pagination
              className="pagination"
              total={newsDataTotalCount}
              current={newsDataCurrentPage}
              showSizeChanger={false}
              onChange={(page) => {
                setPaginationPage(page);
                dispatch(
                  getNewsManagementData({ limit: 10, offset: (page - 1) * 10, search:s ,order: sorting.sortOrder, sortBy: sorting.sortBy })
                );
              }}
            />
          </div>
        </div>


            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsManagement;
