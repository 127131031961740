import React, { useState, useEffect } from "react";
import { Table, Button as AntButton, Spin, Pagination } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import Button from "../../../customComponents/Button";
import AppConstant from "../../../themes/appConstant";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import ConfirmationModal from "../../../customComponents/confirmationModal.jsx";
import NavCard from "../../../customComponents/NavCard";
import { getVideoUrl } from "../../../utils/helpers";
import {
  deleteTrailerAction,
  trailerAction,
} from "../../../store/actions/admin";
// import Iframely from "../../../customComponents/iframly";

export const TrailerVideo = () => {
  const [deleteId, setDeleteId] = useState();
  const [onDeleteTrailer, setOnDeleteTrailer] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [s, setS] = useState("");

  let a = "oTZS_IdUlTo"
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const breadData = [
    {
      name: "Trailer Video",
      route: "/trailerVideo",
    },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const { appState } = useSelector((state) => state);
  const [modalState, setModalState] = useState(false);
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;

  const {
    trailerData,
    trailerDataCurrentPage,
    trailerDataTotalCount,
    onLoad,
    onDeleteTrailerReducer,
  } = useSelector((state) => state.trailerReducer);

  useEffect(() => {
    if (onDeleteTrailer === true && onDeleteTrailerReducer === false) {
      dispatch(trailerAction({ limit: 10, offset: 0 ,search:''}));
      setOnDeleteTrailer(false);
    }
  });

  useEffect(() => {
    document.title = AppConstant.VirtualConcertTrailerVideo;
    dispatch(trailerAction({ limit: 10, offset: 0 , search:''}));
  }, []);

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      search : s || "",
      order: sortOrder || "",
      sortBy: sortBy || "",
    };

    dispatch(trailerAction(params));
  }


  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",

      render: (text, record, index) => (
        <div style={{ width: 45 }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    {
      title: "Video Title",
      dataIndex: "title",
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      sorter: {
        compare: (a, b) => a.genre - b.genre,
        multiple: 2,
      },
      render: (text, record) => {
        return (
          <div style={{ height: 0, width: 180, marginTop: -10 }}>
            <span className="text-dots">{record?.title ? record?.title : "N|A"}</span>
          </div>
        );
      },
    },
    {
      title: "Video",
      dataIndex: "video",

      render: (text, record) => {
        return (
          <div style={{ minWidth: "300px", height: 180 }}>
            {/* <Iframely url={record?.video}/> */}
            <iframe
              height="100%"
              width="90%"
              style={{}}
              src={record?.video && `https://www.youtube.com/embed/${getVideoUrl(record?.video)}`}
              frameBorder={0}
              allowFullScreen={true}
              title="video"
            />

          
          </div>
        );
      },
    },
    {
      title: "Genre(s)",
      dataIndex: "genre",
      sorter: {
        compare: (a, b) => a.genre - b.genre,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: 150 }}>
          {record?.genre?.map((item, index) => {
            return (
              <>
                <span>{index > 0 ? ", " : ""}</span>
                <span style={{}}>{item?.genreName || "N/A"}</span>
              </>
            );
          })}
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",

      render: (text, record) => {
        return (
          <div className="action-style">
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() => { history.push({ pathname: "/trailerDetail", state: { id: record?._id } }) }}

            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </AntButton>
            <AntButton
              className="action-button-inline-style"
              type="primary"
              onClick={() => { history.push({ pathname: "/trailerDetails", state: { id: record?._id } }) }}

            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>EDIT</span>
            </AntButton>

            <AntButton
              className="action-button-inline-style danger-color"
              type="primary"
              onClick={() => {
                setModalState(true);
                setDeleteId(record._id);
                setPaginationPage(1)
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 1 }}>DELETE</span>
            </AntButton>
          </div>
        );
      },
    },
  ];



  return (
    <>
      <Header data={breadData} title={AppConstant.TrailerVideo} />
      <SideNav />
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          setModalState(!modalState);
          dispatch(deleteTrailerAction(deleteId));
          setOnDeleteTrailer(true);
        }}
        text="Are you sure you want to delete this trailer video?"
      />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.AddTrailerVideo}
          bg
          mb
          onClick={() => {
            history.push("/addTrailerVideo");
          }}
        />
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Trailer Video"  
            value={s}
            onChange={(event) => {
              setPaginationPage(1)
              setS(event.target.value)
              console.log("serach val --> ", event.target.value);
              if(event.target.value[0] === " " ){
              }else{
                dispatch(
                  trailerAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                    order: sorting.sortOrder,
                     sortBy: sorting.sortBy

                  })
                );
              }
            }}
            >
              <div className="table-responsive user-Details-Box">
                <Table
                  columns={columns}
                  dataSource={trailerData}
                  pagination={false}
                  showSorterTooltip={false}
                  loading={{
                    indicator: <>{<Spin />}</>,
                    spinning: onLoad ? true : false,
                  }}
                  rowKey={(record) => record._id}
                />
              </div>
              <div className="pagination-style" style={{
                marginTop: -30
              }}>
                <Pagination
                  className="pagination"
                  total={trailerDataTotalCount}
                  onChange={(page) => {
                    setPaginationPage(page);
                    dispatch(trailerAction({ limit: 10, offset: (page - 1) * 10, search:s, order: sorting.sortOrder, sortBy: sorting.sortBy }));
                  }}
                  current={trailerDataCurrentPage}
                  showSizeChanger={false}
                />
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrailerVideo;

