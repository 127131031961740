export const genreJson = [
  {
    genre: "Alternative",
  },
  {
    genre: "Blues",
  },
  {
    genre: "Childrens Music",
  },
  {
    genre: "Christian & Gospel",
  },
  {
    genre: "Classical",
  },
  {
    genre: "Comedy",
  },
  {
    genre: "Country",
  },
  {
    genre: "Easy Listening",
  },
  {
    genre: "Electronic",
  },
  {
    genre: "Hip-Hop",
  },
  {
    genre: "Holiday",
  },
  {
    genre: "Indie Pop",
  },
  {
    genre: "Industrial",
  },
  {
    genre: "Instrumental",
  },
  {
    genre: "J-Pop",
  },
  {
    genre: "K-Pop",
  },
  {
    genre: "Jazz",
  },
  {
    genre: "Latin",
  },
  {
    genre: "Metal",
  },
  {
    genre: "New Age",
  },
  {
    genre: "Pop",
  },
  {
    genre: "R&B/Soul",
  },
  {
    genre: "Rap",
  },
  {
    genre: "Reggae",
  },
  {
    genre: "Rock",
  },
  {
    genre: "World",
  },
];

export const genre_List = [
  "Alternative",
  "Blues",
  "Childrens Music",
  "Christian & Gospel",
  "Classical",
  "Comedy",
  "Country",
  "Easy Listening",
  "Electronic",
  "Hip-Hop",
  "Holiday",
  "Indie Pop",
  "Industrial",
  "Instrumental",
  "J-Pop",
  "K-Pop",
  "Jazz",
  "Latin",
  "Metal",
  "New Age",
  "Pop",
  "R&B/Soul",
  "Rap",
  "Reggae",
  "Rock",
  "World",
];
