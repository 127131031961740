import axios from "axios";
import { apiUrl } from "../../themes/apiConstants";
import cl from "../../utils/cl";

let APIKit = axios.create({
  baseURL: `${apiUrl}api/`,
  timeout: 60000,
});

APIKit.interceptors.request.use(async (config) => {
  const tokenData = localStorage.getItem("token");
  if (tokenData) {
    config.headers["auth-token"] = `${tokenData}`;
  }
  cl("configL", config);
  return config;
});

let BandAPIKit = axios.create({
  baseURL: `${apiUrl}api/`,
  timeout: 60000,
});

BandAPIKit.interceptors.request.use(async (config) => {
  const tokenData = localStorage.getItem("bandToken");
  console.log("tokenData", tokenData);
  if (tokenData) {
    config.headers["auth-token"] = `${tokenData}`;
  }
  cl("configL", config);
  return config;
});

export { APIKit, BandAPIKit };

// export default APIKit;
