import { BandApiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  dashboardData: null,
  onPayRequest: false,
  successModalState: false,
  paymentRequested: null,
};

//DASHBOARD REDUCER
export const bandDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    // DASHBOARD DATA
    case types.BAND_API_GET_DASHBOARD_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.result,
        onLoad: false,
      };

    case types.BAND_API_GET_DASHBOARD_FAILED:
      state.onLoad = false;
      return state;

    case types.BAND_API_GET_DASHBOARD_ERROR:
      state.onLoad = false;
      return state;

    // BAND DASHBOARD PAYMENT REQUEST
    case types.API_BAND_PAYMENT_REQUEST_DASHBOARD_LOAD:
      return {
        ...state,
        onLoad: true,
        onPayRequest: true,
        successModalState: false,
      };

    case types.API_BAND_PAYMENT_REQUEST_DASHBOARD_SUCCESS:
      return {
        ...state,
        paymentRequested: action.result,
        onPayRequest: false,
        successModalState: true,
        onLoad: false,
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        successModalState: false,
      };

    case types.API_BAND_PAYMENT_REQUEST_DASHBOARD_FAILED:
      state.onLoad = false;
      return state;

    case types.API_BAND_PAYMENT_REQUEST_DASHBOARD_ERROR:
      state.onLoad = false;
      return state;

    default:
      return state;
  }
};
