import { Button as AntButton, Pagination, Spin, Table } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../customComponents/Button";
import Header from "../../../customComponents/Header";
import NavCard from "../../../customComponents/NavCard";
import SideNav from "../../../customComponents/SideNav";
import { useStyles } from "../../../customComponents/style";
import { getConcertByIdAction } from "../../../store/actions/admin";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";

import { TiDelete } from "react-icons/ti";
import Loader from "../../../customComponents/loader";
import {
  changeConcertStatusAction,
  removevideoLink,
} from "../../../store/actions/admin/concertActions";
import {
  convertInMinutesSeconds,
  dateConvert,
  timeConvert,
} from "../../../utils/dateTimeConverter";
import { getMediaUrl, getVideoUrl } from "../../../utils/helpers";
import ConfirmationModal from "../../../customComponents/confirmationModal.jsx";
// import Iframely from "../../../customComponents/iframly";
export const ViewConcertDetail = (props) => {
  const breadData = [
    {
      name: "Concert Management",
      route: "/concertManagement",
    },
    {
      name: "Approved Concert",
      route: "/unapprovedConcert",
    },
    {
      name: "Concert Details",
      route: "",
    },
  ];

  const unapprovedBreadData = [
    {
      name: "Concert Management",
      route: "/concertManagement",
    },
    {
      name: "Unapproved Concert",
      route: "/concertManagement",
    },
    {
      name: "Concert Details",
      route: "",
    },
  ];

  const breadData2 = [
    {
      name: "Featured Concerts",
      route: "/featuredConcerts",
    },
    {
      name: "Concert Details",
      route: "",
    },
  ];
  const [modalState, setModalState] = useState(false);
  const [id, setId] = useState(null);
  const [onDeleteTrailer, setOnDeleteTrailer] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { concertById, onLoad } = useSelector((state) => state.concertReducer);
  const { sidebarDrawerToggleState } = appState;
  useEffect(() => {
    if (onDeleteTrailer === true) {
      dispatch(getConcertByIdAction(props?.location?.state?.id));
      setOnDeleteTrailer(false);
      document.body.style.overflow = "";
    }
    // setOnDeleteTrailer(false);
  });
  useEffect(() => {
    document.title = AppConstant.virtualConcertConcertDetails;

    if (props.location?.state?.id) {
      dispatch(getConcertByIdAction(props?.location?.state?.id));
    } else {
      history.push("/concertManagement");
    }
  }, []);

  const history = useHistory();

  console.log("get concert data by id ===> ", props?.location?.state?.id);

  // const onVideoLinkCancel = (e) => {
  //   setModalState(true);
  //   setId(e);
  // };
  const ConcertDetail = (data, onLoad) => {
    const unApprovedStatus = (values, isApproved, shouldNavigate) => {
      let formData = new FormData();
      formData.append("concertId", values._id);
      formData.append("isApproved", isApproved);
      dispatch(changeConcertStatusAction(formData, shouldNavigate));
      // setOnStatusChange(true);
    };
    return (
      <>
        <div className="user-Details-Box" style={{}}>
          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">
                Concert Name (usually band/artist name)
              </span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data?.concertName}</span>
            </div>
          </div>
          <div className="row detail-height1">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Concert Image</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <img
                src={
                  data?.concertImage
                    ? getMediaUrl(data?.concertImage)
                    : AppImages.noImage
                }
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = AppImages.noImage;
                }}
                className="concert-logo-image-Style"
              />
            </div>
          </div>

          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Venue</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data?.concertVenue}</span>
            </div>
          </div>

          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Start Date/Time</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span style={{ marginRight: 10 }}>
                {dateConvert(data?.dateFrom)}
              </span>
              <span>{timeConvert(data?.dateFrom)}</span>
            </div>
          </div>

          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">End Date/Time</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span style={{ marginRight: 10 }}>
                {dateConvert(data?.dateTo)}
              </span>
              <span>{timeConvert(data?.dateTo)}</span>
            </div>
          </div>

          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">
                Total Video Duration (Minutes)
              </span>
            </div>

            <div className="col-sm center-style-items concert-detail-min-width">
              <span>
                {convertInMinutesSeconds(data?.totalDuration) || "N/A"}{" "}
              </span>
            </div>
          </div>
          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">
                Ticket Price (in USD that he/she want to recieve)
              </span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>${data?.ticketPrice + data?.commission || "0"}</span>
            </div>
          </div>
          {/* <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Sponsor Name</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data?.sponserName || "N/A"}</span>
            </div>
          </div> */}

          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Concert Type</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data?.concertType?.genreName || "N/A"}</span>
            </div>
          </div>

          {/* <div className="row detail-height1">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Sponsor Logo</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <img
                src={
                  data?.sponserLogo
                    ? getMediaUrl(data?.sponserLogo)
                    : AppImages.noImage
                }
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = AppImages.noImage;
                }}
                className="concert-logo-image-Style"
              />
            </div>
          </div> */}
          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Band Name</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data.band?.bandName || "N/A"}</span>
            </div>
          </div>
          <div className="row detail-height">
            <div className="col-sm center-style-items concert-detail-min-width">
              <span className="span-title-style">Band Email Address</span>
            </div>
            <div className="col-sm center-style-items concert-detail-min-width">
              <span>{data.band?.bandEmail || "N/A"}</span>
            </div>
          </div>
          <div className="">
            <div className="" style={{ marginTop: 30 }}>
              <span className="span-title-style">Video</span>
            </div>
            {data?.videos &&
              data?.videos?.map((item) => {
                return (
                  <div className="" style={{ marginTop: 25 }}>
                    <div className="col">
                      <p>
                        Total duration time{"  "}
                        {(item["duration"] / 60).toString().split(".")[0]}:
                        {item["duration"] % 60}min
                      </p>
                      {/* <Iframely url={item.url}/>
                       */}
                       <iframe
                        className="video-dimension"
                        height="93%"
                        width="100%"
                        style={{ position: "relative" }}
                        src={
                          data?.videos &&
                          `//www.youtube.com/embed/${getVideoUrl(item.url)}`
                        }
                        frameBorder={0}
                        allowFullScreen={true}
                        title="video"
                      />



                      {!data?.isApproved && data?.videos?.length > 0 && (
                        <span
                          style={{
                            position: "absolute",
                            top: "16px",
                            right: "-3px",
                          }}
                          onClick={() => {
                            setModalState(true);
                            setId(item._id);
                          }}
                        >
                          <TiDelete
                            size={40}
                            color={"#F58020"}
                            cursor={"pointer"}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {!data?.isApproved && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "30px",
                paddingRight: "12px",
              }}
            >
              <AntButton
                // disabled={inProgress}
                className="action-button-inline-style danger-color"
                type="primary"
                onClick={() => {
                  unApprovedStatus(data, false, true);
                }}
              >
                <span style={{ fontWeight: 600, marginTop: 2 }}>REJECT</span>
              </AntButton>
              <AntButton
                // disabled={inProgress}
                className="action-button-inline-style danger-color"
                type="primary"
                onClick={() => {
                  unApprovedStatus(data, true, true);
                }}
              >
                <span style={{ fontWeight: 600, marginTop: 2 }}>APPROVE</span>
              </AntButton>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {props?.location?.state?.breadCrumb ? (
        <Header data={breadData2} title={AppConstant.ConcertManagement} />
      ) : (
        <Header
          data={!props?.location?.state?.from ? breadData : unapprovedBreadData}
          title={AppConstant.ConcertManagement}
        />
      )}

      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        {props?.location?.state?.breadCrumb
          ? null
          : !props?.location?.state?.from && (
              <Button
                title={AppConstant.Users}
                bg
                mb
                onClick={() => {
                  history.push("/premiumUser", { id: concertById._id });
                }}
              />
            )}
      </div>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard noResponsive title="Concert Details" noSearch>
              {ConcertDetail(concertById, onLoad)}
            </NavCard>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          setModalState(!modalState);
          dispatch(
            removevideoLink({
              concertId: props?.location?.state?.id,
              videoId: id,
            })
          );
          setOnDeleteTrailer(true);
          // setOnPayStatus(true);
        }}
        text="Are you sure, you want to delete this video link?"
      />
      <Loader visible={onLoad} />
    </>
  );
};

export default ViewConcertDetail;
