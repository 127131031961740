import React, { useEffect, useState } from "react";
import { Table, Button, Pagination, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import SideNav from "../../../customComponents/SideNav";
import {
  getBandManagementData,
  changeBandStatusAction,
  clearPreviousReducerData,
} from "../../../store/actions/admin/bandManagementAction";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import "../trailerVideo/trailer.css";
import { getMediaUrl } from "../../../utils/helpers";

const breadData = [
  {
    name: "Band Management",
    route: "/bandManagement",
  },
];

const BandManagement = () => {
  const dispatch = useDispatch();
  const [paginationPage, setPaginationPage] = useState(1);
  const [onBandBlock, setOnBandBlock] = useState(false);
  const [s, setS] = useState("");
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const {
    bandData,
    bandDataTotalCount,
    bandDataCurrentPage,
    onLoad,
    onBlock,
    inProgress,
  } = useSelector((state) => state.bandManagementReducer);
  const [spin, setSpin] = useState(true);

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search: s || "",
    };

    dispatch(getBandManagementData(params));
  }
  const history = useHistory();
  const { appState } = useSelector((state) => state);
  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  useEffect(() => {
    document.title = AppConstant.virtualConcertBandManagement;
    dispatch(clearPreviousReducerData());
    dispatch(getBandManagementData({ limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    if (onBandBlock === true && onBlock === false) {
      dispatch(getBandManagementData({ limit: 10, offset: 0 }));
      setOnBandBlock(false);
    }
  });

  useEffect(() => {
    if (bandData.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
    }
  }, [bandData]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "60px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    {
      title: "Band Logo",
      dataIndex: "logo",

      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <img
            src={
              record.bandLogo ? getMediaUrl(record.bandLogo) : AppImages.noImage
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            alt=""
            style={{
              height: 50,
              width: 50,
              borderRadius: 30,
            }}
          />
        </div>
      ),
    },
    {
      title: "Band Name",
      dataIndex: "bandName",

      sorter: true,
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        // <div
        //   style={{ minWidth: "120px", maxWidth: "250px", textAlign: "justify" }}
        // >
        //   <span>{record?.bandName || "N/A"}</span>
        // </div>
        <div
          style={{
            minWidth: "50px",
            // textAlign: "justify",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots3">{record?.bandName}</span>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",

      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "50px",
            textAlign: "justify",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots3">{record?.bandEmail}</span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{ minWidth: "150px", display: "flex", alignItems: "center" }}
        >
          <span className="text-dots4">
            {" "}
            {record?.type?.map((item, index) => {
              if (index === 0) {
                return item?.genreName;
              } else {
                return ", " + item?.genreName;
              }
            })}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="action-style">
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/bandDetails", {
                  Data: record,
                  record: record?._id,
                })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 1 }}>VIEW</span>
            </Button>
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/editBand", {
                  editData: record,
                  image: AppImages.BandDummyImage,
                })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 1 }}>EDIT</span>
            </Button>
            <Button
              disabled={inProgress}
              className="action-button-inline-style danger-color"
              type="primary"
              style={{ width: 100, fontWeight: 600 }}
              onClick={() => {
                dispatch(changeBandStatusAction(record._id, !record.isBlocked));
                setOnBandBlock(true);
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 1 }}>
                {record.isBlocked ? "UNBLOCK" : "BLOCK"}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Header data={breadData} title={AppConstant.BandManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              title="Band Management"
              value={s}
              onChange={(event) => {
                setPaginationPage(1);
                setS(event.target.value);

                if (event.target.value[0] === " ") {
                } else {
                  dispatch(
                    getBandManagementData({
                      limit: 10,
                      offset: 0,
                      search: event.target.value,
                      order: sorting.sortOrder,
                      sortBy: sorting.sortBy,
                    })
                  );
                }
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={bandData}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad ? true : false,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={bandDataTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(
                        getBandManagementData({
                          limit: 10,
                          offset: (page - 1) * 10,
                          search: s,
                          order: sorting.sortOrder,
                          sortBy: sorting.sortBy,
                        })
                      );
                    }}
                    current={bandDataCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default BandManagement;
