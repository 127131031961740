import { apiConstants as types } from "../../../themes/apiConstants";
import adminDashboardDataModal from "../../../helper/ObjectModal/adminDashboardModalData";

const initialState = {
  onLoad: false,
  error: null,
  dashboardData: null,
};

//DASHBOARD REDUCER
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_GET_DASHBOARD_DETAILS_LOAD:
      // state.dashboardData = adminDashboardDataModal(0);
      return { ...state, onLoad: true };
    case types.API_GET_DASHBOARD_DETAILS_SUCCESS:
      state.dashboardData = adminDashboardDataModal(action.result);
      return {
        ...state,
        onLoad: false,
      };

    case types.API_GET_DASHBOARD_DETAILS_FAILED:
      state.dashboardData = adminDashboardDataModal(0);
      state.onLoad = false;
      return state;

    case types.API_GET_DASHBOARD_DETAILS_ERROR:
      state.dashboardData = adminDashboardDataModal(0);
      state.onLoad = false;

      return state;

    default:
      return state;
  }
};
