import React, { useEffect, useState, useRef } from "react";
import TextInput from "../../../customComponents/InputField";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Select } from "antd";
import { useHistory } from "react-router-dom";
import Button from "../../../customComponents/Button";
import CheckBox from "../../../customComponents/CheckBox";
import AppImages from "../../../themes/appImages";
import AppConstant from "../../../themes/appConstant";
import { ValidationConstants } from "../../../themes/validationConstants";
import * as Yup from "yup";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { genreJson } from "../../../mocks/genreJson";

import { getGenreList } from "../../../store/actions";
import {
  registerDataAction,
  clearRegistrationCheckboxAction,
} from "../../../store/actions/Band/authenticationAction";
import "./authentication.css";
import { toast } from "react-toastify";

const Registration = () => {
  const formRef = useRef();
  const { Option } = Select;
  const [checked, setChecked] = useState(false);
  const [bandImage, setBandImage] = useState(null);
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [pickerGenre, setPickerGenre] = useState([]);

  const checkbox = useSelector(
    (state) => state.bandAuthenticationReducer.checkbox
  );

  const { genreListData } = useSelector((state) => state.userManagementReducer);

  const history = useHistory();
  const dispatch = useDispatch();
  const values = {
    bandName: "",
    contactName: "",
    bandEmailAddress: "",
    bandPassword: "",
    bandConfirmPassword: "",
    paypalId: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (bandImage) {
      if (pickerGenre.length > 0) {
        if (termsCheckbox === true) {
          let formData = new FormData();
          formData.append("bandName", values.bandName);
          formData.append("image", bandImage);
          formData.append("contactName", values.contactName);
          formData.append("bandEmail", values.bandEmailAddress);
          values.paypalId && formData.append("paypalId", values.paypalId);
          formData.append("bandPassword", values.bandPassword);
          formData.append("confirmBandPassword", values.bandConfirmPassword);
          formData.append("type", JSON.stringify(pickerGenre));
          dispatch(registerDataAction(formData, history));
        } else {
          toast.error("Please accept terms and conditions.");
        }
      } else {
        toast.error("Please select at least one genre type.");
      }
    } else {
      toast.error("Please select band logo.");
    }
  };

  const validationSchema = Yup.object().shape({
    bandName: Yup.string()
      .max(50, "")
      .min(2, "Band name should be at least 2 characters long.")
      .trim()
      .required(ValidationConstants.username.bandName)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    contactName: Yup.string()
      .min(2, "Contact name should be at least 2 characters long.")
      .trim()
      .required(ValidationConstants.username.contactName)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    // paypalId: Yup.string()
    //   .email(ValidationConstants.paypal.invalid)
    //   .required(ValidationConstants.paypal.empty),
    bandEmailAddress: Yup.string()
      .email(ValidationConstants.email.invalid)
      .required(ValidationConstants.email.empty),
    bandPassword: Yup.string()
      .min(6, "Password should be at least 6 characters long.")
      .required(ValidationConstants.password.empty)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace),
    bandConfirmPassword: Yup.string()
      .required(ValidationConstants.password.confirmPassword)
      .matches(/^(\S+$)/, ValidationConstants.password.noSpace)
      .when("bandPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("bandPassword")],
          ValidationConstants.password.unmathcedConfirm
        ),
      }),
  });

  const registerData = useSelector((state) => state.bandRegisterReducer);
  console.log("register date ", registerData);
  const [heroImage, setHeroImage] = useState(null);
  const selectHeroImage = () => {
    const hero_logo = document.getElementById("select_hero_image");
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };

  const stopCopyPaste = (e) => {
    e.preventDefault();
  };

  const pickerHandleChange = (val) => {
    setPickerGenre(val);
  };

  const setHeroSelectImage = async (file) => {
    // let file = data.files[0];
    // const extentType = ["png", "jpg", "jpeg"];
    // const extent = file && file?.name.split(".");
    // const extentT = extent && extent[extent.length - 1];
    // let file_size = file && file?.size;
    // let max_size = 1024000;

    const extentType = ["jpeg", "jpg", "png"];
    const extent = file && file.name.split(".");
    const extentT = extent && extent[extent.length - 1];
    if (extentType.indexOf(extentT) >= 0) {
      var img = document.createElement("img");
      var blob = URL.createObjectURL(file);
      img.src = blob;
      img.onload = async function () {
        // alert('gggg')
        var w = img.width;
        var h = img.height;
        let ratioCalculate = w / h;
        let roundUpValue = ratioCalculate.toFixed(1);
        // const imgData = URL.createObjectURL(file);
        // let checkSize = file_size / max_size <= 10 ? true : false;
        // if (extentType.indexOf(extentT) >= 0) {
        //   if (checkSize) {
        //     if (roundUpValue.toString().includes("1.4")) {
        //       setBandImage(file);
        //       setHeroImage(imgData);
        //     } else {
        //       toast.error(" Only 7:5 ratio images are allowed.");
        //     }
        //   } else {
        //     toast.error("Maximum 10 MB size images are allowed");
        //   }
        // } else if(file) {
        //   toast.error("Please select image only.");
        // }

        if (roundUpValue.toString().includes("1.4")) {
          setBandImage(file);
          setHeroImage(URL.createObjectURL(file));
        } else {
          toast.error(" Only 7:5 ratio images are allowed.");
        }
      };
    } else {
      toast.error("Please select image only.");
    }
  };

  const disableType = (e) => {
    // e.replace(e,"")
    document.getElementById("stopType").readOnly = true;
    document.getElementById("stopType").maxLength = 0;
  };

  useEffect(() => {
    document.title = AppConstant.Registration;
    disableType();
    dispatch(clearRegistrationCheckboxAction());
    dispatch(getGenreList());
  }, []);

  return (
    <div className="body-background-3">
      <div className="body-Style-terms-3">
        <div className="back-arrow-div">
          <FontAwesomeIcon
            icon={faChevronLeft}
            color="black"
            onClick={() => history.push("/band/login")}
          />
        </div>
        <img
          src={AppImages.LogoImage}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = AppImages.noImage;
          }}
          className="login-Logo-Image"
          style={{ marginTop: 30 }}
        />

        <span className="login-style">{AppConstant.register}</span>

        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div
                style={{ marginBottom: 20, marginTop: 30, marginLeft: -0.5 }}
              >
                <span className="registration-span-style" style={{}}>
                Band Logo (7:5 ratio, PNG or JPEG) - This will appear either side of your virtual stage in your arena.
                </span>

                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                > */}
                <Tooltip placement="topLeft" title="Click to upload band logo">
                  <div
                    onClick={selectHeroImage}
                    className="upload-border-radius-style pointer-Style"
                  >
                    {heroImage ? (
                      <img
                        src={heroImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AppImages.noImage;
                        }}
                        alt="avatar"
                        className="pointer-Style register-image"
                      />
                    ) : (
                      <img
                        src={AppImages.uploadLight}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AppImages.noImage;
                        }}
                        style={{
                          height: 20,
                          width: 20,
                          position: "absolute",
                        }}
                      />
                    )}

                    <input
                      style={{ height: "100%", width: "100%" }}
                      className="d-none"
                      type="file"
                      placeholder="Basic usage"
                      id="select_hero_image"
                      onChange={(evt) => {
                        setHeroSelectImage(evt.target.files[0]);
                      }}
                    />
                  </div>
                </Tooltip>
                {/* <div
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <span
                      style={{
                        color: "grey",
                      }}
                    >
                      Note* (Only 7:5 ratio image allowed){" "}
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-sm">
                  <TextInput
                    id="bandName"
                    placeholder="Band Name"
                    error={Boolean(touched.bandName && errors.bandName)}
                    helperText={touched.bandName && errors.bandName}
                    name="bandName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.bandName}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
                <div className="col-sm">
                  <TextInput
                    id="contactName"
                    placeholder="Contact Name"
                    error={Boolean(touched.contactName && errors.contactName)}
                    helperText={touched.contactName && errors.contactName}
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactName}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: 40,
                  marginBottom: 20,
                }}
              >
                <div className="" style={{ marginBottom: 5 }}>
                  <span className="regis-genre-style">Genre(s)</span>
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  id="stopType"
                  onSearch={(e) => {
                    disableType(e);
                  }}
                  name="genre"
                  style={{ width: "100%", caretColor: "white" }}
                  placeholder="Select Genre"
                  value={pickerGenre}
                  onChange={pickerHandleChange}
                >
                  {genreListData?.map((item, index) => (
                    <Option key={item?._id}>{item?.genreName}</Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: 20 }}>
                <TextInput
                  id="email "
                  placeholder="Email Address"
                  error={Boolean(
                    touched.bandEmailAddress && errors.bandEmailAddress
                  )}
                  helperText={
                    touched.bandEmailAddress && errors.bandEmailAddress
                  }
                  name="bandEmailAddress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.bandEmailAddress}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <TextInput
                  id="paypalId"
                  placeholder="Paypal ID"
                  // error={Boolean(touched.paypalId && errors.paypalId)}
                  // helperText={touched.paypalId && errors.paypalId}
                  name="paypalId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.paypalId}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
                <div className="addConcert-note">
                  <span
                    style={{
                      fontWeight: "600",
                      // marginLeft: 10,
                      color: "#aaaaaa",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span> (Optional - only
                    required if you will be selling tickets for exclusive
                    events, video premiers etc - so you can get paid directly).
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <TextInput
                    id="password"
                    placeholder="Password"
                    securePassword={true}
                    error={Boolean(touched.bandPassword && errors.bandPassword)}
                    helperText={touched.bandPassword && errors.bandPassword}
                    name="bandPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.bandPassword.trim()}
                    onCopy={stopCopyPaste}
                    onPaste={stopCopyPaste}
                  />
                </div>
                <div className="col-sm responsive-margin">
                  <TextInput
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    securePassword={true}
                    error={Boolean(
                      touched.bandConfirmPassword && errors.bandConfirmPassword
                    )}
                    helperText={
                      touched.bandConfirmPassword && errors.bandConfirmPassword
                    }
                    name="bandConfirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.bandConfirmPassword.trim()}
                    onCopy={stopCopyPaste}
                    onPaste={stopCopyPaste}
                  />
                </div>
              </div>

              <div
                className="row"
                style={{
                  marginLeft: -13,
                  marginTop: 30,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="col-sm-1 center-style"
                  style={{ padding: 0, paddingLeft: 0 }}
                >
                  <CheckBox
                    checked={termsCheckbox}
                    name="termsCheckbox"
                    onChange={() => setTermsCheckbox(!termsCheckbox)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                <div
                  className="col-sm center-style min-width-registration pointer-Style"
                  style={{ paddingLeft: 40, paddingTop: 1, position: "fixed" }}
                >
                  <a
                    target="_blank"
                    href={"/band/termsAndCondition"}
                    className="forgot-Password-Style-button"
                    style={{ margin: 0 }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      // history.push("/band/termsAndCondition");
                    }}
                  >
                    {AppConstant.acceptTermsAndCoditions}
                  </a>
                </div>
              </div>

              <div className="register-button-style">
                <Button
                  title={AppConstant.register}
                  type="submit"
                  bg
                  mt20
                  onClick={() => {}}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Registration;

/*



*/
