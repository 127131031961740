import { apiConstants as types } from "../../../themes/apiConstants";

export const removeFeaturedConcertById = (concertId) => {
  const action = {
    type: types.API_DELETE_FROM_FEATURED_CONCERTS_LOAD,
    concertId,
  };
  return action;
};

export const getFeaturedConcertList = () => {
  const action = {
    type: types.API_GET_FEATURED_CONCERTS_LIST_LOAD,
  };
  return action;
};
