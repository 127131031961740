import { apiConstants as types } from "../../../themes/apiConstants";

export const paymentRequestListAction = (params) => {
  const action = {
    type: types.API_GET_PAYMENT_REQUEST_LIST_LOAD,
    params,
  };
  return action;
};

export const payToBandAction = (id) => {
  const action = {
    type: types.API_PAY_TO_BAND_LOAD,
    id,
  };
  return action;
};
