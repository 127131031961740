import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import { toast } from "react-toastify";
import history from "../../../utils/history";
import * as api from "../../Api";
import { ValidationConstants } from "../../../themes/validationConstants";

function* trailerFailedSaga(result) {
  yield put({
    type: types.API_TRAILER_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* trailerErrorSaga(result) {
  yield put({
    type: types.API_TRAILER_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* trailerSaga(action) {
  const { sortBy, order, offset, limit,search } = action.params;
  try {
    const result = yield call(api.trailer, {
      sortBy,
      order,
      offset,
      limit,
      search
    });

    if (result.status === 1) {
      yield put({
        type: types.API_GET_TRAILER_DATA_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.data.message);
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      trailerFailedSaga(result);
    }
  } catch (e) {
    trailerErrorSaga(e);
  }
}

//add trailer

export function* addTrailerSaga(action) {
  console.log("values inside add trailer saga----> ", action.value);
  try {
    const result = yield call(api.addTrailer, action.value);

    if (result.status === 1) {
      yield put({
        type: types.API_ADD_TRAILER_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message);
      history.push("/trailerVideo");
      yield put({
        type: types.EMPTY_CHECKBOX_STATE,
      });
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
      yield put({
        type: types.API_ADD_TRAILER_FAILED,
      });

      // history.push("/dashboard");
    } else {
      yield put({
        type: types.API_ADD_TRAILER_FAILED,
      });
      trailerFailedSaga(result);
    }
  } catch (e) {
    yield put({
      type: types.API_ADD_TRAILER_ERROR,
    });
    trailerErrorSaga(e);
  }
}

export function* deleteTrailerSaga(action) {
  try {
    const result = yield call(api.deleteTrailer, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_DELETE_TRAILER_SUCCESS,
        id: action.id,
        result: false,
      });

      console.log("result ==>", result);
      toast.success(result.result.data.message);
      // yield put({
      //   type: types.API_GET_TRAILER_DATA_LOAD,
      // });
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
      // history.push("/dashboard");
    } else {
      // yield put({
      //   type: types.API_DELETE_TRAILER_FAILED,
      // });
      trailerFailedSaga(result);
    }
  } catch (e) {
    // yield put({
    //   type: types.API_DELETE_TRAILER_ERROR,
    // });
    trailerErrorSaga(e);
  }
}


// get trailer by id


export function* getTrailerByIdSaga(action) {
  // const { sortBy, order, offset, limit } = action.params;

  console.log("action in saga", action)
  try {
    const result = yield call(api.getTrailer, action.id);

    console.log("result in saga trailer", result)

    if (result.status === 1) {
      yield put({
        type: types.API_GET_TRAILER_BY_ID_SUCCESS,
        result: result?.result?.data?.detail,
      });
      toast.success(result.data.message);
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      trailerFailedSaga(result);
    }
  } catch (e) {
    trailerErrorSaga(e);
  }
}


// update trailer 

export function* updateTrailerSaga(action) {
  // const { sortBy, order, offset, limit } = action.params;
  try {
    const result = yield call(api.updateTrailer, action.values);
        console.log("result in saga",result)
    if (result.status === 1) {
      yield put({
        type: types.API_UPDATE_BAND_BY_ID_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
      history.push("/trailerVideo")

    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      trailerFailedSaga(result);
    }
  } catch (e) {
    trailerErrorSaga(e);
  }
}


export default function* rootTrailerSaga() {
  yield takeLatest(types.API_GET_TRAILER_DATA_LOAD, trailerSaga);
  yield takeLatest(types.API_ADD_TRAILER_LOAD, addTrailerSaga);
  yield takeLatest(types.API_DELETE_TRAILER_LOAD, deleteTrailerSaga);
  yield takeLatest(types.API_GET_TRAILER_BY_ID_LOAD, getTrailerByIdSaga);
  yield takeLatest(types.API_EDIT_TRIALER_LOAD, updateTrailerSaga);



}
