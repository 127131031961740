import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination, Spin, Modal } from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { getMediaUrl } from "../../../utils/helpers";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import TextInput from '../../../customComponents/InputField'
import {
  getUserData,
  userBlockStatusAction,
  clearPreviousReducerData,
  addRocksToUserAction,
} from "../../../store/actions/admin";
import Loader from '../../../customComponents/Spinner'
import { faGalacticSenate } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const {
    userData,
    userDataCurrentPage,
    userDataTotalCount,
    onLoad,
    onChangeStatus,
    inProgress,
    rehit
  } = useSelector((state) => state.userManagementReducer);
  let reg = /[^0-9]*$/g;
  const [userBlock, setUserBlock] = useState(false);
  const [addRocksModalState, setAddRocksModalState] = useState(false);


  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const [spin, setSpin] = useState(true);
  const [s, setS] = useState("");

  const [paginationPage, setPaginationPage] = useState(1);
  const [id, setId] = useState("");

  const [rocks, setRocks] = useState(null);


  const breadData = [
    {
      name: "User Management",
      route: "/userManagement",
    },
  ];
  const classes = useStyles();
  const history = useHistory();
  const { appState } = useSelector((state) => state);

  const { sidebarDrawerToggleState } = appState;

  useEffect(() => {
    document.title = AppConstant.VirtualConcertUserManagement;
    dispatch(clearPreviousReducerData());
    dispatch(getUserData({ limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    console.log("spinner state ====> ", spin, userData);
    if (userData.length > 0) {
      setSpin(false);
    } else {
      setSpin(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userBlock === true && onChangeStatus === false) {
      dispatch(getUserData({ limit: 10, offset: 0 }));
      setUserBlock(false);
    }
  });


  useEffect(() => {
    if (rehit) {
      dispatch(getUserData({ limit: 10, offset: 0 }));
      dispatch({ type: "REHIT", value: false })
    }
  }, [rehit])

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search: s || ""
    };

    dispatch(getUserData(params));
  }

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <>
        {console.log("index =>",index , "paginationPage => ", paginationPage)}
        <div style={{ minWidth: "60px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
        </>
      ),
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePic",

      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <img
            src={
              record.profileImage
                ? getMediaUrl(record?.profileImage)
                : AppImages.noImage
            }
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            style={{
              height: 50,
              width: 50,
              borderRadius: 30,
            }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "username",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "100px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots3">{record?.username}</span>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailId",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "80px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots3">{record?.emailId || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: {
        compare: (a, b) => a.gender - b.gender,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "80px" }}>
          <span>{record?.gender || "N/A"}</span>
        </div>
      ),
    },

    {
      title: "Rock$",
      dataIndex: "rocksCount",
      sorter: {
        compare: (a, b) => a.rocksCount - b.rocksCount,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "80px" }}>
          <span>{record?.rocksCount}</span>
        </div>
      ),
    },

    {
      title: "Total Spent",
      dataIndex: "spent",
      // sorter: {
      //   compare: (a, b) => a.gender - b.gender,
      //   multiple: 1,
      // },
      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "80px" }}>
          <span>${record?.totalSpend.toFixed(2)}</span>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (action, record, index) => {
        return (
          <div className="action-style">
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() => history.push("/userDetails", { record: record?._id })}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
            </Button>
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() =>
                history.push("/editUser", {
                  editData: record,
                  image: AppImages.ProfileImage,
                })
              }
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>EDIT</span>
            </Button>

            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() => {
                setAddRocksModalState(true)
                setId(record?._id)
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>ADD ROCK$</span>
            </Button>
            <Button
              disabled={inProgress}
              className="action-button-inline-style danger-color"
              type="primary"
              style={{ width: 100, fontWeight: 600 }}
              onClick={() => {
                dispatch(userBlockStatusAction(record._id, !record.isBlock));
                setUserBlock(true);
              }}
            >
              <span style={{ fontWeight: 600, marginTop: 2 }}>
                {record.isBlock ? "UNBLOCK" : "BLOCK"}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

 
  return (
    <>
      <Header data={breadData} title={AppConstant.UserManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              title="User Management"
              value={s}
              onChange={(event) => {
                setPaginationPage(1)
                setS(event.target.value);
                console.log("serach val --> ", event.target.value);
                if(event.target.value[0] === " " ){
                }else{ 
                dispatch(
                  getUserData({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                    order: sorting.sortOrder,
                    sortBy: sorting.sortBy
                  })
                );
                }
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={userData}
                  onChange={onChange}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad ? true : false,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={userDataTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(getUserData({ limit: 10, offset: (page - 1) * 10, search:s,order: sorting.sortOrder, sortBy: sorting.sortBy }));
                    }}
                    current={userDataCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </NavCard>
          </div>
        </div>
      </div>
      <div className="rocks-modal" style={{
        marginLeft: 100
      }}>
        <Modal
          className="payment-request-modal-style rocks-modal"
          title="ADD ROCK$"
          visible={addRocksModalState}
          onOk={() => { }}
          onCancel={() => setAddRocksModalState(false)}
          style={{ marginTop: 0, position: "absolute" }}
        >
          <div className="addRocksModalStyle">
            <TextInput
              placeholder="ROCK$"
              value={rocks}
              // type="number"
              onKeyPress={(evt) => {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                  return false;
                }
                return true;
              }}
              onChange={(evt) => {
                console.log("evt?.target?.value[0]", typeof (evt?.target?.value[0]))
                if (evt?.target?.value[0] === "0") {
                  setRocks(evt?.target?.value.replace("0", ""))
                } else {
                  setRocks(evt?.target?.value.replace(/[^0-9]$/, ""))
                }
              }}

              name="title"
              onBlur={() => {
              }}
              fullWidth
              inputProps={{
                maxLength: 7,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 8
            }}
          >

            <Button
              onClick={() => {
                setAddRocksModalState(false)
                setRocks("")
              }}
              type="primary"
              className="rocks-cancel"
            >
              <span style={{
                fontWeight: 600
              }} >CANCEL</span>
            </Button>
            <div style={{
              marginLeft: 10
            }}></div>
            <Button
              onClick={() => {
                if (!rocks) {
                  return toast.error("Please enter rocks count.")
                } else {
                  dispatch(addRocksToUserAction({ userId: id, coins: rocks }))
                  setAddRocksModalState(false)
                  setRocks("")
                }
              }}
              type="primary"
            >
              {onLoad ? <Loader color="white" size="15px" thick={5} /> : <span style={{
                fontWeight: 600
              }} >ADD ROCK$</span>}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UserManagement;
