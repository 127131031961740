import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../../customComponents/style";
import {
  updateNewsByIdAction,
  getNewsByIdAction,
  deleteNewsMediaAction,
  addNewsMediaAction,
} from "../../../store/actions/admin";
import * as Yup from "yup";
import { Formik } from "formik";
import { getMediaUrl } from "../../../utils/helpers";
import clsx from "clsx";
import Button from "../../../customComponents/Button";
import TextArea from "../../../customComponents/TextArea";
import TextInput from "../../../customComponents/InputField";
import { toast } from "react-toastify";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";

import { ValidationConstants } from "../../../themes/validationConstants";
import "../trailerVideo/trailer.css";

import { Tooltip, Spin } from "antd";

const EditNews = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const editNewsData = props?.location?.state?.editData;

  const id = editNewsData?._id;

  const [onDeleteMedia, setOnDeleteMedia] = useState(true);
  const [onUpdateMedia, setOnUpdateMedia] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);

  const breadData = [
    {
      name: "News Management",
      route: "/newsManagement",
    },
    {
      name: "Edit News",
      route: "",
    },
  ];

  const { appState } = useSelector((state) => state);
  const { newsByIdData, onLoad } = useSelector(
    (state) => state.newsManagementReducer
  );
  const classes = useStyles();

  useEffect(() => {
    document.title = AppConstant.VirtualConcertEditNews;
    if (!id) {
      history.push("/newsManagement");
    } else {
      dispatch(getNewsByIdAction(id));
    }
  }, []);

  useEffect(() => {
    if (onDeleteMedia === true && onLoad === false) {
      dispatch(getNewsByIdAction(id));
      setOnDeleteMedia(false);
    }
  });

  useEffect(() => {
    if (onUpdateMedia === true && onLoad === false) {
      dispatch(getNewsByIdAction(id));
      setOnUpdateMedia(false);
    }
  });

  const { sidebarDrawerToggleState } = appState;

  const EditNewsdetail = () => {
    const [title, setTitle] = useState(newsByIdData?.title);
    const [description, setDescription] = useState(newsByIdData?.description);
    const [video1, setVideo1] = useState(newsByIdData?.video1);
    const [video2, setVideo2] = useState(newsByIdData?.video2);

    const values = {
      title: title,
      description: description,
      videoLink1: video1,
      videoLink2: video2,
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string()
        .trim()
        .min(2, "Title should be at least 2 characters long.")
        .required(ValidationConstants.titleRequired)
        .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),

      description: Yup.string()
        .trim()
        .min(50, "Description should be at least 50 characters long")
        .max(5000, "Description should not be more than 5000 characters.")
        .required(ValidationConstants.descriptionRequired)
        .matches(
          /^[aA-zZ\d\-_'".,:;/@#$%&*^()\s]+$/,
          "Enter alphanumeric values only."
        ),
      // videoLink1: Yup.string()
      //   .min(5, "Please enter a valid video link.")
      //   .trim()
      //   .matches(
      //     /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      //     "Entered YouTube URL is invalid, please enter valid URL."
      //   ),
      // videoLink2: Yup.string()
      //   .min(5, "Please enter a valid video link.")
      //   .trim()
      //   .matches(
      //     /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      //     "Entered YouTube URL is invalid, please enter valid URL."
      //   ),
    });

    const onSubmit = (values) => {
      const formData = new FormData();
      // formData.append("video1", values.videoLink1);
      // formData.append("video2", values.videoLink2);
      formData.append("title", values.title);
      formData.append("description", values.description);
      dispatch(updateNewsByIdAction(formData, editNewsData._id));
    };

    const selectHeroImage1 = () => {
      const hero_logo = document.getElementById("select_hero_image1");
      hero_logo.setAttribute("type", "file");
      hero_logo.setAttribute("accept", "image/*");
      if (hero_logo) {
        hero_logo.click();
      }
    };

    const setHeroSelectImage1 = (data) => {
      let formData = new FormData();

      if (data?.files[0] !== undefined) {
        let file = data.files[0];
        const extentType = ["png", "jpg", "jpeg"];
        const extent = file && file?.name.split(".");
        const extentT = extent && extent[extent.length - 1];
        let file_size = file && file?.size;
        let max_size = 1024000;

        let checkSize = file_size / max_size <= 10 ? true : false;
        if (extentType.indexOf(extentT) >= 0) {
          if (checkSize) {
            formData.append("newsId", id);
            formData.append("isImage", true);
            formData.append("upload", file);
            dispatch(addNewsMediaAction(formData));
            setOnDeleteMedia(true);
          } else {
            toast.error("Maximum 10 MB size images are allowed.");
          }
        } else if (file) {
          toast.error("Select image only.");
        }
      }
    };

    return onLoad ? (
      <div
        className="user-Details-Box"
        style={{
          height: 400,
          width: 400,
          marginTop: 80,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    ) : (
      <div className="user-Details-Box" style={{ marginTop: 100 }}>
        <div className="media-style">
          <span className="span-title-style" style={{ fontSize: 20 }}>
            Media
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <span className="span-title-style" style={{ marginRight: 20 }}>
            Images
          </span>
          {newsByIdData?.images?.length >= 0 &&
            newsByIdData?.images?.length < 5 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: -2,
                }}
                onClick={() => {}}
              >
                <Tooltip placement="topLeft" title="Click to upload image">
                  <img
                    alt=""
                    className="pointer-Style"
                    src={AppImages.plus}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = AppImages.noImage;
                    }}
                    style={{ height: 25, width: 25 }}
                    onClick={() => {
                      selectHeroImage1();
                    }}
                  />
                </Tooltip>
              </div>
            )}
        </div>

        <div
          className="row"
          style={{
            marginTop: 10,
            marginBottom: 50,
          }}
        >
          {newsByIdData?.images?.map((item, index) => {
            return (
              <div
                className="col-sm-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 20,
                  marginLeft: 0,
                }}
              >
                <div
                  onClick={() => {}}
                  className="upload-image responsive-bottom-margin"
                >
                  {item ? (
                    <img
                      src={getMediaUrl(item.url)}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AppImages.noImage;
                      }}
                      alt=""
                      style={{ width: "100%", height: "100%", minWidth: 180 }}
                    />
                  ) : (
                    <img
                      src={AppImages.Upload}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AppImages.noImage;
                      }}
                      alt=""
                      style={{
                        height: 25,
                        width: 25,
                        position: "absolute",
                        right: "47%",
                      }}
                      onClick={() => {}}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    width: 180,
                  }}
                  onClick={() => {
                    dispatch(
                      deleteNewsMediaAction({
                        newsId: id,
                        mediaId: item._id,
                        isImage: true,
                      })
                    );
                    setOnDeleteMedia(true);
                  }}
                >
                  <Tooltip placement="topLeft" title="Click to delete image">
                    <img
                      alt=""
                      className="pointer-Style"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AppImages.noImage;
                      }}
                      src={AppImages.remove}
                      style={{ height: 25, width: 25 }}
                      onClick={() => {}}
                    />
                  </Tooltip>
                </div>
              </div>
            );
          })}

          <input
            style={{ height: "100%", width: "100%" }}
            className="d-none input-upload"
            type="file"
            placeholder="Basic usage"
            id="select_hero_image1"
            onChange={(evt) => {
              setHeroSelectImage1(evt.target, imageIndex);
            }}
          />
        </div>

        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="bottom-margin-30" style={{ marginTop: 20 }}>
                {/* <TextInput
                  placeholder="Video Link 1"
                  value={values.videoLink1}
                  onChange={handleChange}
                  error={Boolean(touched.videoLink1 && errors.videoLink1)}
                  helperText={touched.videoLink1 && errors.videoLink1}
                  name="videoLink1"
                  onBlur={handleBlur}
                  fullWidth
                  style={{ paddingBottom: 20 }}
                />

                <TextInput
                  placeholder="Video Link 2"
                  value={values.videoLink2}
                  onChange={handleChange}
                  error={Boolean(touched.videoLink2 && errors.videoLink2)}
                  helperText={touched.videoLink2 && errors.videoLink2}
                  name="videoLink2"
                  onBlur={handleBlur}
                  fullWidth
                  style={{ paddingBottom: 20 }}
                /> */}

                <TextInput
                  placeholder="Title"
                  value={values?.title?.trimLeft()}
                  onChange={handleChange}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  name="title"
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    maxLength: 30,
                  }}
                />
              </div>
              <div className="row bottom-margin-30" style={{ marginTop: 60 }}>
                <TextArea
                  placeholder="Description"
                  value={values?.description?.trimLeft()}
                  onChange={handleChange}
                  name="description"
                  onBlur={handleBlur}
                  fullWidth
                />
              </div>

              <span
                style={{
                  color: "red",
                  marginTop: -26,
                  position: "absolute",
                  fontSize: 13,
                }}
              >
                {touched.description && errors.description}
              </span>
              <div className="news-button">
                <Button title={AppConstant.Update} bg mb wd type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <>
      <Header data={breadData} title={AppConstant.NewsManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Edit News" noSearch>
              <EditNewsdetail />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNews;
