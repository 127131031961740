import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import { toast } from "react-toastify";
import history from "../../../utils/history";
import * as api from "../../Api";
import { ValidationConstants } from "../../../themes/validationConstants";

function* trailerFailedSaga(result) {
  yield put({
    type: types.API_TRAILER_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* trailerErrorSaga(result) {
  yield put({
    type: types.API_TRAILER_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

// get list help
export function* getListHelpSaga(action) {
  try {
    const result = yield call(api.getListHelp, action.values);

    if (result.status === 1) {
      yield put({
        type: types.API_FETCH_LIST_HELP_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.data.message);
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      trailerFailedSaga(result);
    }
  } catch (e) {
    trailerErrorSaga(e);
  }
}

// get replied faq list

// get list help
export function* getRepliedFaqSaga(action) {
  try {
    const result = yield call(api.getRepliedList, action.values);

    if (result.status === 1) {
      yield put({
        type: types.API_FETCH_REPLIED_LIST_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.data.message);
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message);
    } else {
      trailerFailedSaga(result);
    }
  } catch (e) {
    trailerErrorSaga(e);
  }
}

//send reply

export function* sendReplySaga(action) {
  console.log("values inside add trailer saga----> ", action.values);
  try {
    const result = yield call(api.sendReply, action.values);

    console.log("result afetr sending reply in saga ", result);

    if (result.status === 1) {
      yield put({
        type: types.API_SEND_REPLY_TO_LIST_HELP_SUCCESS,
        result: result.result.data.data,
      });
      // yield call(getListHelpSaga());
      yield put({
        type: "CAHNGE_REPLY_MODAL_STATE",
        value: false,
      });

      yield put({
        type: "REHIT_LIST",
        value: true,
      });

      toast.success(result.result.data.message);
      //   history.push("/trailerVideo");
    } else if (result.status === 2) {
      toast.error(ValidationConstants.messageStatus500);
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
      yield put({
        type: types.API_SEND_REPLY_TO_LIST_HELP_FAILED,
      });

      // history.push("/dashboard");
    } else {
      yield put({
        type: types.API_SEND_REPLY_TO_LIST_HELP_FAILED,
      });
      trailerFailedSaga(result);
    }
  } catch (e) {
    yield put({
      type: types.API_SEND_REPLY_TO_LIST_HELP_ERROR,
    });
    trailerErrorSaga(e);
  }
}

export default function* rootSupportSaga() {
  yield takeLatest(types.API_FETCH_LIST_HELP_LOAD, getListHelpSaga);
  yield takeLatest(types.API_SEND_REPLY_TO_LIST_HELP_LOAD, sendReplySaga);
  yield takeLatest(types.API_FETCH_REPLIED_LIST_LOAD, getRepliedFaqSaga);
}
