import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../customComponents/loader";
import { termsAndConditionsAction } from "../../../store/actions/Band";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import AppConstant from "../../../themes/appConstant";
import NavCard from "../../../customComponents/NavCard";
import clsx from "clsx";

const TermsAndCondition = () => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const { myTermsAndConditions, onLoad } = useSelector(
    (state) => state.bandTermsAndConditionReducer
  );

  const { sidebarDrawerToggleState } = appState;
  const classes = useStyles();
  const breadData = [
    {
      name: "Terms & Conditions",
      route: "/band/termsAndConditions",
    },
  ];

  useEffect(() => {
    document.title = AppConstant.virtualConcertTermsAndConditions;
    dispatch(termsAndConditionsAction());
  }, []);

  return (
    <>
      <BandHeader data={breadData} title={AppConstant.Dashboard} />
      <BandSideNAv />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard noSearch title={`Terms & Conditions`}>
              <div
                className="user-Details-Box "
                style={{ paddingTop: 30, textAlign: "justify" }}
              >
                <div className="span-title-style" style={{ marginBottom: 30 }}>
                  <span>{myTermsAndConditions?.title}</span>
                </div>
                {myTermsAndConditions?.lines?.map((item) => {
                  return (
                    <div style={{ marginBottom: 15 }}>
                      <span className="span-title-style">
                        <ul className="ulCss">
                          <li>{item}</li>
                        </ul>
                      </span>
                    </div>
                  );
                })}
              </div>
              <Loader visible={onLoad} />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
