import React, { useEffect, useState } from "react";
import { Table, Pagination, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { approvedConcertUserListAction } from "../../../store/actions/admin";
import { dateConvert } from "../../../utils/dateTimeConverter";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { useHistory } from "react-router-dom";
import { getMediaUrl } from "../../../utils/helpers";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const PremiumUser = (props) => {
  const history = useHistory();

  const [paginationPage, setPaginationPage] = useState(1);
  const [s, setS] = useState("");

  const dispatch = useDispatch();

  const [localImage, setLocalImage] = useState();
  const id = props.location?.state?.id;
  const {
    approvedBuyersList,
    concertBuyersTotalCount,
    concertBuyersCurrentPage,
    onLoad,
  } = useSelector((state) => state.concertReducer);
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""

    };

    dispatch(approvedConcertUserListAction(params, id));
  }

  const breadData = [
    {
      name: "Concert Management",
      route: "/concertManagement",
    },
    {
      name: "Approved Concerts",
      route: "/unapprovedConcert",
    },
    {
      name: "Users",
      route: "",
    },
  ];

  const { appState } = useSelector((state) => state);

  useEffect(() => {
    document.title = AppConstant.virtualConcertConcertUser;

    if (!id) {
      history.push("/concertManagement");
    } else {
      dispatch(approvedConcertUserListAction({ limit: 10, offset: 0 }, id));
    }
  }, []);

  const { sidebarDrawerToggleState } = appState;

  const classes = useStyles();

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "60px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    {
      title: "Profile Picture",
      dataIndex: "profileImage",

      render: (text, record) => {
        return (
          <div style={{ minWidth: "100px" }}>
            <img
              alt="aaaaaa"
              src={getMediaUrl(record.profileImage)}
              height={100}
              width={100}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = AppImages.noImage;
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 3,
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "userName",
      sorter: {
        compare: (a, b) => a.userName - b.userName,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px" }}>
          <span>{record?.userName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailId",
      sorter: {
        compare: (a, b) => a.emailId - b.emailId,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
          <span>{record?.emailId || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: {
        compare: (a, b) => a.gender - b.gender,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "80px" }}>
          <span>{record?.gender || "N/A"}</span>
        </div>
      ),
    },

    {
      title: "Ticket Price",
      dataIndex: "ticketPrice",
      sorter: {
        compare: (a, b) => a.ticket - b.ticket,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "100px" }}>
          <span>${record?.ticketPrice || "0"}</span>
        </div>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "buyOn",
      // sorter: {
      //   compare: (a, b) => a.purchase - b.purchase,
      //   multiple: 1,
      // },
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{dateConvert(record.buyOn)}</span>
        </div>
      ),
    },
  ];

  const UserTable = (props) => {
    return (
      <>
        <div className="">
          <Table
            className="table-responsive user-table-style"
            columns={columns}
            dataSource={approvedBuyersList}
            onChange={onChange}
            pagination={false}
            showSorterTooltip={false}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
              spinning: onLoad ? true : false,
            }}
            rowKey={(record) => record._id}
          />
          <div className="pagination-style">
            <Pagination
              className="pagination"
              total={concertBuyersTotalCount}
              onChange={(page) => {
                setPaginationPage(page);
                dispatch(
                  approvedConcertUserListAction(
                    { limit: 10, offset: (page - 1) * 10, search:s  },
                    id
                  )
                );
              }}
              current={concertBuyersCurrentPage}
              showSizeChanger={false}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header data={breadData} title={AppConstant.UserManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
              title="Users"
              onChange={(event) => {

                setS(event.target.value);
                console.log("serach val --> ", event.target.value);
                dispatch(
                  approvedConcertUserListAction(
                    {
                      limit: 10,
                      offset: 0,
                      search: event.target.value,
                    },
                    id
                  )
                );
              }}
            >
              <UserTable />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumUser;
