import { apiConstants as types } from "../../../themes/apiConstants";
import sessionStorage from "../../../utils/localStorage";
const initialState = {
  onLoad: false,
  errorState: null,
  linkExpire: null,
};

// LOGIN REDUCER
export const adminAuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login cases
    case types.API_LOGIN_LOAD:
      return { ...state, onLoad: true };
    case types.API_LOGIN_SUCCESS:
      console.log(action.result);
      sessionStorage.setAuthToken(action.result);
      // window.location.reload();
      return { ...state, onLoad: false };

    // Forgot password
    case types.API_FORGOT_PASSWORD_LOAD:
      return { ...state, onLoad: true };
    case types.API_FORGOT_PASSWORD_SUCCESS:
      return { ...state, onLoad: false };

    // Change Password
    case types.API_CHANGE_PASSWORD_LOAD:
      return { ...state, onLoad: true };
    case types.API_CHANGE_PASSWORD_SUCCESS:
      localStorage.clear();
      return { ...state, onLoad: false };

    // Reset Password
    case types.API_RESET_PASSWORD_LOAD:
      return { ...state, onLoad: true };
    case types.API_RESET_PASSWORD_SUCCESS:
      console.log("reser password reducer hits");
      return { ...state, onLoad: false };

    // Check Reset Password Expire
    case types.API_RESET_PASSWORD_EXPIRE_LOAD:
      return { ...state, onLoad: true };
    case types.API_RESET_PASSWORD_EXPIRE_SUCCESS:
      console.log(
        "check reset password expire reducer hits",
        action.result.linkExpire
      );

      return { ...state, onLoad: false, linkExpire: action.result.linkExpire };

    //Fail cases
    case types.API_AUTHENTICATION_FAILED:
      return { ...state, onLoad: false };
    case types.API_AUTHENTICATION_ERROR:
      return { ...state, onLoad: false };
    default:
      return state;
  }
};
