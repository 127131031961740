import React, { useEffect, useState } from "react";
import NavCard from "../../../customComponents/NavCard";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination, Spin } from "antd";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import SideNav from "../../../customComponents/SideNav";
import AppConstant from "../../../themes/appConstant";
import ConfirmationModal from "../../../customComponents/paymentConfirmationModal";
import clsx from "clsx";
import {
  paymentRequestListAction,
  payToBandAction,
} from "../../../store/actions/admin";
import { dateWithTimeConverter } from "../../../utils/dateTimeConverter";

let breadData = [
  {
    name: "Payment Requests",
    route: "/paymentRequest",
  },
];

const PaymentRequest = () => {
  const classes = useStyles();
  const [paymentId, setPaymentId] = useState();
  const [onPay, setOnPay] = useState(false);
  const [s, setS] = useState("");
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const [modalState, setModalState] = useState(false);
  const dispatch = useDispatch();
  const [paginationPage, setPaginationPage] = useState(1);
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });
  const {
    paymentRequestTotalCount,
    paymentRequestCurrentPage,
    paymentRequestList,
    onLoad,
    onPayRequest,
  } = useSelector((state) => state.paymentRequestReducer);

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,

      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(paymentRequestListAction(params));
  }

  const getColor = (action) => {
    if (action) {
      return "paid-button";
    } else {
      return "action-button-inline-style";
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: "60px" }}>
            <span>{(paginationPage - 1) * 10 + index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Band Name",
      dataIndex: "bandName",
      sorter: {
        compare: (a, b) => a.bandName - b.bandName,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.bandName || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Date of Request",
      dataIndex: "createdOn",
      sorter: {
        compare: (a, b) => a.createdOn - b.createdOn,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px", maxWidth: "250px" }}>
          <span>{dateWithTimeConverter(record?.createdOn) || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>${record?.amount || "0"}</span>
        </div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "isPaid",
      key: "isPaid",
      sorter: {
        compare: (a, b) => a.isPaid - b.isPaid,
        multiple: 1,
      },
      render: (text, record) => {
        return (
          <div className="action-style" style={{ minWidth: "120px" }}>
            <Button
              disabled={record?.isPaid}
              className={`${getColor(record?.isPaid)}`}
              type="primary"
              onClick={() => {
                setModalState(true);
                setPaymentId(record?._id);
              }}
            >
              <span
                style={{ fontWeight: 600, minWidth: "60px", paddingTop: 1.3 }}
              >
                {record?.isPaid ? "Paid" : "Pay"}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = AppConstant.virtualConcertPaymentRequest;
    dispatch(paymentRequestListAction({ limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    if (onPay === true && onPayRequest === false) {
      dispatch(paymentRequestListAction({ limit: 10, offset: 0 }));
      setOnPay(false);
    }
  });

  return (
    <>
      <Header data={breadData} title={AppConstant.Dashboard} />
      <SideNav tabPath={window.location.pathname} />

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
             value={s}
              title="My Payment Request"
              onChange={(event) => {
                console.log("search val --> ", event.target.value);
                setPaginationPage(1)
                setS(event.target.value)
                dispatch(
                  paymentRequestListAction({
                    limit: 10,
                    offset: 0,
                    search: event.target.value,
                    order: sorting.sortOrder,
                    sortBy: sorting.sortBy
                  })
                );
              }}
            >
              <div className="table-responsive">
                <Table
                  className="table-responsive user-table-style"
                  columns={columns}
                  dataSource={paymentRequestList}
                  pagination={false}
                  showSorterTooltip={false}
                  rowKey={(record) => record._id}
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: onLoad ? true : false,
                  }}
                />
                <div className="pagination-style">
                  <Pagination
                    className="pagination"
                    total={paymentRequestTotalCount}
                    onChange={(page) => {
                      setPaginationPage(page);
                      dispatch(
                        paymentRequestListAction({
                          limit: 10,
                          offset: (page - 1) * 10, search:s ,order: sorting.sortOrder, sortBy: sorting.sortBy
                        })
                      );
                    }}
                    current={paymentRequestCurrentPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
              <ConfirmationModal
                title="Alert"
                visible={modalState}
                closeModal={() => {
                  setModalState(false);
                }}
                setVisible={() => {
                  setModalState(!modalState);
                  dispatch(payToBandAction(paymentId));
                  setOnPay(true);
                }}
                text="Are you sure you want to pay this band?"
              />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentRequest;
