import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authenticationSaga";
import rootDashboardSaga from "./dashboardSaga";
import rootTrailerSaga from "./trailerSaga";
import rootConcertSaga from "./concertSaga";
import rootUserManagementSaga from "./userManagementSaga";
import rootBandManagementSaga from "./bandManagementSaga";
import rootNewsManagementSaga from "./newsManagementSaga";
import rootEarningManagementSaga from "./earningManagementSaga";
import rootPaymentRequestSaga from "./paymentRequestSaga";
import rootSupportSaga from "./supportSaga";
import rootFeaturedListSaga from "./featuredConcert";
import rootProductSaleListSaga from "./productSaleSaga";

export default function* rootAdminSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootDashboardSaga),
    fork(rootTrailerSaga),
    fork(rootConcertSaga),
    fork(rootUserManagementSaga),
    fork(rootBandManagementSaga),
    fork(rootNewsManagementSaga),
    fork(rootEarningManagementSaga),
    fork(rootPaymentRequestSaga),
    fork(rootSupportSaga),
    fork(rootFeaturedListSaga),
    fork(rootProductSaleListSaga),
  ]);
}
