import { takeLatest, call, put, delay } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import history from "../../../utils/history";
import { toast } from "react-toastify";
import * as api from "../../Api";

function* bandManagementFailedSaga(result) {
  yield put({
    type: types.API_GET_BAND_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* bandManagementErrorSaga(result) {
  yield put({
    type: types.API_GET_BAND_MANAGEMENT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* bandManagementSaga(action) {
  console.log("action  ===>  ", action);
  const { sortBy, order, offset, limit, search } = action.params;

  try {
    const result = yield call(api.bandManagement, {
      sortBy,
      order,
      offset,
      limit,
      search,
    });

    if (result.status === 1) {
      yield put({
        type: types.API_GET_BAND_MANAGEMENT_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);
      history.push("/login");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_BAND_MANAGEMENT_FAILED });
      yield call(bandManagementFailedSaga, result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_BAND_MANAGEMENT_ERROR });
    yield call(bandManagementErrorSaga, e);
  }
}

// fetch band by id

export function* getBandByIdSaga(action) {
  try {
    const result = yield call(api.getBandById, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_BAND_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);
      history.push("/login");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else if (result.status === 8) {
      history.push("/bandManagement");
    } else {
      // yield put({ type: types.API_GET_BAND_BY_ID_FAILED });
      yield call(bandManagementFailedSaga, result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_BAND_BY_ID_ERROR });
    yield call(bandManagementErrorSaga, e);
  }
}

// change band status

export function* changeBandStatusSaga(action) {
  const { bandId, isBlock } = action;
  try {
    const result = yield call(api.changeBandBlockStatus, { bandId, isBlock });

    if (result.status === 1) {
      toast.success(result.result.data.message, {
        toastId: result?.status || "est",
      });
      yield delay(1500);
      yield put({
        type: types.API_BLOCK_BAND_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      // yield put({ type: types.API_GET_BAND_BY_ID_FAILED });
      yield call(bandManagementFailedSaga, result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_BAND_BY_ID_ERROR });
    yield call(bandManagementErrorSaga, e);
  }
}

// update band by id

export function* updateBandByIdSaga(action) {
  try {
    console.log("action data --> ", action);
    const result = yield call(api.updateBandById, action);
    console.log("result in update band saga ==> ", result);
    if (result.status === 1) {
      yield put({
        type: types.API_UPDATE_BAND_BY_ID_SUCCESS,
        result: result.result.data.data,
      });

      toast.success(result.result.data.message, {
        toastId: result?.status || "est",
      });

      history.push("/bandManagement");
    } else {
      // yield put({ type: types.API_UPDATE_BAND_BY_ID_FAILED });
      yield call(bandManagementFailedSaga, result);
    }
  } catch (e) {
    // yield put({ type: types.API_UPDATE_BAND_BY_ID_ERROR });
    yield call(bandManagementErrorSaga, e);
  }
}

export default function* rootBandManagementSaga() {
  yield takeLatest(types.API_GET_BAND_MANAGEMENT_LOAD, bandManagementSaga);
  yield takeLatest(types.API_GET_BAND_BY_ID_LOAD, getBandByIdSaga);
  yield takeLatest(types.API_UPDATE_BAND_BY_ID_LOAD, updateBandByIdSaga);
  yield takeLatest(types.API_BLOCK_BAND_LOAD, changeBandStatusSaga);
}
