import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Pagination, Spin, Input, Select } from "antd";
import { useHistory } from "react-router-dom";
import Loader from "./loader";
import ReplyModal from "./replyModal";
import {
  dateConvert,
  timeConvert,
  dateWithTimeConverter,
} from "../utils/dateTimeConverter";
import moment from "moment";
import { BandConcertTabStateAction } from "../store/actions/Band/ConcertTabState";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BandTabPanel from "./BandTabPanel";
import AppColors from "../themes/appColors";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { getConcertDataAction } from "../store/actions/Band";
import { getListHelpAction, getRepliedFaqAction } from "../store/actions/admin";
import "./dashboardLayout.scss";
import "./customComponentStyle.css";

const { Search } = Input;

const useStyles = makeStyles({
  root: {
    textTransform: "none",
  },
});
const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
  },
  typography: {
    button: {
      textTransform: "unset !important",
      fontSize: 14,
      fontWeight: 600,
    },
  },
});

const SupportCard = (props) => {
  // let type = "all";
  const classes = useStyles();
  const { Option } = Select
  const [paginationPage, setPaginationPage] = useState(1);
  const [replypaginationPage, setReplyPaginationPage] = useState(1);
  const [replyGone, setReplyGone] = useState(false);
  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });
  const [replyId, setReplyId] = useState(null);
  const dispatch = useDispatch();
  const tabState = useSelector((state) => state.concertTabsState.value);
  const { replyModalState } = useSelector((state) => state.supportReducer);
  const {
    listHelpData,
    repliedListData,
    listHelpPagination,
    repliedListPagination,
    onLoad,
    replySent,
    rehit,
  } = useSelector((state) => state.supportReducer);
  const [isReplyModal, setIsReplyModal] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [type, setType] = React.useState('all');
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Content of the modal");

  const showModal = () => {
    setIsReplyModal(true);
  };

  console.log("list Help Data ========> ", listHelpData, listHelpPagination);
  console.log(
    "replied list data ========> ",
    repliedListData,
    repliedListPagination
  );

  console.log("tabstate", tabState);

  const handleOk = () => {
    // setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setIsReplyModal(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setIsReplyModal(false);
  };

  // const {
  //   concertData,
  //   concertDataTotalCount,
  //   concertDataCurrentPage,
  //   onLoad,
  // } = useSelector((state) => state.bandConcertReducer);
  // const [paginationPage, setPaginationPage] = useState(1);
  // const getColor = (action) => {
  //   if (action) {
  //     return "paid-button";
  //   } else {
  //     return "action-button-inline-style";
  //   }
  // };
  const history = useHistory();
  const [concertType, setConcertType] = useState(null);
  // const [sorting, setSorting] = useState({
  //   sortBy: null,
  //   sortOrder: null,
  // });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      type: type
    };

    dispatch(getListHelpAction(params));
  }

  const replylisteners = (key) => ({
    onClick: () => replytableSort(key),
  });

  function replytableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      isReply: true,
      limit: 10,
      offset: replypaginationPage ? 10 * (replypaginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      type: type
    };

    dispatch(getRepliedFaqAction(params));
  }

  useEffect(() => {
    if (tabState === 0) {
      setPaginationPage(1);
      dispatch(getListHelpAction({ limit: 10, offset: 0, type: type }));
    } else {
      setReplyPaginationPage(1);
      dispatch(getRepliedFaqAction({ isReply: true, limit: 10, offset: 0, type: type }));
    }
  }, [tabState]);

  useEffect(() => {
    console.log("rehit api ====> ", rehit);

    if (rehit === true) {
      console.log("rehit api", rehit);
      dispatch(getListHelpAction({ limit: 10, offset: 0 }));
      dispatch({ type: "REHIT_LIST", value: false });
    }
  }, [rehit]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "60px", maxWidth: "100px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      sorter: {
        compare: (a, b) => a.title - b.title,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "200px", maxWidth: "200px" }}>
          <span>{record?.title}</span>
        </div>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      sorter: {
        compare: (a, b) => a.concern - b.concern,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "300px", maxWidth: "300px" }}>
          <span
            style={{
              textAlign: "justify",
            }}
          >
            {record?.concern}
          </span>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      sorter: {
        compare: (a, b) => a.concern - b.concern,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div
          className="hightlight-user"
          style={{ minWidth: "120px", maxWidth: "120px", color: "#f58020" }}
        >
          <span
            style={{
              textAlign: "justify",
            }}
            onClick={() => {
            if(record?.user) {
              history.push("/userDetails", {
                record: record?.user?._id,
              });
            }else{
              history.push("/bandDetails", {
                record: record?.band?._id,
              });
            }
            }}
          >
            {record?.user ? record?.user?.username : record?.band?.bandName}
          </span>
        </div>
      ),
    },

    {
      title: "Date & Time",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.concern - b.concern,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "150px", maxWidth: "160px" }}>
          <span
            style={{
              textAlign: "justify",
            }}
          >
            {dateWithTimeConverter(record?.createdOn)}
          </span>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",

      render: (action, record, index) => {
        return (
          <div className="action-style">
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() => {
                setReplyId(record?._id);
                dispatch({
                  type: "CAHNGE_REPLY_MODAL_STATE",
                  value: true,
                });
              }}
            >
              <span style={{ fontWeight: 600, paddingTop: 1.3 }}>Reply</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const replyColumn = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "60px", maxWidth: "100px" }}>
          {/* <span>{index + 1}</span> */}
          <span>{(replypaginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      sorter: {
        compare: (a, b) => a.title - b.title,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => replylisteners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "150px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots">{record?.title}</span>
        </div>
      ),
    },

    {
      title: "Description",
      dataIndex: "concern",
      sorter: {
        compare: (a, b) => a.concern - b.concern,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => replylisteners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "250px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots">{record?.concern}</span>
        </div>
      ),
    },

    {
      title: "Reply",
      dataIndex: "reply",
      sorter: {
        compare: (a, b) => a.concertName - b.concertName,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => replylisteners(dataIndex),
      render: (text, record) => (
        <div
          style={{
            minWidth: "250px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="text-dots">{record?.reply}</span>
          {/* <span>{record.concertName}</span> */}
        </div>
      ),
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   sorter: {
    //     compare: (a, b) => a.concern - b.concern,
    //     multiple: 2,
    //   },
    //   onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
    //   render: (text, record) => (
    //     <div style={{ minWidth: "150px", maxWidth: "160px" }}>
    //       <span
    //         style={{
    //           textAlign: "justify",
    //         }}
    //       >
    //         {dateWithTimeConverter(record?.createdOn)}
    //       </span>
    //     </div>
    //   ),
    // },

    {
      title: "Action",
      dataIndex: "action",

      render: (action, record, index) => {
        return (
          <div className="action-style">
            <Button
              className="action-button-inline-style"
              type="primary"
              onClick={() => {
                history.push("/replyDetails", {
                  replyData: record,
                });
              }}
            >
              <span style={{ fontWeight: 600, paddingTop: 1.3 }}>View</span>
            </Button>
          </div>
        );
      },
    },
  ];

 

  function SimpleTabs() {
    const handleChange = (event, newValue) => {
      dispatch(BandConcertTabStateAction(newValue));
      // setPaginationPage(1);
      demoMethod(newValue);
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    return (
      <div className="tabs-responsive-style">
        <AppBar position="static">
          <div className="topNav-responsive">
            <Tabs
              indicatorColor="transparent"
              value={tabState}
              onChange={handleChange}
              className={classes.root}
            >
              <Tab className="tab" label="Asked Problems" {...a11yProps(0)} />
              <Tab className="tab" label="Replied" {...a11yProps(1)} />

              <div className="filter-select" style={{
                position:"absolute",
                right:10,
                top:"23%"
              }}>
            {props?.selectType && (
              <Select
                className="filter-select"
                defaultValue={type}
                style={{ width: 150 }}
                onChange={(e, data) => {
                  setType(data.value)
                  if (tabState === 0) {
                    dispatch(getListHelpAction({ limit: 10, offset: 0, type: data.value }));
                  } else {
                    dispatch(getRepliedFaqAction({ limit: 10, offset: 0, type: data.value }));
                  }
                }}
              >
                <Option value="all">All</Option>;
                <Option value={"band"}>{"Band"}</Option>
                <Option value={"user"}>{"User"}</Option>

              </Select>
            )}
          </div>


            </Tabs>


    


          </div>

        </AppBar>



      </div>
    );
  }
  const demoMethod = (newValue) => {
    props.sendData(newValue);
  };

  return (
    <>
      <div className={props.tabNav ? "band-top-nav-Header" : "band-top-Header"}>

        <div className="band-tabs-table-header-style">
          <SimpleTabs />
          

        </div>
      </div>
      <div
      style={{
        paddingTop:90
      }}
        className={`nav-Card  ${props.noResponsive ? null : "table-responsive"
          } ${props.extraClass} bandTabsTopMargin `}
      >

        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >

   


        </div>
        <BandTabPanel value={tabState} index={0} >
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={columns}
              dataSource={listHelpData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={listHelpPagination?.totalCount}
                onChange={(page) => {
                  setPaginationPage(page);
                  dispatch(
                    getListHelpAction({ limit: 10, offset: (page - 1) * 10, type: type })
                  );
                }}
                current={listHelpPagination?.currentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>
        <BandTabPanel value={tabState} index={1}>
          <div className="">
            <Table
              className="table-responsive band-table-style"
              columns={replyColumn}
              dataSource={repliedListData}
              pagination={false}
              showSorterTooltip={false}
              rowKey={(record) => record._id}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: onLoad,
              }}
            />
            <div className="pagination-style">
              <Pagination
                className="pagination"
                total={repliedListPagination?.totalCount}
                onChange={(page) => {
                  setReplyPaginationPage(page);
                  dispatch(
                    getRepliedFaqAction({
                      isReply: true,
                      limit: 10,
                      offset: (page - 1) * 10,
                      type: type
                    })
                  );
                }}
                current={repliedListPagination?.currentPage}
                showSizeChanger={false}
              />
            </div>
          </div>
        </BandTabPanel>
      </div>


      <ReplyModal
        title="Reply.."
        visible={isReplyModal}
        replyId={replyId}
        onOk={handleOk}
        onCancel={handleCancel}
        onSubmitReply={() => {
          setIsReplyModal(false);
        }}
      />
    </>
  );
};

export default SupportCard;
