import { apiConstants as types } from "../../../themes/apiConstants";

export const getNewsManagementData = (params) => {
  return {
    type: types.API_GET_NEWS_MANAGEMENT_LOAD,
    params,
  };
};

export const getNewsByIdAction = (id) => {
  return {
    type: types.API_GET_NEWS_BY_ID_LOAD,
    id,
  };
};

export const addNewsAction = (values) => {
  return {
    type: types.API_ADD_NEWS_LOAD,
    values,
  };
};

export const addNewsImagesAction = (index, imgUrl, file) => {
  return {
    type: types.API_ADD_NEWS_IMAGE_LOAD,
    index,
    imgUrl,
    file,
  };
};

export const addNewsImageBoxAction = () => {
  return {
    type: types.API_ADD_NEWS_BOX_LOAD,
  };
};

export const addNewsVideosBoxAction = () => {
  return {
    type: types.API_ADD_NEWS_VIDEOS_BOX_LOAD,
  };
};

export const addNewsVideosAction = (index, imgUrl, file) => {
  return {
    type: types.API_ADD_NEWS_VIDEO_LOAD,
    index,
    imgUrl,
    file,
  };
};

export const deleteNewsAction = (id) => {
  return {
    type: types.API_DELETE_NEWS_LOAD,
    id,
  };
};

export const updateNewsByIdAction = (values, id) => {
  return {
    type: types.API_UPDATE_NEWS_LOAD,
    values,
    id,
  };
};

export const deleteNewsMediaAction = (values) => {
  return {
    type: types.API_DELETE_NEWS_MEDIA_LOAD,
    values,
  };
};

export const addNewsMediaAction = (values) => {
  return {
    type: types.API_ADD_NEWS_MEDIA_LOAD,
    values,
  };
};



export const clearAddNewsReducerPreviousDataAction = () => {
  return {
    type: types.ADD_NEWS_CLEAR_PREVIOUS_REDUCER_DATA,
  };
};

export const clearPreviousReducerData = () => {
  const action = {
    type: types.CLEAR_REDUCER,
  };
  return action;
};

export const deleteAddedNewsImageAction = (index) => {
  const action = {
    type: types.CLEAR_ADDED_NEWS,
    index,
  };
  return action;
};
