import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  trailerData: [],
  checkbox: [],
  trailerDataTotalCount: 0,
  trailerDataCurrentPage: 1,
  trailerDataNextPage: 0,
  onDeleteTrailerReducer: false,
  checkboxErrorMessage: "",
  trailerDetails : null
};

export const trailerReducer = (state = initialState, action) => {
  switch (action.type) {
    //FTECH ALL TRAILERS
    case types.API_GET_TRAILER_DATA_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_TRAILER_DATA_SUCCESS:
      state.trailerData = action.result.data;
      state.trailerDataTotalCount = action.result.pagination.totalCount;
      state.trailerDataCurrentPage = action.result.pagination.currentPage;

      console.log("hope-----------", { state });
      return { ...state, onLoad: false };

    //ADD TRAILER
    case types.API_ADD_TRAILER_LOAD:
      return { ...state, onLoad: true };

    case types.API_ADD_TRAILER_SUCCESS:
      return { ...state, onLoad: false };

    case types.API_ADD_TRAILER_FAILED:
      return { ...state, onLoad: false };

    case types.API_ADD_TRAILER_ERROR:
      return { ...state, onLoad: false };

    //DELETE TRAILER
    case types.API_DELETE_TRAILER_LOAD:
      return { ...state, onLoad: true, onDeleteTrailerReducer: true };

    case types.API_DELETE_TRAILER_SUCCESS:
      return { ...state, onLoad: false, onDeleteTrailerReducer: false };


    //GET TRAILER BY ID
    case types.API_GET_TRAILER_BY_ID_LOAD:
      return { ...state, onLoad: true,  };

    case types.API_GET_TRAILER_BY_ID_SUCCESS:
console.log("trailerDetails reducer" , action)

      return { ...state, trailerDetails: action.result , onLoad: false};



          //UPDATE TRAILER
    case types.API_EDIT_TRIALER_LOAD:
      return { ...state, onLoad: true };

    case types.API_EDIT_TRIALER_SUCCESS:
      return { ...state, onLoad: false };


    //empty checkbox state
    case types.EMPTY_CHECKBOX_STATE:
      state.checkbox = [];
      return state;

    case types.API_ADD_TRAILER_CHECKBOX:
      if (state.checkbox.includes(action.value)) {
        const index = state.checkbox.indexOf(action.value);
        state.checkbox.splice(index, 1);
      } else {
        state.checkbox.push(action.value);
      }

      return state;

    case types.API_TRAILER_FAILED:
      return { ...state, onLoad: false };

    case types.API_TRAILER_ERROR:
      return { ...state, onLoad: false };

    default:
      return state;
  }
};
