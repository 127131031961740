import React from "react";
// Styles
import { useStyles } from "./style";

import Typography from "@material-ui/core/Typography";
// Navigation
import { Route, MemoryRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import AppColors from "../themes/appColors";
import AppImages from "../themes/appImages";

// Redux
import { useDispatch } from "react-redux";
import { handleNavigationStateAction } from "../store/actions";

const Breadcrumbs = (props) => {
  const { pathSnippets } = props;
  // Hooks declarations
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBreadCrumbState = () => {
    const urlPath = window.location.pathname;
    const urlBand = urlPath.split("/");
    dispatch(handleNavigationStateAction(0, false));
    urlBand[1] === "band"
      ? history.push("/band/dashboard")
      : history.push("/dashboard");
  };

  // To get path snippets from url dynamicaly
  // const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = props?.pathSnippets.map((breadcrumb, index) => {
    // To make url for the breadcrumb link dynamicaly
    // const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return breadcrumb.name === "/dashboard" ? (
      true
    ) : (
      <React.Fragment key={index.toString()}>
        <div className={classes.separator}>
          <img
            src={AppImages.rightArrowSvg}
            alt=""
            className={classes.rightArrow}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
          />
        </div>
        <Breadcrumb.Item
          key={breadcrumb.route}
          className={classes.breadcrumbItem}
        >
          {index === pathSnippets.length - 1 ? (
            <Typography variant="subtitle1">{breadcrumb.name}</Typography>
          ) : (
            <Typography
              style={{ position: "relative", top: -3 }}
              variant="subtitle1"
              onClick={() => history.push(breadcrumb.route)}
              display="inline"
              className={classes.breadcrumbLink}
            >
              {breadcrumb.name}
            </Typography>
          )}
        </Breadcrumb.Item>
      </React.Fragment>
    );
  });

  // Home breadcrumb and concated extra breadcrumbs
  const breadcrumbItems = [
    <Breadcrumb.Item key="home" className={classes.breadcrumbItem}>
      <FontAwesomeIcon
        icon={faHome}
        color={AppColors.mainColor}
        onClick={() => {
          handleBreadCrumbState();
        }}
        className={classes.breadcrumbIcon}
      />
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <MemoryRouter initialEntries={["/dashboard"]} initialIndex={0}>
      <Route>
        <Breadcrumb
          aria-label="breadcrumb"
          separator=">"
          className={classes.breadcrumbs}
        >
          {breadcrumbItems}
        </Breadcrumb>
      </Route>
    </MemoryRouter>
  );
};
export default Breadcrumbs;
