const Types = {
  HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
  HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

  GET_GENRE_LIST_LOAD: "GET_GENRE_LIST_LOAD",
  GET_GENRE_LIST_SUCCESS: "GET_GENRE_LIST_SUCCESS",
  GET_GENRE_LIST_FAILED: "GET_GENRE_LIST_FAILED",
  GET_GENRE_LIST_ERROR: "GET_GENRE_LIST_ERROR",
};

export default Types;
