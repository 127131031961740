import React, { useState } from "react";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import AppColors from "../themes/appColors";
import "./customComponentStyle.css";
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  cssLabel: {
    color: AppColors.labelColor,
    "&.Mui-focused": {
      color: AppColors.labelColor,
    },
    fontSize: 14,
    fontWeight: 600,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppColors.mainColor,
    },
  },
});
const TextInput = (props) => {
  const {
    id,
    error,
    helperText,
    placeholder,
    name,
    onBlur,
    onChange,
    type,
    value,
    inputProps,
    maxLength,
    onInput,
    autoComplete,
    disabled,
    multiline,
    securePassword,
    editValue,
    onKeyPress,
    onCopy,
    onPaste,
    onKeyDown,
    style,
    InputProps,
    min,
    addName
  } = props;

  const classes = useStyles();
  // const [value, setValue] = useState(editValue);
  // console.log("value", value)
  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <TextField
        // placeholder={placeholder}
        error={error}
        style={style}
        noValidate
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        label={placeholder}
        type={type}
        className={`input-Style ${addName} `}
        id={id}
        min={min}
        onKeyPress={onKeyPress}
        onInput={onInput}
        inputProps={inputProps}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onCopy={onCopy}
        onPaste={onPaste}
        disabled={disabled}
        InputProps={InputProps}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },

        }}
      />
      {/* </ThemeProvider> */}
    </>
  );
};

export default TextInput;
