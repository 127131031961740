import { tr } from "date-fns/locale";
import { actionChannel } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import cl from "../../../utils/cl";

const initialState = {
  onLoad: false,
  errorState: null,
  concertData: [],
  concertDataById: [],
  concertDataTotalCount: 0,
  concertDataCurrentPage: 1,
  bandDataNextPage: 0,
  inProgress: false,
};

export const bandConcertReducer = (state = initialState, action) => {
  switch (action.type) {
    // all concert
    case types.BAND_API_FETCH_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_CONCERT_SUCCESS:
      console.log("concert reducer hits ==> ", action);

      state.concertData = action.result.data;
      state.concertDataTotalCount = action.result.pagination.totalCount;
      state.concertDataCurrentPage = action.result.pagination.currentPage;

      return { ...state, onLoad: false };

    // today Concert
    case types.BAND_API_FETCH_TODAY_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_TODAY_CONCERT_SUCCESS:
      return { ...state, todayConcert: action.result, onLoad: false };

    // past concert
    case types.BAND_API_FETCH_PAST_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_PAST_CONCERT_SUCCESS:
      return { ...state, pastConcert: action.result, onLoad: false };

    // upcoming concert
    case types.BAND_API_FETCH_UPCOMING_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_UPCOMING_CONCERT_SUCCESS:
      return { ...state, upcomingConcert: action.result, onLoad: false };

      //fetch unapproved concert

          // upcoming concert
    case types.BAND_API_FETCH_UNAPPROVED_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_UNAPPROVED_CONCERT_SUCCESS:
    console.log("Unapproved concert ----> ", action.result);
      return { ...state, concertData: action.result, onLoad: false };



    // add band concert
    case types.BAND_API_ADD_CONCERT_LOAD:
      return { ...state, onLoad: true, inProgress: true };
    case types.BAND_API_ADD_CONCERT_SUCCESS:
      console.log("add concert reducer", action);
      return { ...state, onLoad: false, inProgress: false };

    // fetch band concert by id
    case types.BAND_API_GET_CONCERT_BY_ID_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_GET_CONCERT_BY_ID_SUCCESS:
      console.log("fetch concert by id reducer", action);
      return { ...state, concertDataById: action.result, onLoad: false };

    // edit concert
    case types.BAND_API_EDIT_CONCERT_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_EDIT_CONCERT_SUCCESS:
      console.log("edit concert reducer", action);
      return { ...state, onLoad: false };

    //failed case
    case types.BAND_API_CONCERT_FAILED:
      return { ...state, onLoad: true, inProgress: false };

    // error case
    case types.BAND_API_CONCERT_ERROR:
      return { ...state, onLoad: false, inProgress: false };

    default:
      return state;
  }
};
