import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import sessionStorage from "../utils/localStorage";
import { Modal, Button as AntButton, Spin } from 'antd';
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Loader from './Spinner'

import "./customComponentStyle.css";



export default function ConfirmationModal(props) {

const state = useSelector((state) => state.bandAuthenticationReducer)
    const {
        resend,
        close, msg, modalState
    } = props;

    return (
        <div className="verify-modal" style={{
            // marginLeft:100
        }}>
            <Modal
                className="payment-request-modal-style verify-modal"
                title="Verify Email"
                visible={modalState}
                onOk={resend}
                onCancel={close}
                style={{ marginTop: 0, position: "absolute" }}
     
            >
                <p>{msg}</p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <AntButton
                        onClick={close}
                        style={{ marginRight: 10 }}
                        type="primary"
                    >
                        Cancel
                    </AntButton>
                    <AntButton
                        onClick={resend}
                        type="primary"
                    >
                       {state?.loading ?  <Loader color="white" size ="15px" thick={5} />: "Resend"} 
                    </AntButton>
                </div>
            </Modal>

        </div>
    );
}
