import React, { useState, useEffect } from "react";
import { Table, Button as AntButton, Pagination, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import ConfirmationModal from "../../../customComponents/paymentConfirmationModal";
import Header from "../../../customComponents/Header";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import AppConstant from "../../../themes/appConstant";
import Button from "../../../customComponents/Button";
import AppImages from "../../../themes/appImages";
import { dateConvert, timeConvert } from "../../../utils/dateTimeConverter";
import "../../../customStyles/antdStyles.css";
import "../../../customComponents/dashboardLayout.scss";
import { getProductSaleList } from "../../../store/actions/admin";
import { getMediaUrl } from "../../../utils/helpers";

function onChange(pagination, filters, sorter, extra) {}

const ConcertTable = (props) => {
  const {
    data,
    history,
    dispatch,
    current,
    totalCount,
    onLoad,
    setPaginationPage,
    paginationPage,
    s
  } = props;
  const [spin, setSpin] = useState(true);

  const [sorting, setSorting] = useState({
    sortBy: null,
    sortOrder: null,
  });

  const listeners = (key) => ({
    onClick: () => tableSort(key),
  });

  function tableSort(key) {
    console.log("key", key);
    let sortBy = key;
    let sortOrder = null;
    if (sorting.sortBy !== key) {
      sortOrder = 1;
    } else if (sorting.sortBy === key && sorting.sortOrder === 1) {
      sortOrder = -1;
    } else if (sorting.sortBy === key && sorting.sortOrder === -1) {
      sortBy = sortOrder = 0;
    }
    setSorting({ sortBy, sortOrder });

    const params = {
      limit: 10,
      offset: paginationPage ? 10 * (paginationPage - 1) : 0,
      order: sortOrder || "",
      sortBy: sortBy || "",
      search : s || ""
    };

    dispatch(getProductSaleList(params));
  }

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "key",
      render: (text, record, index) => (
        <div style={{ minWidth: "50px" }}>
          <span>{(paginationPage - 1) * 10 + index + 1}</span>
        </div>
      ),
    },
    // {
    //   title: "Transaction ID",
    //   dataIndex: "concertName",
    //   sorter: {
    //     compare: (a, b) => a.name - b.name,
    //     multiple: 3,
    //   },
    //   onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
    //   render: (text, record) => (
    //     <div style={{ minWidth: "120px", maxWidth: "250px" }}>
    //       <span>{record?.concertName || "N/A"}</span>
    //     </div>
    //   ),
    // },
    {
      title: "Product Name",
      dataIndex: "itemName",
      sorter: {
        compare: (a, b) => a.itemName - b.itemName,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.itemName || "N/A"}</span>
        </div>
      ),
    },

    {
      title: "Product Image",
      dataIndex: "itemName",
      // sorter: {
      //   compare: (a, b) => a.itemImage - b.itemImage,
      //   multiple: 2,
      // },
      // onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <img
            src={
              record.itemImage
                ? getMediaUrl(record.itemImage)
                : AppImages.noImage
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = AppImages.noImage;
            }}
            alt=""
            style={{
              height: 50,
              width: 50,
              borderRadius: 30,
            }}
          />
        </div>
      ),
    },

    {
      title: "Product Type",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
        multiple: 2,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px", maxWidth: "250px" }}>
          <span>{record?.type || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Product Price",
      dataIndex: "itemPrice",
      sorter: {
        compare: (a, b) => a.itemPrice - b.itemPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{record?.itemPrice + " ROCK$" || 0}</span>
        </div>
      ),
    },

    {
      title: "Sale Count",
      dataIndex: "itempurchaseCount",
      sorter: {
        compare: (a, b) => a.itemPrice - b.itemPrice,
        multiple: 1,
      },
      onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
      render: (text, record) => (
        <div style={{ minWidth: "120px" }}>
          <span>{record?.itempurchaseCount}</span>
        </div>
      ),
    },
    // {
    //   title: "Total Earning",
    //   dataIndex: "totalEarning",
    //   sorter: {
    //     compare: (a, b) => a.totalEarning - b.totalEarning,
    //     multiple: 1,
    //   },
    //   onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
    //   render: (text, record) => (
    //     <div style={{ minWidth: "120px" }}>
    //       <span> {record?.totalEarning || "0"}</span>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Total Price",
    //   dataIndex: "totalDuration",
    //   sorter: {},
    //   onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
    //   render: (text, record) => (
    //     <div style={{ minWidth: "150px" }}>
    //       <span>{record?.totalDuration || "N/A"}</span>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Total Price",
    //   dataIndex: "ticketPrice",
    //   sorter: {
    //     compare: (a, b) => a.ticketPrice - b.ticketPrice,
    //     multiple: 1,
    //   },
    //   onHeaderCell: ({ dataIndex }) => listeners(dataIndex),
    //   render: (text, record) => (
    //     <div style={{ minWidth: "120px" }}>
    //       <span>${record?.ticketPrice + record?.commission || "0"}</span>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",

    //   render: (text, record) => {
    //     return (
    //       <div className="action-style">
    //         {/* <AntButton
    //           className="action-button-inline-style"
    //           type="primary"
    //           onClick={() =>
    //             history.push("/concertDetails", { id: record._id })
    //           }
    //         >
    //           <span style={{ fontWeight: 600, marginTop: 2 }}>VIEW</span>
    //         </AntButton> */}
    //       </div>
    //     );
    //   },
    // },
  ];

  // useEffect(() => {
  //   console.log("spinner state ====> ", spin, data);
  //   if (data.length > 0) {
  //     setSpin(false);
  //   } else {
  //     setSpin(true);
  //   }
  // }, [data]);
  return (
    <>
      <div className="">
        <Table
          className="table-responsive user-table-style"
          columns={columns}
          dataSource={data}
          onChange={onChange}
          pagination={false}
          showSorterTooltip={false}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: onLoad ? true : false,
          }}
          rowKey={(record) => record._id}
        />
        <div className="pagination-style">
          <Pagination
            className="pagination"
            total={totalCount}
            onChange={(page) => {
              setPaginationPage(page);
              dispatch(
                getProductSaleList({ limit: 10, offset: (page - 1) * 10, search:s,order: sorting.sortOrder, sortBy: sorting.sortBy  })
              );
            }}
            current={current}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
};

const ProductSale = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [onPayStatus, setOnPayStatus] = useState(false);
  const [s, setS] = useState("");

  
  const [paginationPage, setPaginationPage] = useState(1);

  const [Id, setId] = useState();

  const breadData = [
    {
      name: "Product Sale Analytics",
      route: "/productSales",
    },
    // {
    //   name: "Approved Concerts",
    //   route: "",
    // },
  ];
  const classes = useStyles();
  const { appState } = useSelector((state) => state);
  const { sidebarDrawerToggleState } = appState;
  const { productSaleData, onLoad, onPaidLoad, paginationData } = useSelector(
    (state) => state.ProductSaleReducer
  );

  useEffect(() => {
    document.title = AppConstant.virtualConcertproductSale;
    // dispatch(clearPreviousReducerData());
    dispatch(getProductSaleList({ limit: 10, offset: 0 }));
    // dispatch(approvedConcertAction({ limit: 10, offset: 0 }));
  }, []);

  return (
    <>
      <Header data={breadData} title={AppConstant.shop} />
      <SideNav />
      {/* <ConfirmationModal
        title="Alert"
        visible={modalState}
        closeModal={() => {
          setModalState(false);
        }}
        setVisible={() => {
          setModalState(!modalState);
          dispatch(approvedConcertPaymentStatusAction(Id));
          setOnPayStatus(true);
        }}
        text="Are you sure you want to pay for this Concert?"
      /> */}

      {/* <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <Button
          title={AppConstant.UnapprovedConcert}
          bg
          mb
          onClick={() => {
            history.push("/concertManagement");
          }}
        />
      </div> */}

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard
             value={s}
              title="Product Sale Analytics"
              onChange={(event) => {
                setPaginationPage(1)
                setS(event.target.value)
                console.log("serach val --> ", event.target.value);
                if(event.target.value[0] === " " ){
                }else{ 

                  dispatch(
                    getProductSaleList({
                      limit: 10,
                      offset: 0,
                      search: event.target.value,
            
                    })
                  );
                }

         
              }}
            >
              <ConcertTable
                history={history}
                data={productSaleData}
                dispatch={dispatch}
                current={paginationData?.currentPage}
                totalCount={paginationData?.totalCount}
                onLoad={onLoad}
                setModalState={setModalState}
                setId={setId}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
                s={s}
              />
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSale;
