import { takeLatest, call, put, delay } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";
import Types from "../../actionTypes/index";
import * as api from "../../Api";
import { toast } from "react-toastify";
import history from "../../../utils/history";

function* userManagementFailedSaga(result) {
  yield put({
    type: types.API_GET_USER_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* userManagementErrorSaga(result) {
  yield put({
    type: types.API_GET_USER_MANAGEMENT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* userManagementSaga(action) {
  const { sortBy, order, offset, limit, search } = action.params;
  console.log("search --> ", search);
  const result = yield call(api.userManagement, {
    sortBy,
    order,
    offset,
    limit,
    search,
  });
  try {
    if (result.status === 1) {
      yield put({
        type: types.API_GET_USER_MANAGEMENT_SUCCESS,
        result: result.result.data,
      });
    } else {
      // yield put(types.API_GET_USER_MANAGEMENT_FAILED);
      userManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put(types.API_USER_MANAGEMENT_ERROR);
    userManagementErrorSaga(e);
  }
}

// fetch user by id

export function* getUserByIdSaga(action) {
  const result = yield call(api.getUserById, action.id);
  try {
    if (result.status === 1) {
      yield put({
        type: types.API_GET_USER_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 8) {
      history.push("/userManagement");
    } else {
      // yield put(types.API_GET_USER_BY_ID_FAILED);
      userManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put(types.API_GET_USER_BY_ID_ERROR);
    userManagementErrorSaga(e);
  }
}

// change block status for user
export function* changeUserBlockStatusSaga(action) {
  const { userId, isBlock } = action;
  const result = yield call(api.ChangeUserStatus, userId, isBlock);
  try {
    if (result.status === 1) {
      toast.success(result.result.data.message);
      // setTimeout(() => {}, 1500);
      yield delay(1800);
      yield put({
        type: types.API_CHANGE_USER_STATUS_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
      // history.push("/dashboard");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put(types.API_GET_USER_BY_ID_FAILED);
      userManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put(types.API_GET_USER_BY_ID_ERROR);
    userManagementErrorSaga(e);
  }
}

// update user by id

export function* updateUserByIdSaga(action) {
  console.log("result in saga --> ", action);
  const result = yield call(api.updateUserById, action);
  try {
    if (result.status === 1) {
      yield put({
        type: types.API_UPDATE_USER_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
      history.push("/userManagement");
      toast.success(result.result.data.message);
    } else {
      // yield put(types.API_UPDATE_USER_BY_ID_FAILED);
      userManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put(types.API_UPDATE_USER_BY_ID_ERROR);
    userManagementErrorSaga(e);
  }
}

// get users predefined images
export function* getUserPredefinedImagesSaga() {
  const result = yield call(api.getUserPredefinedImages);
  try {
    if (result.status === 1) {
      yield put({
        type: types.API_GET_USER_PREDEFINED_IMAGES_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 8) {
      history.push("/userManagement");
    } else {
      userManagementFailedSaga(result);
    }
  } catch (e) {
    userManagementErrorSaga(e);
  }
}

export function* getGenreListSaga() {
  const result = yield call(api.getGenreList);
  try {
    if (result.status === 1) {
      yield put({
        type: Types.GET_GENRE_LIST_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 8) {
      // history.push("/userManagement");
    } else {
      userManagementFailedSaga(result);
    }
  } catch (e) {
    userManagementErrorSaga(e);
  }
}


// add rocks To user

export function* addRocksToUserSaga(action) {
  const result = yield call(api.addRocksToUser, action.values);
  try {
    if (result.status === 1) {
      yield put({
        type: types.API_ADD_ROCKS_TO_USER_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message);

      yield put({
        type: "REHIT", value: true
      })

    } else if (result.status === 8) {
      // history.push("/userManagement");
    } else {
      userManagementFailedSaga(result);
    }
  } catch (e) {
    userManagementErrorSaga(e);
  }
}


export default function* rootUserManagementSaga() {
  yield takeLatest(types.API_GET_USER_MANAGEMENT_LOAD, userManagementSaga);
  yield takeLatest(types.API_GET_USER_BY_ID_LOAD, getUserByIdSaga);
  yield takeLatest(types.API_UPDATE_USER_BY_ID_LOAD, updateUserByIdSaga);
  yield takeLatest(
    types.API_CHANGE_USER_STATUS_LOAD,
    changeUserBlockStatusSaga
  );
  yield takeLatest(
    types.API_GET_USER_PREDEFINED_IMAGES_LOAD,
    getUserPredefinedImagesSaga
  );
  yield takeLatest(Types.GET_GENRE_LIST_LOAD, getGenreListSaga);
  yield takeLatest(types.API_ADD_ROCKS_TO_USER_LOAD, addRocksToUserSaga);

}
