import React, { Fragment, useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker as MDatePicker,
} from "@material-ui/pickers";
import { Input, TextFieldProps, TextField } from "@material-ui/core";
// import EventIcon from '@material-ui/icons/Event';
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import AppColors from "../themes/appColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faClock, faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import "./customComponentStyle.css";

import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  cssLabel: {
    color: AppColors.labelColor,
    "&.Mui-focused": {
      color: AppColors.labelColor,
    },
    fontSize: 14,
    fontWeight: 600,
  },
  pickerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end !important",
  },
  timeIcon: {
    position: "absolute",
    right: 0,
    height: 18,
    width: "18px !important",
    bottom: 6,
    color: "#707070",
  },
  selectInput: {
    width: "100%",
    cursor: "pointer",
  },
}));

function DatePicker(props) {
  const {
    open,
    onOpen,
    onClose,
    onChange,
    val,
    disablePast,
    onAccept,
    shouldDisableDate,
    disableBackdropClick,
    type,
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(val);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: AppColors.mainColor,
      },
    },
    outline: "none",
  });

  return (
    <Fragment>
      {/* <ThemeProvider theme={theme}> */}
      <div className={classes.pickerWrapper}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <MDatePicker
            disabled={props.disable}
            readOnly={props.readOnly}
            label={props.title}
            autoOk={true}
            showTodayButton={true}
            // value={val}
            onChange={onChange}
            // disableBackdropClick={disableBackdropClick}
            value={val}
            type={type}
            disablePast={disablePast}
            open={open}
            onAccept={onAccept}
            shouldDisableDate={shouldDisableDate}
            // inputValue={inputValue}
            inputMode="none"
            // onBeforeInput={onOpen}
            format="DD-MM-YYYY"
            // onChange={(date,value)=> console.log("date ===>",date)}
            className={clsx([
              classes.selectInput,
              classes.timePicker,
              "datePickerPointer",
            ])}
            InputProps={{
              // disabled:true
              // onChange:()=>{},
              onClick: onOpen,
            }}
            onOpen={onOpen}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            onClose={onClose}
          />
        </MuiPickersUtilsProvider>
        <FontAwesomeIcon
          icon={faCalendarDay}
          className={classes.timeIcon}
          onClick={onOpen}
        />
      </div>
      {/* </ThemeProvider> */}
    </Fragment>
  );
}

export default DatePicker;
