const AppColors = {
  mainColor: "#F58020",
  lightGrey: "#AAAAAA",
  dangerColor: "#f55a4e",
  labelColor: "#aaaaaa",
  headerIcon: "#555555",
  white: "white",
};

export default AppColors;
