import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { genreJson } from "../../../mocks/genreJson";
import SideNav from "../../../customComponents/SideNav";
import Button from "../../../customComponents/Button";
import NavCard from "../../../customComponents/NavCard";
import TextInput from "../../../customComponents/InputField";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import { toast } from "react-toastify";
import { ValidationConstants } from "../../../themes/validationConstants";

import { Tooltip, Spin, Select } from "antd";
import {
  updateBandByIdAction,
  getBandByIdAction,
} from "../../../store/actions/admin/bandManagementAction";
import "../trailerVideo/trailer.css";
import { getMediaUrl } from "../../../utils/helpers";
import * as Yup from "yup";
import { Formik } from "formik";
import { getGenreList } from "../../../store/actions";

const breadData = [
  {
    name: "Band Management",
    route: "/bandManagement",
  },

  {
    name: "Edit Band",
    route: "/editBand",
  },
];

const EditBand = (props) => {
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const { bandByIdData, onLoad } = useSelector(
    (state) => state.bandManagementReducer
  );

  const { genreListData } = useSelector((state) => state.userManagementReducer);
  const { Option } = Select;

  const [updateImage, setUpdateImage] = useState(null);
  const id = props?.location?.state?.editData._id;
  const history = useHistory();
  const [editImage, setEditImage] = useState(null);
  const [userSelectImage, setUserSelectImage] = useState(null);

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const [emailAddress, setEmailAddress] = useState(bandByIdData.bandEmail);
  const [showEditImage, setEditImageUrl] = useState(null);
  const [name, setName] = useState(bandByIdData?.bandName);
  const [paypal, setPaypal] = useState(bandByIdData?.paypalId);
  const [type, setType] = useState(bandByIdData.type);

  const [pickerGenre, setPickerGenre] = useState([]);

  const pickerHandleChange = (val) => {


    setPickerGenre(val);
  };


  const values = {
    name: name,
    email: emailAddress,
    paypalId: paypal,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(2, "Name should be at least 2 characters long.")
      .required(ValidationConstants.bandNameRequired)
      .matches(/^[aA-zZ\d\s]+$/, "Enter alphanumeric values only."),
    contactName: Yup.string()
      .trim()
      .min(2, "Contact name should be at least 2 characters long.")
      .required(ValidationConstants.contactNameRequired)
      .matches(/^[aA-zZ\d\s]+$/, "Enter alphanumeric values only."),
    email: Yup.string()
      .min(8, "Email should be at least 8 characters long.")
      .email(ValidationConstants.email.invalid)
      .max(255)
      .required(ValidationConstants.email.empty),
  });

  const disableType = () => {
    // e.replace(e,"")
    document.getElementById("stopType").readOnly = true;
    document.getElementById("stopType").maxLength = 0;
  };

  useEffect(() => {
    disableType();
    document.title = AppConstant.virtualConcertEditBand;
    if (id) {
      dispatch(getBandByIdAction(id));
      dispatch(getGenreList());


    } else {
      history.push("/bandManagement");
    }
  }, []);

  useEffect(() => {
    values.name = bandByIdData.bandName;
    values.email = bandByIdData.bandEmail;
    values.contactName = bandByIdData.contactName;
    values.paypalId = bandByIdData.paypalId;
    setType(bandByIdData.type);
    if (bandByIdData.bandLogo) {
      setEditImage(bandByIdData.bandLogo);
    }
    if (bandByIdData?.type) {
      setPickerGenre(bandByIdData?.type?.map((item) => item?._id));
    }
  }, [bandByIdData]);

  const onSubmit = (values) => {
    let formData = new FormData();
    if (pickerGenre.length > 0) {
      formData.append("bandName", values.name);
      formData.append("contactName", values.contactName);
      formData.append("bandEmail", values.email);
      formData.append("paypalId", values.paypalId);
      formData.append("type", JSON.stringify(pickerGenre));
      formData.append("upload", updateImage);

      dispatch(updateBandByIdAction(formData, id));
    } else {
      toast.error("Please select at least one genre type.");
    }
  };

  const selectHeroImage = () => {
    const hero_logo = document.getElementById("select_hero_image");
    hero_logo.setAttribute("type", "file");
    hero_logo.setAttribute("accept", "image/*");

    if (hero_logo) {
      hero_logo.click();
    }
  };




  const setHeroSelectImage = (data) => {
    if (data?.files[0] !== undefined) {
      let file = data.files[0];
      const extentType = ["png", "jpg", "jpeg"];
      const extent = file && file?.name.split(".");
      const extentT = extent && extent[extent.length - 1];
      let file_size = file && file?.size;
      let max_size = 1024000;
      const imgData = URL.createObjectURL(data.files[0]);
      let checkSize = file_size / max_size <= 10 ? true : false;

      var aspr;
      var img = document.createElement("img");
      var blob = URL.createObjectURL(file);
      img.src = blob;
      if (extentType.indexOf(extentT) >= 0) {
      img.onload = async function () {
        var w = img.width;
        var h = img.height;



        // aspr = await ratio(w, h)

        let ratioCalculate = w / h;
        let roundUpValue = ratioCalculate.toFixed(1);


        // if (aspr == '16:9') {
        // if (roundUpValue == 1.78) {

        const imgData = URL.createObjectURL(data.files[0]);
        let checkSize = file_size / max_size <= 10 ? true : false;
          if (checkSize) {
            if (roundUpValue.toString().includes("1.4")) {
              setUpdateImage(file);
              setEditImageUrl(imgData);
              setUserSelectImage(imgData);
              setEditImage(imgData);
            } else {
              toast.error(" Only 7:5 ratio images are allowed.");
            }
          } else {
            toast.error("Maximum 10 MB size images are allowed");
          }
        } 
      }
      else{
        toast.error("Please select image only.");
      }
    }
  };

  return (
    <>
      <Header data={breadData} title={AppConstant.BandManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Edit Band" noSearch>
              {onLoad ? (
                <div
                  className="user-Details-Box"
                  style={{
                    height: 400,
                    width: 400,
                    marginTop: 80,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <div className="edit-user-table-style">
                  <div className="row bottom-margin-30">
                    <div
                      className="col-sm-5 center-style band-logo-responsive"
                      style={{ padding: 0 }}
                    >
                      <span className="span-title-style">
                        {AppConstant.BandLogo} (7:5 ratio, PNG or JPEG)
                      </span>
                    </div>
                    <div className="col-3">
                      <Tooltip
                        placement="topLeft"
                        title="Click to change image"
                      >
                        <div
                          onClick={selectHeroImage}
                          className="upload-edit-user-image"
                          style={{ display: "flex" }}
                        >
                          {editImage ? (
                            <img
                              src={
                                showEditImage
                                  ? showEditImage
                                  : editImage
                                  ? getMediaUrl(editImage)
                                  : AppImages.BandDummyImage
                              }
                              alt="avatar"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = AppImages.noImage;
                              }}
                              className="pointer-Style"
                            />
                          ) : (
                            <img
                              src={AppImages.Upload}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = AppImages.noImage;
                              }}
                              style={{
                                height: 20,
                                width: 20,
                                position: "absolute",
                              }}
                            />
                          )}

                          <input
                            style={{ height: "100%", width: "100%" }}
                            className="d-none"
                            type="file"
                            placeholder="Basic usage"
                            id="select_hero_image"
                            onChange={(evt) => {
                              setHeroSelectImage(evt.target);
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <Formik
                    initialValues={values}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                    }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <div
                          className=" row bottom-margin-30"
                          style={{ marginTop: 30 }}
                        >
                          <TextInput
                            placeholder="Name"
                            value={values?.name?.trimLeft()}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            inputProps={{
                              maxLength: 30,
                            }}
                          />
                        </div>

                        <div className=" row bottom-margin-30">
                          <TextInput
                            placeholder="Contact Name"
                            value={values?.contactName?.trimLeft()}
                            onChange={handleChange}
                            error={Boolean(
                              touched.contactName && errors.contactName
                            )}
                            helperText={
                              touched.contactName && errors.contactName
                            }
                            name="contactName"
                            onBlur={handleBlur}
                            type="contactName"
                            fullWidth
                            inputProps={{
                              maxLength: 30,
                            }}
                          />
                        </div>

                        <div className=" row bottom-margin-30">
                          <TextInput
                            placeholder="Paypal ID"
                            value={values.paypalId}
                            onChange={handleChange}
                            error={Boolean(touched.paypalId && errors.paypalId)}
                            helperText={touched.paypalId && errors.paypalId}
                            name="paypalId"
                            onBlur={handleBlur}
                            disabled={true}
                            fullWidth
                            inputProps={{
                              maxLength: 100,
                            }}
                          />
                        </div>

                        <div className=" row bottom-margin-30">
                          <TextInput
                            placeholder="Email Address"
                            value={values.email}
                            onChange={handleChange}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            name="email"
                            onBlur={handleBlur}
                            type="email"
                            disabled={true}
                            fullWidth
                            inputProps={{
                              maxLength: 100,
                            }}
                          />
                        </div>

                        <div
                          className=""
                          style={{ marginBottom: 30, marginTop: 15 }}
                        >
                          <div>
                            <span className="span-title-style">Genre(s)</span>
                          </div>
                          <div className="" style={{ marginTop: 10 }}>
                            {/* <Select
                              mode="multiple"
                              allowClear
                              name="genre"
                              style={{ width: "100%" }}
                              placeholder="Select Genre"
                              value={[]}
                              onChange={pickerHandleChange}
                            >
                              {genreListData?.map((item, index) => (
                                <Option key={item?._id}>{item?.genreName}</Option>
                              ))}
                            </Select> */}

                            <Select
                              mode="multiple"
                              allowClear
                              id="stopType"
                              name="genre"
                              style={{ width: "100%", caretColor: "white" }}
                              onSearch={(e) => {
                                disableType();
                              }}
                              placeholder="Select Genre"
                              value={pickerGenre}
                              onChange={pickerHandleChange}
                            >
                              {genreListData?.map((item, index) => (
                                <Option key={item?._id}>
                                  {item?.genreName}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>

                        <div className="user-update-button">
                          <Button
                            title={AppConstant.Update}
                            bg
                            mb
                            wd
                            type="submit"
                            mt20
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </NavCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBand;
