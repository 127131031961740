import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types } from "../../../themes/apiConstants";

import { toast } from "react-toastify";
import history from "../../../utils/history";
import * as api from "../../Api";

function* newsManagementFailedSaga(result) {
  yield put({
    type: types.API_GET_NEWS_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* newsManagementErrorSaga(result) {
  yield put({
    type: types.API_GET_NEWS_MANAGEMENT_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* newsManagementSaga(action) {
  const { sortBy, order, offset, limit, search } = action.params;
  try {
    const result = yield call(api.newsManagement, {
      sortBy,
      order,
      offset,
      limit,
      search,
    });

    if (result.status === 1) {
      yield put({
        type: types.API_GET_NEWS_MANAGEMENT_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      toast.error(result.error.response.data.message);

      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_FAILED });
      newsManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put({ type: types.API_GET_NEWS_MANAGEMENT_ERROR });
    newsManagementErrorSaga(e);
  }
}

// get news by id
// fetch band by id

export function* getNewsByIdSaga(action) {
  try {
    const result = yield call(api.getNewsById, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_GET_NEWS_BY_ID_SUCCESS,
        result: result.result.data.data,
      });
    } else if (result.status === 3) {
      console.log(
        "error in auth saga ==> ",
        result.error.response.data.message
      );
      // toast.error(result.error.response.data.message);
      // history.push("/login");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else if (result.status === 8) {
      history.push("/newsManagement");
    } else {
      // yield put({ type: types.API_GET_NEWS_BY_ID_FAILED });
      newsManagementFailedSaga(result);
    }
  } catch (e) {
    newsManagementErrorSaga(e);
    // yield put({ type: types.API_GET_NEWS_BY_ID_ERROR });
  }
}

//add news by id

export function* addNewsSaga(action) {
  try {
    const result = yield call(api.addNews, action.values);

    if (result.status === 1) {
      yield put({
        type: types.API_ADD_NEWS_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
      yield put({ type: types.ADD_NEWS_CLEAR_PREVIOUS_REDUCER_DATA });
      history.push("/newsManagement");
    } else if (result.status === 3) {
      toast.error(result.error.response.data.message, {
        toastId: result?.status || "est",
      });
      yield put({
        type: types.API_GET_NEWS_MANAGEMENT_FAILED,
      });
      // history.push("/dashboard");
      // toast.error(result?.error, { toastId: result?.status || "est" });
    } else {
      // yield put({ type: types.API_ADD_NEWS_FAILED });
      newsManagementFailedSaga(result);
    }
  } catch (e) {
    // yield put({ type: types.API_ADD_NEWS_ERROR });
    newsManagementErrorSaga(e);
  }
}

// delete news by id

export function* deleteNewsSaga(action) {
  try {
    const result = yield call(api.deleteNews, action.id);

    if (result.status === 1) {
      yield put({
        type: types.API_DELETE_NEWS_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
      history.push("/newsManagement");
    } else {
      newsManagementFailedSaga(result);
      // yield put({ type: types.API_DELETE_NEWS_FAILED });
    }
  } catch (e) {
    // yield put({ type: types.API_DELETE_NEWS_ERROR });
    newsManagementErrorSaga(e);
  }
}

//delete news media

export function* deleteNewsMediaSaga(action) {
  try {
    const result = yield call(api.deleteNewsMedia, action.values);

    if (result.status === 1) {
      console.log("success", result);
      yield put({
        type: types.API_DELETE_NEWS_MEDIA_SUCCESS,
        result: result.result.data,
      });

      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_LOAD });
      // history.push("/editNews");
    } else {
      newsManagementFailedSaga(result);
      // yield put({ type: types.API_DELETE_NEWS_FAILED });
    }
  } catch (e) {
    // yield put({ type: types.API_DELETE_NEWS_ERROR });
    newsManagementErrorSaga(e);
  }
}

//delete news media

export function* addNewsMediaSaga(action) {
  try {
    const result = yield call(api.addNewsMedia, action.values);

    if (result.status === 1) {
      console.log("success", result);
      yield put({
        type: types.API_ADD_NEWS_MEDIA_SUCCESS,
        result: result.result.data,
      });
      // yield put({ type: types.API_GET_NEWS_MANAGEMENT_LOAD });
      // history.push("/editNews");
    } else {
      newsManagementFailedSaga(result);
      // yield put({ type: types.API_DELETE_NEWS_FAILED });
    }
  } catch (e) {
    // yield put({ type: types.API_DELETE_NEWS_ERROR });
    newsManagementErrorSaga(e);
  }
}

// update news by id

export function* updateNewsSaga(action) {
  const { values, id } = action;
  try {
    const result = yield call(api.updateNews, action);

    if (result.status === 1) {
      yield put({
        type: types.API_UPDATE_NEWS_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message);
      history.push("/newsManagement");
    } else {
      newsManagementFailedSaga(result);
      // yield put({ type: types.API_UPDATE_NEWS_FAILED });
    }
  } catch (e) {
    // yield put({ type: types.API_UPDATE_NEWS_ERROR });
    newsManagementErrorSaga(e);
  }
}

export default function* rootNewsManagementSaga() {
  yield takeLatest(types.API_GET_NEWS_MANAGEMENT_LOAD, newsManagementSaga);
  yield takeLatest(types.API_ADD_NEWS_LOAD, addNewsSaga);
  yield takeLatest(types.API_UPDATE_NEWS_LOAD, updateNewsSaga);
  yield takeLatest(types.API_DELETE_NEWS_LOAD, deleteNewsSaga);
  yield takeLatest(types.API_GET_NEWS_BY_ID_LOAD, getNewsByIdSaga);
  yield takeLatest(types.API_DELETE_NEWS_MEDIA_LOAD, deleteNewsMediaSaga);
  yield takeLatest(types.API_ADD_NEWS_MEDIA_LOAD, addNewsMediaSaga);
}
