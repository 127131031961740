import SignIn from "../Band/components/authentication/login";
import ForgotPassword from "../Band/components/authentication/forgotPassword";
import ChangePassword from "../Band/components/authentication/changePassword";
import Registration from "../Band/components/authentication/registration";
import Dashboard from "../Band/components/dashboard/dashboard";
import Profile from "../Band/components/myProfile/Profile";
import EditProfile from "../Band/components/myProfile/editProfile";
import AllConcerts from "../Band/components/concerts/allConcerts";
import ViewConcert from "../Band/components/concerts/viewConcert";
import EditConcert from "../Band/components/concerts/editConcert";
import AddConcert from "../Band/components/concerts/addConcert";
import TermsAndCondition from "../Band/components/termsAndCondition/termsAndCondition";
import Faq from "../Band/components/faq/faq";
import RegistrationTermsAndConditions from "../Band/components/authentication/registrationTermsAndConditions";
import RegistrationPopUp from "../Band/components/authentication/registrationPopUp";
import BookingManagement from "../Band/components/Booking/bookingManagement";
import PaymentRequest from "../Band/components/paymentRequest/paymentRequest";
import SupportManagement from '../Band/components/supportManagement/support'
export const routeConfig = [
  {
    path: "/band/login",
    component: SignIn,
    exact: true,
  },

  // {
  //   path: "/login",
  //   component: SignIn,
  //   exact: true,
  // },

  {
    path: "/band/forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/band/registration",
    component: Registration,
  },
  {
    path: "/band/dashboard",
    component: Dashboard,
  },
  {
    path: "/band/profile",
    component: Profile,
  },
  {
    path: "/band/editProfile",
    component: EditProfile,
  },
  {
    path: "/band/changePassword",
    component: ChangePassword,
  },
  {
    path: "/band/paymentRequest",
    component: PaymentRequest,
  },
  {
    path: "/band/allConcerts",
    component: AllConcerts,
  },

  {
    path: "/band/unapprovedConcerts",
    component: AllConcerts,
  },

  {
    path: "/band/viewConcert",
    component: ViewConcert,
  },
  {
    path: "/band/editConcert",
    component: EditConcert,
  },
  {
    path: "/band/addConcert",
    component: AddConcert,
  },
  {
    path: "/band/termsAndConditions",
    component: TermsAndCondition,
  },
  {
    path: "/band/faq",
    component: Faq,
  },
  {
    path: "/band/termsAndCondition",
    component: RegistrationTermsAndConditions,
  },
  {
    path: "/band/registrationPopUp",
    component: RegistrationPopUp,
  },
  {
    path: "/band/bookingManagement",
    component: BookingManagement,
  },

  {
    path: "/band/support",
    component: SupportManagement,
  },

];
