import { apiConstants as types } from "../../../themes/apiConstants";

const initialState = {
  onLoad: false,
  error: null,
  featuredList: null,
  listHelpData: null,
  repliedListData: null,
  listHelpPagination: null,
  repliedListPagination: null,
  replyModalState: false,
  replySent: false,
  rehit: false,

  //   trailerDataTotalCount: 0,
  //   trailerDataCurrentPage: 1,
  //   trailerDataNextPage: 0,
  //   onDeleteTrailerReducer: false,
  //   checkboxErrorMessage: "",
};

export const FeaturedConcertReducer = (state = initialState, action) => {
  switch (action.type) {
    //FTECH LIST HELP
    case types.API_GET_FEATURED_CONCERTS_LIST_LOAD:
      return { ...state, onLoad: true };

    case types.API_GET_FEATURED_CONCERTS_LIST_SUCCESS:
      //   state.trailerDataTotalCount = action.result.pagination.totalCount;
      //   state.trailerDataCurrentPage = action.result.pagination.currentPage;

      return { ...state, featuredList: action.result.data, onLoad: false };

    // change reply modal state
    case "CAHNGE_REPLY_MODAL_STATE":
      return { ...state, replyModalState: action.value };

    case "REHIT_LIST":
      return { ...state, rehit: action.value };

    default:
      return state;
  }
};
