import { takeLatest, call, put } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import * as api from "../../Api";
import { toast } from "react-toastify";
import cl from "../../../utils/cl";

function* faqFailedSaga(result) {
  cl("FAQ failed", result);
  yield put({
    type: types.BAND_API_FETCH_FAQ_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* faqErrorSaga(result) {
  cl("HAHAHAHAA you got error  ", result);
  yield put({
    type: types.BAND_API_FETCH_FAQ_ERROR,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

export function* getFaqSaga() {
  try {
    const result = yield call(api.getFaq);
    if (result.status === 1) {
      yield put({
        type: types.BAND_API_FETCH_FAQ_SUCCESS,
        result: result.result.data.data,
      });
    } else {
      yield call(faqFailedSaga, result);
    }
  } catch (error) {
    yield call(faqErrorSaga, error);
  }
}

export default function* rootFaqSaga() {
  yield takeLatest(types.BAND_API_FETCH_FAQ_LOAD, getFaqSaga);
}
