import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BandHeader from "../../../customComponents/BandHeader";
import { useStyles } from "../../../customComponents/style";
import BandSideNAv from "../../../customComponents/BandSideNav";
import { useHistory } from "react-router-dom";
import { getConcertDataByIdAction } from "../../../store/actions/Band";
import AppConstant from "../../../themes/appConstant";
import NavCard from "../../../customComponents/NavCard";
import Button from "../../../customComponents/Button";
import DatePicker from "../../../customComponents/DatePicker";
import TextInput from "../../../customComponents/InputField";
import TimePicker from "../../../customComponents/TimePicker";
import Loader from "../../../customComponents/loader";
import { getVideoUrl, convertTime, getMediaUrl } from "../../../utils/helpers";
import clsx from "clsx";
import "./concert.css";
import AppImages from "../../../themes/appImages";
import moment from "moment";
import {
  dateConvert,
  timeConvert,
  convertInMinutesSeconds,
} from "../../../utils/dateTimeConverter";
import { toast } from "react-toastify";
// import Iframely from "../../../customComponents/iframly";

let breadData = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "All Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Concert Details",
    route: "",
  },
];
let breadData2 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Past Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Concert Details",
    route: "",
  },
];
let breadData3 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Today's Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Concert Details",
    route: "",
  },
];
let breadData4 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Upcoming Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Concert Details",
    route: "",
  },
];

let breadData5 = [
  {
    name: "Concerts",
    route: "/band/allConcerts",
  },
  {
    name: "Unapproved Concerts",
    route: "/band/unapprovedConcerts",
  },
  {
    name: "Concert Details",
    route: "",
  },
];

const ViewConcert = (props) => {
  console.log("props of id ", props);
  const id = props?.location?.state?.id;
  const breadType = props?.location?.state?.breadType;
  const [Breadvalue, setValue] = useState(breadData);
  const [shouldEdit, setShouldEdit] = useState(true);
  const dispatch = useDispatch();
  const { concertDataById, onLoad } = useSelector(
    (state) => state.bandConcertReducer
  );
  const { value } = useSelector((state) => state.concertTabsState);

  const { appState } = useSelector((state) => state);

  const classes = useStyles();
  const { sidebarDrawerToggleState } = appState;
  const history = useHistory();

  console.log("record  ", concertDataById);
  useEffect(() => {
    // toast.dismiss()
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.title = AppConstant.virtualConcertViewConcert;

    if (id) {
      dispatch(getConcertDataByIdAction(id));
    } else {
      history.push("/band/allConcerts");
    }
  }, []);

  // cases for breadcrumb

  useEffect(() => {
    switch (value) {
      case 1:
        setValue(breadData2);
        return Breadvalue;

      case 2:
        setValue(breadData3);
        return Breadvalue;

      case 3:
        setValue(breadData4);
        return Breadvalue;

      case 4:
        setValue(breadData5);
        return Breadvalue;

      default:
        setValue(breadData);
        return Breadvalue;
    }
  }, [value]);

  // function for checking to show edit button or not
  console.log(concertDataById);
  const checkEdit = () => {
    let close = moment(new Date(), "DD-MM-YYYYThh:mm:ss A");
    let open = moment(concertDataById?.dateFrom);
    let diff = open.diff(close, "minute");
    console.log("diff", diff);
    if (diff > 1438 && concertDataById?.buyers?.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // function to render concert buyers list

  // const userListData = (data) => {
  //   if (data?.buyers?.length > 0) {
  //     return data?.buyers.map((item) => {
  //       return (
  //         <div
  //           className="row"
  //           style={{ alignItems: "center", marginBottom: 20, marginLeft: 0 }}
  //         >
  //           <img
  //             src={getMediaUrl(item?.user?.profileImage)}
  //             alt=""
  //             onError={(e) => {
  //               e.target.onerror = null;
  //               e.target.src = AppImages.noImage;
  //             }}
  //             style={{
  //               height: 40,
  //               width: 40,
  //               borderRadius: 25,
  //               marginRight: 10,
  //             }}
  //           />
  //           <span>{item?.user?.username}</span>
  //         </div>
  //       );
  //     });
  //   } else {
  //     return <span style={{ paddingTop: 15 }}>No Customer Available</span>;
  //   }
  // };

  // function to render concert details

  const ShowVideos = () => {
    return (
      concertDataById?.videos &&
      concertDataById?.videos?.map((item) => {
        return (
          <div
            className="col-sm center-style-items concert-detail-min-width"
            style={{ marginTop: 20, marginBottom: 30, marginLeft: -50 }}
          >
            <div className="col">
              <p style={{ paddingLeft: "50px" }}>
                Total duration time{"  "}
                {(item["duration"] / 60).toString().split(".")[0]}:
                {item["duration"] % 60}min
              </p>
              {/* <Iframely url={item.url} />
               */}
             <iframe
                src={`//www.youtube.com/embed/${getVideoUrl(item.url)}`}
                frameBorder={0}
                title="video"
                className=" center-style-items band-video-dimension"
                allowFullScreen={true}
              />

            </div>
          </div>
        );
      })
    );
  };

  return (
    <>
      <BandHeader data={Breadvalue} title={AppConstant.Dashboard} />
      <BandSideNAv />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard noSearch title="View Concert">
              <div className="user-Details-Box" style={{}}>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">
                      Concert Name (usually band/artist name)
                    </span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span>
                      {concertDataById?.concertName
                        ? concertDataById?.concertName
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row  detail-height1">
                  <div className="col-sm-6 center-style-items concert-detail-min-width">
                    <span className="span-title-style">Concert Image</span>
                  </div>

                  <div
                    className="col-sm center-style-items concert-detail-min-width"
                    // style={{ marginLeft: 80 }}
                  >
                    <img
                      src={
                        concertDataById?.concertImage
                          ? getMediaUrl(concertDataById?.concertImage)
                          : AppImages.noImage
                      }
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AppImages.noImage;
                      }}
                      className="concert-logo-image-Style"
                    />
                  </div>
                </div>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">Concert Type</span>
                  </div>

                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span>
                      {concertDataById?.concertType?.genreName || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">Concert Venue</span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width scrollable-div-style">
                    <span>{concertDataById?.concertVenue || "N/A"}</span>
                  </div>
                </div>
                {/* <div className="row  detail-height1">
                  <div
                    className="col-sm-6 center-style-items concert-detail-min-width"
                    style={{ marginTop: 0 }}
                  >
                    <span className="span-title-style">Sponsor Logo</span>
                  </div>

                  <div
                    className="col-sm center-style-items concert-detail-min-width"
                    // style={{ marginLeft: 80 }}
                  >
                    <img
                      src={
                        concertDataById?.sponserLogo
                          ? getMediaUrl(concertDataById?.sponserLogo)
                          : AppImages.noImage
                      }
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = AppImages.noImage;
                      }}
                      className="concert-logo-image-Style"
                    />
                  </div>
                </div>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">Sponsor Name</span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width scrollable-div-style">
                    <span>{concertDataById?.sponserName || "N/A"}</span>
                  </div>
                </div> */}
                <div className="col" style={{ padding: "0px" }}>
                  <div
                    // className="col-sm-6 center-style-items concert-detail-min-width"
                    style={{ marginTop: 20 }}
                  >
                    <span className="span-title-style">Videos</span>
                  </div>
                  {ShowVideos()}
                </div>
                <div className="row detail-height responsive-time">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">Start Date/Time</span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span style={{ marginRight: 5 }}>
                      {dateConvert(concertDataById?.dateFrom)}
                    </span>
                    {timeConvert(concertDataById?.dateFrom)}
                  </div>
                </div>
                <div className="row detail-height responsive-time">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">End Date/Time</span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span style={{ marginRight: 5 }}>
                      {dateConvert(concertDataById?.dateTo)}
                    </span>
                    {timeConvert(concertDataById?.dateTo)}
                  </div>
                </div>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">
                      Total Video Duration (Minutes) – Event / videos will
                      repeat to this duration.
                    </span>
                  </div>

                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span>
                      {convertInMinutesSeconds(
                        concertDataById?.totalDuration
                      ) || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row detail-height">
                  <div className="col-sm center-style-items concert-detail-min-width">
                    <span className="span-title-style">
                      Ticket Price (in USD that artist / band will receive)
                    </span>
                  </div>
                  <div className="col-sm center-style-items concert-detail-min-width scrollable-div-style">
                    <span>${concertDataById?.ticketPrice || "0"}</span>
                  </div>
                </div>
                {!concertDataById?.isApproved && checkEdit() && (
                  <div className="band-button" style={{ marginTop: 30 }}>
                    <Button
                      title={AppConstant.edit}
                      bg
                      // mb
                      wd
                      mt20
                      onClick={() => {
                        history.push("/band/editConcert", {
                          id,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </NavCard>
            <Loader visible={onLoad} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewConcert;
