import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, Spin, Modal } from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../../customComponents/Header";
import { useStyles } from "../../../customComponents/style";
import clsx from "clsx";
import AppConstant from "../../../themes/appConstant";
import AppImages from "../../../themes/appImages";
import SideNav from "../../../customComponents/SideNav";
import NavCard from "../../../customComponents/NavCard";
import TextInput from "../../../customComponents/InputField";
import SelectInput from "../../../customComponents/Select";
import {
  updateUserByIdAction,
  getUserByIdAction,
  getUserPredefinedImages,
  
} from "../../../store/actions/admin";
import Button from "../../../customComponents/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import "./user.css";
import { ValidationConstants } from "../../../themes/validationConstants";
import "../trailerVideo/trailer.css";
import { getMediaUrl } from "../../../utils/helpers";

const breadData = [
  {
    name: "User Management",
    route: "/userManagement",
  },
  {
    name: "Edit User",
    route: "",
  },
];

const EditUser = (props) => {
  const formRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const { appState } = useSelector((state) => state);
  const { userById, onLoad, userPredefinedImagesArray } = useSelector(
    (state) => state.userManagementReducer
  );
  const [updateImage, setUpdateImage] = useState();
  const id = props?.location?.state?.editData._id;
  const [editImage, setEditImage] = useState(null);

  const [emailAddress, setEmailAddress] = useState(userById?.emailId);
  const [name, setName] = useState(userById?.username);
  const [type, setType] = useState(userById?.gender);
  const [showEditImage, setEditImageUrl] = useState(null);
  const values = {
    name: name,
    email: emailAddress,
  };

  const renderPredefinedImagesModal = () => {
    return (
      <Modal
        className="modalScroll"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={550}
        centered
        bodyStyle={{
          height: "350px",
          overflowY: "scroll",
        }}
      >
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {userPredefinedImagesArray?.map((item) => {
            return (
              <>
                <div
                  style={{
                    height: 150,
                    width: 150,
                    margin: 20,
                    marginTop: 50,
                    borderRadius: 8,
                  }}
                  className="pointer-Style"
                  onClick={() => {
                    setEditImage(item.avatar);
                    setUpdateImage(item.avatar);
                    setIsModalVisible(false);
                  }}
                >
                  <img
                    alt=""
                    src={getMediaUrl(item.avatar)}
                    style={{ height: 150, width: 150, borderRadius: 8 }}
                  />
                </div>
              </>
            );
          })}
        </div>
      </Modal>
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(2, "Name should be at least 2 characters long.")
      .required(ValidationConstants.usernameRequired)
      .matches(/^[aA-zZ\d\-_\s]+$/, "Enter alphanumeric values only."),
    email: Yup.string()
      .min(8, "Email should be at least 8 characters long")
      .email(ValidationConstants.email.invalid)
      .max(255),
  });

  useEffect(() => {
    document.title = AppConstant.VirtualConcertEditUserDetail;

    if (id) {
      dispatch(getUserByIdAction(id));
      // dispatch(getGenreList())
    } else {
      history.push("/userManagement");
    }
    dispatch(getUserPredefinedImages());
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("name", userById?.username);
      formRef.current.setFieldValue("email", userById?.emailId);
    }

    setType(userById?.gender);
    if (userById?.profileImage) {
      setEditImage(userById?.profileImage);
    }
  }, [userById]);

  const classes = useStyles();

  const { sidebarDrawerToggleState } = appState;

  const onSubmit = (values) => {
    let formData = new FormData();

     if(updateImage){
     formData.append("image", updateImage);
          }

    formData.append("username", values.name);
    formData.append("emailId", values.email);
    formData.append("gender", type);
    
    dispatch(updateUserByIdAction(id, formData));
  };
  return (
    <>
      <Header data={breadData} title={AppConstant.UserManagement} />
      <SideNav />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarDrawerToggleState,
        })}
      >
        <div className="row">
          <div className="col-sm">
            <NavCard title="Edit User" noSearch>
              <>
                {onLoad ? (
                  <div
                    className="user-Details-Box"
                    style={{
                      height: 400,
                      width: 400,
                      marginTop: 80,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <div className="edit-user-table-style">
                    <div className="row bottom-margin ">
                      <div className="col-sm-5 center-style band-logo-responsive">
                        <span className="span-title-style">
                          Profile Picture
                        </span>
                      </div>
                      <div className="col-3">
                        <Tooltip
                          placement="topLeft"
                          title="Click to change image"
                        >
                          <div
                            onClick={() => {
                              setIsModalVisible(true);
                            }}
                            className="upload-edit-user-image"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {editImage ? (
                              <img
                                src={
                                  showEditImage
                                    ? showEditImage
                                    : editImage
                                    ? getMediaUrl(editImage)
                                    : AppImages.noImage
                                }
                                alt="avatar"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "50%",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = AppImages.noImage;
                                }}
                                className="pointer-Style"
                              />
                            ) : (
                              <img
                                className="pointer-Style"
                                src={AppImages.noImage}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = AppImages.noImage;
                                }}
                                style={{
                                  height: 120,
                                  width: 120,
                                  alignSelf: "center",
                                }}
                              />
                            )}

                            <input
                              style={{ height: "100%", width: "100%" }}
                              className="d-none"
                              type="file"
                              placeholder="Basic usage"
                              id="select_hero_image"
                              onChange={(evt) => {}}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>

                    <Formik
                      initialValues={values}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      innerRef={formRef}
                    >
                      {({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="row bottom-margin-30">
                            <TextInput
                              placeholder="Name"
                              value={values?.name?.trimLeft()}
                              onChange={handleChange}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              name="name"
                              onBlur={handleBlur}
                              fullWidth
                              inputProps={{
                                maxLength: 40,
                              }}
                            />
                          </div>

                          <div className="row bottom-margin-30">
                            <TextInput
                              id="editEmailAddress"
                              placeholder="Email Address"
                              disabled
                              value={values.email}
                              onChange={handleChange}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              name="email"
                              onBlur={handleBlur}
                              type="email"
                              fullWidth
                              inputProps={{
                                maxLength: 80,
                              }}
                            />
                          </div>
                          <SelectInput
                            title="Gender"
                            forGender
                            options={["Male", "Female"]}
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                          />
                          <div className="user-update-button">
                            <Button
                              title={AppConstant.Update}
                              bg
                              mb
                              wd
                              type="submit"
                            />
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}
              </>
            </NavCard>
          </div>
        </div>
      </div>

      {renderPredefinedImagesModal()}
    </>
  );
};

export default EditUser;
