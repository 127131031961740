import { apiConstants as types } from "../../../themes/apiConstants";

export const filteredBandListAction = () => {
  const action = {
    type: types.API_GET_FILTERED_BAND_LIST_LOAD,
  };
  return action;
};

export const concertEarningListAction = (params) => {
  const action = {
    type: types.API_GET_CONCERT_EARNING_LIST_LOAD,
    params,
  };
  return action;
};
