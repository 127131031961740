import { tr } from "date-fns/locale";
import { actionChannel } from "redux-saga/effects";
import { BandApiConstants as types } from "../../../themes/apiConstants";
import cl from "../../../utils/cl";

const initialState = {
  onLoad: false,
  errorState: null,
  faqData: [],
};

export const bandFaqReducer = (state = initialState, action) => {
  switch (action.type) {
    // fetch faq
    case types.BAND_API_FETCH_FAQ_LOAD:
      return { ...state, onLoad: true };
    case types.BAND_API_FETCH_FAQ_SUCCESS:
      return { ...state, faqData: action.result, onLoad: false };

    //failed case
    case types.BAND_API_FETCH_FAQ_FAILED:
      return { ...state, onLoad: true };

    // error case
    case types.BAND_API_FETCH_FAQ_ERROR:
      return { ...state, onLoad: false };

    default:
      return state;
  }
};
